import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormLabel, FormControl } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';

const EditPopup = ({valueName, updateQnA, ...props}) => {
    let ModalTitle = "";
    if(props.editType === "Question") {
        ModalTitle = "Modify Question Title";
    } else {
        ModalTitle = "Modify Answer Reveal";
    }
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            inputField: valueName, 
        },
        mode: 'onChange',
    });
     
    const onSubmit = (data) => {
        updateQnA(data.inputField);
        props.handleClose();
    }
    const handleCancel = () => {
        props.handleClose();
    };
  return (
        <Grid className='paper modalstyle'>
            <Grid className="modal-title-2">
                {ModalTitle}
                <button className='closeModal' onClick={handleCancel}></button>
            </Grid>
            <Grid className="modal-body">
            <FormControl className='formControl' fullWidth={true}>
                <FormLabel htmlFor='inputField' className='font-weight-bold' require error>{ModalTitle}</FormLabel>
                <TextField variant="outlined" size='small'
                {...register('inputField', { required: `${props.editType} is required.` })} error={!!errors.inputField} 
                helperText={errors.inputField?.message}/>
            </FormControl>
            </Grid>
            <Grid className="modal1FoBtn modal-footer" container justifyContent='flex-end'>
                <button type="button" className="modalBtn" onClick={handleCancel}>Cancel</button>
                <button type="button" className="modalBtn" onClick={handleSubmit(onSubmit)}>OK</button>
            </Grid>
        </Grid>
  )
}

export default EditPopup