import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../home.css';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@mui/material/Tooltip';
import playlistIcon from '../../../public/img/playlist.png';
import videoThumb from '../../../public/img/video.png';
import { useNavigate } from "react-router-dom";
import { HomeService } from '../Service/homeService';
import PIInfo from '../../shared/PIInfo';

const PlayList = (props) => {
    const [thumbnailImage, SetThumbnailImage] = React.useState('');
    const [isNewLabel, setIsNewLabel] = React.useState(true);
    const titleElementRef = React.useRef();
    const isTitleTruncated = titleElementRef.current && titleElementRef.current.scrollHeight > titleElementRef.current.clientHeight;
    const navigate = useNavigate();
    let _service = new HomeService();
    function validURL(str) {
        try {
            new URL(str);
            return true;
        }
        catch (err) {
            return false;
        }
      }
    React.useEffect(() => {
        let service = new HomeService();
        if (!validURL(`'${props.playlist.thumbnail}'`)) {

            if (props.playlist.thumbnail.length > 0) {
                service.GetImageFromURL(props.playlist.thumbnail).then((data) => {
                    if (data) {
                        SetThumbnailImage(data);
                    }
                })
            } else {
                if (props.playlist.thumbnail) {
                    SetThumbnailImage(props.playlist.thumbnail);
                }
            }
        } else {
            if (props.playlist.thumbnail) {
                SetThumbnailImage(props.playlist.thumbnail);
            }
        }

        let currDate = new Date();
        let contentDate = new Date(props.playlist.created_at);
        var date = currDate.getTime()-contentDate.getTime();
        var isWeekAgo = date / (1000 * 3600 * 24);
        if(isWeekAgo>7) {
            setIsNewLabel(false)
        }
    },[props.playlist.created_at,props.playlist.thumbnail]);

    return (
        <Grid id='playlistElementId' className={props.slides === 3 ? 'sOuterlg' : 'sOuter'} onClick={() => {
            return;
            _service.VideoSelectedGAEvent(props.playlist.playlist_id, props.playlist.title)
            navigate(`/content-watch/${props.playlist.kaltura_id}-${props.playlist.playlist_id}/${props.playlist.playlist_id}`)}}>
            <Grid className='sCover' onClick={() => {
            _service.VideoSelectedGAEvent(props.playlist.playlist_id, props.playlist.title);
            _service.NavigatedToVideoEvent(props.playlist.playlist_id, 'Navigated to video from homepage', props.filter);
            navigate(`/content-watch/${props.playlist.kaltura_id}-${props.playlist.playlist_id}/${props.playlist.playlist_id}`)}}>
            {isNewLabel ? <img src={require('../../../public/img/new-img/NEW_REDIndicate@2x.png')} className='newimg' alt='img' /> : null}
                <Grid className='sCoverInnerlg'>
                    <img src={playlistIcon} className={props.slides === 3 ? 'svgIconlg playlgIcon' : 'svgIcon'} alt='icon' />
                </Grid>
                {thumbnailImage && <img src={thumbnailImage.length > 0 ? thumbnailImage : videoThumb} className='most-recent-image simg-lg' alt='img' />}
            </Grid>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <Paper className='playlistTag'>Playlist</Paper>
                </Grid>
            </Grid>
            <Tooltip title={isTitleTruncated ? props.playlist.title : ''} placement='bottom'>
                <Paper ref={titleElementRef} onClick={() => {
                _service.VideoSelectedGAEvent(props.playlist.playlist_id, props.playlist.title);
                _service.NavigatedToVideoEvent(props.playlist.playlist_id, 'Navigated to video from homepage', props.filter)
                navigate(`/content-watch/${props.playlist.kaltura_id}-${props.playlist.playlist_id}/${props.playlist.playlist_id}`)}} className='cont'>{props.playlist != null ? props.playlist.title : 'NA'}</Paper>
            </Tooltip>
            <Paper className="cont-piinfo-home">
                {props.playlist?.category1_id?.length ? <PIInfo category1Ids={props.playlist.category1_id} /> : ""}
            </Paper>
        </Grid>
    );
}
export default PlayList