import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useForm } from 'react-hook-form';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const VideoSelectionModal = ({ open, onClose, onSubmit, videoList }) => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
    });

    const handleClose = () => {
        onClose();
        reset();
    };
    const onFormSubmit = (data) => {
        onSubmit(data.video);
        reset();
    };

    return (
        <Dialog open={open} scroll='body' maxWidth={false} TransitionComponent={Transition} onClose={handleClose} disableEscapeKeyDown className='speaker-modal'>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <DialogTitle className='modal-title-2'>
                    Select a Content
                    <button type="button" className='closeModal' onClick={handleClose}></button>
                </DialogTitle>
                <DialogContent className='modal-body'>
                    <FormControl component="fieldset" className='formControl'>
                        <Controller
                            name="video"
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={videoList}
                                    value={field.value || null}
                                    getOptionLabel={(option) => option.title || ""}
                                    onChange={(_, value) => field.onChange(value)}
                                    renderInput={(params) => <TextField {...params} placeholder="Search by Content Title" name="speaker" size="small" InputLabelProps={{ shrink: false }} variant="outlined" error={!!errors.video} helperText={!!errors.video && 'Content is required. Please select an appropriate content..'} />}
                                />)}
                            rules={{ required: true }}
                            control={control}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions className='modal-footer'>
                    <button type="button" className="modalBtn" onClick={handleClose}>Cancel</button>
                    <button type="submit" className="modalBtn">OK</button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
export default VideoSelectionModal