import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useNavigate } from "react-router-dom";
import GreenTickImg from '../../../public/img/green_tick.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ModalDialog = ({modalFunc ,displayMessage =''})=> {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    
    React.useEffect(() => {
        modalFunc.current = handleClickOpen
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])


    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        navigate('/home');
      };

  
    return (
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
           
        <DialogTitle className='userDialogTitle' id="alert-dialog-slide-title">
          <IconButton className='closeBtn'
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='modal-body text-center'>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <img alt="green tick" src={GreenTickImg} />
    <span>{displayMessage ? displayMessage :' Woops'}</span>
  </div>
</DialogContent>

            <DialogActions className='modal-footer'>                
                <button type="button" className="btn-ok modalBtn" onClick={handleClose}>Ok</button>
            </DialogActions>
        </Dialog>
    )
}
export default ModalDialog