/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PlaylistService } from '../Service/playlist-service';

const ContentLibrary = (props) => {
  const [allLibrary, SetallLibrary] = React.useState([]);
  const [selectedlibrary, Setselectedlibrary] = React.useState([]);
  const handlerCheckbox = (event, affiliate_id) => {
    if (event.target.checked) {
      props.SetLibraries([...props.libraries, event.target.value])
    } else {
      var array = [...props.libraries];
      const filteredArray = array.filter((item) => {
        if (item === event.target.value) {
          return false;
        }
        return true;
      });
      props.SetLibraries(filteredArray);
    }
  }
  React.useEffect(() => {
    let service = new PlaylistService();
    service.GetAllLibrary().then((data) => {
      SetallLibrary(data.data)
    })
  }, []);
  React.useEffect(() => {
    let list = []
    if (allLibrary.length > 0 && props.affiliates.length > 0) {
      for (let j = 0; j < props.affiliates.length; j++) {
        let key = [];
        let arr = [];
        key['name'] = props.affiliates[j].country_name
        if (props.affiliates[j].library_active === 3) {
          for (let i = 0; i < allLibrary.length; i++) {
            if(allLibrary[i].status){
              if (allLibrary[i].country_code === props.affiliates[j].country_code) {
                arr.push(allLibrary[i])
              }
              key['list'] = arr
            }
           
          }
        }
        list.push(key)
      }
      Setselectedlibrary(list)
    }

  }, [props.affiliates, allLibrary]);



  return (
    <>
      {props.affiliates?.length > 0 ?
        <Grid container className="content white_row">
          <Grid item lg={12} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select Library</FormLabel>
              <div className='ml20 tableData contentLibrary'>
                {selectedlibrary.length > 0 ? selectedlibrary.map((item, index) => (
                  <Grid container key={index}>
                    <Grid item lg={3} md={3} sm={3} xs={12} className="libraryCountrySelected">
                      {item.name}
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={12}>
                      <ul className='ul_list'>
                        <Grid className='affiliates'>
                          {item.list && item.list.length > 0 ? item.list.map((it, index) => (
                            <>
                              <li className='ul_list_inner'>
                                <FormControlLabel
                                  value={it.library_id}
                                  className='labelTxt-user'
                                  checked={props.libraries.length > 0 && props.libraries.includes(it.library_id)? true:false}
                                  control={<Checkbox onChange={(event) => { handlerCheckbox(event, it.country_code); }} />}
                                  label={it.library_name}
                                  labelPlacement="end"
                                />
                              </li>
                            </>
                          )) : ""}
                        </Grid>
                      </ul>
                    </Grid>
                  </Grid>
                )) : ""}
              </div>
            </FormControl>
          </Grid>
        </Grid>
      : ''}
    </>
  )
}
export default ContentLibrary