import React from 'react';
import Breadcrumb from '../../layout/breadcrumb';


const ContactUs = (props) => {
  const BreadcrumbData = [
    { value: 'Home', path: '/home' },
    { value: 'İLETİŞİM', path: '/contact-us' }
  ];
  
  return (
  <>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
  <div className="contentsArea">
     <div className="staticPage shortWidth3">
          <h2>İLETİŞİM</h2>
          <p>Soru,öneri ve taleplerinizi bize  <a className="staticLink" href="mailto:site_sorumlusu@lilly.com">site_sorumlusu@lilly.com</a> email adresinden iletebilirsiniz. <br/>
          Lilly İlaç Ticaret Ltd Şti. <br/>    
          Acıbadem Mah. Çeçen Sokak Akasya Acıbadem Kent Etabı A Blok Kat: 3 34660 Üsküdar / İstanbul <br/>
          Telefon : 0216 554 00 00<br/>
          Faks : 0216 474 71 99 <br/>
          
          Yan etkileri 24 saat içinde, Farmakovijilans Departmanına, Yan Etki Bildirim Hattı aracılığıyla 0216 554 00 00 numaralı telefondan 2’yi tuşlayarak ya 
          da <a className="staticLink" href="mailto:pharmacovigilance_turkey@lilly.com">pharmacovigilance_turkey@lilly.com</a> adresi üzerinden bildirebilirsiniz. Bildirimlerinizi 0216 554 01
          81 numaralı faksa da gönderebilirsiniz
          </p>
        <br/>
      </div>
  </div>
  </>
  )
}

export default ContactUs;