import React from 'react';
import OneLogo from '../../../public/img/1.png';
import TwoLogo from '../../../public/img/2.png';
import ThreeLogo from '../../../public/img/3.png';
import FourLogo from '../../../public/img/4.png';

const Czech = (props) => {
    React.useEffect(() => {
        var head = document.head;
        var link1 = document.createElement("link");
        link1.type = "text/css";
        link1.rel = "stylesheet";
        link1.href = window.location.origin +"/css/landing2.css";
        head.appendChild(link1);
        // document.getElementById('link10').disabled = true
        return () => { 
            head.removeChild(link1);
        }
    }, []);
  return (
    <>
    {/* <link rel="stylesheet" href={window.location.origin +"/css/landing2.css"}></link> */}

    <div className="lp-container">
        <div className="lp-header">
            <div className="inner"><span className="lilly">Lilly</span><span className="play">|</span>Play</div>
        </div>

        <div className="lp-mainContent">
            <div className="topNavBtn">
                <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonAA">Registrace</div></a>
                <a href name="landing-Login" className="cz-login" onClick={props.passportLogin}><div className="buttonBB">Přihlášení</div></a>
            </div>
            <div className="clearfix"></div>
            <div className="title">
                <h1 className="font2 promotional-video-title" title="CZ LillyPlay">Vítejte na Lilly Play</h1>
                <h2 className="font3 promotional-video-title2">Nabídka odborných videí pro lékaře</h2>
            </div>
            <div className="clearfix"></div>
            <div className="centerBtn">
                <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonA">Registrace</div></a>
            </div>
            <div className="clearfix"></div>
            <div className="biglogo">
                <p className="fon1"><span className="lilly">Lilly</span><span className="play">|</span><span className="colorBlack">Play</span></p>
                <p className="fon4">Natočeno pro vás</p>
            </div>
            <div className="clearfix"></div>
            <div className="paddingbox">
                <p className="font3">Připravili jsme pro vás zajímavý obsah – rozhovory, video prezentace, informační či instruktážní videa, záznamy diskusí a mnoho dalšího. Spolupracujeme s odborníky z domova i ze zahraničí.</p>
            </div>
            <table>
                <tbody>
                    <tr>
                        <td className="height20">&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="fontbox2"><img className="img1" src={OneLogo} alt="" /><br /><span className="fontbox">Aktuální témata</span><br />z vašeho oboru</td>
                        <td className="fontbox2"><img className="img2" src={TwoLogo} alt="" /><br /><span className="fontbox">Vše na jednom místě  </span><br />jednoduše a pohodlně</td>
                    </tr>
                    <tr>
                        <td className="height20">&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="fontbox2"><img className="img3" src={ThreeLogo} alt="" /><br /><span className="fontbox">Nejlepší odborníci</span><br />z domova i ze zahraničí</td>
                        <td className="fontbox2"><img className="img4" src={FourLogo} alt="" /><br /><span className="fontbox"> Videa</span><br />odborné materiály ve formě videí</td>
                    </tr>
                </tbody>
            </table>
            <div className="height30"></div>
            <div className="centerBtn">
                <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonA">Zaregistrujte se na Lilly Play</div></a>
            </div>
            <div className="height50"></div>
        </div>

        <div className="lp-footer-content">
            <footer id="myFooter">
                <div className="footer-container float_left">
                    <div className="spaceDiv"><br />&nbsp;</div>
                    <div className="footer-copyright">
                        <div className="pullLeft">Copyright © 2022 Eli Lilly and Company.&nbsp;</div>
                        <div className="pullRight">&nbsp;PP-MG-CZ-0009&nbsp;&nbsp;</div>
                    </div>
                </div>
                <div className="height40"></div>
                <div className="navLinks">
                    <ul>
                        <li><a href="https://www.lilly.cz/smluvni-podminky" rel="noreferrer" target="_blank" >SMLUVNÍ PODMÍNKY</a></li>
                        <li><a href="https://www.lillyprivacy.com/cz-cs/hcp" rel="noreferrer" target="_blank" >OCHRANA OSOBNÍCH ÚDAJŮ A COOKIES</a></li>
                        <li><a href="https://www.lilly.cz/autorska-prava" rel="noreferrer" target="_blank" >AUTORSKÁ PRÁVA</a></li>
                        <li><a href="https://www.lilly.cz/prohlaseni-o-pristupnosti" rel="noreferrer" target="_blank">Prohlášení o přístupnosti</a></li>
                        <li><a href="https://www.lilly.cz/kontakt" rel="noreferrer" target="_blank">Kontakt</a></li>
                        <li><a href="/czech/Informace-o-úhradě.html" rel="noreferrer" target="_blank">Informace o úhradě</a></li>
                        <li><a href="#cookie" data-triggers-cookie-settings="true">Nastavení souborů cookie</a></li>
                    </ul>
                </div>
            </footer>
        </div>
    </div>
    </>
  )
}

export default Czech