import * as jose from 'jose';
import LoginRoute from '../login-route';
class AuthVerifyService {

  checkPreviousRedirectURL(navigate) {
    if (localStorage.getItem('previousRouteBeforeLogin')) {
      navigate(localStorage.getItem('previousRouteBeforeLogin'))
    } else {
      navigate('/home');
    }
  }
  GetSessionCookie(name) {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let _cookies = cookies[i].split('=');
      if (_cookies[0].trim() === name) {
        let signValue = _cookies[1].trim();
        return signValue;
      }
    }
  }
  UpdateLocalStorage() {
    for (var key in localStorage) {
      if (key == 'country' || key == 'ga_code') {
      } else {
        localStorage.removeItem(key);
      }
    }
  }
  checkAuthTokenUser(navigate) {
    if (localStorage.getItem("jwtToken") == null) {
      let currentURL = new URL(window.location.href);
      let params = (new URL(currentURL)).searchParams;
      let access_token = params.get("access_token");
      if (access_token != null) {
        console.log("---Allow Contact Watch Link for Guest User and Login---");
        return true;
      } else if (localStorage.getItem('passportToken')) {
        console.log("---found passportToken---", localStorage.getItem("passportToken"));
        return true;
      } else if (window.location.hash.length > 0) {
        return true;
      }
      else {

        let redirectURL = currentURL.href.replace(currentURL.origin, "");
        let cookie = this.GetSessionCookie('sessioncookie');
        let hcpDirectCookie = this.GetSessionCookie('hcpDirect');
        console.log("---previousRouteBeforeLogin without local clear ---", cookie, hcpDirectCookie);
        // return true;
        if (redirectURL !== '/' && access_token == null && cookie == null && hcpDirectCookie == null) {
          console.log("---previousRouteBeforeLogin---");
          //localStorage.clear();
          this.UpdateLocalStorage();
          sessionStorage.clear();
          if (localStorage.getItem('previousRouteBeforeLogin') == null) {
            localStorage.setItem('previousRouteBeforeLogin', redirectURL);
          }
        }
        navigate('/')
        
      }
    } else {
      let decodedToken = jose.decodeJwt(localStorage.getItem("jwtToken"));
      let expiredToken = (new Date()).getTime() / 1000 > decodedToken.exp;
      let sessionDate = localStorage.getItem("sessionDate")?localStorage.getItem("sessionDate"):'';
      let indicator = localStorage.getItem('HCP');
      console.log("sessionDate", sessionDate, expiredToken);
      // alert(sessionDate);
      if (expiredToken) {
        if (indicator === 'true') {
          sessionStorage.clear();
          this.UpdateLocalStorage();
          // window.location.href = window.location.origin + '/sso';
        }
        else {
          let redirect = localStorage.getItem('country').toLowerCase();
          sessionStorage.clear();
          this.UpdateLocalStorage();
          window.location.href = window.location.origin + '/' + redirect;
        }
        sessionStorage.clear();
        this.UpdateLocalStorage();

        return false;
      } 
      // else if (!sessionDate){
      //   if (indicator === 'true') {
      //     sessionStorage.clear();
      //     this.UpdateLocalStorage();
      //     window.location.href = window.location.origin + '/sso';
      //   }
      //   else {
      //     let redirect = localStorage.getItem('country').toLowerCase();
      //     sessionStorage.clear();
      //     this.UpdateLocalStorage();
      //     window.location.href = window.location.origin + '/' + redirect;
      //   }
      // }
      return true;
    }
  }
}

export { AuthVerifyService }
