/* eslint-disable no-unused-vars */
// import React from 'react';

import { PlaylistService } from '../Service/playlist-service';
import { Grid } from '@material-ui/core';
import Breadcrumb from '../../../layout/breadcrumb';
import React from 'react';
import '../playlist-update/playlist-update.css';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import PreviewAndType from '../Components/PreviewAndType';
import Quiz from '../Components/QuizDetails';
import Affiliates from '../Components/Affiliates';
import Library from '../Components/Library';
import Audience from '../Components/Audience';
import Specialities from '../Components/Specialities';
import TherapeuticArea from '../Components/TherapeuticArea';
import PlaylistPublish from '../Components/PlaylistPublish';
import TimeRange from '../Components/TimeRange';
import VideoSelected from '../Components/VideoSelected';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Button from '@material-ui/core/Button';
import download from '../../../public/img/download.gif';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Snackbar from '@material-ui/core/Snackbar';
import useCustomForm from '../../../hooks/useCustomForm';
import VideoSelectionModal from '../Components/VideoSelectionModal';
import useCategoryTree from '../../../hooks/useCategoryTree';
import { prepareCategoryTreeByCountries } from '../../../helpers/countryHelper';
// import PlaylistModal from './PlaylistModal';

const defaultSelectedCategories = {
  TAIds: [],
  cat1Ids: [],
  cat2Ids: [],
}

const PlaylistUpdate = (props) => {
  let playlistService = new PlaylistService();
  const navigate = useNavigate();
  const [sendDataToModal, setSendDataToModal] = React.useState("");
  const [secondOwner, SetSecondOwner] = React.useState('');
  const [id, SetId] = React.useState(0);
  const [thumbnailUrl, SetThumbnailUrl] = React.useState('');
  const [status, setStatus] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [affiliatesName, SetAffiliatesName] = React.useState([]);
  const [checkAffiliates, SetCheckAffiliates] = React.useState(false);
  const [libraries, SetLibraries] = React.useState([]);
  const [audience, SetAudience] = React.useState([]);
  const [specialties, SetSpecialties] = React.useState([]);
  const [affiliateObjModel, SetAffiliateObjModel] = React.useState({});
  const {categoryTree, onCategorySelect, initializeCategoryTree, selectedTAIds, selectedCat1Ids, selectedCat2Ids} = useCategoryTree([]);
  const [selectedCategories, setSelectedCategories] = React.useState(defaultSelectedCategories)
  const [affiliateToPublish, setAffiliateToPublish] = React.useState({});
  let selectorValue = useSelector(state => state.session);
  const translationReduxState = useSelector(state => state.translation);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [isRoleHCP, setIsRoleHCP] = React.useState(false);
  const [isRoleInternal, setIsRoleInternal] = React.useState(false);
  const [isRolePublisher, setIsRolePublisher] = React.useState(false);
  const [isRoleAdmin, setIsRoleAdmin] = React.useState(false);
  const [created_by, Setcreated_by] = React.useState();
  const [updated_by, Setupdated_by] = React.useState();
  const [videoDetails, setVideoDetails] = React.useState([]);
  const [videoIds, setVideoIds] = React.useState([]);
  const [kalturaIds, setKalturaIds] = React.useState('');
  const [isOpenVideoModal, setIsOpenVideoModal] = React.useState(false);
  const [video, setVideo] = React.useState({});
  const [description, SetDescription] = React.useState('');
  const [isSeries, setIsSeries] = React.useState(0);
  const [validationPopup, SetvalidationPopup] = React.useState(false);
  const [videoPreviewImage, SetVideoPreviewImage] = React.useState(false);
  const [isCustomImage, setIsCustomImage] = React.useState(false);
  const [submissionStatus, SetSubmissionStatus] = React.useState(false);
  const [imgPreview, setImgPreview] = React.useState('');
  const [quizDetails, SetQuizDetails] = React.useState({});
  const [quizSelected, SetQuizSelected] = React.useState([]);
  const [quizId, SetQuizId] = React.useState(0);
  const [customThumbFlag, setCustomThumbFlag] = React.useState(false);
  const [user_id, setUser_id] = React.useState();
  const [audienceMap, SetAudienceMap] = React.useState({});
  const [specialtyMap, SetSpecialtyMap] = React.useState({});
  const [loader, setloader] = React.useState(false);
  const [countryName, setCountryName] = React.useState();
  const [countryChangePopup, setcountryChangePopup] = React.useState(false);
  const [detectChange, setdetectChange] = React.useState(false);
  const [changeCountryState, setchangeCountryState] = React.useState(false);
  const [publisherCountryList, SetPublisherCountryList] = React.useState([]);
  const [VVPMDetails, SetVVPMDetails] = React.useState([1, 2]);
  const [vvpmAffiliateMap, SetVVPMAffiliateMap] = React.useState({});
  const [vvpmCode, SetVvpmCode] = React.useState('');
  const [contentlost, setcontentlost] = React.useState('false');
  const [VVPMFromDate, setVVPMFromDate] = React.useState("");
  const [VVPMExpirationDate, setVVPMExpirationDate] = React.useState("");

  const [s3UploadCheck, SetS3UploadCheck] = React.useState(false);
  const [s3UploadErrorCheck, SetS3UploadErrorCheck] = React.useState(false);
  const [imageSizeCheck, SetImageSizeCheck] = React.useState(false);
  const [imageTypeCheck, SetImageTypeCheck] = React.useState(false);
  const [imageDimensionCheck, SetImageDimensionCheck] = React.useState(false);
  const [countriesApprovedStatus, setCountriesApprovedStatus] = React.useState({});
  const [isAffiliateAltered, setIsAffiliateAltered] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarType, setSnackbarType] = React.useState('');
  const [snackbarMsg, setSnackbarMsg] = React.useState('')
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [playlistLengthValidation,setPlaylistLengthValidation] = React.useState(false);
  
  const { formData, errors, handleChange, setValue, validateForm, inputRefs } = useCustomForm({
    title: '',
    affiliates: [],
    selectedBrands: [],
    timeRange: []
  }, {
    title: ['required'],
    affiliates: ['required'],
    selectedBrands: ['required'],
    timeRange: ['customPlayListDuration']
  });
  const handleValidationPopup = () => {
    SetvalidationPopup(false);
  }
  const handleSubmitPopup = () => {
    SetSubmissionStatus(false);
    navigate('/playlist-management')
  }

  let playlistId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
  // const ContentCreate = (props) => {
  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'Playlist Management', path: '/playlist-management' },
    { value: 'Playlist Details', path: '/playlist-update/' + playlistId }
  ]

  React.useEffect(() => {
    if (sessionState.sessionValue?.payload?.loginroute) {

      if (sessionState.sessionValue?.payload?.user) {
        Setcreated_by(sessionState.sessionValue.payload.user.id);
        Setupdated_by(sessionState.sessionValue.payload.user.id);
        setUser_id(sessionState.sessionValue.payload.user.id);
      }

      if (sessionState.sessionValue.payload.affiliate.length) {
        let countryListArr = [];
        for (let i = 0; i < sessionState.sessionValue.payload.affiliate.length; i++) {
          countryListArr[sessionState.sessionValue.payload.affiliate[i].country_code] = sessionState.sessionValue.payload.affiliate[i];
        }
        SetPublisherCountryList(countryListArr)
      }

      for (let i = 0; i < sessionState.sessionValue.payload.roles.length; i++) {

        if (sessionState.sessionValue.payload.roles[i].role_name === "hcp") {

          setIsRoleHCP(true);

        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "internal") {

          setIsRoleInternal(true);
        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "publisher") {

          setIsRolePublisher(true);

        }
        if (sessionState.sessionValue.payload.roles[i].role_name === "admin") {

          setIsRoleAdmin(true);
        }

      }
      

    }
  }, [sessionState]);

  React.useEffect(() => {
    // Set obj for VVPM
    let affiliateObjModel = {};
    for (let i = 0; i < affiliatesName.length; i++) {
      affiliateObjModel[affiliatesName[i].country_code] = { from: "", to: "", rows: [] };

    }
    SetAffiliateObjModel(affiliateObjModel);
    if (Object.keys(affiliateObjModel).length > 0) {
      // commented to avoid rre-rendering loop
      //SetHasRows(true)
    }

    //Set obj for Countries to be published


    let publishCountriesObj = { ...affiliateToPublish };
    let countryNameArr = [];
    if (isRolePublisher) {
      for (let i = 0; i < affiliatesName.length; i++) {

        if (!publishCountriesObj[affiliatesName[i].country_code]) {

          let pubstatus = "Active";
          if (!publisherCountryList[affiliatesName[i].country_code]) {
            pubstatus = "Pending";
          }
          publishCountriesObj[affiliatesName[i].country_code] = {
            name: affiliatesName[i].country_name,
            status: pubstatus
          }
        }

        countryNameArr.push(affiliatesName[i].country_name)
      }
    }
    else {
      for (let i = 0; i < affiliatesName.length; i++) {
        if (!publishCountriesObj[affiliatesName[i].country_code]) {
          publishCountriesObj[affiliatesName[i].country_code] = {
            name: affiliatesName[i].country_name,
            status: "Pending"
          }
        }
        countryNameArr.push(affiliatesName[i].country_name)
      }
    }
    setCountryName(countryNameArr);
    let uri = window.location.href;
    let videoId = uri.split('/')[uri.split('/').length - 1];
    if (videoId === "playlist-update" || changeCountryState === true) {
      setAffiliateToPublish(publishCountriesObj);
    }
    console.log('isAffiliateAltered',publisherCountryList)

  }, [affiliatesName]);

  React.useEffect(() => {
    let uri = window.location.href;
    let playlistId = uri.split('/')[uri.split('/').length - 1];
    if (playlistId !== "playlist-update") {
      SetId(playlistId)
      playlistService.GetPlaylistbyId(playlistId).then((data) => {
        let jsondata = data;
        SetPlaylistDetails(jsondata);
        SetVideoPreviewImage(true);
      }).catch((err) => {});
    }   
    
    GetVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetVideos = (videoIds) => {
    if (videoIds) {
      playlistService.GetPlayListVideos(videoIds).then((data) => {
        let jsondata = data.data;
        let newjsondata = [];
        if (jsondata.length > 0) {
          for (let i = 0; i < videoIds.length; i++) {
            for (let j = 0; j < jsondata.length; j++) {
              if ((videoIds[i]).toString() === (jsondata[j].content_id).toString()) {
                newjsondata.push(jsondata[j]);
              }
            }
          }
        }
        setVideoDetails(newjsondata);
      }).catch((err) => {});
    } else {
      playlistService.GetAllContent().then((data) => {
        let jsondata = data.data;
        setVideo(jsondata);
      }).catch((err) => {});
    }
  }

  const validURL = (str) => {
    try {
      new URL(str);
      return true;
    }
    catch (err) {
      return false;
    }
  }

  //Update details on edit
  const SetPlaylistDetails = (playlist) => {
    if (playlist.length > 0) {
      let data = playlist[0];
      if (data.thumbnail) {
        if (validURL(`'${data.thumbnail}'`)) {
          setImgPreview(data.thumbnail);
        } else {
          playlistService.GetS3File(data.thumbnail).then((data) => {
            setImgPreview(data);
            setCustomThumbFlag(true);
          });
        }
      }
      SetThumbnailUrl(data.thumbnail);
      GetVideos(data.content_ids);
      setValue('title', data.title);
      SetLibraries(data.content_libraries)
      SetDescription(data.description);
      setValue('affiliates', Array.isArray(data.country_code) ? data.country_code : []);
      setSelectedCategories({
        TAIds: data.therapeutic_area_id,
        cat1Ids: data.category1_id,
        cat2Ids: data.category2_id
      })
      setStatus(data.status);
      setOwner(data.created_by);
      SetVvpmCode(data.vvpm_details);
      Setupdated_by(data.updated_by);
      Setcreated_by(data.created_by);
      SetSecondOwner(data.second_owner);
      setStatus(data.status);
      setIsSeries(data.is_series);
      SetQuizDetails(data.quiz_id);
      setAffiliateToPublish(data.countries_approved);
      setCountriesApprovedStatus(data.countries_approved);
      SetAudience(data.audience_mapping ? data.audience_mapping : []);
      SetSpecialties(data.specialities ? data.specialities : []);
      if (Object.keys(data.time_range).length > 0) {
        for (let i = 0; i < Object.keys(data.time_range).length; i++) {
          let key = Object.keys(data.time_range)[i]
          if (data.time_range[key].start_date !== "" && data.time_range[key].exp_date !== "") {
            data.time_range[key].start_date = new Date(data.time_range[key].start_date)
            data.time_range[key].exp_date = new Date(data.time_range[key].exp_date)
          } else {
            data.time_range[key].start_date = new Date()
          }
        }
      }
      setValue('timeRange', data.time_range);
    }
  }

  // Handle Affiliates States
  const addAffiliates = (affiliate) => {
    SetAffiliatesName([...affiliatesName, affiliate])
  }
  const removeAffiliates = (affiliate_obj) => {
    var array = [...affiliatesName];
    const filteredArray = array.filter(item => item !== affiliate_obj);
    SetAffiliatesName(filteredArray);

    var filteredPublishArray = {};
    let selectedKeys = Object.keys(affiliateToPublish)
    for (let i = 0; i < selectedKeys.length; i++) {
      let key = selectedKeys[i];
      if (affiliate_obj.country_code !== key) {
        filteredPublishArray[key] = {
          name: affiliateToPublish[key].name,
          status: affiliateToPublish[key].status,
        }
      }
    }
    setAffiliateToPublish(filteredPublishArray);
  }

  const handleVideoModalClose = (event, reason) => {
    setIsOpenVideoModal(false);
  };

  //Set time range obj model
  React.useEffect(() => {
    let checkedItems = [];
    if (id === 0) {
      let dateObjModel = {};
      for (let i = 0; i < affiliatesName.length; i++) {
        dateObjModel[affiliatesName[i].country_code] = { affiliate_name: affiliatesName[i].country_name, start_date: "", exp_date: "" };

      }
    }
    //Set obj for Countries to be published
    let currentData =  { ...countriesApprovedStatus };
    let publishCountriesObj = {};
    let countryNameArr = [];
    if (affiliatesName) {
      for (let i = 0; i < affiliatesName.length; i++) {
        let pubstatus = "Active";
        if (!publisherCountryList[affiliatesName[i].country_code] || !isRolePublisher) {
          pubstatus = "Pending";
        }
        if (!publishCountriesObj[affiliatesName[i].country_code]) {
          publishCountriesObj[affiliatesName[i].country_code] = {
            name: affiliatesName[i].country_name,
            status: currentData[affiliatesName[i].country_code]?currentData[affiliatesName[i].country_code]['status']:pubstatus
          }
        }
        countryNameArr.push(affiliatesName[i].country_name)
      }
    }
    setAffiliateToPublish(publishCountriesObj);
    setCountryName(countryNameArr);
    if (id === 0 && formData.affiliates.length ===0 && typeof(publisherCountryList)!=='undefined' && isAffiliateAltered === false){
      checkedItems = Object.keys(publisherCountryList)
      setValue('affiliates', [...checkedItems])
    }    
  }, [affiliatesName, id]);



  //Set id of videos selected
  React.useEffect(() => {
    let arr = [...videoDetails];
    let video_id = [];
    let kaltura_id = "";
    if (arr.length > 0) {
      kaltura_id = arr[0].kaltura_id;
      for (let i = 0; i < arr.length; i++) {
        video_id.push(arr[i].content_id)
      }
      if (customThumbFlag === false) {
        if (arr[0].thumbnail != null) {
          if (!isCustomImage) {
            //if(!videoPreviewImage) {
            setImgPreview(arr[0].thumbnail);
            SetThumbnailUrl(arr[0].thumbnail);
            //}
          }
        }
      }
    } else {
      if (customThumbFlag === false) {
        if (!isCustomImage) {
          //if(!videoPreviewImage) {
          setImgPreview('');
          SetThumbnailUrl('');
          //}
        }
      }
    }
    setVideoIds(video_id)
    setKalturaIds(kaltura_id)
  }, [customThumbFlag, videoDetails, videoPreviewImage, isCustomImage]);

  let model = {
    id: id,
    affiliates: formData.affiliates,
    description: description,
    title: formData.title,
    video_ids: videoIds,
    kaltura_id: kalturaIds,
    vvpmDetails: affiliateObjModel,
    secondOwner: secondOwner,
    status: "",
    created_by: created_by,
    updated_by: updated_by,
    therapeuticAreas: selectedTAIds,
    brands: selectedCat1Ids,
    indications: selectedCat2Ids,
    thumbnail: thumbnailUrl,
    countries_approved: affiliateToPublish,
    libraries: libraries,
    audience: audience,
    specialties: specialties,
    timeRange: formData.timeRange,
    quiz: JSON.stringify(quizDetails),
    is_series: isSeries
  };
  const handleSave = () => {
    if (!validateForm({skip: ['timeRange']})) {
      return;
    }
    let submissionStatus = "draft";
    model.status = submissionStatus;
    // return;
    playlistService.UpdatePlaylist(model).then((data) => {

      SetSubmissionStatus(true);
      let msg = "Successfully saved as a draft!";
      setSendDataToModal(msg);


    }).catch((err) => {});

  }
  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    if (model.video_ids.length < 2){
      setPlaylistLengthValidation(true)
      SetvalidationPopup(true)
      return;
    }
    let submissionStatus = "";
    let msg = "";
    if (videoIds.length > 1) {
      submissionStatus = "submitted";
      msg = "Submitted successfully!";

    } else {
      submissionStatus = "draft";
      msg = "Successfully saved as a draft!";
    }

    model.status = submissionStatus;
    
    let publisherEmailSet = new Set();
    playlistService.UpdatePlaylist(model).then((data) => {
      if (data.status.toLowerCase() === 'submitted') {
        console.log(' ', data)
          let countrySelected = []
          let contentUrl = window.location.href + "/" + data.playlist_id;
          let date = new Date();
          var hours = date.getHours();
          var minutes = date.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0' + minutes : minutes;
          var strTime = hours + ':' + minutes + ' ' + ampm;
          const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
          let datestring = months[date.getMonth()] + " " + ("0" + date.getDate()).slice(-2) + " " + date.getFullYear() + ", " + strTime;
          let obj=data.countries_approved;
          let country_code=Object.keys(obj)
          for (let i=0;i<country_code.length;i++){
            let status = obj[country_code[i]].status;
            if (status.toLowerCase()==='pending'){
              countrySelected.push(country_code[i])
            }
          }
          if (countrySelected?.length > 0){
            let userStr = "country_code=" + countrySelected;
            playlistService.GetPublishersByCountry(userStr).then((data) => {
              let jsondata = data.data;
              for (let i = 0; i < jsondata.length; i++) {
                publisherEmailSet.add(jsondata[i].user_email)
              }
              playlistService.SendEmail(formData.title, countryName, datestring, contentUrl, Array.from(publisherEmailSet))
            }).catch((err) => {});
          }
       }
      SetSubmissionStatus(true);
      setSendDataToModal(msg);
    }).catch((err) => {});
    


    setIsDisabled(true)

  }
  const handleVideoModalSubmit = (video) => {
    setVideoDetails([...videoDetails, video])
    setIsOpenVideoModal(false);
  };

  let compareTwoArrayOfObjects = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.country_code === element_2.country_code &&
            element_1.value === element_2.value
        )
      )
    );
  };
  let compareTwoArrayOfObjectsNested = (first_array_of_objects, second_array_of_objects) => {
    return (
      first_array_of_objects.length === second_array_of_objects.length &&
      first_array_of_objects.every((element_1) =>
        second_array_of_objects.some(
          (element_2) =>
            element_1.country_code === element_2.country_code &&
            JSON.stringify(element_1.value) === JSON.stringify(element_2.value)
        )
      )
    );
  };
  let booleanChecker = arr => arr.every(v => v === true);

  const addVideos = () => {
    if (!validateForm()) {
      return false;
    }
    setloader(true)
    playlistService.GetVideosForPlaylist(formData.affiliates, selectedTAIds, selectedCat1Ids, selectedCat2Ids, libraries, audience, specialties, formData.timeRange).then((data) => {
      // let jsondata = data.data;
      // let list = []
      let jsondata1 = data.data;
      let list = [];
      let temp_jsondata = [];
      for(let val1 = 0;val1<jsondata1.length;val1++){
        for(let val2 = 0;val2<formData.affiliates.length;val2++){
          if(jsondata1[val1].content_approved[formData.affiliates[val2]].status === 'Active'){
            temp_jsondata.push(jsondata1[val1])
          }
        }
      }
      let jsondata = Array.from(new Set(temp_jsondata.map(JSON.stringify))).map(JSON.parse);
      for (let i = 0; i < jsondata.length; i++) {
        let content = jsondata[i];
        if (!videoIds.includes(content.content_id)) {

          let audienceCheck = true;
          if (audience.length || content.audience_mapping.length) {
            audienceCheck = compareTwoArrayOfObjects(content.audience_mapping, audience);
          }
          let specialtiesCheck = true;
          let filtered = specialties.filter(item => item.value.length > 0);
          if (specialties.length || content.specialities.length) {
            specialtiesCheck = compareTwoArrayOfObjectsNested(content.specialities, specialties);
          }
          let timeRangeCheckArr = [];
          if (formData.affiliates.length) {
            for (let i = 0; i < formData.affiliates.length; i++) {
              let key = formData.affiliates[i];
              let seectedArray = formData.timeRange.filter(v => v.id == key);
              let selected = Array.isArray(seectedArray) ? seectedArray[0] : null; //e timeRange[key];
              let existing = content.vvpm_details[key];
              if (selected && existing && existing.to) {
                var d1 = new Date(selected.start_date);
                var d2 = new Date(selected.exp_date);
                var d3 = new Date(existing.to);
                if (d3 >= d1 && d3 <= d2) {
                  timeRangeCheckArr.push(true);
                } else {
                  timeRangeCheckArr.push(false);
                }
              } else {
                timeRangeCheckArr.push(true);
              }
            }
          }

          if (booleanChecker(timeRangeCheckArr)) {
            if (audienceCheck) {
              if (specialtiesCheck) {
                list.push(content)
              }
            }
          }
        }
      }
      setVideo(list);
      setIsOpenVideoModal(true);
      setloader(false)
    }).catch((err) => {});
  }

  const handlecountryChangePopup = () => {
    setcountryChangePopup(false)
    setdetectChange(false)
  }
  React.useEffect(() => {
    if (id != 0 && detectChange) {
      setcountryChangePopup(true)
    }
  }, [detectChange]);
  const handlecountryChangeCondition = () => {
    setSelectedCategories(defaultSelectedCategories);
    setchangeCountryState(true)
    setcountryChangePopup(false)
    setdetectChange(false)
    SetSpecialties([])
    SetSpecialtyMap([])

  }

  React.useEffect(() => {
    const tree = prepareCategoryTreeByCountries(props.allcategory, affiliatesName);
    initializeCategoryTree(tree, selectedCategories);
  }, [affiliatesName, props.allcategory, selectedCategories, initializeCategoryTree])

  React.useEffect(() => {
    handleChange('selectedBrands', selectedCat1Ids)
  }, [selectedCat1Ids, handleChange])

  return (<>
    <Modal open={s3UploadCheck} onClose={() => { SetS3UploadCheck(false) }}>
      <Grid className='paper modalstyle'>
        <Grid className="modal-title">
          <button className='closeModal' onClick={() => { SetS3UploadCheck(false) }}></button>
        </Grid>
        <Grid className='box'>
          <Grid className='attenSec attenSecvalidationImage'>
            <span className='attenIcnSec'>
              <ReportProblemIcon className='attenIcn' />
              Error
            </span>
            <div className="m20">
              {imageSizeCheck ? (
                <Alert severity="warning">The thumbnail you selected does not meet the standard of file size. The file is too large. Please, select another thumbnail which size is less than 2.0MB</Alert>
              ) : ""}
              {imageTypeCheck ? (
                <Alert severity="warning">The thumbnail you selected does not meet the standard of thumbnail format. The file format is not allowed by system. Please, select another thumbnail which formats are: .jpg, .jpeg, .png.</Alert>
              ) : ""}
              {imageDimensionCheck ? (
                <Alert severity="warning">The thumbnail you selected does not meet the standard of thumbnail dimensions. The file is not of correct dimension. Please, select another thumbnail which dimensions are 1280x720px</Alert>
              ) : ""}
              {s3UploadErrorCheck ? (
                <Alert severity="warning">The thumbnail you selected could not be uploaded. Please, try again later. If you submit the video now, Lilly Play will use the thumbnail that comes from Kaltura.</Alert>
              ) : ""}
            </div>
            {/* <p className='attenTxt'>
                The file Was unable to upload to S3. Please retry later.
              </p> */}
          </Grid>
        </Grid>
        <Grid className="modal1FoBtn modal-footer">
          <button type="button" className="modalBtn" onClick={() => { SetS3UploadCheck(false) }}>Ok</button>
        </Grid>
      </Grid>
    </Modal>
    <Modal open={countryChangePopup} onClose={handlecountryChangePopup}>
      <Grid className='paper modalstyle'>
        <Grid className="modal-title">
          <button className='closeModal' onClick={handlecountryChangePopup}></button>
        </Grid>
        <Grid className='box'>
          {/* <Alert severity="warning">{urltextPopup}</Alert> */}
          <Grid className='attenSec attenSecvalidationImage'>
            <span className='attenIcnSec'>
              <ReportProblemIcon className='attenIcn' />
              Attention
            </span>
            <p className='attenTxt'>
              All the previously saved configurations that are country specific will be lost, Are you sure you want to continue?
            </p>
          </Grid>
        </Grid>
        <Grid className="modal1FoBtn modal-footer">
          <button type="button" className="modalBtn" onClick={handlecountryChangePopup}>Cancel</button>
          <button type="button" className="modalBtn" onClick={handlecountryChangeCondition}>Ok</button>
        </Grid>
      </Grid>
    </Modal>
    <Modal open={validationPopup} onClose={handleValidationPopup}>
      <Grid className='paper modalstyle validationPopup'>
        <Grid className="modal-title">
          <button className='closeModal' onClick={handleValidationPopup}></button>
        </Grid>
        <Grid className="modal-body">
          <div className="m20">
            {playlistLengthValidation ? (
              <Alert severity="warning">Your playlist needs to have more than 1 content for it to be published. Please add at least 2 videos.</Alert>
            ): ""}
          </div>
        </Grid>
        <Grid className="modal1FoBtn modal-footer ">
          <button type="button" onClick={handleValidationPopup} className="modalBtn validationpopupFooter">Close</button>
        </Grid>
      </Grid>
    </Modal>

    <Modal open={submissionStatus} onClose={handleSubmitPopup}>
      <Grid className='paper modalstyle validationPopup'>
        <Grid className="modal-title">
          <button className='closeModal' onClick={handleSubmitPopup}></button>
        </Grid>
        <Grid className="modal-body">
          <div className="m20">
            {sendDataToModal}
          </div>
        </Grid>
        <Grid className="modal1FoBtn modal-footer ">
          <button type="button" onClick={handleSubmitPopup} className="modalBtn validationpopupFooter">Close</button>
        </Grid>
      </Grid>
    </Modal>
    <Snackbar
      open={snackbarOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      onClose={() => { setSnackbarOpen(false) }}>
      <Alert
        className={`snackbar ${snackbarType}`}
        onClose={() => { setSnackbarOpen(false) }}
        severity={`${snackbarType}`}>
        {snackbarMsg}
      </Alert>
    </Snackbar>
    <VideoSelectionModal open={isOpenVideoModal} onClose={handleVideoModalClose} onSubmit={handleVideoModalSubmit} videoList={video} />
    <Grid>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
      <Grid className="content_alignCenter">
        <Grid item lg={8} xs={11} className="stepper_container">
          <PreviewAndType title={formData.title} id={id} created_by={created_by} Setcreated_by={Setcreated_by} handleChange={handleChange} ref={inputRefs} errors={errors} SetS3UploadCheck={SetS3UploadCheck} SetImageSizeCheck={SetImageSizeCheck} SetImageTypeCheck={SetImageTypeCheck} SetS3UploadErrorCheck={SetS3UploadErrorCheck} SetImageDimensionCheck={SetImageDimensionCheck}
            description={description} SetDescription={SetDescription}
            secondOwner={secondOwner} SetSecondOwner={SetSecondOwner}
            owner={owner} setOwner={setOwner}
            thumbnailUrl={thumbnailUrl} SetThumbnailUrl={SetThumbnailUrl}
            isSeries={isSeries} setIsSeries={setIsSeries}
            setImgPreview={setImgPreview} imgPreview={imgPreview}
            setIsCustomImage={setIsCustomImage} status={status} isEditMode={id} />
          {id !== 0 ? <Quiz SetQuizId={SetQuizId} quizId={quizId} SetQuizSelected={SetQuizSelected} quizSelected={quizSelected} SetQuizDetails={SetQuizDetails} quizDetails={quizDetails} /> : null}
          {videoDetails.length > 0 ?
            <VideoSelected videoDetails={videoDetails} setVideoDetails={setVideoDetails} />
            : null}

          <Affiliates
            removeAffiliates={removeAffiliates} addAffiliates={addAffiliates}
            affiliates={formData.affiliates} handleChange={handleChange} ref={inputRefs} errors={errors} publisherCountryList={publisherCountryList}
            SetCheckAffiliates={SetCheckAffiliates} checkAffiliates={checkAffiliates}
            SetAffiliatesName={SetAffiliatesName} affiliatesName={affiliatesName}
            id={id}
            setchangeCountryState={setchangeCountryState} changeCountryState={changeCountryState}
            setdetectChange={setdetectChange}
            isAffiliateAltered = {isAffiliateAltered}
            setIsAffiliateAltered = {setIsAffiliateAltered}
          />

          {formData.affiliates.length > 0 ? <Library affiliates={affiliatesName} libraries={libraries} SetLibraries={SetLibraries} /> : null}

          <TherapeuticArea ref={inputRefs} errors={errors} categoryTree={categoryTree} onCategorySelect={onCategorySelect}/>

          {formData.affiliates.length > 0 ? <Audience affiliates={affiliatesName} audience={audience} SetAudience={SetAudience}
            SetAudienceMap={SetAudienceMap} audienceMap={audienceMap} /> : null}
          <Specialities affiliates={affiliatesName} specialties={specialties} SetSpecialties={SetSpecialties} />
          {formData.affiliates.length > 0 ? <TimeRange timeRange={formData.timeRange} handleChange={handleChange} ref={inputRefs} errors={errors} setcontentlost={setcontentlost} affiliates={formData.affiliates} affiliatesName={affiliatesName} VVPMDetails={VVPMDetails} SetVVPMDetails={SetVVPMDetails} VVPMFromDate={VVPMFromDate} setVVPMFromDate={setVVPMFromDate} VVPMExpirationDate={VVPMExpirationDate} setVVPMExpirationDate={setVVPMExpirationDate} SetAffiliateObjModel={SetAffiliateObjModel} affiliateObjModel={affiliateObjModel} vvpmAffiliateMap={vvpmAffiliateMap} SetVVPMAffiliateMap={SetVVPMAffiliateMap}
          /> : null}
          {id !== 0 ? isRolePublisher ?
            <PlaylistPublish publisherCountryList={publisherCountryList} affiliatesName={affiliatesName} affiliateToPublish={affiliateToPublish} setAffiliateToPublish={setAffiliateToPublish} /> : null : null
          }

          <Grid container className="content grey_row">
            <Grid container className="row_inner_box playlistBtns">
              <Grid item lg={5} xs={12} className="text-left">
                <Button variant="outlined" className='createBtn' onClick={() => navigate('/playlist-management')}>Back</Button>
              </Grid>
              <Grid item lg={6} xs={12} className="text-right">
                <Button variant="outlined" className='createBtn ' disabled={isDisabled} onClick={async () => { await handleSubmit(); }}>Submit</Button>
                <Button variant="outlined" className='createBtn ml10' onClick={async () => { await handleSave(); }}>Save</Button>
                <Button variant="outlined" onClick={async () => { await addVideos(); }} className='createBtn ml10' color="secondary">
                  {loader === true ? <img src={download} className='content_loader' alt="Loader" /> : 'Add Content'}
                </Button>
                {/* <Button variant="outlined" className='createBtn ml10' onClick={async () => { await addVideos(); }}>Add Content</Button> */}
              </Grid>
            </Grid>
          </Grid>


        </Grid>


      </Grid>
    </Grid>
  </>
  );

}
export default PlaylistUpdate