import React from 'react';
import OneLogo from '../../../public/img/1.png';
//import TwoLogo from '../../../public/img/2.png';
import ThreeLogo from '../../../public/img/3.png';
import FourLogo from '../../../public/img/4.png';
import GlobeLogo from '../../../public/img/5.png';

const Romania = (props) => {

    React.useEffect(() => {
        var head = document.head;
        var link1 = document.createElement("link");
        link1.type = "text/css";
        link1.rel = "stylesheet";
        link1.href = window.location.origin +"/css/landing2.css";
        head.appendChild(link1);
// document.getElementById('link10').disabled = true
        return () => { 
            head.removeChild(link1);
        }
    }, []);
    
    React.useEffect(() => {
        async function InitializePlayer(kalturaId) {
            let widgetSetting = {
                'targetId': 'kaltura_player_1520843605',
                'wid': '_1759891',
                'uiconf_id': '32105511',
                'cache_st': '1520843605',
                'entry_id': kalturaId,
                'flashvars': {
                    'autoPlay': false,
                    'autoMute': true,
                    'allowMutedAutoPlay': false
                }
            };
            window.kWidget.embed(widgetSetting);
        }
        InitializePlayer('1_byikbr5g');
    },[]);

  return (
    <>
    <div className="lp-container">
      <div className="lp-header">
          <div className="inner"><span className="lilly">Lilly</span><span className="play">|</span>Play</div>
      </div>

      <div className="lp-mainContent">
          <div className="topNavBtn">
              <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonAA">Înregistrare</div></a>
              <a href name="landing-Login" className="cz-login" onClick={props.passportLogin}><div className="buttonBB">Conectare</div></a>
          </div>
          <div className="clearfix"></div>
          <div className="title">
              <h1 className="font2 promotional-video-title" title="CZ LillyPlay">Accesaţi Lilly Play!</h1>
              <h2 className="font3 promotional-video-title2">Portalul video dedicat medicilor specialişti</h2>
          </div>
          <div className="clearfix"></div>
          <div className="videoSec">
            <section>
                <section>
                    <div className="video">
                        <div id="kaltura_player_1520843605" className="first-video"></div>
                    </div>
                </section>
            </section>
          </div>
          <div className="clearfix"></div>
          <div className="centerBtn">
              <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonA">Înregistrare</div></a>
          </div>
          <div className="clearfix"></div>
          <div className="biglogo">
              <h1 className="font2">DESCOPERIŢI BENEFICIILE</h1>
              <p className="fon1"><span className="lilly">Lilly</span><span className="play">|</span><span className="colorBlack">Play</span></p>
              <p className="fon4">Acces nelimitat la materiale video dedicate specialiştilor din domeniul sănătăţii</p>
          </div>
          <div className="clearfix"></div>
          <div className="paddingbox">
              <p className="font3">
              Lilly vă pune la dispoziţie un portal video cu <b>conţinut ştiinţific accesibil oricând</b>. Experienţa clinică a celor mai <b>cunoscuţi lideri de opinie locali şi internaţionali</b> este acum la un click distanţă!
              <br/>&nbsp;<br/> Accesaţi acum <a href name="landing-Login" className="janrain_sign-in">www.lillyplay.ro</a>
              </p>
          </div>
          <table>
              <tbody>
                  <tr>
                      <td className="height20">&nbsp;</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td className="fontbox2"><img className="img1" src={OneLogo} alt="" /><br />folosit deja de peste<br/><span className="fontbox">3000</span><br />de medici din 11 ţări</td>
                      <td className="fontbox2"><img className="img2" src={GlobeLogo} alt="" /><br /><span className="fontbox"></span>Experienţă clinică din întreaga lume</td>
                  </tr>
                  <tr>
                      <td className="height20">&nbsp;</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td className="fontbox2"><img className="img3" src={ThreeLogo} alt="" /><br /><span className="fontbox"></span>Lectori apreciaţi pe plan local şi internaţional</td>
                      <td className="fontbox2"><img className="img4" src={FourLogo} alt="" /><br /><span className="fontbox"></span>Materiale video cu conţinut ştiinţific de înaltă calitate</td>
                  </tr>
              </tbody>
          </table>
          <div className="height30"></div>
          <div className="centerBtn">
              <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonA">Înregistrare</div></a>
          </div>
          <div className="height50"></div>
      </div>

      <div className="lp-footer-content">
          <footer id="myFooter">
              <div className="footer-container float_left">
                  <div className="spaceDiv"><br />&nbsp;</div>
                  <div className="footer-copyright">
                      <div className="pullLeft">Copyright © 2023 Eli Lilly and Company.&nbsp;</div>
                      <div className="pullRight">&nbsp;PP-LILLY-RO-0043&nbsp;&nbsp;</div>
                  </div>
              </div>
              <div className="height40"></div>
              <div className="navLinks">
                  <ul>
                      <li><a href="/public/romania/Termeni-de-Utilizare.html" rel="noreferrer" target="_blank" >Termeni de Utilizare</a></li>
                      <li><a href="/public/romania/Politica-de-Confidențialitate.html" rel="noreferrer" target="_blank" >Politica de Confidențialitate</a></li>
                      <li><a href="https://www.eli-lilly.ro/accessibility-statement" rel="noreferrer" target="_blank">Declarație de accesibilitate</a></li>
                      <li><a href="https://www.eli-lilly.ro/contact" rel="noreferrer" target="_blank">Contactați-ne</a></li>
                      <li><a href data-triggers-cookie-settings="true" onClick={props.cookieSettingBtnHandler}>Setări privind cookie-urile</a></li>
                  </ul>
              </div>
          </footer>
      </div>
    </div>
    </>
  )
}

export default Romania