/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@material-ui/core';
import lillyIcon from '../../../public/img/event_LillyLogo.png';
import { EventService } from '../Services/event-service';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import Diabetes from '../../../public/img/Diabetes.jpg';
import Oncology from '../../../public/img/Oncology.png';
import LillyLogo from '../../../public/img/LillyLogo.png';
import {ListboxComponent, filterOptions} from '../../shared/SearchOptimize';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { ContentService } from '../../content/Service/content-service';

const ContentEventDetails = (props) => {
    let contentService = new ContentService();
    const [openModal, setOpenModal] = React.useState(false);
    const [userData, setUserData] = React.useState([]);
    const [secondOwner, setSecondOwner] = React.useState('')
    const [activeUser, setactiveUser] = React.useState('')
    const [ImageUploadpreview, setImageUploadpreview] = React.useState(false)
    const [ImagePreview, setImagePreview] = React.useState('')
    const staticImage = ['Diabetes.jpg', 'Oncology.png', 'LillyLogo.png']
    let selectorValue = useSelector(state => state.session);
    const [s3UploadCheck, SetS3UploadCheck] = React.useState(false);
    const [imageSizeCheck, SetImageSizeCheck] = React.useState(false);
    const [imageTypeCheck, SetImageTypeCheck] = React.useState(false);
    const [s3UploadErrorCheck, SetS3UploadErrorCheck] = React.useState(false);
    const [imageDimensionCheck, SetImageDimensionCheck] = React.useState(false);
    let eventServices = new EventService();
    React.useEffect(() => {
        if (selectorValue) {
            if (selectorValue.sessionValue.payload) {
                if (selectorValue.sessionValue.payload.user) {
                    if (selectorValue.sessionValue.payload.user) {
                        setactiveUser(selectorValue.sessionValue.payload.user.id)
                    }
                }
            }
        }

    }, [selectorValue]);
    React.useEffect(() => {
        eventServices.GetAllUsers().then((data) => {
            let jsondata = data.data;
            let arr = []
            for (let i = 0; i < jsondata.length; i++) {
                if (jsondata[i].user_name != null && jsondata[i].user_name !== '') {
                    if (jsondata[i].user_name != null && jsondata[i].user_name !== ''
                        && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
                        jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
                        jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name} (${jsondata[i].user_name})`
                        arr.push(jsondata[i])
                    }
                }
                if (jsondata[i].id === props.secondOwner) {
                    setSecondOwner(jsondata[i]);
                }
            }
            setUserData(arr);
        }).catch((err) => {});
    }, []);

    React.useEffect(() => {
        let eventService = new EventService();
        if(props.avatar_attachment_id !=''){
            eventService.GetS3File("Event/Image/" + props.avatar_attachment_id).then((data) => {
                setImagePreview(data)
            });
        }
    }, [props.avatar_attachment_id]);


    const convertEmailToUsername = (user_name) => {
        let name = user_name.split('@')[0]
        let userName = name.split('_');
        return userName[1] + " " + userName[0]
    }

    const saveSecondOwner = () => {
        if(secondOwner) {
            setSecondOwner(secondOwner);
            props.SetSecondOwner(secondOwner.id);
        }
        setOpenModal(false);
    }
    const handleSecondOwner = () => {
        setOpenModal(true);
    }
    const handleModalClose = () => {
        setOpenModal(false);
    };
    const imgPreviewHandler = (file) => {
        
        SetS3UploadCheck(false);
        SetImageDimensionCheck(false);
        SetImageSizeCheck(false);
        SetImageTypeCheck(false);
        SetS3UploadErrorCheck(false);

        //Check for file size - 2 MB 
        let imageByteSize = file.size;
        if(imageByteSize > 2097152){ //2MB Limit
            SetS3UploadCheck(true);
            SetImageSizeCheck(true);
            setImageUploadpreview(false)
            return;
        } else {
            SetImageSizeCheck(false);
        }

        //Check for file type
        let imageType = file.type;
        const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!validImageTypes.includes(imageType)) {
            SetS3UploadCheck(true);
            SetImageTypeCheck(true);
            setImageUploadpreview(false)
            return;
        } else {
            SetImageTypeCheck(false);
        }

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                let image = new Image();
                image.onload = function () {
                    let width = this.width;
                    let height = this.height;
                    if (!(width === 1280 && height === 720)) {
                        SetS3UploadCheck(true);
                        SetImageDimensionCheck(true);
                        setImageUploadpreview(false)
                    } else {
                        let eventService = new EventService();
                        eventService.UploadEventFileToS3(file.name, reader.result).then((data) => {
                            eventService.GetS3File("Event/Image/" + file.name).then((data) => {
                                setImagePreview(data)
                                setImageUploadpreview(false)
                                props.setavatar_attachment_id(file.name)
                            }).catch(async (err)=> {
                                let errorObj = {
                                    user_id: props.created_by,
                                    thumbnail_name: file.name, 
                                    log_event_type:"Event", 
                                    thumbnail_size: imageByteSize, 
                                    thumbnail_height: height, 
                                    thumbnail_width: width, 
                                    thumbnail_type: imageType, 
                                    record_id: props.id,
                                    error_code: err.status?err.status:'',
                                    error_message: err.message
                                }
                                try {
                                    await contentService.UpdateS3ImagesErrorLogs(errorObj);
                                } catch (saveerr) {  }
                                SetS3UploadCheck(true);
                                SetS3UploadErrorCheck(true);
                                setImageUploadpreview(false)
                            });
                        }).catch(async (err)=> {
                            let errorObj = {
                                user_id: props.created_by,
                                thumbnail_name: file.name, 
                                log_event_type:"Event", 
                                thumbnail_size: imageByteSize, 
                                thumbnail_height: height, 
                                thumbnail_width: width, 
                                thumbnail_type: imageType, 
                                record_id: props.id,
                                error_code: err.status?err.status:'',
                                error_message: err.message
                            }
                            try {
                                await contentService.UpdateS3ImagesErrorLogs(errorObj);
                            } catch (saveerr) { }
                            SetS3UploadCheck(true);
                            SetS3UploadErrorCheck(true);
                            setImageUploadpreview(false)
                        });
                    }
                }
                image.src = reader.result;
            }
            reader.readAsDataURL(file);
        }
    }
    
    const uploadEventImage = (index) => {
        
        let eventService = new EventService();
        eventService.GetS3File("Event/Image/" + staticImage[index]).then((data) => {
            props.setavatar_attachment_id(staticImage[index])
            setImagePreview(data)
            setImageUploadpreview(false)
        });
    }

    let eventDetails = props.eventDetails;
    let event_start_date_local = (new Date(eventDetails.goodafter)).toLocaleString('en-US', { hour12: true, dateStyle: 'full', timeStyle: 'medium' });
    let event_start_date_on24 = (new Date(eventDetails.goodafter)).toLocaleString('en-US', { hour12: true, dateStyle: 'full', timeStyle: 'medium' });
    return (
        <>
        <Modal open={s3UploadCheck} onClose={()=> {SetS3UploadCheck(false)}}>
            <Grid className='paper modalstyle'>
            <Grid className="modal-title">
                <button className='closeModal' onClick={()=> {SetS3UploadCheck(false)}}></button>
            </Grid>
            <Grid className='box'>
                <Grid className='attenSec attenSecvalidationImage'>
                <span className='attenIcnSec'>
                    <ReportProblemIcon className='attenIcn' />
                    Error
                </span>
                <div className="m20">
                    {imageSizeCheck ? (
                        <Alert severity="warning">The thumbnail you selected does not meet the standard of file size. The file is too large. Please, select another thumbnail which size is less than 2.0MB</Alert>
                    ) : ""}
                    {imageTypeCheck ? (
                    <Alert severity="warning">The thumbnail you selected does not meet the standard of thumbnail format. The file format is not allowed by system. Please, select another thumbnail which formats are: .jpg, .jpeg, .png.</Alert>
                    ) : ""}
                    {imageDimensionCheck ? (
                    <Alert severity="warning">The thumbnail you selected does not meet the standard of thumbnail dimensions. The file is not of correct dimension. Please, select another thumbnail which dimensions are 1280x720px</Alert>
                    ) : ""}
                    {s3UploadErrorCheck ? (
                    <Alert severity="warning">The thumbnail you selected could not be uploaded. Please, try again later. If you submit the video now, Lilly Play will use the thumbnail that comes from Kaltura.</Alert>
                    ) : ""}
                </div>
                {/* <p className='attenTxt'>
                    The file Was unable to upload to S3. Please retry later.
                </p> */}
                </Grid>
            </Grid>
            <Grid className="modal1FoBtn modal-footer">
                <button type="button" className="modalBtn" onClick={()=> {SetS3UploadCheck(false)}}>Ok</button>
            </Grid>
            </Grid>
        </Modal>
            <Modal open={openModal} onClose={handleModalClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        Create Second Owner
                        <button className='closeModal' onClick={() => handleModalClose()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="text-center row_inner_box">
                            <FormControl component="fieldset" className='formControl'>
                                <FormLabel component="legend">Select the Owner</FormLabel>
                                <Autocomplete
                                    ListboxComponent={ListboxComponent}
                                    filterOptions={filterOptions}
                                    options={userData}
                                    value={secondOwner}
                                    onChange={(event, newValue) => {
                                        setSecondOwner(newValue);
                                    }}
                                    getOptionLabel={(option) => option.fullName || ""}
                                    renderInput={(params) => <TextField {...params} className="autocompleteInput" placeholder="Search by name of user" name="second_owner" size="small" InputLabelProps={{ shrink: false }} variant="outlined" />}
                                />
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid className="modal-footer">
                        <button type="button" onClick={() => handleModalClose()} className="modalBtn closeBtn">Close</button>
                        <button className='modalBtn' onClick={saveSecondOwner}>Save changes</button>
                    </Grid>
                </Grid>
            </Modal>
            <Grid container className='content grey_row'>
                <Grid item xs={12} lg={4} className=''>

                    <img src={ImagePreview =='' || ImagePreview == undefined || ImagePreview == 'undefined'? lillyIcon :ImagePreview} className='lillyIcon' alt='Lilly Logo ' onClick={(e) => {
                        setImageUploadpreview(true)
                    }} />
                </Grid>
                {ImageUploadpreview ?
                    <>
                        <Grid className='triangle-left'></Grid>
                        <Grid className='uploadLibInner'>
                            <Grid className='uploadHdSec'>
                                Please click image to select your choice.
                                <button className='closeModal' onClick={(e) => {
                                    setImageUploadpreview(false)
                                }
                                }></button>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} lg={7}>
                                    <ul className='uploadLiblist'>
                                        <li className='uploadLiblistInner'>
                                            <img onClick={(event) => {
                                                setImageUploadpreview(false)
                                                uploadEventImage(0)
                                            }} src={Diabetes} alt='education icon' />
                                        </li>
                                        <li className='uploadLiblistInner'>
                                            <img onClick={(event) => {
                                                setImageUploadpreview(false)
                                                uploadEventImage(1)
                                            }} src={Oncology} alt='events icon' />
                                        </li>
                                        <li className='uploadLiblistInner'>
                                            <img onClick={(event) => {
                                                setImageUploadpreview(false)
                                                uploadEventImage(2)
                                            }} src={LillyLogo} alt='promotional icon' />
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12} lg={5} className='br-left text-center'>
                                    <img className='empty-img' src={require('../../../public/img/empty.png')} alt='empty icon' />
                                    <FormControl component="fieldset" className='formControl'>

                                        <label >
                                            <input
                                                accept="image/*"
                                                className='hidden'
                                                id={"contained-button-file"}
                                                name="event_photo"
                                                type="file"
                                                onInput={(e) => imgPreviewHandler(e.target.files[0])}
                                            // onChange={ imgPreviewHandler(index) }
                                            />
                                            <Button className="event_logo_upload_btn" component="span">
                                                Upload Your Own
                                            </Button>
                                        </label>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    : null}
                <Grid item xs={12} lg={8} className='event_details'>
                    <p>Event ID: {eventDetails.event_id}</p>
                    <p>Event Title: {eventDetails.description} </p>
                    <p>Event Start Time (Local Time (Publisher)): {event_start_date_local}</p>
                    <p>Event Start Time (Platform Timezone (On24)): {event_start_date_on24} {eventDetails.displaytimezonecd}</p>
                    {(props.action == 'local') ? (<p>Status: {eventDetails.status}</p>) : ""}
                    {(props.action == 'local') ? (<p>Owner: {eventDetails.user && eventDetails.user.user_name} </p>) : ""}
                    <FormControl >
                        <FormLabel  className='flex'>
                            {(props.action == 'local') ? (<span className='font14'>Second Owner:</span>) : (<span className='font14'>Create Second Owner:</span>)}
                            {secondOwner && secondOwner.first_name ? secondOwner.first_name+' '+secondOwner.last_name : ''}
                            &nbsp;
                            {(props.action == 'live') || activeUser == props.owner ? (
                                <img src={require('../../../public/img/GreyEdit@3x.png')} width='18px' alt="Second Owner" onClick={(event) => { handleSecondOwner(); }} />
                            ) : ""}
                        </FormLabel>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}
export default ContentEventDetails