/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ContentList from '../../shared/ContentList/ContentList';
import { SearchService } from './../Service/search-service';
import Breadcrumb from '../../../layout/breadcrumb';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import loaderNewGif from '../../../public/img/new-loader.png';
import SortIcon from '@material-ui/icons/Sort';
import { useNavigate, useLocation } from "react-router-dom";
import { HomeService } from '../../home/Service/homeService'
import { HomeServiceBaseMatchingModel } from '../Service/homeService';
import { useSelector, useDispatch } from 'react-redux';
import { UserBrandService } from '../Service/userBrandService';

export let util = { loadProducts: null };

const SpeakerDetails = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    let searchService = new SearchService();
    const [sortOrder, setSortOrder] = React.useState('recent');
    const [videoData, setVideoData] = React.useState([]);
    const [hasMore, setHasmore] = React.useState(false);
    const [lastPosition, setLastPosition] = React.useState(5);
    const [startPosition, setStartPosition] = React.useState(0);
    const [allvideoData, setAllvideoData] = React.useState([]);
    const [showLoader, setShowLoader] = React.useState(false);
    const [pageLoader, setPageLoader] = React.useState(false);
    const [categoryView, setCategoryView] = React.useState(false);
    const [searchKeyword, setSearchKeyword] = React.useState('');
    const [imagePreview, setimagePreview] = React.useState(require('../../../public/img/speakerImg.png'));
    const [speciality, setSpeciality] = React.useState('');
    const [audiance, setaudiance] = React.useState('');
    let selectorValue = useSelector(state => state.session);

    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [taIdsArrData, SetTaIdsArrData] = React.useState([]);
    const [userBrandFilterData, SetUserBrandFilterData] = React.useState(false);
    const [brandCompletion, SetBrandCompletion] = React.useState(false);
    const [brandIdsArrData, SetBrandIdsArrData] = React.useState([]);
    const [indIdsArrData, SetIndIdsArrData] = React.useState([]);
    React.useEffect(() => {
        let url = window.location.href;
        let searchTerm;
        if (url.includes('category_id')) {
            searchTerm = url.split('/').pop(3).split('=').pop(1);
            setCategoryView(true);
        } else {
            searchTerm = url.split("/").pop();
            setCategoryView(false);
        }
        let searchKeyword = props.search ? props.search.toString() : searchTerm.toString();
        setSearchKeyword(decodeURI(searchKeyword.replace(/%20/g, " ")));

    }, [searchKeyword, location, categoryView]);
    const GetHomeServiceModel = (specialty = '', profession = '') => {
        let countryName = localStorage.getItem('country');
        let localeName = localStorage.getItem('lang');
        let loginROute = '';
        if (props.selectorValueSession) {
            if (props.selectorValueSession.sessionValue.payload) {
                if (props.selectorValueSession.sessionValue.payload.loginroute) {
                    loginROute = props.selectorValueSession.sessionValue.payload.loginroute
                }
                if (props.selectorValueSession.sessionValue.payload.decodedToken) {
                    if (props.selectorValueSession.sessionValue.payload.decodedToken) {
                        let _spec = props.selectorValueSession.sessionValue.payload.decodedToken.specialty ? props.selectorValueSession.sessionValue.payload.decodedToken.specialty : "";
                        let _prof = props.selectorValueSession.sessionValue.payload.decodedToken.profession ? props.selectorValueSession.sessionValue.payload.decodedToken.profession : "";

                        setSpeciality(_spec)
                        setaudiance(_prof)
                        let brandIdsArr = [];
                        let taIdsArr = [];
                        let indIdsArray = [];
                        if (props.selectorValueSession.sessionValue.payload.user) {
                            let userTA = props.selectorValueSession.sessionValue.payload.user.therapeutic_area;
                            let categories = userTA && Array.isArray(userTA) ? userTA : [];
                            let countryTAList = [];
                            let countryCat1Ids = [];
                            let countryCat2Ids = [];
                            if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)) {
                                for (let k = 0; k < props.selectorValueSession.sessionValue.payload.affiliate.length; k++) {
                                    if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].therapeutic_area_ids)) {
                                        countryTAList = countryTAList.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].therapeutic_area_ids);
                                    }
                                    if (props.selectorValueSession.sessionValue.payload.affiliate[k].speciality_customization) {
                                        if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].category_1_ids)) {
                                            countryCat1Ids = countryCat1Ids.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].category_1_ids);
                                        }
                                        if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].category_2_ids)) {
                                            countryCat2Ids = countryCat2Ids.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].category_2_ids);
                                        }
                                    }

                                }
                            }
                            if (categories?.length > 0) {
                                for (let i = 0; categories?.length > i; i++) {
                                    let ta_id = categories[i].therapeutic_area_id;
                                    if (countryTAList.includes(ta_id)) {
                                        taIdsArr.push(ta_id);
                                    }

                                    let brand_id = categories[i].brand_id;
                                    if (countryCat1Ids.includes(brand_id)) {
                                        brandIdsArr.push(brand_id);
                                    }

                                    let ind_id = categories[i].indication_id;
                                    if (countryCat2Ids.includes(ind_id)) {
                                        if (ind_id !== null) {
                                            indIdsArray.push(ind_id);
                                        }
                                    }

                                }
                            }
                        }
                        let taArray = [];
                        if (props.allThArea.includes(',')) {
                            taArray = props.allThArea.split(',');
                        } else {
                            taArray.push(props.allThArea);
                        }
                        if (props.selectorValueSession?.sessionValue?.payload?.user?.language) {
                            localeName = props.selectorValueSession?.sessionValue?.payload?.user?.language;
                            if (countryName == 'Switzerland') {
                                if (localeName == 'de-CH') {
                                    localeName = 'German'
                                } else if (localeName == 'fr_CH') {
                                    localeName = 'French'
                                }
                            } else {
                                if (localeName == 'nl-BE') {
                                    localeName = 'Dutch'
                                } else if (localeName == 'fr-BE') {
                                    localeName = 'French'
                                }
                            }
                        }

                        let model = new HomeServiceBaseMatchingModel(props.selectorValueSession?.sessionValue?.payload?.affiliate.map(x => x.country_code), props.selectedLibraryid, _spec, _prof, taArray, 'Active', brandIdsArr, indIdsArray, '', countryName, localeName, loginROute);
                        return model;
                    }
                }
            }
        }
        let taFilter = props.allThArea;
        if (typeof taFilter == 'string') {
            taFilter = taFilter.split(',')
        }
        let model = new HomeServiceBaseMatchingModel(props.selectedCountry, props.selectedLibraryid, specialty, profession, taFilter, 'Active', brandIdsArrData, indIdsArrData, '', countryName, localeName, loginROute);
        return model;
    }
    function resetQueryData() {
        setAllvideoData([]);
        setVideoData([]);
        setLastPosition(5);
        setStartPosition(0);
    }

    util.loadProducts = async () => {
        let countryName = localStorage.getItem('country') ?? '';
        let localeName = localStorage.getItem('lang') ?? '';
        let loginROute = '';
        let content_id=[];
        const paginateData = await searchService.getContentListCount(startPosition, lastPosition, searchKeyword, sortOrder, props.allThArea, props.selectedCountry, props.selectedLibraryid, speciality ? speciality : "", audiance ? audiance : "", countryName, localeName, loginROute, GetHomeServiceModel());
        if (paginateData.data.length >= 5) {
            const tempData = paginateData.data
            let list = []
            let array1 = videoData.concat(tempData);
            for (let i = 0; i < array1.length; i++) {
                if (!content_id.includes(array1[i].content_id)) {
                    list.push(array1[i])
                    content_id.push(array1[i].content_id)
                }
            }
            setVideoData(list);
            setLastPosition((prev) => prev + 5);
            setStartPosition((prev) => prev + 5);
            setHasmore(true);
            console.log('banshoman line 174',videoData)
        } else {
            const tempData = paginateData.data
            let list = []
            let array1 = videoData.concat(tempData);
            for (let i = 0; i < array1.length; i++) {
                if (!content_id.includes(array1[i].content_id)) {
                    list.push(array1[i])
                    content_id.push(array1[i].content_id)
                }
            }
            setVideoData(list);
            setLastPosition((prev) => prev + 5);
            console.log('banshoman line 179', videoData)
            setStartPosition((prev) => prev + 5);
            setHasmore(false);
        }
        return true;
    };

    util.fetchList = (startPosition, lastPosition, searchValue, orderValue) => {
        if (searchValue.length > 0) {
            let countryName = localStorage.getItem('country') ?? '';
            let localeName = localStorage.getItem('lang') ?? '';
            let loginROute = '';
            setShowLoader(true);
            searchService.getContentListCount(startPosition, lastPosition, searchValue, orderValue, props.allThArea, props.selectedCountry, props.selectedLibraryid, speciality ? speciality : "", audiance ? audiance : "", countryName, localeName, loginROute, GetHomeServiceModel()).then(resp => {
                if (resp.data?.length > 0) {
                    let tempData = (resp.data);
                    let content_id = [];
                    let array1 = videoData.concat(tempData);
                    let list = []
                    for (let i = 0; i < array1.length; i++) {
                        if (!content_id.includes(array1[i].content_id)) {
                            list.push(array1[i])
                            content_id.push(array1[i].content_id)
                        }
                    }
                    // let res = Array.from(new Set(list.map(JSON.stringify))).map(JSON.parse);
                    setHasmore(true);
                    setVideoData(list);
                    console.log('banshoman line 207', videoData)
                    setShowLoader(false);
                    setLastPosition((prev) => prev + 5);
                    let StartPoint = startPosition + 6;
                    if (startPosition === 0) {
                        StartPoint = startPosition + 6
                    }
                    // setStartPosition((prev) => prev + 5);
                    setStartPosition(StartPoint);
                    setPageLoader(false);
                } else {
                    resetQueryData();
                    setPageLoader(false);
                }
            }).catch(err => { setShowLoader(false); setPageLoader(false); });
            searchService.getContentList(searchValue, orderValue, props.allThArea, props.selectedCountry, props.selectedLibraryid, speciality ? speciality : "", audiance ? audiance : "", countryName, localeName, loginROute, GetHomeServiceModel()).then(resp => {
                if (resp.data?.length > 0) {
                    // setAllvideoData(resp.data)
                    let content_id = [];
                    let list = []
                    let tempData = resp.data
                    for (let i = 0; i < tempData.length; i++) {
                        if (!content_id.includes(tempData[i].content_id)) {
                            list.push(tempData[i])
                            content_id.push(tempData[i].content_id)
                        }
                    }
                    // let res = Array.from(new Set(list.map(JSON.stringify))).map(JSON.parse);
                    setAllvideoData(list);
                    console.log('banshoman, line 236', list)
                } else {
                    resetQueryData();
                }
            }).catch(err => { });
            setShowLoader(false);
            return true;
        }
    }

    // eslint-disable-next-line no-unused-vars
    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
        resetQueryData();
    };


    React.useEffect(() => {
        window.scrollTo(0, 0);
        setPageLoader(true);
        resetQueryData();
        util.fetchList(0, 5, searchKeyword, sortOrder);
        // util.loadProducts()
        searchService.GetSpeakerByQuery2(searchKeyword).then(resp => {
            if (resp.data[0].avatar_attachment_id) {
                (new HomeService()).GetImageFromURL(resp.data[0]?.avatar_attachment_id).then((data) => {

                    setimagePreview(data);
                });
            }

        }).catch(err => { });
        return () => {
            props.SetSearchName('');
        }


    }, [searchKeyword, sortOrder, props.allThArea, props.selectedCountry, props.selectedLibraryid, props.selectorValueSession, speciality, audiance]);

    const sectionTitle = searchKeyword;
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'Video of Speaker', path: '' }
    ]

    let param = localStorage.getItem('country');

    return (
        <>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <Grid className='outer-wrapper'>
                {categoryView ?
                    <Grid className='searchSortSec'>
                        <SortIcon className='searchSort' />
                        <span className='searchSortTxt'> {searchKeyword} </span>
                        <img alt='close' className='searchClose' onClick={() => navigate('/home')} src={require('../../../public/img/CrossGrey@3x.png')} />
                    </Grid> : null}
                <Paper className='search_header m-0-5'>
                    <Grid container alignItems="center">
                        <Grid item>
                            <img alt='img' src={imagePreview} width='40' style={{ borderRadius: "50%" }} />
                        </Grid>
                        <Grid className='section-title' item xs={8} sm={6}>
                            <span >{sectionTitle} <b>|</b> {allvideoData.length} {allvideoData.length < 2 && (param === 'Brazil'||param === 'France') ? props.translations['content found'] ? props.translations['content found'] : 'content found' : props.translations['contents found'] ? props.translations['contents found'] : 'contents found'}</span>
                        </Grid>
                    </Grid>
                </Paper>


                {pageLoader ? <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate m20Top' alt='icon' /></Grid> :
                    <>
                        {
                            videoData.length > 0 ?
                                <ContentList
                                    contentData={videoData}
                                    loadProducts={util.loadProducts}
                                    hasMore={hasMore}
                                    showLoader={showLoader}
                                    translations={props.translations}
                                />
                                : <Grid className="ml10">{props.translations['No Content Found'] ? props.translations['No Content Found'] : 'No Content Found'}</Grid>
                        }
                    </>
                }
            </Grid>
        </>
    )
}

export default SpeakerDetails