import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

const ContentShare = (props) => {
    return (
        <div className='content-share-section'>
            <Grid container>
                <Grid className='content-field' item sm={12}>
                    <FormControl component="fieldset">
                        <label className='input-label'>
                            Content Sharing
                        </label>
                        <label className='helper-text'>
                            Is this a shareable content? (If you select "Yes", the HCPs will be able to copy and share the link of this content with others)
                        </label>
                        <RadioGroup className='radio-input' name="content_type" value={props.shareable} onChange={(e) => props.setShareable(e.target.value)} >
                            <FormControlLabel disabled={!props.enableFields} className='radio-options' value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel disabled={!props.enableFields} className='radio-options' value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
}

export default ContentShare;