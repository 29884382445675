import React from 'react';
import { FormLabel, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { TranslationService } from '../services/translation-service';
import { useForm } from 'react-hook-form';

const TranslationEdit = (props) => {
  const activeUser = localStorage.getItem('userID');

  const onSubmit = (formData) => {
    const data = {
      locale: props.data.locale,
      text: formData.text,
      key: props.data.key,
      updated_at: new Date(),
      updated_by: activeUser,
      translation_id: props.data.id,
    };
    const service = new TranslationService();
    service.translationEdit(data).then((data) => {
      props.closePopUp(false);
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      text: props.data.text,
    },
    mode: 'onChange',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className='paper modalstyle'>
        <Grid className='modal-title-2'>
          Translation Edit:
          <button
            className='closeModal'
            onClick={() => props.closePopUp(false)}
          ></button>
        </Grid>

        <Grid className='modal-body'>
          <FormControl component='fieldset' className='formControl'>
            <FormLabel
              htmlFor='locale'
              className='font-weight-bold'
              required
            >
              Translation Locale
            </FormLabel>
            <TextField
              disabled={true}
              variant='outlined'
              size='small'
              placeholder='Locale Name'
              name='locale'
              value={props.data.locale}
            />
          </FormControl>

          <FormControl component='fieldset' className='formControl'>
            <FormLabel
              htmlFor='Key'
              className='font-weight-bold'
              required
            >
              Translation Key
            </FormLabel>
            <TextField
              disabled={true}
              variant='outlined'
              size='small'
              placeholder='Key'
              name='key'
              value={props.data.key}
            />
          </FormControl>

          <FormControl component='fieldset' className='formControl'>
            <FormLabel
              htmlFor='text'
              className='font-weight-bold'
              required
            >
              Translation Text
            </FormLabel>
            <TextField
              className='textAreaStyle'
              variant='outlined'
              size='small'
              multiline
              minRows={3}
              error={!!errors.text}
              helperText={!!errors.text && 'Translation text is required.'}
              {...register('text', { required: true })}
            />
          </FormControl>
        </Grid>
        <Grid className='modal-footer' container justifyContent='flex-end'>
          <button
            type='button'
            className='modalBtn'
            onClick={() => {
              props.closePopUp(false);
            }}
          >
            Cancel
          </button>
          <button type='submit' className='modalBtn'>
            OK
          </button>
        </Grid>
      </Grid>
    </form>
  );
};
export default TranslationEdit;
