





class KalturaMediaEntry
{
    constructor(_name , _description )
    {
        this.mediaType  = 1;
        this.name  = _name;
        this.description  = _description;
    }
}
class VVPMModel{
  constructor(_docName='', _subType='', _code ='', _classification = '')
  {
    this.docName = _docName;
     this.subType = _subType;
      this.code= _code; 
      this.classification = _classification;
  }
}
export {KalturaMediaEntry, VVPMModel}