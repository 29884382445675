import React from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import approvedIcon from '../../../public/img/approvedIcon.png';
import approvalPendingIcon from '../../../public/img/approvalPendingIcon.png';
import rejectDisabled from '../../../public/img/rejectDisabled.png';
import rejectEnabled from '../../../public/img/rejectEnabled.png';
import ContentModal from '../Components/ContentModal';
// import '../content-update/content-update.css';

const PlaylistPublish = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [isContentAvailable, setIsContentAvailable] = React.useState(false);
  const [sendDataToModal, setSendDataToModal] = React.useState("");
  const modalFunc = React.useRef(null);
 
  const updateStatus = (affiliate) => {
    modalFunc.current();
    let msg = "Playlist is active in " + props.affiliateToPublish[affiliate].name;
    setSendDataToModal(msg);
    var filteredArray = {...props.affiliateToPublish};
    let selectedKeys = Object.keys(props.affiliateToPublish)
    for (let i=0;i<selectedKeys.length; i++) {
      let key = selectedKeys[i];
      if(affiliate === key) {
        filteredArray[key] = {
          name: props.affiliateToPublish[key].name,
          status: "Active"
        }
      }
    }
    props.setAffiliateToPublish(filteredArray);
  }

  const removeAffiliate = (affiliate) => {
    modalFunc.current();
    let msg = "Playlist is inactive in " + props.affiliateToPublish[affiliate].name;
    setSendDataToModal(msg)
    var filteredArray = {...props.affiliateToPublish};
    let selectedKeys = Object.keys(props.affiliateToPublish)
    for (let i=0;i<selectedKeys.length; i++) {
      let key = selectedKeys[i];
      if(affiliate === key) {
        filteredArray[key] = {
          name: props.affiliateToPublish[key].name,
          status: "Inactive"
        }
      }
    }
    props.setAffiliateToPublish(filteredArray);
  }

  React.useEffect(() => {
    
    if(Object.keys(props.affiliateToPublish).length>0) {
      setIsContentAvailable(true);
    } else {
      setIsContentAvailable(false);
    }
  }, [props.affiliatesName]);

  return (
    <Grid container className=" content white_row contentPublish">
      <Grid item lg={12} xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Country to Publish</FormLabel>
          <Grid>
            <table className="vvpmTbl">
              <thead>
                <tr>
                  <th width="80%">Country</th>
                  <th width="10%">Status</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {Object.keys(props.affiliateToPublish).length>0 ? Object.keys(props.affiliateToPublish).map((affiliate, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td width="70%">{props.affiliateToPublish[affiliate].name}</td>
                          <td width="15%">{props.affiliateToPublish[affiliate].status}</td>
                          <td width="15%" className="selectedActionToPublish"> 
                            <Grid container item lg={12}>
                              <Grid item lg={6} xs={12}>
                                {props.affiliateToPublish[affiliate].status === "Active" ?
                                  <img src={approvedIcon} className={!props.publisherCountryList[affiliate] ? 'disabledapprovedIconContent' : 'approvedIconContent'} alt="Approval Icon"   /> :
                                  <img src={approvalPendingIcon} className={!props.publisherCountryList[affiliate] ? 'disabledapprovedIconContent' : 'approvedIconContent'} alt="Approval Pending Icon" onClick={() => updateStatus(affiliate)} />
                                }
                              </Grid>
                              <Grid item lg={6} xs={12}>
                                {props.affiliateToPublish[affiliate].status === "Inactive" ?
                                  <img src={rejectDisabled} className={!props.publisherCountryList[affiliate] ? 'disabledapprovedIconContent' : 'approvedIconContent'} alt="Approval Rejected Icon"  /> :
                                  <img src={rejectEnabled} className={!props.publisherCountryList[affiliate] ? 'disabledapprovedIconContent' : 'approvedIconContent'} onClick={() => removeAffiliate(affiliate)} alt="Approval Rejected Icon"  />
                                }
                              </Grid>
                              <ContentModal modalFunc={modalFunc} sendDataToModal={sendDataToModal} />
                            </Grid>
                          </td>
                        </tr>
                      </>
                    )
                  }) : null}
                </>
              </tbody>
            </table>
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  )
}
export default PlaylistPublish