import { React, useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { useNavigate } from "react-router-dom";
import Typography from '@material-ui/core/Typography';


const UpNext = (props) => {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(8);
    useEffect(() => {
        const element = document.getElementById('player-content-widget');
        if (element) {
            element.innerHTML = '';
        }
    });
    useEffect(() => {
        let interval;
        if (counter > 0) {
            interval = setInterval(() => setCounter(counter - 1), 1000);
        }
        return () => {
            clearInterval(interval)
        }
    }, [counter]);


    return (
        <Grid style={{ paddingTop: "2%" }}>
            <Typography variant="h4" style={{ color: "white", paddingTop: "2%" }}>{props.translations['Next Video:'] ? props.translations['Next Video:'] : 'Next Video:'}</Typography>
            <Typography variant="h4" style={{ color: "white", paddingTop: "2%" }}>{props.title}</Typography>
            <Typography style={{ paddingTop: "2%", size: "20px" }}>
                <a href onClick={() => { navigate(`/content-watch/${props.kaltura_id}/${props.content_id}`); props.stateChanger(true); }}>
                    <img src={require('../../../public/img/videoplayer/icon-play-next.png')}
                        alt='icon' /></a>
                {counter === 0 ? (props.stateChanger(true), navigate(`/content-watch/${props.kaltura_id}/${props.content_id}`)) : null}
            </Typography>
            <Typography variant="h4" style={{ color: "white", paddingTop: "2%" }}>{props.translations['Video will play in {{variable}} seconds.'] ? String(props.translations['Video will play in {{variable}} seconds.']).replace('{{variable}}', counter) : String('Video Will Play in {counter} seconds').replace('{counter}', counter)}</Typography>
        </Grid>
    )

}

export default UpNext;