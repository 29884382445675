import axios from 'axios';
class TAModel{
    constructor(_ta, _cat1, _cat2){
        this.ta = _ta;
        this.cat1 = _cat1;
        this.cat2 = _cat2
    }
}

class EventService {

    constructor(jwtToken)
    {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken?jwtToken:localStorage.getItem('jwtToken')
            }
        };
    }

    async GetAllArea() {
        return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/category/GetTherapeuticAreaByQuery?del=false', {}, this.authHeaders);
    };
    async GetAllBrands () {
        return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/category/GetBrandByQuery?del=false', {}, this.authHeaders);
    };
    async GetAllIndications() {
        return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/category/GetIndicationByQuery?del=false', {}, this.authHeaders);
    };

    async GetAllSpecialities() {
        return await axios.post(this.baseUrl + '/management/api/country/GetSpecialtyByQuery', {}, this.authHeaders);
    }
    async GetEventsByQuery(params) {
        let qs = "";
        if(params) {
            qs += "?";
            if(params.searchKeyword) {
                qs += '&title='+ params.searchKeyword;
            }
            if(params.country_code) {
                qs += '&countries='+ params.country_code;
            }
            if(params.affiliate_id) {
                qs += '&countries='+ params.affiliate_id;
            }
            if(params.user_id) {
                qs += '&user_id='+ params.user_id;
            }
            if(params.event_id) {
                qs += '&event_id='+ params.event_id;
            }
        }
        return axios.post(process.env.REACT_APP_BASE_URL +'/content/api/event/GetEventsByQuery'+ qs, {}, this.authHeaders);
    };
    async GetEventsByFilter(params) {
        let qs = "";
        if(params) {
            qs += "?";
            if(params.searchKeyword) {
                qs += '&title='+ params.searchKeyword;
            }
            if(params.country_code) {
                qs += '&countries='+ params.country_code;
            }
            if(params.affiliate_id) {
                qs += '&countries='+ params.affiliate_id;
            }
            if(params.user_id) {
                qs += '&user_id='+ params.user_id;
            }
            if(params.event_id) {
                qs += '&event_id='+ params.event_id;
            }
        }
        return axios.post(process.env.REACT_APP_BASE_URL +'/content/api/event/GetEventsByFilter'+ qs, {}, this.authHeaders);
    };
    async GetAllUsers() {
        return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/user/GetUsersIdByRoles', {"roles":["2","3","4"]}, this.authHeaders);
    };
    async GetAllAffiliates() {
        return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/user/GetAffiliateByQuery', {}, this.authHeaders);
    };
    async GetOn24Events(params, pagecount) {
        let qs = "";
        if(params) {
            qs += "?";
            if(params.searchKeyword) {
                qs += '&description='+ params.searchKeyword;
            }
        }
        qs+='&pageOffset='+pagecount;
        return axios.post(process.env.REACT_APP_BASE_URL + '/content/api/event/GetOn24Events' + qs, {}, this.authHeaders);
    };
    
    async GetAllCategory() {
        return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/category/GetAllCategory', {}, this.authHeaders);
    };
    
    async GetSpeakersByQuery() {
        return axios.post(process.env.REACT_APP_BASE_URL +'/management/api/speaker/GetSpeakersByQueryPaginationCout', {}, this.authHeaders);
    };

    async SendEmail(title, country, created, link, publisherEmail) {
        let obj = {
            'mail_type': 'event_uploaded',
            'to': publisherEmail,
            'event_name': title,
            'country': country,
            'created_at': created,
            'event_link': link,
            'subject': 'Event Uploaded : '+title
        }
        return axios.post(process.env.REACT_APP_BASE_URL +'/content/api/aws/SendEmail', obj, this.authHeaders);
    };
    
    async GetPublishersByCountry(param) {
        return axios.post(this.baseUrl + '/management/api/user/GetPublishersByCountry?' + param, {}, this.authHeaders);
    };
    
    async UpdateEvents(modal) {
        return axios.post(process.env.REACT_APP_BASE_URL +'/content/api/event/UpdateEvents', modal, this.authHeaders);
    };

    async DeleteEvents(modal) {
        return axios.post(process.env.REACT_APP_BASE_URL +'/content/api/event/DeleteEvents', {event_id: modal}, this.authHeaders);
    };
    async GetAllLibrary() {
        return axios.post(process.env.REACT_APP_BASE_URL +'/management/api/library/GetAllLibrary', {}, this.authHeaders);
    };
    async GetAllHubs  ()  {
        return axios.post(process.env.REACT_APP_BASE_URL + '/management/api/user/GetHubsByQuery', {}, this.authHeaders);
    };
    async GetCategory2fromIds(ids)
    {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetCategory2ByTaIds', 
        ids, this.authHeaders);
        return data.data;
    }
    async GetCategory1fromIds(ids)
    {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetCategory1ByTaIds', 
        ids, this.authHeaders);
        return data.data;
    }
    async GetTAfromTAIds(ids)
    {
        let data = await axios.post(this.baseUrl + '/management/api/category/GetTherapeuticAresByTaIds', 
        ids, this.authHeaders);
        return data.data;
    }
    async GetAllLibrary() {
        return axios.post(this.baseUrl +'/management/api/library/GetAllLibrary', {}, this.authHeaders);
    };
    async UploadEventFileToS3(fileName, renderResult){
        let _key = 'Event/Image/'+ fileName;
        let _result = renderResult;
        let dataUrl = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedUrlPutObject', {key : _key}, this.authHeaders);
        return await axios.put(dataUrl.data, renderResult);//dataUrl;
    }
    async GetS3File(key){
        try{
            if(key !=null && key.length>0){
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedUrlGetObject', {key : key.trim()}, this.authHeaders);
                return (await axios.get(data.data)).data//data.data.binarySring;
            } else{
                return null;
            }
        }catch(err){
            return null;
        }
    }
    
}
export {EventService}