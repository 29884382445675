import React from 'react';
import HeaderIMG from '../../../public/img/MEA-header-banner.png';
import ContentIMG from '../../../public/img/MEA-main-banner.png';
import FooterIMG from '../../../public/img/MEA-sign-in-banner.png';

const Mea = (props) => {
  const [disabled, SetDisabled] = React.useState(true);
  const handleChange = (value) => {
    if(value !== '0') {
      SetDisabled(false);
    } else {
      SetDisabled(true);
    }
  }

  React.useEffect(() => {
    var head = document.head;
    var link1 = document.createElement("link");
    link1.type = "text/css";
    link1.rel = "stylesheet";
    link1.href = "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";
    head.appendChild(link1);

    var link2 = document.createElement("link");
    link2.type = "text/css";
    link2.rel = "stylesheet";
    link2.href = window.location.origin +"/css/landing6.css";
    head.appendChild(link2);

    var cookieLink = document.createElement("link");
    cookieLink.type = "text/css";
    cookieLink.rel = "stylesheet";
    cookieLink.href = window.location.origin +"/css/cookie-banner.css";
    head.appendChild(cookieLink);
// document.getElementById('link10').disabled = true
    var cookieVariable = document.cookie.indexOf('acceptCookies');
    if(cookieVariable !== -1) {
        document.getElementById('cookieConsent').style.display = 'none';
    }

    return () => { 
      head.removeChild(link1); 
      head.removeChild(link2);
      head.removeChild(cookieLink);
    }
  }, []);

  const handleAcceptBtn = () => {
    var d = new Date();
    d.setTime(d.getTime() + (30*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = "acceptCookies='true';" + expires;
    document.getElementById('cookieConsent').style.display = 'none';
  }
  const handleRejectBtn = () => {
    document.getElementById('cookieConsent').style.display = 'none';
  }
  return (
    <>
      {/* <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"></link>
      <link rel="stylesheet" href={window.location.origin +"/css/landing6.css"}></link> */}

      <div id="cookieConsent" className="cookie-consent">
          <div className="cookie-consent-content-container wrapper">
              <div className="cookie-consent-message-container">
                  <h5>&nbsp;</h5>
                  <p>
                      Our Website Uses Cookies. We and third parties we work with use cookies to collect 
                      information about your internet browsing activities to provide you relevant content 
                      and advertising on this and other websites, media and devices you use, 
                      and to understand your interests to improve the site. 
                      For more information on our use of cookies, please view our full
                      &nbsp;<a href="https://www.lillyprivacy.com/mea-en/hcp" rel="noreferrer" target="_blank">Privacy Statement</a>. Click 'Accept' to continue.
                  </p>
              </div>
              <div className="cookie-consent-btn-container">
                  <button type="button" className="button-base btn-settings btn btn-default" id="acceptBtn" onClick={handleAcceptBtn}>Cancel</button>
                  <button type="button" className="button-base button btn-accept btn btn-default" id="rejectBtn" onClick={handleRejectBtn}>Accept</button>
              </div>
          </div>
      </div>

      <table bgcolor="#FFFFFF" cellpadding="0" cellspacing="0" className="nl-container" role="presentation" valign="top" width="100%">
        <tbody>
          <tr valign="top">
            <td valign="top">
              <div>
                <div className="block-grid">
                  <div className="block-grid-inner">
                    <div className="col num12">
                      <div>
                        <div className="block-grid-inner2">
                          <div align="center" className="img-container center autowidth">
                            <img align="center" alt="Lilly Play Logo" border="0" className="center autowidth" src={HeaderIMG} title="Alternate text" width="900" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="block-grid">
                  <div className="block-grid-inner">
                    <div className="col num12">
                      <div>
                        <div className="block-grid-inner2">
                          <div align="center" className="img-container center autowidth">
                            <a href="#cokkie" tabindex="-1" target="_blank"> 
                              <img align="center" alt="Lilly Play MEA" border="0" class="center autowidth" src={ContentIMG} title="Alternate text" width="900" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="block-grid">
                  <div className="block-grid-inner">
                    <div className="col num12">
                      <div>
                        <div className="block-grid-inner2">
                          <div align="center" className="button-container center autowidth">
                            <div className="declare">
                              <div className="row">
                                  <div className="col-md-3 col-sm-12"></div>
                                  <div className="col-md-5 col-sm-12"><p className="textp">Please select your country of residence:</p></div>
                                  <div class="col-md-4 col-sm-12"></div>
                              </div>
                              <div class="row login_row">
                                  <div class="col-md-3 col-sm-12"></div>
                                  <div class="col-md-3 col-sm-12 countryList">
                                      <select id="country_list" class="form-control" onChange={e => handleChange(e.target.value)}>
                                        <option value="0">Select your country</option>
                                        <option value="1" class="firstlevel">Iraq</option>    
                                        <option value="2" class="firstlevel">Kuwait</option>
                                        <option value="3" class="firstlevel">Lebanon</option>
                                        <option value="4" class="firstlevel">Oman</option>
                                        <option value="5" class="firstlevel">Pakistan</option>
                                        <option value="6" class="firstlevel">Qatar</option>
                                        <option value="7" class="firstlevel">United Arab Emirates</option>
                                      </select>
                                  </div>
                                  <div class="col-md-3">
                                      <button disabled={disabled} id="country_continue" class="btn btn-default" type="submit" onClick={props.passportLogin}>Continue</button>
                                  </div>
                                  <div class="col-md-3 col-sm-12"></div>
                                  <div class="col-md-12 nocountry_warning hidden">
                                      Lilly Play is not implemented in your affiliate, please wait for the great things to come.
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="block-grid">
                  <div className="block-grid-inner">
                    <div className="col num12">
                      <div>
                        <div className="block-grid-inner2">
                          <div align="center" className="img-container center autowidth">
                            <a id="country_continue" href="#cookie" tabindex="-1" target="_blank"> 
                              <img align="center" alt="Lilly Play MEA Sign In" border="0" class="center autowidth" src={FooterIMG} title="Alternate text" width="900" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="block-grid">
                  <div className="block-grid-inner">
                    <div className="col num12">
                      <div>
                        <div className="block-grid-inner2">
                          <div align="center" className="footer-container center autowidth">
                          <div className="footer_inner">
                            <p>
                              <span>PP-LILLY-AE-0039 | 
                                <a href="https://www.lillyprivacy.com/mea-en/hcp" rel="noreferrer" target="_blank">Privacy Policy</a> | 
                                <a href="/public/mea/terms-of-use.html" rel="noreferrer" target="_blank">Terms of Use</a>
                              </span> 
                              <span>| 
                                <a href="/public/mea/accessibility_link.html" rel="noreferrer" target="_blank">Accessibility Statement</a>| 
                                <a href="/public/mea/contact-us.html" rel="noreferrer" target="_blank">Contact Us</a>
                              </span>
                            </p>
                            <p><span>© 2022 Eli Lilly and Company. All Rights Reserved. </span></p>
                            <p><span>This webpage is intended for healthcare professionals only.</span></p>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default Mea