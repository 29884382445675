/* eslint-disable linebreak-style */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SpeakerManagement from './speaker-management/speaker-management';
import Forbidden from '../../forbidden/forbidden'

const SpeakerRoute = (props) => (
    <Routes>
        {props.isRoleAdmin || props.isRolePublisher ? <Route exact path="/speaker-management" element={<SpeakerManagement translations={props.translations} setFilter={props.setFilter}/>}/>: 
        <Route exact path="/speaker-management" element={<Forbidden translations={props.translations} />}/>
        }
    </Routes>
);

export default SpeakerRoute;