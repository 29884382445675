import axios from 'axios';

class userManagementService {

    constructor(jwtToken) {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken ? jwtToken : localStorage.getItem('jwtToken')
            }
        };
    }

    async GetAllUsers(params, start, end) {
        let qs = "";
        let bodyParam = {}
        if (params) {
            qs += "?";
            if (params.searchKeyword) {
                let first_name = params.searchKeyword.split(' ')[0]
                let last_name = params.searchKeyword.split(' ')[1]
                qs += '&first_name=' + first_name;
                if(last_name){
                    qs += '&last_name=' + last_name;
                }  
                // qs += '&last_name=' + last_name;
            }
            if (params.affiliate_code) {
                qs += '&country_code=' + params.affiliate_code;
            }
            if (params.affiliate_id) {
                qs += '&country_id=' + params.affiliate_id;
            }
            if (params.role_id) {
                qs += '&role_ids=' + params.role_id;
            }
            if (params.sorting) {
                bodyParam = {sorting:params.sorting};
            }
            qs += '&start='+start
            qs += '&end='+end
        }
        return axios.post(this.baseUrl + '/management/api/user/GetUsersByQueryPagination' + qs, bodyParam, this.authHeaders);
    };

    async GetUsersByQueryCount(params) {
        let qs = "";
        if (params) {
            qs += "?";
            if (params.searchKeyword) {
                let first_name = params.searchKeyword.split(' ')[0]
                let last_name = params.searchKeyword.split(' ')[1]
                qs += '&first_name=' + first_name;
                if(last_name){
                    qs += '&last_name=' + last_name;
                }                // qs += '&last_name=' + last_name;
            }
            if (params.affiliate_code) {
                qs += '&country_code=' + params.affiliate_code;
            }
            if (params.affiliate_id) {
                qs += '&country_id=' + params.affiliate_id;
            }
            if (params.role_id) {
                qs += '&role_ids=' + params.role_id;
            }
            // qs += '&start='+start
            // qs += '&end='+end
        }
        return axios.post(this.baseUrl + '/management/api/user/GetUsersByQueryCount'+ qs, {}, this.authHeaders);
    };

    async GetAllAffiliates() {
        return axios.post(this.baseUrl + '/management/api/user/GetAffiliateByQuery', {}, this.authHeaders);
    };

    async GetAllRoles() {
        return axios.post(this.baseUrl + '/management/api/user/GetRolesByQuery', {}, this.authHeaders);
    };

    async DeleteUser(lilly_id) {
        return axios.post(this.baseUrl + '/management/api/user/DeleteUser?id=' + lilly_id, {}, this.authHeaders);
    };

}

export { userManagementService }