import axios from 'axios';

class TranslationService {

    constructor(jwtToken)
    {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken?jwtToken:localStorage.getItem('jwtToken')
            }
        };
        // this.url = "http://localhost:5001";
        this.url = process.env.REACT_APP_BASE_URL;
    }

 
    async GetAllTranslation() {
        //return axios.post(process.env.REACT_APP_BASE_URL +`/management/api/translation/GetTranslation`, {}, this.authHeaders);
        return axios.post(this.url +`/management/api/translation/GetTranslation`, {}, this.authHeaders);

    };

    async UpdateTranslation(data) {
        return axios.post(this.url +`/management/api/translation/UpdateTranslation`, data, this.authHeaders);
    };

    async DeleteTranslation(data) {
        return axios.post(this.url +`/management/api/translation/DeleteTranslation`,{ ids:{translation_id:data}}, this.authHeaders);
    };    

    async getFilteredTranslation(obj) {
        return axios.post(this.url +`/management/api/translation/getFilteredTranslation`,obj, this.authHeaders);
    }

    async translationEdit(data) {
        return axios.post(this.url +`/management/api/translation/UpdateTranslation`,data, this.authHeaders);
    }

    async translationCreate(data) {
        return axios.post(this.url +`/management/api/translation/translationCreate`,data, this.authHeaders);

    }

}



export {TranslationService}