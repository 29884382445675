import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const Accordion = withStyles({
    root: {
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        boxShadow: 'none',
        width: '100%',
        margin: '5px',
        '&.Mui-disabled': {
            backgroundColor: '#fff',
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '5px',
            borderRadius: '4px',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        flexDirection: 'row-reverse',
        minHeight: '35px!important',
        backgroundColor: '#EAECF0',
        '&.disabled': {
            backgroundColor: '#fff!important',
            '& .MuiAccordionSummary-expandIcon': {
                display: 'none'
            }
        },
        '& .MuiAccordionSummary-expandIcon': {
            padding: '0!important',
            color: '#475467'
        },
        '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        label: {
            margin: '0px',
        },
    },
    content: {
        margin: '0!important',
        padding: '0px 0px 0px 20px',
        fontSize: '14px',
        fontWeight: '600',
        color: '#475467',
    }
})(MuiAccordionSummary)

const AccordionDetails = withStyles({
    root: {
        display: 'grid',
        gridTemplateRows: '0.5fr 1fr',
        '&.disabled': {
            display: 'none',
        },
        '& .subcategory': {
            marginTop: '20px',
        },
        '& .heading': {
            color: '#83786E',
            textTransform: 'uppercase',
            fontSize: '14px',
            width: '100%',
        },
        '& .accordian-content': {
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
        }
    }
})(MuiAccordionDetails)

export const CustomCheckbox = withStyles({
    root: {
        color: 'white',
        '& .MuiIconButton-label': {
            border: '1px solid #D0D5DD',
            borderRadius: '4px',
        },
        '&$checked': {
            color: 'white',
            '& .MuiIconButton-label': {
                position: "relative",
                zIndex: 0,
                border: '1px solid #D92D20',
                borderRadius: '4px',
            },
            '& .MuiIconButton-label:after': {
                content: '""',
                left: 4,
                top: 4,
                height: 15,
                width: 15,
                position: 'absolute',
                backgroundColor: '#D92D20',
                zIndex: -1
            }
        },
        '&.Mui-disabled': {
            color: ' rgba(0, 0, 0, 0)',
            backgroundColor: 'transparent',
            '&$checked': {
                color: 'white',
                '& .MuiIconButton-label': {
                    position: "relative",
                    zIndex: 0,
                    border: '1px solid #D92D20',
                    borderRadius: '4px',
                },
                '& .MuiIconButton-label:after': {
                    content: '""',
                    left: 4,
                    top: 4,
                    height: 15,
                    width: 15,
                    position: 'absolute',
                    backgroundColor: '#D92D20',
                    zIndex: -1
                }
            },
        }
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomAccordain = ({name, defaultExpanded, disabled, children}) => {
    const [expanded, setExpanded] = React.useState('');

    useEffect(() => {
        defaultExpanded && setExpanded('panel1')
    }, [defaultExpanded]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>
            <Accordion
                disabled={disabled}
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}>
                <AccordionSummary
                    className={disabled ? 'disabled' : ''}
                    expandIcon={<KeyboardArrowRightIcon color='#475467' />}
                    aria-controls="panel1d-content" id="panel1d-header">
                    <label> {name} </label>
                </AccordionSummary>
                <AccordionDetails
                    className={disabled ? 'disabled' : ''}
                >
                    {children}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default CustomAccordain