import { useState, useCallback, useRef } from 'react';
import validateField from '../helpers/validation';

const useCustomForm = (initialState, initialFieldRules = {}) => {
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [fieldRules, setFieldRules] = useState(initialFieldRules);
  const inputRefs = useRef({});

  // Handle changes to form fields, without triggering validation
  const setValue = useCallback((key, data) => {
    setFormData((prevState) => ({ ...prevState, [key]: data }));
  }, []);

  // Handle changes to form fields
  const handleChange = useCallback(
    (key, data) => {
      setFormData((prevState) => ({ ...prevState, [key]: data }));

      // Validate the field on change
      const fieldErrors = validateField(key, data, fieldRules[key]);

      // Create a copy of the current errors and update the specific field error
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };

        // If validation failed, add the error; otherwise, delete the field error
        if (fieldErrors[key]) {
          newErrors[key] = fieldErrors[key];
        } else {
          delete newErrors[key];
        }

        return newErrors;
      });
    },
    [fieldRules]
  );

  // Validate the entire form
  const validateForm = useCallback(
    (params = {}) => {
      let isValid = true;
      const formErrors = {};
      let focusedOnInvalid = false;

      // Loop through all fields to validate them
      Object.keys(formData).forEach((field) => {
        if (params.skip?.includes(field)) {
          return;
        }
        const error = validateField(field, formData[field], fieldRules[field]);
        if (error[field]) {
          formErrors[field] = error[field];
          // Focus on the first invalid field
          if (!focusedOnInvalid) {
            inputRefs.current[field]?.focus();
            focusedOnInvalid = true;
          }
          isValid = false;
        }
      });

      setErrors(formErrors);
      return isValid;
    },
    [formData, fieldRules]
  );

  // A helper function to update fieldRules dynamically
  const updateFieldRules = (newRules) => {
    setFieldRules((prevRules) => ({ ...prevRules, ...newRules }));
  };

  return {
    formData,
    errors,
    handleChange,
    validateForm,
    inputRefs,
    setValue,
    updateFieldRules
  };
};

export default useCustomForm;
