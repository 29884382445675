import React from 'react';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { UserProfileService } from '../Services/user-profile-service';
import { BlackTriangleUser } from "../../shared/BlackTriangle";
import { useSelector } from 'react-redux';
import {useQuery} from 'react-query'

import axios from 'axios';

const TherapeuticArea = (props) => {
  let userProfileService = new UserProfileService();
  const [categoryTree, SetCategoryTree] = React.useState([]);
  const [innerChk, setInnerChk] = React.useState([]);
  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [libraryActive, SetlibraryActive] = React.useState(3);
  const [taToggle, SettaToggle] = React.useState(false);
  const [taIds, SettaIds] = React.useState([]);
  const [cat1Ids, Setcat1Ids] = React.useState([]);
  const [cat2Ids, Setcat2Ids] = React.useState([]);

  const getAllCategory = async () => {
    return !isLoading ?  finalAllCategoryData : null;
  }; 

  const fetchAffiliateCategory = async () => {

 let data = await axios.post(userProfileService.baseUrl + '/management/api/category/GetAllCategory?del=false', {}, userProfileService.authHeaders);
 return (data);
  }

  const {data : finalAllCategoryData, isLoading} = useQuery({
  queryKey: ['all_category_data'],
  queryFn: fetchAffiliateCategory
   })
   
  React.useEffect(() => {
    SetSessionState(selectorValue);
  }, [selectorValue, props.TADetails])

  React.useEffect(() => {
    if (sessionState.sessionValue?.payload?.affiliate?.length) {
      SetlibraryActive(sessionState.sessionValue.payload.affiliate[0].library_active);
      SettaToggle(sessionState.sessionValue.payload.affiliate[0].ta_toogle);
      SettaIds(sessionState.sessionValue.payload.affiliate[0].therapeutic_area_ids);
      Setcat1Ids(sessionState.sessionValue.payload.affiliate[0].category_1_ids);
      Setcat2Ids(sessionState.sessionValue.payload.affiliate[0].category_2_ids);
      
    } else {
      let country = localStorage.getItem('country');
      if (country) {
        userProfileService.GetAffiliateByCountryName({ affiliate_id: country }).then((data) => {
          SetlibraryActive(data.data[0].library_active);
          SettaToggle(data.data[0].ta_toogle);
          SettaIds(data.data[0].therapeutic_area_ids);
          Setcat1Ids(data.data[0].category_1_ids);
          Setcat2Ids(data.data[0].category_2_ids);
          
        })
      }
    }
  }, [sessionState]);

  React.useEffect(() => {
    getAllCategory().then((data) => {
      let jsondata = data.data;
      let list = []
      if (jsondata.length > 0) {
        for (let i = 0; i < jsondata.length; i++) {
          if (taIds.includes(jsondata[i].id)) {
            list.push(jsondata[i])
          }
        }
        SetCategoryTree(list);
      }
    }).catch((err) => {});
  }, [taIds, props.selectedCategories, sessionState, finalAllCategoryData]);

  React.useEffect(() => {
    setInnerChk(props.TADetails);
  }, [props.TADetails, props.selectedCategories])

  React.useEffect(() => {
  }, [categoryTree])
  const showInner = (cat_id, value) => {
    let innerChkArr = { ...innerChk };
    innerChkArr[cat_id] = value;
    setInnerChk(innerChkArr);
  }
  const handleChkCategories = (isCheked, ta_id, brand_id, ind_id) => {
    
    let list = [];
    let UID = ta_id + "_" + brand_id;
    if (ind_id !== null) {
      UID += "_" + ind_id;
    }
    if (isCheked) {
      list.push(UID);
      list = props.selectedCategories.concat(list);
      props.SetSelectedCategories(list);
      if (props.SetIsTherapeuticDirty) {
        props.SetIsTherapeuticDirty(true);
      }
    } else {
      list = props.selectedCategories.concat(list);
      let index = list.indexOf(UID);
      if (index !== -1) {
        list.splice(index, 1);
      }
      props.SetSelectedCategories(list);
      if (props.SetIsTherapeuticDirty) {
        props.SetIsTherapeuticDirty(true);
      }
    }
  }

  return (
    <Grid container className="categoryDetails">
      <Grid item lg={12} xs={12}>
        {(libraryActive == 3 && !taToggle) || libraryActive == 1 ?

          <FormControl className='therapeutic-area-div' component="fieldset">
           
            <FormLabel component="legend">{props.translations['To improve your experience, please select the brands of interest to you.'] ? props.translations['To improve your experience, please select the brands of interest to you.'] : 'To improve your experience, please select the brands of interest to you.'}</FormLabel>
            <div className='categoryData'>
              <Grid container className='category_list'>
                {categoryTree.length > 0 ? categoryTree.map((ta, index) => {
                  return (
                    <Grid item xs={12} sm={3} md={3} lg={3} className="categoryItems">
                      <Grid className="category">
                        <Grid className='category0'>
                          {ta.children.length != 0 ? taIds.includes(ta.id) ?
                            <Typography className='category_list_inner'>{
                              <BlackTriangleUser brand_name={props.translations[ta.name] ? props.translations[ta.name] : ta.name} />}</Typography>
                            : null : null}
                        </Grid>
                        <Grid className='category1'>
                          <ul>
                            {ta.children.length > 0 ? ta.children.map((cat1) => {
                              return (
                                <>
                                  {cat1Ids.includes(cat1.id) ?
                                    <li className='category_list_inner'>
                                      <FormControlLabel
                                        value={props.translations[cat1.name] ? props.translations[cat1.name] : cat1.name}
                                        className='labelTxt-user'
                                        checked={props.selectedCategories.includes(ta.id + "_" + cat1.id)}
                                        control={<Checkbox className='wrap-text' onChange={(e) => { showInner(cat1.id, e.target.checked); handleChkCategories(e.target.checked, ta.id, cat1.id, null); }} inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                                        label={<BlackTriangleUser brand_name={props.translations[cat1.name] ? props.translations[cat1.name] : cat1.name} />}
                                        labelPlacement="end"
                                      />
                                    </li>
                                    : null}
                                  {typeof innerChk[cat1.id] !== "undefined" && innerChk[cat1.id] === true ?
                                    <Grid className='category2'>
                                      {cat1.children.length > 0 ? cat1.children.map((cat2) => {
                                        return (
                                          <>
                                            {cat2Ids.includes(cat2.id) ?
                                              <li className='category_list_inner'>
                                                <FormControlLabel
                                                  value={props.translations[cat2.name] ? props.translations[cat2.name] : cat2.name}
                                                  className='labelTxt-user'
                                                  checked={props.selectedCategories.includes(ta.id + "_" + cat1.id + "_" + cat2.id)}
                                                  control={<Checkbox className='wrap-text' onLoad={(e) => { handleChkCategories(e.target.checked, ta.id, cat1.id, cat2.id); }} onChange={(e) => { handleChkCategories(e.target.checked, ta.id, cat1.id, cat2.id); }} inputProps={{ 'aria-label': 'secondary checkbox' }} />}
                                                  label={<BlackTriangleUser brand_name={props.translations[cat2.name] ? props.translations[cat2.name] : cat2.name} />}
                                                  labelPlacement="end"
                                                />
                                              </li>
                                              : null}
                                          </>
                                        )
                                      }) : null}
                                    </Grid> : null
                                  }
                                </>
                              )
                            }) : null}
                          </ul>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                }) : ""}
              </Grid>
            </div>
          </FormControl>
          : null}
      </Grid>
    </Grid>
  )
}
export default TherapeuticArea