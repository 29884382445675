/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react';
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import Breadcrumb from '../../layout/breadcrumb';
import Modal from '@material-ui/core/Modal';
import { PrescribingInfoService } from './Services/prescribing-information-service';
import { Typography } from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import PDFbg from '../../public/img/new-img/pdf-bg.jpg'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import styles from './prescribing-Information.module.css';

// Prescribing Information Component 
const PrescirbingInfo = (props) => {
    let prescribingService = new PrescribingInfoService();
   
    const [countryDetails, setcountryDetails] = React.useState([]);
    const [brandData, setBrandData] = React.useState([]);
    const [brand, setBrand] = React.useState('');
    const [pdfData, setPdfData] = React.useState([]);

    const [btnDisable, setBtnDisable] = React.useState(false);
    let selectorValue = useSelector(state => state.session);
    const translationReduxState = useSelector(state => state.translation);
    const [sessionState, SetSessionState] = React.useState(selectorValue);

    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'Información para preescribir', path: '/informacion-para-prescribir' }
    ];

    React.useEffect(() => {
        SetSessionState(selectorValue);
    }, [selectorValue]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        // if (sessionState?.country) {
        if (localStorage.getItem('country')!=undefined) {
            // prescribingService.getByCountryName(sessionState.country).then(response => {
                prescribingService.getByCountryName(localStorage.getItem('country')).then(response => {
                if (response && response.data?.items.length > 0 && response.data?.items) {
                    setcountryDetails(response.data?.items);
                    prescribingService.getByCountryId(response.data?.items[0]?.sys.id).then(response => {
                        if (response && response.data?.items) {
                            const Brandlist = response.data?.items.sort((a, b) => {
                                if (a.fields.brandName > b.fields.brandName) {
                                    return 1;
                                }
                                else {
                                    return -1;
                                }
                            })
                            setBrandData(Brandlist);
                        }
                    });
                }
            }).catch(err=> {});
        }
    }, [setBrandData, sessionState]);

    const getPDFData = () => {
        if (countryDetails.length > 0 && brand.length !== 0) {
            prescribingService.getByBrandCountry(brand, countryDetails[0].sys.id).then(response => {
                if (response && response.data?.items) {
                    let pdfItems = response.data?.items;
                    pdfItems.forEach((elem, index)=>{
                        if (response && response.data?.includes?.Asset) {
                            elem.pdfUrl = (response.data?.includes?.Asset[index]);
                        }
                    })
                    setPdfData(pdfItems)
                    handlePdfModalClose();
                }
            });
        }
    }

    const [pdfModalOpen, setPdfModalOpen] = React.useState(false);
    const handlePdfModalOpen = () => setPdfModalOpen(true);
    const handlePdfModalClose = () => setPdfModalOpen(false);

    return (
        <>
            <Modal open={pdfModalOpen} onClose={handlePdfModalClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                    Seleccionar Marca
                        <span>
                            <button className='closeModal' onClick={() => handlePdfModalClose()}></button>
                        </span>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            <Typography className={styles.selTxt}>
                                Material Exclusivo para Profesionales de la Salud
                            </Typography>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-filled-label">Seleccionar marca</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="select"
                                    onChange={(e) => { setBrand(e.target.value); }}
                                    label="Brand">
                                    {brandData.map((data, index) => (
                                        <MenuItem key={index} onClick={()=> setBtnDisable(false)} value={data.sys.id}>{data.fields.brandName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => handlePdfModalClose()} className="modalBtn">Cancelar</button>
                        <button type="button" disabled={btnDisable ? true : false}  onClick={() => getPDFData()} className="modalBtn">Continuar</button>
                    </Grid>
                </Grid>
            </Modal>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <div className='speaker-mgmt'>
                <div className="main-wrp">
                    <Grid className="mgmt_header">
                        <Grid container className="row">
                            <Grid item xs={12}>
                                <label className="label-hd" sx={{ mt: 2 }} >
                                    Información para prescribir
                                </label>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }} className={styles.m_1_2}>
                                <Typography>
                                    La información del producto a la que se está accediendo fue aprobada en el país que ha seleccionado y podría no estar aprobada en otro país, el uso que se de a la misma deberá ser de acuerdo al país al que fue destinado y es sólo para estricto uso del Profesional Médico registrado por esta vía.
                                </Typography>
                            </Grid>
                            <Grid item lg={2} sm={2} xs={12} sx={{ mt: 2 }} >
                                <div className="action_buttons " >
                                    <Button className='createBtn' onClick={handlePdfModalOpen}>Ver PDF</Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className="row">
                            {pdfData.map((data, index) => (
                                <Grid item xs={12} sm={6} lg={3} sx={{ mt: 2 }} key={index}>
                                    <Card className={styles.root}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                                alt="Lilly PDF"
                                                height="140"
                                                image={PDFbg}
                                                title="Lilly PDF"
                                            />
                                            <CardContent className={styles.cardCont}>
                                                <Typography className={styles.pdftitle} gutterBottom variant="h5" component="h2">
                                                    {data.fields.pdfTitle}
                                                </Typography>
                                                <Typography className={styles.pdftxt} gutterBottom variant="p" component="p">
                                                    PDF
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <a className={styles.pdfbutton} href={`https:${data.pdfUrl?.fields?.file?.url}`} target="_blank" rel="noreferrer">
                                                <svg className={styles.pdfbuttonIn} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="18px" height="18px"><path style={{ color: '#fff' }} d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                                                Descargar
                                            </a>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}


                        </Grid>
                    </Grid>

                </div>
            </div>
        </>
    )
}

export default PrescirbingInfo;