import axios from 'axios';
import { GoogleAnalyticsService } from '../../../GA/GoogleAnalyticsService';
class HomeServiceBaseMatchingModel {
    constructor(_country = '', _library = '', _specialty = '', _profession = '', _ta = [], _status = '', _cat1 = [], _cat2 = [], _secFilterId = '', _countryName = '', _localeName = '', _loginRoute) {
        this.country = _country;
        this.library = _library;
        this.specialty = _specialty;
        this.profession = _profession;
        this.ta = _ta;
        this.status = _status;
        this.cat1 = _cat1;
        this.cat2 = _cat2;
        this.category = _secFilterId;
        this.countryName = _countryName;
        this.localeName = _localeName;
        this.loginRoute = _loginRoute;
    }
}
class HomeService {

    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeader = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': localStorage.getItem('jwtToken')
            }
        };
        this.gaService = new GoogleAnalyticsService();
    }
    NavigatedToVideoFromShowMoreEvent(videoId, message, ta){
        if(ta){
            let taSplit = ta.split(',');
            if(window.location.href.includes('favorite')){
                let model = {
                    event : 'NavigatedToVideoFromFavourite',
                    eventCategory : 'Navigated to video',
                    eventDescription :message,// 'Navigated to video from TA Homepage',
                    videoSelectedID : videoId,
                    eventAction: 'Favorites page origin',
                    eventLabel : ta
                };
                this.gaService.PushObjectInDatalayer(model);
            }else{
                let model = {
                    event : 'NavigatedToVideoFromShowMoreEvent',
                    eventCategory : 'Navigated to video',
                    eventDescription :message,// 'Navigated to video from TA Homepage',
                    videoSelectedID : videoId,
                    eventAction: 'Show more page origin',
                    eventLabel : ta
        
                };
                this.gaService.PushObjectInDatalayer(model);
            }
        }else{

        }
        
        
    }   
    NavigatedToVideoEvent(videoId, message, ta){
        const expr = message
        if(ta){
            let taSplit = ta.split(',');
            if(Array.isArray(taSplit) && taSplit.length>1){
                let model = {
                    event: 'NavigatedToVideoEvent',
                    eventCategory: 'Navigated to video',
                    eventDescription: message,// 'Navigated to video from TA Homepage',
                    videoSelectedID: videoId,
                    eventLabel: ta,
                    eventAction: 'Homepage origin'
                };
                switch(expr){
                    case 'Navigated to video from homepage':
                        model["eventAction"]='Homepage Origin'
                        break;
                    case 'Navigated to video from TA home':
                        model["eventAction"]=ta + 'origin';
                        break;
                    case 'Navigated to video from Search results page':
                        model["eventAction"]='Search Results Page Origin';
                        break;
                    case 'Navigated to video from another content page':
                        model["eventAction"]='Content page origin';
                        break;
                    case 'Navigated to video from the show more page':
                        model["eventAction"]='Show more page origin';
                        break;
                    case 'Navigated to video from the favorites page':
                        model["eventAction"]='Favorites page origin';
                        break;
                    default:
                        model["eventAction"]='';
                        break;
                }
                this.gaService.PushObjectInDatalayer(model);
            }else{
                if(ta=='All'){
                    let model = {
                        event : 'NavigatedToVideoEvent',
                        eventCategory : 'Navigated to video',
                        eventDescription : 'Navigated to video from Homepage',
                        videoSelectedID : videoId,
                        eventLabel: ta
            
                    };
                    this.gaService.PushObjectInDatalayer(model);
                }
                else{
                    let model = {
                        event : 'NavigatedToVideoEventFromTaHomePage',
                        eventCategory : 'Navigated to video',
                        eventDescription : 'Navigated to video from TA Homepage',
                        videoSelectedID : videoId,
                        eventLabel: ta
            
                    };
                    this.gaService.PushObjectInDatalayer(model);
                }
                
            }
        }else{
            let model = {
                event : 'NavigatedToVideoEvent',
                eventCategory : 'Navigated to video',
                eventDescription : 'Navigated to video from TA Homepage',
                videoSelectedID : videoId,
                eventLabel: ta
    
            };
            this.gaService.PushObjectInDatalayer(model);
        }
        
        
    } 
    VideoSearchSelectedGAEvent(videoId, videoTitle= null) {
        this.gaService.PushSingleVariables('videoId', videoId)
        this.gaService.PushSingleVariables('videoSelectedID', videoId)
        let model = {
            event: 'videoSearchselected',
            videoSelectedID : videoId,
            // VideoSearchSelectedGAEventTitle: videoId//'Content with id ' + videoId + ' and title ' + videoId + ' was selected',


        };
        this.gaService.PushObjectInDatalayer(model);
    }
    VideoSelectedGAEvent(videoId, videoTitle= null) {
        let model = {
            event: 'video selected',
            // videoSelectedTitle: videoId,
            videoId : videoId,
            videoSelectedID: videoId//'Content with id ' + videoId + ' and title ' + videoId + ' was selected',


        };
        this.gaService.PushObjectInDatalayer(model);
    }
    async GetCountryBanner(affiliate_id, setterDictionary, SetBannerDictionaryOrder) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/country/GetCountryBannerInfo?country_code=' +
            affiliate_id, {}, this.authHeader);
        if (Array.isArray(data.data)) {
            if (data.data.length > 0) {
                if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    SetBannerDictionaryOrder(data.data[0].mobile_ta_bannerid_order);
                    await this.GenerateS3Files(data.data[0].mobile_order_dictionary, setterDictionary);
                } else {
                    SetBannerDictionaryOrder(data.data[0].ta_bannerid_order);
                    await this.GenerateS3Files(data.data[0].order_dictionary, setterDictionary);
                }
                return data.data;
            }
        }

        return [];
    }
    CheckUrl(thumbnail) {
        try {
            let uri = new URL(thumbnail);
            return false;
        } catch (err) { return true; }
    }
    async GenerateS3Files(_dict, setterDictionary) {
        let count = 0;
        let dict = Object.assign({}, _dict);
        for (let i = 0; i < Object.keys(dict).length; i++) {
            let keyouter = Object.keys(dict)[i];
            for (let j = 0; j < Object.keys(dict[keyouter]).length; j++) {
                let keyInner = Object.keys(dict[keyouter])[j];

                let image = await this.GetImage(dict[keyouter][keyInner]['file']);
                if (image) {
                    let fileReader = new FileReader();
                    // eslint-disable-next-line no-loop-func
                    fileReader.onload = function () {
                        dict[keyouter][keyInner]['displayfile'] = this.result;
                        count = count + 1;
                        setterDictionary(dict);
                        if (count === Object.keys(dict).length * Object.keys(dict[Object.keys(dict)[0]]).length) {
                            return dict;
                        }
                    }
                    if (image.length > 0) {
                        dict[keyouter][keyInner]['displayfile'] = image;
                    }
                } else {
                    count = count + 1;
                }
            }
        }
        setterDictionary(dict);
    }
    async GetImageFromURL(uri) {
        try {
            let uriNew = new URL(uri);
            if (uriNew.host == 'cfvod.kaltura.com') {
                return uri;
            }
            else {
                let data = await axios.get(uri);
                return data.data;
            }
        } catch (err) {
            return await this.GetImage(uri);
        }

    }
    async GetImage(key) {
        const CancelToken = axios.CancelToken
        const cancelTokenSource = CancelToken.source()
        try {
            if (key != null && key.length > 0) {
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', { key: key }, this.authHeader);
                try{
                    let uriNew = new URL(data.data);
                    let imageRaw = await axios.get(data.data, {cancelToken:cancelTokenSource.token}).catch(function(thrown){
                        if (axios.isCancel(thrown)){
                            console.error('Operation Cancelled', thrown.message);
                        } else {
                            console.error('Server Response error')
                        }
                    })
                    return imageRaw.data
                }catch(err){
                    return data.data.binarySring;
                }
                return data.data.binarySring;
            } else {
                return null;
            }
        } catch (err) {
        }
        cancelTokenSource.cancel('Operation cancelled by user.');
    }
    async RegisterLiveEvent(event_id) {
        const user_info = JSON.parse(localStorage.getItem('user_info'));
        const affiliate_name = localStorage.getItem('country') ? localStorage.getItem('country') : '';
        let reg_data = {
            event_id: event_id,
            firstname: user_info.firstName,
            lastname: user_info.lastName,
            email: user_info.email,
            country: affiliate_name,
            std8: user_info.id,
            std9: user_info.customerId,
            company: user_info.customerId,
            partnerRef: 'lilly-website-LillyPlay',
        }
        let data = await axios.post(this.baseUrl + '/content/api/event/RegisterLiveEvent', reg_data, this.authHeader);
        return data.data;
    }
    async GetLiveEventRegistrations(eventId) {
        let data = await axios.post(this.baseUrl + '/content/api/event/GetLiveEventRegistrations', {event_id: eventId}, this.authHeader);
        return data.data;
    }
    async GetAffiliateNameByCode(code) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/user/GetAffiliateByQuery', { Affiliate_code: code }, this.authHeader);
        let affiliate_info = data.data;
        return affiliate_info[0].affiliate_name;
    };
    async GetLiveEvents(category_id, country, library) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/content/api/event/GetLiveEvents?category_id=' + category_id + '&country=' + country + "&library=" + library, { category_id: category_id, country: country, library: library }, this.authHeader);
        return data.data;
    }
    async GetMostRecentData(model) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        // model.specialty = '';
        let data = await axios.post(this.baseUrl + `/home/api/content/ShowMoreData/0/100`, model, this.authHeader);
        let trimmedList = data.data.sort(function (a, b) {
                return b.updated_at.localeCompare(a.updated_at);
            });
        return trimmedList;
    }
    async GetVideosData(categoryId) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/content/api/video/GetVideosByQuery?category_id=' + categoryId, {}, this.authHeader);
        return data.data;
    }

    async GetPlaylistData(setPlaylistState, categoryId) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/content/api/playlist/GetAllPlaylist?category_id=' + categoryId, {}, this.authHeader);
        setPlaylistState(data.data);
        return data.data;
    }
    async GetPodcastData(setPodcastState, categoryId) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/content/api/video/GetAllPodcasts?content_type=podcast&category_id=' + categoryId, {}, this.authHeader);
        setPodcastState(data.data);
        return data.data;
    }
    async GetFavoriteContentData(categoryId, userID, country, library, model) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        model.category_id = model.ta
        let data = await axios.post(this.baseUrl + '/home/api/favorites/GetAllFavorites?category_id=' + categoryId + '&user_id=' + userID + '&country=' + country + "&library=" + library, model, this.authHeader);
        return data.data;
    }
    async GetPopularSpeakersContentData(model) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/home/api/popular/GetAllPopularSpeakerContents', model, this.authHeader);
        return data.data;
    }
    async GetPopularContentData(model) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/home/api/popular/GetAllPopularContents', model, this.authHeader);
        return data.data;
    }
    async GetKeepWatchingContentData(categoryId, userID, country, library, model) {
        model.category_id = model.ta
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        // return [];
        let data = await axios.post(this.baseUrl + '/home/api/watch/GetAllKeepWatchingContents?category_id=' + categoryId + '&user_id=' + userID + '&country=' + country + "&library=" + library, model, this.authHeader);
        return data.data;
    }
    async GetWatchedContentData(categoryId, userID, country, library, model) {
        model.category_id= model.ta
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        // return [];
        let data = await axios.post(this.baseUrl + '/home/api/watch/GetAllWatchedContents?category_id=' + categoryId + '&user_id=' + userID + '&country=' + country + "&library=" + library, model, this.authHeader);
        return data.data;
    }
    async getLibrary(obj) {
        let data = await axios.post(this.baseUrl + '/management/api/library/getLibrary', obj, this.authHeader);
        return data;
    }
    async GetS3File(key) {
        try {
            if (key != null && key.length > 0) {
                let _key = "";
                let mainArray = key.split('/');
                if(mainArray?.length > 1) {
                    _key = key;
                } else {
                    _key = 'Country/library/'+key;
                }
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', { key: _key.trim() }, this.authHeader);
                return (await axios.get(data.data)).data
            } else {
                return null;
            }
        } catch (err) { }
    }
}
export { HomeService, HomeServiceBaseMatchingModel }