/* eslint-disable react-hooks/exhaustive-deps */
// import kaltura from 'kaltura-client'


import VideoPlayer from './Component/VideoPlayer';
import React from 'react';


const Video = (props) => {
    React.useEffect(() => {
    }, [props]);
    return (
        <VideoPlayer></VideoPlayer>
    )
}
export default Video;

