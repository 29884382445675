import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

 function GetSessionCookie(name) {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          let _cookies = cookies[i].split('=');
          if (_cookies[0].trim() === name) {
            let signValue = _cookies[1].trim();
            return signValue;
          }
        }
      }
export default function ProtectedRoute({ children }) {
    let cookie  = GetSessionCookie('sessioncookie');
    let isLoggedIn = localStorage.getItem('access_token') === null || cookie==null  ? false : true;
    return isLoggedIn || true? <Outlet /> : <Navigate to="/" replace />
}