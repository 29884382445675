import axios from 'axios';
class SimulatorModel{
    constructor(country, locale, profession, specialty){
        this.profession=profession;
        this.specialty=specialty;
        this.affiliateCode=country;
        this.locale=locale;
    }
}
class UserProfileService {
    constructor(jwtToken)
    {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken?jwtToken:localStorage.getItem('jwtToken')
            }
        };
    }
    async StartSimulation(country, locale, profession, specialty, dispatch)
    {
        let model = new SimulatorModel(country, locale, profession, specialty);
        let response = await axios.post(this.baseUrl+'/management/api/user/GetSimulationUser', model,this.authHeaders)
        let replacementTOken = localStorage.getItem('sessionValue');
        // localStorage.setItem('simulationAdmin', replacementTOken);
        localStorage.setItem('sessionValue', response.data.sessionHash);
        localStorage.removeItem('active_library');
        return response.data;
    }
    async GetAllArea() {
        return axios.post(this.baseUrl + '/management/api/category/GetTherapeuticAreaByQuery?del=false', {}, this.authHeaders);
    };
    async GetAllBrands () {
        return axios.post(this.baseUrl + '/management/api/category/GetCategory1ByQuery?del=false', {}, this.authHeaders);
    };
    async GetAllIndications() {
        return axios.post(this.baseUrl + '/management/api/category/GetCategory2ByQuery?del=false', {}, this.authHeaders);
    };
    async GetAllCategory() {
        return axios.post(this.baseUrl + '/management/api/category/GetAllCategory?del=false', {}, this.authHeaders);
    }
    async UpdateUser(modal) {
        return axios.post(this.baseUrl +`/management/api/user/UpdateUser`, modal, this.authHeaders);
    };
    async GetUsersByQuery(param) {
        return axios.post(this.baseUrl +`/management/api/user/GetUsersByQuery?`+ param, {}, this.authHeaders);
    };
    async CreateNewUser(model) {
        return axios.post(this.baseUrl +`/management/api/user/CreateNewUser`, model, this.authHeaders);
    };
    async GetAffiliateByQuery(query) {
        if(!query){query=''}
        return axios.post(this.baseUrl +`/management/api/user/GetAffiliateByQuery?` + query, {}, this.authHeaders);
    };
    async GetRolesByQuery() {
        return axios.post(this.baseUrl +`/management/api/user/GetRolesByQuery`, {}, this.authHeaders);
    };
    async GetHubsByQuery() {
        return axios.post(this.baseUrl +`/management/api/user/GetHubsByQuery`, {}, this.authHeaders);
    };
    async GetAllAffiliates() {
        return axios.post(this.baseUrl +`/management/api/user/GetAffiliateByQuery`, {}, this.authHeaders);
    };
    async GetAffiliateByCountryName(params) {
        let qs = "?status=Active";
        if(params) {
            qs += "";
            if(params.affiliate_id) {
                qs += '&country_name='+ params.affiliate_id;
            }
        }
        let data = await axios.post(this.baseUrl + '/management/api/user/GetAffiliateByQuery' + qs, {}, this.authHeaders);
        return data;
    
    }
}

export {UserProfileService}