import React from 'react';
import Grid from '@material-ui/core/Grid';
import './ContentList.css';
import Paper from '@material-ui/core/Paper';
import videoplayIcon from '../../../public/img/videoPlay.svg';
import podcastIcon from '../../../public/img/podcast.svg';
import playlistIcon from '../../../public/img/playlist.png';
import loaderNewGif from '../../../public/img/new-loader.png';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { BlackTriangleReplacer } from "../../shared/BlackTriangle";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import meetSpeakerIcon from '../../../public/img/meetSpeakerIcon.svg';
import Thumbnail from '../Thumbnail';
import PIInfo from '../PIInfo';
import Modal from '@material-ui/core/Modal';
import {HomeService} from '../../home/Service/homeService';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function removeHtmlTags(str) {
    if ((str === null) || (str === ''))
        return false;
    else
        str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
}

const ContentList = ({ contentData, loadProducts, hasMore, showLoader, translations, filter }) => {
    const navigate = useNavigate();
    const [speakerNames, setSpeakerNames] = React.useState([]);
    const [popupState, setPopupState] = React.useState(false);
    const [redirectLink, SetRedirectLink] = React.useState('');
    const [redirectWaringModal, SetRedirectWaringModal] = React.useState(false);
    const menuSearch = (value) => {
        window.location.href = window.location.origin + '/speaker-detail/' + value;
        // navigate('/speaker-detail/' + value);

    }
    React.useEffect(() => {
        let targetLinks = document.querySelectorAll('.cont-desc a');
        for (let i = 0; i < targetLinks.length; i++) {
            targetLinks[i].addEventListener('click', function (event) {
                const anchor = event.currentTarget;
                let href = this.getAttribute('href');
                if (this.classList.contains('openNewWinDow')) {
                    event.preventDefault();
                    SetRedirectLink(href);
                    SetRedirectWaringModal(true);
                } else {
                    let targetValue = anchor.getAttribute('target') === '_blank';
                    console.log("targetValue", targetValue);
                    if(!targetValue){
                        event.preventDefault();
                        window.open(href, '_blank');
                    }
                }
            });
        }
    }, [contentData]);
    return (
        <>
        <Modal open={redirectWaringModal} onClose={() => { SetRedirectWaringModal(false) }}>
                <Grid className='paper modalstyle redirectWarningModal'>
                    <Grid className="modal-body">
                        <button className='closeModal' onClick={() => SetRedirectWaringModal(false)}></button>
                        <div className="m20">
                            <div className="text-center">
                                <h3>You are now leaving {window.location.origin}</h3>
                                <p>The link you clicked on will take you to a site maintained by a third party, which is solely responsible for its content. Eli Lilly Company and its affiliates do not control, influence, or endorse this site, and the opinions, claims, or comments expressed on this site should not be attributed to Eli Lilly Company and its affiliates. Eli Lilly Company and its affiliates are not responsible for the  privacy policy of any third-party websites. We encourage you to read the privacy policy of every website you visit.</p>
                                <p>Click "Continue" to leave site or "Return to Lilly Play" to close this window and return to {window.location.origin}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="modal-footer">
                        <button className='modalBtn' onClick={() => { SetRedirectWaringModal(false) }}>Return to Lilly Play</button>
                        <button type="button" onClick={() => { window.open(redirectLink, '_blank'); SetRedirectWaringModal(false); }} className="modalBtn">Continue</button>
                    </Grid>
                </Grid>
            </Modal>
            <Dialog open={popupState} scroll='body' maxWidth='md' TransitionComponent={Transition} onClose={() => setPopupState(false)} disableEscapeKeyDown className='speakerModal'>
                <DialogTitle className='modal-top'>
                    <Grid className="modal-title border0 alignCenter">

                        <img src={meetSpeakerIcon} alt="speakerIcon" />

                        <button className='closeModal' onClick={() => setPopupState(false)}></button>
                    </Grid>

                </DialogTitle>
                <DialogContent className='modal-middle'>
                    <Grid>
                        <Paper className='speakerTitle'> {translations['Speakers:'] ? translations['Speakers:'] : 'Speakers:'}</Paper>

                        {speakerNames.length > 0 ? speakerNames.map((speaker, index) => {
                            return (<Paper className='upNameSpeaker' key={index} onClick={() => menuSearch(speaker.toString())}>{speaker}</Paper>
                            );
                        }) : null}

                    </Grid>
                </DialogContent>
                <DialogActions className='modal-bottom text-center border0'>
                    <button type="button" className="modalBtn" onClick={() => setPopupState(false)}>Close</button>
                </DialogActions>
            </Dialog>
            <Grid style={{ overflow: 'hidden' }}>
                {showLoader ? <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid> :
                    <InfiniteScroll style={{ overflow: 'hidden' }} dataLength={contentData.length} next={loadProducts} hasMore={hasMore}
                        loader={<Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>}>
                        {contentData ? contentData.map((content, index) => {
                            let contentIcon = content.content_type === 'video' ? videoplayIcon :
                                content.content_type === 'playlist' ? playlistIcon :
                                    content.content_type === 'podcast' ? podcastIcon : null;
                                    
                            let contentSpeakers =[];
                            if(content.speakers_id){
                                for(let i = 0; i<content.speakers_id.length; i++){
                                    if(content.speakers_id[i]){
                                        contentSpeakers.push(content.speakers_id[i])
                                    }
                                }
                            }
                            const contentDescription = content?.description?.replaceAll('1.5rem','14px');
                            let content_category1 = content.content_type === 'playlist' ? content.category1_id : content.category_1_id;
                            
                            let contentURL = content.content_type === 'playlist' ? `/content-watch/${content.kaltura_id}-${content.playlist_id}/${content.playlist_id}` : `/content-watch/${content.kaltura_id}/${content.content_id}`;
                            return (
                                <Grid id="searchListItem" key={index} style={{ overflow: 'hidden', marginBottom: '10px' }}>
                                    <Grid container>
                                        <Grid item xs={12} lg={2} sm={3}>
                                            <Grid className='sCover-content clickableLink' onClick={() => {
                                                (new HomeService()).VideoSearchSelectedGAEvent(content.content_id, '');
                                                (new HomeService()).NavigatedToVideoFromShowMoreEvent(content.content_id,'Navigated to video from the show more page', filter)
                                                navigate(contentURL)}}>
                                                {/* <Grid className='sCoverInner-Content'>
                                                    <img src={contentIcon} className={'svgIcon-content ' + content.content_type} alt='icon' />
                                                </Grid> */}
                                                <Thumbnail content={content} page={'content-list'}/>
                                                {parseInt(content.duration) > 0 && <span className="videTime-search">{(content.duration) / 3600 > 1 ? (new Date(content.duration * 1000)).toISOString().slice(11, 19) :
                                                    (new Date(content.duration * 1000)).toISOString().slice(14, 19)}</span>}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={10} sm={9} className='pl10'>
                                            <Grid><Paper className={content.content_type + 'Tag-content'}>{content.content_type}</Paper></Grid>
                                            <Grid className='cont-title clickableLink' onClick={() => {
                                                (new HomeService()).VideoSearchSelectedGAEvent(content.content_id, '');
                                                (new HomeService()).NavigatedToVideoFromShowMoreEvent(content.content_id,'Navigated to video from the show more page', filter);
                                                navigate(contentURL)}}>
                                                <div dangerouslySetInnerHTML = {{__html:BlackTriangleReplacer(content.title)}}></div>
                                            </Grid>
                                            <Grid className='cont-desc'>
                                                {removeHtmlTags(contentDescription).length > 0 ? <div dangerouslySetInnerHTML = {{__html:BlackTriangleReplacer(contentDescription)}}></div> : ''}
                                            </Grid>
                                            {contentSpeakers != null && contentSpeakers.length > 0 ?
                                                contentSpeakers.length > 1 ? <Grid className='upName' onClick={() => { setSpeakerNames(contentSpeakers); setPopupState(true); }}>
                                                   {translations['Meet the Speakers'] ? translations['Meet the Speakers'] : 'Meet the Speakers'}
                                                </Grid> : <Paper className='upName' onClick={() => menuSearch(contentSpeakers[0])}>{contentSpeakers[0]}</Paper>
                                            : null}
                                            <Grid className="cont-piinfo">
                                                {content_category1?.length ? <PIInfo category1Ids={content_category1} /> : ""}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        }) : <Grid className='cont-noData'>No Data Found</Grid>}
                    </InfiniteScroll>
                }
            </Grid>
        </>
    );
}

export default ContentList;