



import React from 'react';
import {HomeService} from '../../../home/Service/homeService'



const SpeakerThumbnail =(props)=> {
    const[thumbnailImage,SetThumbnailImage] = React.useState('');
    React.useEffect(()=> {
        let service = new HomeService();
        if(props.speaker.avatar_attachment_id){
            service.GetImageFromURL(props.speaker.avatar_attachment_id).then((data)=> {
                if(data){
                    SetThumbnailImage(data);
                }
            })
        } else {
            SetThumbnailImage('');
        }
    },[]);
    React.useEffect(()=> {
        let service = new HomeService();
        if(props.speaker.avatar_attachment_id){
            service.GetImageFromURL(props.speaker.avatar_attachment_id).then((data)=> {
                if(data){
                    SetThumbnailImage(data);
                }
            })
        } else {
            SetThumbnailImage('');
        }
    },[props.speaker.avatar_attachment_id]);
    return (
        <>{thumbnailImage.length>0 ? <img class="imageBorderRadius" alt='img' src={thumbnailImage} width='40' /> : 
        <img alt='img' src={require('../../../../public/img/speakerImg.png')} width='40' />}
        </>
    )
}
export default SpeakerThumbnail