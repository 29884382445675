// Map category selection by level
export const mapSelection = (nodes, selectedIdsByLevels, level = 0) => {
  return nodes.map((node) => {
    if (selectedIdsByLevels[level]?.includes(node.id)) {
      if (node.children?.length) {
        const updatedChildren = mapSelection(
          node.children,
          selectedIdsByLevels,
          level + 1
        );

        return {
          ...node,
          children: updatedChildren,
          isChecked:
            level === 0
              ? updatedChildren?.some((item) => item.isChecked)
              : true,
        };
      }
      return { ...node, isChecked: true };
    }

    return node;
  });
};

export const filterTreeByIds = (nodes, visibleIdsByLevels, level = 0) => {
  return nodes
    .filter(node => visibleIdsByLevels[level]?.includes(node.id)) // Filter nodes based on the provided IDs
    .map(node => ({
      ...node,
      children: node.children?.length ? filterTreeByIds(node.children, visibleIdsByLevels, level + 1) : [] // Recursively filter the children
    }))
    .filter(node => !(level === 0 && !node.children?.length)); //Remove all Level-0(therapetic area) nodes which hasn't children(category1)
};

// Handle category selection updates
export const updateSelection = (categoryTree, id, isChecked) => {
  // Uncheck all children recursively
  const uncheckAll = (nodes) => {
    if (!nodes || nodes.length === 0) return [];

    return nodes.map((node) => {
      const updatedChildren = uncheckAll(node.children);
      return { ...node, children: updatedChildren, isChecked: false };
    });
  };

  const update = (nodes, level = 0) => {
    return nodes.map((node) => {
      if (node.id === id) {
        if (!isChecked && node.children && node.children.length > 0) {
          const updatedChildren = uncheckAll(node.children);
          return { ...node, children: updatedChildren, isChecked };
        }
        return { ...node, isChecked };
      } else if (node.children && node.children.length > 0) {
        const updatedChildren = update(node.children, level + 1);
        const hasCheckedChild = updatedChildren?.some((item) => item.isChecked);
        let isChecked = false;
        if (level !== 0) {
          isChecked = hasCheckedChild || node.isChecked;
        } else {
          isChecked = hasCheckedChild;
        }
        return { ...node, children: updatedChildren, isChecked };
      }

      return node;
    });
  };

  return update(categoryTree);
};
