import React from 'react';
import BannerLogo from '../../../public/img/responsive_banner.png';
import LillyPlayLogo from '../../../public/img/logo-lilyplay.png';

const Sweden = (props) => {
  React.useEffect(() => {
    var head = document.head;
    var link1 = document.createElement("link");
    link1.type = "text/css";
    link1.rel = "stylesheet";
    link1.href = window.location.origin +"/css/lilyplay.webflow.css";
    head.appendChild(link1);

    var link2 = document.createElement("link");
    link2.type = "text/css";
    link2.rel = "stylesheet";
    link2.href = window.location.origin +"/css/webflow.css";
    head.appendChild(link2);
    // document.getElementById('link10').disabled = true
    var link3 = document.createElement("link");
    link3.type = "text/css";
    link3.rel = "stylesheet";
    link3.href = window.location.origin +"/css/landing1.css";
    head.appendChild(link3);

    return () => { 
      head.removeChild(link1); 
      head.removeChild(link2); 
      head.removeChild(link3); 
    }
  }, []);
  return (
    <>
      <div className="navbar">
        <div className="custom-container">
          <div className="w-row">
            <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
              <img src={LillyPlayLogo} alt="Lilly Play" className="logo-navbar" />
              <p className="newLogo">Lilly <span>| Play</span></p>
              <small>Relevant video content available when you need it</small>
            </div>
            <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
              <div className="button-list-nav">
                <a href name="landing-Login" className="botao-nav au-login" onClick={props.passportLogin}>Logga in</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainContent">
        <div className="mainBanner">
            <div className="lp-content">
                <h3>Ta del av korta videoinspelningar, intervjuer från kongresser, aktuella ämnen och fortbildning on-line.<br /><br /> Lilly Play är en videoplattform tillgänglig för dig som vårdgivare när du behöver det. </h3>
                <img className="banner_mobile" src={BannerLogo} alt="banner_mobile"/>
                <div className="button-list-nav">
                    <a href name="landing-Register" className="botao-nav au-signup" onClick={props.passportRegister}>Skapa ditt Lilly konto idag</a>
                </div>
                <span className="login">Har du redan ett konto? <a href name="landing-Login" className="au-login loginLink" onClick={props.passportLogin}>Logga in</a></span>
            </div>
            <div className="banner"><img src={BannerLogo} alt="banner" /></div>
        </div>
      </div>

      <div className="lp-footer">
        <div className="custom-container">
          <div className="w-row">
            <div className="w-col w-col-4 w-col-sm-3 w-hidden-small w-hidden-tiny coluna-logo-footer">
              <img src={LillyPlayLogo} className="logo-footer" alt="Lilly Play" />
            </div>
            <div className="coluna-textos w-col w-col-8 w-col-sm-9">
              <p className="disclaimer">This website is intended for Sweden Healthcare Professionals only.</p>
              <div>
                <p className="copyright-text">
                  <span className="white">© 2022 Eli Lilly and Company. Med ensamrätt. Lilly är ett registrerat varumärke som tillhör Eli Lilly and Company. Eli Lilly Sweden AB, Gustav III Boulevard 42, Box 721, 169 27 Solna, Tel: +46 8 737 88 00</span>
                </p>
                <p className="termos">
                  <a href="https://www.lilly.se/terms-of-use" className="link-2" rel="noreferrer" target="_blank"><span className="white">Användarvillkor</span></a>
                  <span className="white"> | </span>
                  <a href="https://www.lillyprivacy.com/SE-sv/hcp" className="link" rel="noreferrer" target="_blank"><span className="white">Privacy Policy</span></a>
                  <span className="white"> | </span>
                  <a href="https://www.lilly.se/accessibility-statement" className="link" rel="noreferrer" target="_blank"><span className="white">Tillgänglighetsutlåtande</span></a>
                  <span className="white"> | </span>
                  <a href="https://www.lilly.se/copyright" className="link" rel="noreferrer" target="_blank"><span className="white">Copyright</span></a>
                  <span className="white"> | </span>
                  <a href className="link" rel="noreferrer" target="_blank" onClick={props.cookieSettingBtnHandler}><span className="white">Cookieinställningar</span></a>
                  <br />
                  PP-MG-SE-0099 Senast uppdaterad: Feb 2022
                </p>
              </div>
            </div>
            <div className="w-col w-col-4 w-col-sm-3 visible-xs">
              <img src={LillyPlayLogo} className="logo-footer center-block" alt="Lilly Play" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sweden