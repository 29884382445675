import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useForm } from "react-hook-form";
import { FormControl, FormLabel } from "@material-ui/core";

const AddEditPopup = (props) => {
  const ModalTitle =
    props.indType === "Category1"
      ? "Category 1"
      : props.indType === "Category2"
      ? "Category 2"
      : "Therapeutic Area";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      indName: props.indName,
    },
    mode: 'onChange'
  });
  const onSubmit = (data) => {
    props.saveItem(data.indName);
    props.setIsDisabled(true);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className="paper modalstyle">
        <Grid className="modal-title-2">
          {props.commandName === "insert" ? "Create New " : "Edit "}
          {ModalTitle}
          <button className="closeModal" onClick={props.handleClose}></button>
        </Grid>
        <Grid className="modal-body">
          <FormControl className="formControl">
            <FormLabel
              htmlFor="indName"
              className="font-weight-bold"
              required
            >
              {ModalTitle} Name
            </FormLabel>
            <TextField
              variant="outlined"
              size="small"
              error={!!errors.indName}
              helperText={!!errors.indName && `${ModalTitle} is required.`}
              {...register("indName", { required: true })}
            />
          </FormControl>
        </Grid>
        <Grid
          className="modal1FoBtn modal-footer"
          container
          justifyContent="flex-end"
        >
          <button
            type="button"
            className="modalBtn"
            onClick={() => props.handleClose()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="modalBtn"
            disabled={props.isDisabled}
          >
            OK
          </button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddEditPopup;
