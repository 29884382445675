import React from 'react';
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        document.querySelector("header.appbarBck").style.display = 'none';
        return () => {
            localStorage.clear();
            sessionStorage.clear();
        }
    }, []);

    return (
        <div className="text-center">
            <h2>You are successfully logged out</h2>
            <button onClick={() => navigate('/sso')} className='ssoBtn'>Sign In Again</button>
        </div>
    )
}

export default Logout