import React from 'react';
import BannerLogo from '../../../public/img/responsive_banner.png';
import LillyPlayLogo from '../../../public/img/logo-lilyplay.png';

const Taiwan = (props) => {
  React.useEffect(() => {
    var head = document.head;
    var link1 = document.createElement("link");
    link1.type = "text/css";
    link1.rel = "stylesheet";
    link1.href = window.location.origin +"/css/lilyplay.webflow.css";
    head.appendChild(link1);

    var link2 = document.createElement("link");
    link2.type = "text/css";
    link2.rel = "stylesheet";
    link2.href = window.location.origin +"/css/webflow.css";
    head.appendChild(link2);

    var link3 = document.createElement("link");
    link3.type = "text/css";
    link3.rel = "stylesheet";
    link3.href = window.location.origin +"/css/landing1.css";
    head.appendChild(link3);
    // document.getElementById('link10').disabled = true
    return () => { 
      head.removeChild(link1); 
      head.removeChild(link2); 
      head.removeChild(link3); 
    }
  }, []);
  return (
    <>
      {/* <link rel="stylesheet" href={window.location.origin +"/css/lilyplay.webflow.css"}></link>
      <link rel="stylesheet" href={window.location.origin +"/css/webflow.css"}></link>
      <link rel="stylesheet" href={window.location.origin +"/css/landing1.css"}></link> */}

      <div className="navbar">
        <div className="custom-container">
          <div className="w-row">
            <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
              <img src={LillyPlayLogo} alt="Lilly Play" className="logo-navbar" />
              <p className="newLogo">Lilly <span>| Play</span></p>
              <small>&nbsp;</small>
            </div>
            <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
              <div className="button-list-nav">
                <a href name="landing-Login" className="botao-nav au-login" onClick={props.passportLogin}>登入</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainContent">
        <div className="mainBanner">
            <div className="lp-content">
                <h3>Lilly Play，一個專為醫事人員建立的醫藥資訊平台，提供您醫療學術以及禮來藥品相關影音內容。</h3>
                <img className="banner_mobile" src={BannerLogo} alt="banner_mobile"/>
                <div className="button-list-nav">
                    <a href name="landing-Register" className="botao-nav au-signup" onClick={props.passportRegister}><font>註冊</font></a>
                </div>
                <span className="login"><font>您已擁有帳號? </font><a href name="landing-Login" className="au-login loginLink" onClick={props.passportLogin}><font>登入</font></a></span>
            </div>
            <div className="banner"><img src={BannerLogo} alt="banner" /></div>
        </div>
      </div>

      <div className="lp-footer">
        <div className="custom-container">
          <div className="w-row">
            <div className="w-col w-col-4 w-col-sm-3 w-hidden-small w-hidden-tiny coluna-logo-footer">
              <img src={LillyPlayLogo} className="logo-footer" alt="Lilly Play" />
            </div>
            <div className="coluna-textos w-col w-col-8 w-col-sm-9">
              <p className="disclaimer">本網站僅供醫療保健專業人員使用。</p>
              <div>
                <p className="copyright-text">
                  <span className="white">Copyright © 2022 Eli Lilly and Company. All rights reserved.</span>
                </p>
                <p className="termos">
                  <a href="https://www.lilly.com/tw/terms-of-use" class="link-2" rel="noreferrer" target="_blank"><span className="white"><font>使用條款</font></span></a>
                  <span className="white"> | </span>
                  <a href="https://www.lillyprivacy.com/tw-zh/hcp" class="link" rel="noreferrer" target="_blank"><span className="white"><font>隱私權政策</font></span></a>
                  <span className="white"> | </span>
                  <a href="https://www.lilly.com.tw/accessibilty-statement" class="link" rel="noreferrer" target="_blank"><span className="white"><font>造訪便利性聲明</font></span></a>
                  <br />
                  P-MKT-001-22-Feb-14
                </p>
              </div>
            </div>
            <div className="w-col w-col-4 w-col-sm-3 visible-xs">
              <img src={LillyPlayLogo} className="logo-footer center-block" alt="Lilly Play" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Taiwan