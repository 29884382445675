/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@material-ui/core";
import ContentPublishModal from '../Components/ContentPublishModal';
import ContentSubmitPublishModal from '../Components/ContentSubmitPublishModal';
import { useSelector } from 'react-redux';

const DoneStep = ({ props, goToStep, data, content_data }) => {
  const navigate = useNavigate();

  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);

  const [countryPublisher, SetcountryPublisher] = React.useState([]);
  const [openChangeStatusModal, setOpenChangeStatusModal] = React.useState(false);

  const [activeContentSelected, setActiveContentSelected] = React.useState({});
  const [isactionperformed, setIsActionPerformed] = React.useState(false);

  const [currentStatus, setCurrentStatus] = React.useState('Draft');
  const [actionStatus, setActionStatus] = React.useState('');
  const [publisherAccess, setPublisherAccess] = React.useState(false);
  const [isSingleCountry, setIsSingleCountry] = React.useState(false);

  useEffect(() => {
    const publishCountry = sessionState.sessionValue?.payload?.affiliate.map((e) => e.country_code);
    SetcountryPublisher(publishCountry);
    let flag = false;
    if (sessionState.sessionValue?.payload?.user.role_ids.includes(3)) {
      Object.keys(content_data?.country_approve).forEach(x => {
        if (publishCountry.includes(x)) {
          flag = true
        }
      })
    }
    setPublisherAccess(flag)
    let allCountryList = Object.keys(content_data.affiliates_to_publish);
    if (allCountryList.length === 1) {
      setCurrentStatus(content_data.affiliates_to_publish[allCountryList[0]].status == 'Active' ?
        'Published' : content_data.affiliates_to_publish[allCountryList[0]].status
      )
      setActionStatus(content_data.affiliates_to_publish[allCountryList[0]].status)
      setIsSingleCountry(true)
    } else {
      const action_priority = [];
      const current_priority = [];
      allCountryList.map(country_code => {
        if (content_data.affiliates_to_publish[country_code].prevstatus) {
          action_priority.push(content_data.affiliates_to_publish[country_code].status);
        }
        current_priority.push(content_data.affiliates_to_publish[country_code].status);
      })
      // if we have some tracking actions
      if (action_priority.length > 0) {
        // withing the action performed check the priority
        if (action_priority.includes('Published')) {
          setCurrentStatus('Published')
        } else if (action_priority.includes('Active')) {
          setCurrentStatus('Published')
        } else if (action_priority.includes('Pending')) {
          setCurrentStatus('Pending')
        } else if (action_priority.includes('Inactive')) {
          setCurrentStatus('Inactive')
        } else if (action_priority.includes('Rejected')) {
          setCurrentStatus('Rejected')
        } else if (action_priority.includes('Draft')) {
          setCurrentStatus('Draft')
        }
      } else {
        if (current_priority.includes('Published')) {
          setCurrentStatus('Published')
        } else if (current_priority.includes('Active')) {
          setCurrentStatus('Published')
        } else if (current_priority.includes('Pending')) {
          setCurrentStatus('Pending')
        } else if (current_priority.includes('Inactive')) {
          setCurrentStatus('Inactive')
        } else if (current_priority.includes('Rejected')) {
          setCurrentStatus('Rejected')
        } else if (current_priority.includes('Draft')) {
          setCurrentStatus('Draft')
        }
      }
      setIsSingleCountry(false);
    }
    // if all countries are in draft 
    const countries = Object.keys(content_data?.country_approve);
    if (countries.length > 1) {
      let allDraft = countries.every(x => content_data.country_approve[x].status == 'Draft');
      if (allDraft) {
        // make all to pending
        countries.forEach(x => {
          content_data.country_approve[x].status = 'Pending'
        });
        setActionStatus('Draft');
      }
    }
  }, [content_data])

  return (
    <>
      <>
        {Object.keys(activeContentSelected).length > 0
          && (
            <>
              {
                activeContentSelected.record_type === 'SubmitForApproval' && (
                  <ContentSubmitPublishModal
                    open={openChangeStatusModal}
                    handleClose={(value) => {
                      setOpenChangeStatusModal(value); setActiveContentSelected({});
                    }}
                    content={activeContentSelected}
                    onCloseRefresh={false}
                    onSubmitApi={true}
                    handlebtnInactivate={(value) => { setIsActionPerformed(value); setCurrentStatus('Pending') }}
                  />
                )
              }
              {
                activeContentSelected.record_type === 'SubmitToPublish' && (
                  <ContentSubmitPublishModal
                    open={openChangeStatusModal}
                    handleClose={(value) => {
                      setOpenChangeStatusModal(value); setActiveContentSelected({});
                    }}
                    content={activeContentSelected}
                    onCloseRefresh={false}
                    onSubmitApi={true}
                    handlebtnInactivate={(value) => { setIsActionPerformed(value); setCurrentStatus('Published') }}
                  />
                )
              }
              {
                activeContentSelected.record_type === 'Publish' && (
                  <ContentPublishModal
                    open={openChangeStatusModal}
                    handleClose={(value) => {
                      setOpenChangeStatusModal(value); setActiveContentSelected({});
                    }}
                    content={activeContentSelected}
                    onCloseRefresh={false}
                    onSubmitApi={true}
                    handlebtnInactivate={(value) => { setIsActionPerformed(value); setCurrentStatus('Published') }}
                  />
                )
              }
            </>
          )
        }
      </>
      <Grid container>
        {/* Changes for Done Step */}
        <div className="final-step">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
              <path d="M64.1673 32.3167V35C64.1637 41.2896 62.1271 47.4095 58.3612 52.447C54.5953 57.4845 49.3019 61.1697 43.2704 62.9531C37.239 64.7364 30.7927 64.5222 24.8929 62.3426C18.9931 60.1629 13.9559 56.1345 10.5326 50.8581C7.10934 45.5818 5.48338 39.3402 5.89721 33.0643C6.31104 26.7883 8.7425 20.8143 12.829 16.0331C16.9154 11.252 22.4379 7.91988 28.5729 6.53377C34.7078 5.14766 41.1264 5.78183 46.8715 8.34168" stroke="#11993E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M64.1667 11.6667L35 40.8626L26.25 32.1126" stroke="#11993E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <>
            {/* message logic */}
            <div className='content-message'>
              {currentStatus == 'Draft' &&
                <div className='content-message'>
                  Your content is saved as draft successfully!
                </div>
              }
              {currentStatus == 'Pending' &&
                <div className='content-message'>
                  Your content is sent for approval successfully!
                </div>
              }
              {currentStatus == 'Published' &&
                <div className='content-message'>
                  Your content is published successfully!
                </div>
              }
              {currentStatus == 'Inactive' &&
                <div className='content-message'>
                  Your content is inactivated successfully!
                </div>
              }
              {currentStatus == 'Rejected' &&
                <div className='content-message'>
                  Your content is rejected successfully!
                </div>
              }
            </div>
          </>
          <div className="action-items">
            {/* Action Buttons logic */}
            {/* Single country - Draft - user is an internal user */}
            {isSingleCountry && actionStatus === 'Draft' && !publisherAccess &&
              <div className="action-buttons red"
                onClick={() => {
                  setOpenChangeStatusModal(true);
                  setActiveContentSelected({
                    id: content_data?.content_data_id,
                    content_approved: content_data?.country_approve,
                    title: content_data?.title,
                    type: content_data?.content_type,
                    record_type: 'SubmitForApproval',
                    country: 'single',
                    vvpm_details: content_data?.vvpm_details,
                    owner: content_data?.owner,
                    secondary_owner: content_data?.secondary_owner,
                    created: content_data?.recording_date,
                  })
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <path d="M13.8327 10.0001L10.4993 6.66675M10.4993 6.66675L7.16602 10.0001M10.4993 6.66675V13.3334M18.8327 10.0001C18.8327 14.6025 15.1017 18.3334 10.4993 18.3334C5.89698 18.3334 2.16602 14.6025 2.16602 10.0001C2.16602 5.39771 5.89698 1.66675 10.4993 1.66675C15.1017 1.66675 18.8327 5.39771 18.8327 10.0001Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <label>Submit for Approval</label>
              </div>
            }
            {/* Single country - Draft - user is a publisher */}
            {isSingleCountry && actionStatus === 'Draft' && publisherAccess &&
              <div className="action-buttons red"
                onClick={() => {
                  setOpenChangeStatusModal(true);
                  setActiveContentSelected({
                    id: content_data?.content_data_id,
                    content_approved: content_data?.country_approve,
                    title: content_data?.title,
                    type: content_data?.content_type,
                    record_type: 'SubmitToPublish',
                    country: 'single',
                    vvpm_details: content_data?.vvpm_details,
                    owner: content_data?.owner,
                    secondary_owner: content_data?.secondary_owner,
                    created: content_data?.recording_date,
                  })
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <path d="M13.8327 10.0001L10.4993 6.66675M10.4993 6.66675L7.16602 10.0001M10.4993 6.66675V13.3334M18.8327 10.0001C18.8327 14.6025 15.1017 18.3334 10.4993 18.3334C5.89698 18.3334 2.16602 14.6025 2.16602 10.0001C2.16602 5.39771 5.89698 1.66675 10.4993 1.66675C15.1017 1.66675 18.8327 5.39771 18.8327 10.0001Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <label>Submit to Publish</label>
              </div>
            }
            {/* Single country - Pending - user is a publisher */}
            {isSingleCountry && actionStatus === 'Pending' && publisherAccess &&
              <div className="action-buttons red"
                onClick={() => {
                  setOpenChangeStatusModal(true);
                  setActiveContentSelected({
                    id: content_data?.content_data_id,
                    content_approved: content_data?.country_approve,
                    title: content_data?.title,
                    type: content_data?.content_type,
                    record_type: 'Publish',
                    country: 'single',
                    vvpm_details: content_data?.vvpm_details,
                    owner: content_data?.owner,
                    secondary_owner: content_data?.secondary_owner,
                    created: content_data?.recording_date,
                  })
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M8.74928 11.25L17.4993 2.50002M8.85559 11.5234L11.0457 17.1551C11.2386 17.6512 11.3351 17.8993 11.4741 17.9717C11.5946 18.0345 11.7381 18.0345 11.8587 17.9719C11.9978 17.8997 12.0946 17.6517 12.2881 17.1558L17.78 3.08269C17.9547 2.63504 18.0421 2.41121 17.9943 2.26819C17.9528 2.14398 17.8553 2.04651 17.7311 2.00501C17.5881 1.95723 17.3643 2.04458 16.9166 2.21927L2.84349 7.71122C2.34759 7.90474 2.09965 8.0015 2.02739 8.14059C1.96475 8.26116 1.96483 8.4047 2.02761 8.5252C2.10004 8.66421 2.3481 8.76067 2.84422 8.95361L8.47589 11.1437C8.5766 11.1829 8.62695 11.2024 8.66935 11.2327C8.70693 11.2595 8.7398 11.2924 8.7666 11.3299C8.79685 11.3723 8.81643 11.4227 8.85559 11.5234Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <label>Publish</label>
              </div>
            }
            {/* Multi country - All Draft - user is an internal user */}
            {!isSingleCountry && actionStatus === 'Draft' && !publisherAccess &&
              <div className="action-buttons red"
                onClick={() => {
                  setOpenChangeStatusModal(true);
                  setActiveContentSelected({
                    id: content_data?.content_data_id,
                    content_approved: content_data?.country_approve,
                    title: content_data?.title,
                    type: content_data?.content_type,
                    record_type: 'SubmitForApproval',
                    country: 'single',
                    vvpm_details: content_data?.vvpm_details,
                    owner: content_data?.owner,
                    secondary_owner: content_data?.secondary_owner,
                    created: content_data?.recording_date,
                  })
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                  <path d="M13.8327 10.0001L10.4993 6.66675M10.4993 6.66675L7.16602 10.0001M10.4993 6.66675V13.3334M18.8327 10.0001C18.8327 14.6025 15.1017 18.3334 10.4993 18.3334C5.89698 18.3334 2.16602 14.6025 2.16602 10.0001C2.16602 5.39771 5.89698 1.66675 10.4993 1.66675C15.1017 1.66675 18.8327 5.39771 18.8327 10.0001Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <label>Submit for Approval</label>
              </div>
            }
            <div className="action-buttons" onClick={() => navigate('/content-update/reload')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M18 12.5V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V12.5M14.6667 6.66667L10.5 2.5M10.5 2.5L6.33333 6.66667M10.5 2.5V12.5" stroke="#303030" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <label>Upload new content</label>
            </div>
            <div className="action-buttons" onClick={() => navigate('/content-management')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M6.74935 9.16667H4.33268C3.86597 9.16667 3.63262 9.16667 3.45436 9.25749C3.29756 9.33739 3.17007 9.46487 3.09018 9.62167C2.99935 9.79993 2.99935 10.0333 2.99935 10.5V17.5M14.2493 9.16667H16.666C17.1327 9.16667 17.3661 9.16667 17.5443 9.25749C17.7011 9.33739 17.8286 9.46487 17.9085 9.62167C17.9993 9.79993 17.9993 10.0333 17.9993 10.5V17.5M14.2493 17.5V5.16667C14.2493 4.23325 14.2493 3.76654 14.0677 3.41002C13.9079 3.09641 13.6529 2.84144 13.3393 2.68166C12.9828 2.5 12.5161 2.5 11.5827 2.5H9.41602C8.48259 2.5 8.01588 2.5 7.65937 2.68166C7.34576 2.84144 7.09079 3.09641 6.93101 3.41002C6.74935 3.76654 6.74935 4.23325 6.74935 5.16667V17.5M18.8327 17.5H2.16602M9.66602 5.83333H11.3327M9.66602 9.16667H11.3327M9.66602 12.5H11.3327" stroke="#303030" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <label>Go to content management</label>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}

export default DoneStep;