import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Breadcrumb from '../../../layout/breadcrumb';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import editImg from '../../../public/img/new-img/GreyEdit@3x.png';
import editImgBlue from '../../../public/img/new-img/BlueEdit@3x.png';
import deleteImg from '../../../public/img/CloseGrey@3x.png';
import deleteImgBlue from '../../../public/img/new-img/RedClose@3x.png';
import '../quiz.css';
import { QuizService } from '../services/quiz-service';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    /**First page click event on pagination */
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    /**Back click event on pagination */
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    /**Handle next click event on pagination */
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    /**Last page click event on pagination */
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

/**Decending comparator on header */


function descendingComparator(a, b, orderBy) {
    if(orderBy == 'time_of_creation' || orderBy == 'time_of_modification' ){
    let aDate = new Date(a[orderBy]);
    a[orderBy] = aDate.getTime();
    let bDate = new Date(b[orderBy]);
    b[orderBy] = bDate.getTime();
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

/**Fetches comparator of order on header */
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

/**Sort function on header */
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'quiz_title', numeric: true, disablePadding: false, label: 'Quiz Title', sorting: true },
    { id: 'created_by', numeric: true, disablePadding: false, label: 'Created By', sorting: true },
    { id: 'time_of_creation', numeric: true, disablePadding: false, label: 'Time of Creation', sorting: true },
    { id: 'modified_by', numeric: true, disablePadding: false, label: 'Modified By', sorting: true },
    { id: 'time_of_modification', numeric: true, disablePadding: false, label: 'Time of Modification', sorting: true },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action', sorting: false },
];

/**Function provides shorting of header section  */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={!headCell.sorting}
                            class={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className='visuallyHidden'>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    videobuttonedit: {
        backgroundImage: `url(${editImg})`,
        height: '20px',
        width: '20px',
        border: '0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '0 1px',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        '&:hover': {
            backgroundImage: `url(${editImgBlue})`
        }
    },
    videobuttonremove: {
        backgroundImage: `url(${deleteImg})`,
        height: '20px',
        width: '20px',
        border: '0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '0px 0px',
        backgroundColor: 'transparent',
        marginLeft: '10px',
        cursor: 'pointer',
        '&:hover': {
            backgroundImage: `url(${deleteImgBlue})`
        }
    }
}));


export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, SetRows] = React.useState([]);
    const [fetchDataStatus, setFetchDataStatus] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [openPopup, setOpenPopup] = React.useState(false);
    const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
    const [rowID, setrowID] = React.useState('');
    const [activeContentName, setactiveContentName] = React.useState('');

    const navigate = useNavigate();

    const handleRequestSort = (event, property) => {
        if (property !== 'action') {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }

    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [rows]);

    /**Function invokes click event on table row */
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    /**Function changes the page number on pagination*/
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    /**Function changes page on rows per page*/
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    /**Variable shows selected index for page*/
    const isSelected = (name) => selected.indexOf(name) !== -1;

    /**Empty rows details*/
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    /**Breadcrumb for Quiz management*/
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'Quiz Management', path: '/quiz-management' }
    ];

    const handleDelConfirmClose = () => {
        setopenDelConfirmModal(false);
    };
    const openDelConfirm = (quiz_id, content_name) => {
        // alert(content_name)
        setrowID(quiz_id);
        setopenDelConfirmModal(true);
        setactiveContentName(content_name)
    };

    const handleDelete = () => {

        let service = new QuizService();
        service.DeleteQuiz(rowID).then((data) => {
            setopenDelConfirmModal(false);
            GetAllQuiz();
        }).catch((err) => {});
    }

    const GetAllQuiz = () => {
        let service = new QuizService();

        service.GetAllQuiz().then((data) => {
            let list = [];

            service.GetAllContent().then((content) => {
                let result = data.data;
                let videos = content.data;
                for (let i = 0; i < result.length; i++) {
                    // result[i]['content_id'] = ""
                    result[i]['content_name'] = []
                    let arr = []
                    let creation_date = new Date(result[i]['created_at']);
                    creation_date = creation_date.getTime();
                    let modification_date = new Date(result[i]['updated_at']);
                    modification_date = modification_date.getTime();
                    for (let j = 0; j < videos.length; j++) {
                        if (videos[j].quiz_credit) {
                            let quiz_credit = videos[j].quiz_credit
                            if (quiz_credit.quiz_id && result[i]['quiz_id'] == quiz_credit.quiz_id) {
                                // result[i]['content_id'] = videos[j].content_id
                                arr.push(videos[j].title)
                            }
                            result[i]['content_name'] = arr.join(', ')
                        }
                    }
                    list.push({ quiz_id: result[i]['quiz_id'], quiz_title: result[i]['quiz_title'], created_by: result[i]['created_by'], time_of_creation: creation_date, modified_by: result[i]['updated_by'], time_of_modification: modification_date, content_id: result[i]['content_id'], content_name: result[i]['content_name'] })
                }
                setFetchDataStatus(true);
                SetRows(list);
            })
        })
    }

    function getDateTime(obj) {
        let d = new Date(obj);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let datestring = ("0" + d.getDate()).slice(-2) + " " + months[d.getMonth()] + " " + d.getFullYear() + ", " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
        return datestring;
    }

    React.useEffect(() => {
        GetAllQuiz()
    }, []);

    return (
        <>
            <Modal open={openDelConfirmModal} onClose={handleDelConfirmClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handleDelConfirmClose()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            {activeContentName == '' && <Alert severity="warning">Quiz <b></b> will be deleted, are you sure?</Alert>}
                            {activeContentName != '' && <Alert severity="warning">This quiz is binding with video: {activeContentName}, click OK to continue deleting.</Alert>}

                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button>
                        <button type="button" onClick={() => handleDelete()} className="modalBtn">OK</button>
                    </Grid>
                </Grid>
            </Modal>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />



            <div className='content-mgmt'>
                <div className="main-wrp">
                    <Grid className="mgmt_header">
                        <Grid
                            container
                            className="row"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            {/* <Grid container > */}

                            <Grid item lg={5} xs={12} >
                                <label className="label-hd">
                                    Quiz Management <span className='user'> {rows.length} quizzes</span>
                                </label>
                            </Grid>
                            {/* </Grid> */}
                            <Grid container
                                lg={7} xs={12}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center">


                                <Grid item lg={3} sm={3} xs={12} className="marginTop">
                                    <Button className='createBtn createNewClass' onClick={() => navigate('/quiz-management/quiz-detail')}>Create New Quiz</Button>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.name);
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.name)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.name}
                                                selected={isItemSelected}>

                                                <TableCell align="center">{row.quiz_title}</TableCell>
                                                <TableCell align="center">{row.created_by}</TableCell>
                                                <TableCell align="center" >{getDateTime(new Date(row.time_of_creation).toString())} </TableCell>
                                                <TableCell align="center" >{row.modified_by}</TableCell>
                                                <TableCell align="center" >{getDateTime(new Date(row.time_of_modification).toString())} </TableCell>
                                                <TableCell width="12%" align="center">
                                                    <Link to={"/quiz-management/quiz-detail/" + row.quiz_id}>
                                                        <button type="button" className={classes.videobuttonedit}></button>
                                                    </Link>
                                                    <button onClick={() => openDelConfirm(row.quiz_id, row.content_name)} type="button" className={classes.videobuttonremove}></button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        {rows.length <= 0 ?
                                            <TableCell align="center" colSpan={7}>{fetchDataStatus ? 'No Records Found.' : 'Loading...'}</TableCell> :
                                            <TableCell colSpan={7} />
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
            </div>
        </>
    );

}