import React from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';

const DeleteConfirmPopup = (props) => {
  return (
    <Modal open={props.openDelModal} onClose={props.deletePopupClose}>
        <Grid className='paper modalstyle'>
            <Grid className="modal-title">
                <button className='closeModal' onClick={() => props.deletePopupClose()}></button>
            </Grid>
            <Grid className='box'>
                {props.indType == 'Therapeutic Area'?'Therapeutic Area':props.indType == 'Category1'?'Category 1':props.indType == 'Category2'?'Category 2':"" }  <b>{props.indName}</b> will be deleted, are you sure?
            </Grid>
            <Grid className="modal1FoBtn modal-footer">
                <button type="button" className="modalBtn" onClick={() => props.deletePopupClose()}>Cancel</button>
                <button type="button" className="modalBtn" disabled={props.isDisabled} onClick={() => {props.deleteItem(); props.setIsDisabled(true)}}>Delete</button>
            </Grid>
        </Grid>
    </Modal>
  )
}

export default DeleteConfirmPopup