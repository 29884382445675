import React from 'react';
import Grid from '@material-ui/core/Grid';
import { msalInstance } from './Services/login-service';
import { useNavigate } from "react-router-dom";
import * as msal from "@azure/msal-browser";
import { getPassportToken, getSSOToken } from './Services/login-service';
import loaderNewGif from '../../public/img/new-loader.png';
import { useSelector, useDispatch } from 'react-redux'
import { SessionService } from './Services/SessionService';
import { setSession } from '../../Session/sessionSlice';
import * as jose from 'jose';
import { AuthVerifyService } from './Services/AuthVerifyService';
import countryListJson from '../../CountryPassport/CountryPassport.json';

const Login = (props) => {
    const navigate = useNavigate();
    const [userAuthenticate, setUserAuthenticate] = React.useState(false);

    // Session Get/Set Methods
    const currentStore = useSelector(state => state.session);
    const dispatch = useDispatch();

    // Redirection Rules
    const ssoLogin = () => {
        localStorage.setItem('passportToken', 'as');
        localStorage.setItem('lang', 'en-US');
        msalInstance.loginRedirect({
            redirectUri: window.location.origin + `/`
        });
    }
    const HCPRedirect = () => {
        let origin = window.location.origin;
        
        origin = origin.toLowerCase();
        if(origin.includes('lilly-conexiones.com') || origin.includes('www.lilly-conexiones.com')){
            window.location.href = window.location.origin + '/mexico'
            return true;
        }
        if(origin.includes('lillyplay.me') || origin.includes('www.lillyplay.me')){
            window.location.href = window.location.origin + '/kuwait'
            return true;
        }
        if(origin.includes('play.lilly.com.au') || origin.includes('www.play.lilly.com.au')){
            window.location.href = window.location.origin + '/australia'
            return true;
        }
        if(origin.includes('lillymed.at') || origin.includes('www.lillymed.at')){
            window.location.href = window.location.origin + '/austria'
            return true;
        }
        if(origin.includes('play.lilly.be') || origin.includes('www.play.lilly.be')){
            window.location.href = window.location.origin + '/belgium'
            return true;
        }
        if(origin.includes('lillyplay.com.br') || origin.includes('www.lillyplay.com.br')){
            window.location.href = window.location.origin + '/brazil'
            return true;
        }
        if(origin.includes('lillyplay.cz') || origin.includes('www.lillyplay.cz')){
            window.location.href = window.location.origin + '/czech'
            return true;
        }
        if(origin.includes('lillyplay.fi') || origin.includes('www.lillyplay.fi')){
            window.location.href = window.location.origin + '/finland'
            return true;
        }
        if(origin.includes('play.lilly.fr') || origin.includes('www.play.lilly.fr')){
            window.location.href = window.location.origin + '/france'
            return true;
        }
        if(origin.includes('lillyplay.de') || origin.includes('www.lillyplay.de')){
            window.location.href = window.location.origin + '/germany'
            return true;

        }
        if(origin.includes('play.lilly.com.hk') || origin.includes('www.play.lilly.com.hk')){
            window.location.href = window.location.origin + '/hongkong'
            return true;

        }
        if(origin.includes('play.lilly.co.il') || origin.includes('www.play.lilly.co.il')){
            window.location.href = window.location.origin + '/israel'
            return true;

        }
        if(origin.includes('play.lilly.it') || origin.includes('www.play.lilly.it')){
            window.location.href = window.location.origin + '/italy'
            return true;

        }
        if(origin.includes('lillyplay.co.kr') || origin.includes('www.lillyplay.co.kr')){
            window.location.href = window.location.origin + '/korea'
            return true;

        }
        if(origin.includes('play.lilly.nl') || origin.includes('www.play.lilly.nl')){
            window.location.href = window.location.origin + '/netherlands'
            return true;

        }
        if(origin.includes('play.lilly.co.nz') || origin.includes('www.play.lilly.co.nz')){
            window.location.href = window.location.origin + '/newzealand'
            return true;

        }
        if(origin.includes('lillypro.pl') || origin.includes('www.lillypro.pl')){
            window.location.href = window.location.origin + '/poland'
            return true;

        }
        if(origin.includes('lillyplay.pt') || origin.includes('www.lillyplay.pt')){
            window.location.href = window.location.origin + '/portugal'
            return true;

        }
        if(origin.includes('lillyplay.ro') || origin.includes('www.lillyplay.ro')){
            window.location.href = window.location.origin + '/romania'
            return true;

        }
        if(origin.includes('lillyconnect-sa.com') || origin.includes('www.lillyconnect-sa.com')){
            window.location.href = window.location.origin + '/saudiarabia'
            return true;

        }
        if(origin.includes('lillyplay.sk') || origin.includes('www.lillyplay.sk')){
            window.location.href = window.location.origin + '/slovakia'
            return true;

        }
        if(origin.includes('lillyep2p.com') || origin.includes('www.lillyep2p.com')){
            window.location.href = window.location.origin + '/southafrica'
            return true;

        }
        if(origin.includes('lillyplay.es') || origin.includes('www.lillyplay.es')){
            window.location.href = window.location.origin + '/spain'
            return true;

        }
        if(origin.includes('lillyplay.se') || origin.includes('www.lillyplay.se')){
            window.location.href = window.location.origin + '/sweden'
            return true;

        }
        if(origin.includes('lillyplay.ch') || origin.includes('www.lillyplay.ch')){
            window.location.href = window.location.origin + '/switzerland'
            return true;

        }
        if(origin.includes('play.lilly.com.tw') || origin.includes('www.play.lilly.com.tw')){
            window.location.href = window.location.origin + '/taiwan'
            return true;

        }
        if(origin.includes('lillyplay.co.uk') || origin.includes('www.lillyplay.co.uk')){
            window.location.href = window.location.origin + '/uk'
            return true;

        }
        if(origin.includes('lillyconnect.co.in') || origin.includes('www.lillyconnect.co.in')){
            window.location.href = window.location.origin + '/india'
            return true;

        }
        if(origin.includes('lillyakademi.com') || origin.includes('www.lillyakademi.com')){
            window.location.href = window.location.origin + '/turkey'
            return true;

        }
        if(origin.includes('play.lilly.com') || origin.includes('www.play.lilly.com')){
            if(!origin.includes('sso')){
                // window.location.href = window.location.origin + '/sso'
                return true;
            }


        }
        return false

    }
    const DirectSSOLogin = () => {
        if (localStorage.getItem('access_token') === null) {
             const { pathname } = window.location;
                const paths = pathname.split("/").filter(entry => entry !== "");
                const country_name = paths[paths.length - 1];
             let countryData = getCountryDetails(country_name);
            let checkRedirection = HCPRedirect();
            
            let params = (new URL(window.location.href)).searchParams;
            if (props.redirect) {
               
               
                console.log("countryData:", countryData, country_name);
                if (countryData) {
                    props.SelectedCountry(countryData.locale);
                    localStorage.setItem('lang', countryData.locale);
                    let sessionService = new SessionService(localStorage.getItem('jwtToken'));
                    sessionService.PushCOuntrySelected(country_name);
                    const landingCountries = ["Australia", "Brazil", "Czech", "Germany", "Italy", "New Zealand", "Poland", "Romania", "Spain", "Slovakia", "Taiwan"];
                    const blackListedCountries=["UK", "Sweden","Finland"];
                    const lacamCountries = ['Argentina', 'Aruba', 'Bahamas', 'Barbados', 'Belize', 'Bolivia', 'Chile', 'Colombia', 'Costa Rica', 'Curacao', 'Dominican Republic', 'Ecuador', 'El Salvador', 'Guatemala', 'Guyana', 'Honduras', 'Jamaica', 'Mexico', 'Nicaragua', 'Panama', 'Paraguay', 'Peru', 'Trinidad and Tobago', 'Uruguay', 'Venezuela', 'Curacao'];
                    const meaCountries = ["Iraq", "Kuwait", "Lebanon", "Oman", "Pakistan", "Qatar", "United Arab Emirates"];
                    if (landingCountries.includes(countryData.country)) {
                        window.location.href = window.location.origin + '/landing/' + country_name + '/index.html';
                    } else if (blackListedCountries.includes(countryData.country)){
                        window.location.href = window.location.origin + '/landing/'+ country_name +'/maintenance.html';
                    } else if (lacamCountries.includes(countryData.country)) {
                        window.location.href = window.location.origin + '/landing/lacam/index.html';
                    } else if (meaCountries.includes(countryData.country)) {
                        window.location.href = window.location.origin + '/landing/mea/index.html';
                    } else {
                        window.location.href = `${process.env.REACT_APP_PASSPORT_BASE_URL}${countryData.locale}/signin/?url=${window.location.origin}/`;
                    }
                    //alert(57);
                }
                if (country_name === 'sso') {
                    if (params.get('access_token') === null && window.location.hash.length === 0) {
                        //alert(60);
                        // if(checkRedirection){
                        //     return false;
                        // }
                        // window.location.href = window.location.origin;
                        ssoLogin();
                        return false;
                    }
                }
            } else {
                if (params.get('access_token') === null && window.location.hash.length === 0) {
                    //alert(67);
                    if(checkRedirection){
                        return false;
                    }
                    ssoLogin();
                    return false;
                }
            }
        }
        if(window.location.hash.length>0){
            return true;
        }
        return false;
    }
    const getCountryDetails = (country_name) => {
        if (country_name !== undefined) {
            if (countryListJson.length) {
                for (var i = 0; i < countryListJson.length; i++) {
                    let cntName = countryListJson[i].country.split(" ").join("").toLowerCase();
                    if (cntName == country_name) {
                        localStorage.setItem('ga_code', countryListJson[i].gtm_code);
                        localStorage.setItem('country', cntName);
                        return countryListJson[i];
                    }
                }
            }
        }
        return '';
    }
    React.useEffect(() => {
        return;
        let furtherProcess = DirectSSOLogin();

        if (furtherProcess) {
            return;
        }
        if (localStorage.getItem('access_token') === null) {
            let params = (new URL(window.location.href)).searchParams;
            if (props.redirect) {
                const { pathname } = window.location;
                const paths = pathname.split("/").filter(entry => entry !== "");
                const country_name = paths[paths.length - 1];
                let countryData = getCountryDetails(country_name);

                if (countryData) {
                    props.SelectedCountry(countryData.locale);
                    localStorage.setItem('lang', countryData.locale);

                    let sessionService = new SessionService(localStorage.getItem('jwtToken'));
                    sessionService.PushCOuntrySelected(country_name);
                    const landingCountries = ["Australia", "Brazil", "Czech", "Germany", "Italy", "Newzealand", "Poland", "Romania", "Slovakia",  "Sweden", "Taiwan", "UK"];
                    const lacamCountries = ['Argentina', 'Aruba', 'Bahamas', 'Barbados', 'Belize', 'Bolivia', 'Chile', 'Colombia', 'Costa Rica', 'Curacao', 'Dominican Republic', 'Ecuador', 'El Salvador', 'Guatemala', 'Guyana', 'Honduras', 'Jamaica', 'Mexico', 'Nicaragua', 'Panama', 'Paraguay', 'Peru', 'Trinidad and Tobago', 'Uruguay', 'Venezuela', 'Curacao'];
                    const meaCountries = ["Iraq", "Kuwait", "Lebanon", "Oman", "Pakistan", "Qatar", "United Arab Emirates"];
                    if (landingCountries.includes(countryData.country)) {
                        window.location.href = window.location.origin + '/landing/' + country_name + '/index.html';
                    } else if (lacamCountries.includes(countryData.country)) {
                        window.location.href = window.location.origin + '/landing/lacam/index.html';
                    } else if (meaCountries.includes(countryData.country)) {
                        window.location.href = window.location.origin + '/landing/mea/index.html';
                    } else {
                        window.location.href = `${process.env.REACT_APP_PASSPORT_BASE_URL}{countryData.locale}/signin/?url=${window.location.origin}/`;
                    }
                }
                if (country_name === 'sso') {
                    if (params.get('access_token') === null && window.location.hash.length === 0) {
                        ssoLogin();
                    }
                }
            } else {
                if (params.get('access_token') === null && window.location.hash.length === 0) {
                    ssoLogin();
                }
            }
        }
    }, [props]);

    /**Token Setup on Passport Authentication */
    const CalculateNavigatingUrl = () => {
        let uri = new URL(window.location.href);
        if (uri.pathname.includes('content-watch') || true) {
            let params = uri.searchParams;
            if (params) {
                let passportToken = params.get('access_token') ? params.get('access_token') : '';
                if (passportToken) {
                    let redirectURL = uri.pathname;
                    let navigatePath = '/?access_token=' + passportToken + '&url=' + redirectURL;
                    localStorage.setItem('CalculateNavigatingUrl', navigatePath)
                }
            }
        }
    }
    const RetrySessionDetails = () => {

    }
    React.useEffect(() => {
        let _authVerifyService = new AuthVerifyService();

        let Checkparams = (new URL(window.location.href)).searchParams;

        if (window.location.hash.length == 0 && !Checkparams.get('access_token')) {
            let furtherProcess = DirectSSOLogin();
            // return;
        }
        if (localStorage.getItem('access_token') === null) {
            localStorage.setItem('sessionDate', new Date());
            let passportToken = window.location.href.split('=');
            let params = (new URL(window.location.href)).searchParams;
            passportToken = params.get('access_token') ? params.get('access_token') : '';
            let hcpDirectLink = window.location.href.split('&url=')[1]//; params.get('url') ? params.get('url') : '';
            let currLoc = params.get('currLoc');
            if (passportToken.length >= 2 && !window.location.href.includes('code')) {
                setUserAuthenticate(true);
                localStorage.setItem('access_token', passportToken);
                CalculateNavigatingUrl();
                let decoded_accessToken = jose.decodeJwt(passportToken);
                localStorage.setItem('user_info', JSON.stringify(decoded_accessToken));
                if (decoded_accessToken.locale){
                    localStorage.setItem('lang',decoded_accessToken.locale)
                }
                
                if (window.location.search.includes('currLoc')) {
                    localStorage.setItem('passportLanguage', currLoc);
                }
                let authHeader = {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorizationtoken': passportToken
                    },
                };
                if (decoded_accessToken?.email) {
                    (new SessionService(localStorage.getItem('jwtToken'))).getPassportToken(decoded_accessToken.email, authHeader).then(response => {
                        setUserAuthenticate(true);
                        localStorage.setItem('passportToken', 'as');
                        localStorage.setItem('jwtToken', response.data);
                        localStorage.setItem('HCP', false);
                        let sessionService = new SessionService(localStorage.getItem('jwtToken'));
                        sessionService.SetHCPIdFields(decoded_accessToken.id, decoded_accessToken.customerId);
                        //sessionService.PushHCPId(decoded_accessToken.customerId);
                        sessionService.PushJanrainId(decoded_accessToken.id);
                        sessionService.PushCOuntrySelected(decoded_accessToken.countryCode)
                        sessionService.GetPassportSession(decoded_accessToken).then((passportSession) => {
                            passportSession.token = localStorage.getItem('jwtToken');
                            passportSession.currLoc = currLoc;
                            dispatch(setSession(passportSession));
                            if (hcpDirectLink && hcpDirectLink.length > 0) {
                                if(window.location.hash){
                                    if(window.location.hash.length>0){
                                        hcpDirectLink = hcpDirectLink;// + '/'+ window.location.hash;
                                    }
                                }
                                // let url = (new URL(hcpDirectLink)).searchParams;
                                // let anchorLinkUrl = params.get('ta') ? params.get('ta') : '';
                                // if(anchorLinkUrl){
                                //     let mainNavigatorUrl = new URL(hcpDirectLink);
                                //     let finalNavigatorAncho = mainNavigatorUrl.origin+ mainNavigatorUrl.pathname+ '#'+anchorLinkUrl;
                                //     navigate(finalNavigatorAncho);
                                // }
                                // navigate(hcpDirectLink);
                                window.location.href = hcpDirectLink;
                            } else {
                                // alert("140")
                                let url = new URL(window.location.href);
                                let urlHash = decodeURIComponent(url.hash);
                                let searchCatTerm;
                                console.log("chipSelect", url)
                                if (urlHash.includes('#')) {
                                    searchCatTerm = urlHash.replace("#", "");
                                    // navigate('/home#' + searchCatTerm);
                                    window.location.href = '/home#' + searchCatTerm
                                } else {
                                    // navigate('/home');
                                    if(localStorage.getItem('oldUrl')){
                                        let tempUrl =localStorage.getItem('oldUrl');;
                                        localStorage.removeItem('oldUrl')
                                        window.location.href =tempUrl;//localStorage.getItem('oldUrl');
                                    }else{
                                        window.location.href ='/home'
                                    }
                                }
                                //_authVerifyService.checkPreviousRedirectURL(navigate);
                            }
                        })
                    }).catch(error => {
                        console.log(error);
                        setUserAuthenticate(false);
                    });
                }
            }
            const msalConfig = {
                auth: {
                    clientId: process.env.REACT_APP_FEDERATE_CLIENT_ID,
                    authority: process.env.REACT_APP_SSO_AUTHORITY,
                    redirectUri: '/',
                }
            };
            const msalInstance = new msal.PublicClientApplication(msalConfig);
            msalInstance.acquireTokenRedirect().then(() => {
            
            }).catch((err) => {
                
            });
            msalInstance.handleRedirectPromise().then((tokenResponse) => {
                
                if (tokenResponse !== null) {
                    setUserAuthenticate(true);
                    localStorage.setItem('access_token', tokenResponse.accessToken);
                    localStorage.setItem('id_token', tokenResponse.idToken);
                    let authHeader = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + tokenResponse.idToken
                        }
                    };
                    let federateObject = jose.decodeJwt(tokenResponse.idToken);
                    (new SessionService(localStorage.getItem('jwtToken'))).getSSOToken(federateObject.preferred_username, authHeader).then(response => {
                        setUserAuthenticate(true);
                        localStorage.setItem('jwtToken', response.data);
                        let sessionService = new SessionService(response.data);
                        let federateObject = jose.decodeJwt(tokenResponse.idToken);
                        
                        let nameQuery = '?user_email=' + federateObject.preferred_username;
                        sessionService.GetSessionValue(currentStore, nameQuery, federateObject).then((sessionResponse) => {
                            sessionResponse.token = response.data;
                            dispatch(setSession(sessionResponse))
                       
                        localStorage.setItem('HCP', true);
                        // alert("189")
                        let url = new URL(window.location.href);
                        let urlHash = decodeURIComponent(url.hash);
                        let searchCatTerm;
                        console.log("chipSelect", url)
                        if (urlHash.includes('#')) {
                            searchCatTerm = urlHash.replace("#", "");
                            navigate('/home#' + searchCatTerm);
                        } else {
                            if(localStorage.getItem('oldUrl')){
                                let uriSSO = new URL(localStorage.getItem('oldUrl'));
                                localStorage.removeItem('oldUrl');
                                navigate(uriSSO.pathname);
                                // window.location.href =localStorage.getItem('oldUrl');
                            }else{
                                navigate('/home');
                                // window.location.href ='/home'
                            }
                            // navigate('/home');
                        }
                    });
                        //_authVerifyService.checkPreviousRedirectURL(navigate);
                    }).catch(error => {
                        setUserAuthenticate(false);
                    });
                }
            }).catch((error) => {
            });
        } else {
            setUserAuthenticate(true);
            // alert(202)
            let url = new URL(window.location.href);
            let urlHash = decodeURIComponent(url.hash);
            let searchCatTerm;


            const { pathname } = window.location;
            const paths = pathname.split("/").filter(entry => entry !== "");
            const country_name = paths[paths.length - 1];
            let countryData = getCountryDetails(country_name);
            // alert(countryData)
            if (countryData !== '' || country_name === 'sso') {
                if (urlHash.includes('#')) {
                    searchCatTerm = urlHash.replace("#", "");
                    navigate('/home#' + searchCatTerm);
                } else {
                    navigate('/home');
                }
            } else {


                if (country_name === undefined || country_name === '') {
                    if (urlHash.includes('#')) {
                        searchCatTerm = urlHash.replace("#", "");
                        navigate('/home#' + searchCatTerm);
                    } else {
                        navigate('/home');

                    }
                }
                // 
            }

        }
    }, []);

    return (
        <>
            {/* {!userAuthenticate ? 
                <Grid className='text-center'>
                    <img src={loaderNewGif} className='loadSvg rotate' alt='icon' />
                </Grid>
            : ""} */}
        </>
    );
}

export default Login;
