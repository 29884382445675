import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import UploadTabs from './UploadTabs';

const Banners = (props) => {
  return (
    <>
    <Grid container className='content'>
        <Grid container item xs={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={9} md={9} lg={9}>
                <FormLabel component="label"><b>Desktop</b> Banner Options (Drag the window to change the banner image order.)</FormLabel>
                <UploadTabs device_type="desktop" bannersDict={props.bannersDict} SetBannersDict={props.SetBannersDict} 
                therapeuticAreaMap={props.therapeuticAreaMap} fixedMenus={props.fixedMenus} dictionaryOrder={props.dictionaryOrder}
                SetFixedMenus={props.SetFixedMenus} SetDictionaryOrder={props.SetDictionaryOrder}
                 SetBannerMap={props.SetBannerMap} bannerMap={props.bannerMap}
                 SeturltextPopup={props.SeturltextPopup} SeturltextPopupBool={props.SeturltextPopupBool}
                 taBannerOrderDict={props.taBannerOrderDict} SettaBannerOrderDict={props.SettaBannerOrderDict}
                 countryUrlValidationDict={props.countryUrlValidationDict} SetcountryUrlValidationDict={props.setcountryUrlValidationDict}
                 countryBannerValidationDict={props.countryBannerValidationDict} SetcountryBannerValidationDict={props.SetcountryBannerValidationDict}
                 SetcountryBannerValidationBool={props.SetcountryBannerValidationBool}
                 />
                <br />
                <FormLabel component="label"><b>Mobile</b> Banner Options (Drag the window to change the banner image order.)</FormLabel>
                <UploadTabs device_type="mobile" bannersDict={props.mobilebannersDict} SetBannersDict={props.SetMobilebannersDict} 
                dictionaryOrder={props.mobiledictionaryOrder}
                taBannerOrderDict={props.mobiletaBannerOrderDict} SettaBannerOrderDict={props.SetMobiletaBannerOrderDict}
                therapeuticAreaMap={props.therapeuticAreaMap} SetDictionaryOrder={props.SetMobiledictionaryOrder} fixedMenus={props.fixedMenus} SetBannerMap={props.SetMobilebannersMap} bannerMap={props.mobilebannersMap}
                SeturltextPopup={props.SeturltextPopup} SeturltextPopupBool={props.SeturltextPopupBool}
                countryUrlValidationDict={props.countryUrlValidationDict} SetcountryUrlValidationDict={props.setcountryUrlValidationDict}
                countryBannerValidationDict={props.countryBannerValidationDict} SetcountryBannerValidationDict={props.SetcountryBannerValidationDict}
                 SetcountryBannerValidationBool={props.SetcountryBannerValidationBool}

                // mobilebannersDict={mobilebannersDict} SetMobilebannersDict={SetMobilebannersDict}
                // mobiledictionaryOrder={mobiledictionaryOrder} SetMobiledictionaryOrder={SetMobiledictionaryOrder}
                // mobilebannersMap={mobilebannersMap} SetMobilebannersMap={SetMobilebannersMap}
                // mobiletaBannerOrderDict={mobiletaBannerOrderDict} SetMobiletaBannerOrderDict={SetMobiletaBannerOrderDict}
                />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
                <Grid className='attenSec'>
                    <span className='attenIcnSec'>
                        <ReportProblemIcon className='attenIcn' />
                        Attention
                    </span>
                    <p className='attenTxt'>
                        Check the correct proportions of the banner before uploading
                    </p>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    </>
  )
}

export default Banners