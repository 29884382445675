


import { createSlice } from '@reduxjs/toolkit';


export const playerDimesionSlice = createSlice({
    name: 'playerDimesion',
    initialState: {        
        dimension: {},        
    },
    reducers: {
        setDimension: (state, action) => {
            state.dimension = action;
        },
       
    }
});
export const {  setDimension } = playerDimesionSlice.actions

export default playerDimesionSlice.reducer;