import React from 'react';
import OneLogo from '../../../public/img/1.png';
import TwoLogo from '../../../public/img/2.png';
import ThreeLogo from '../../../public/img/3.png';
import FourLogo from '../../../public/img/4.png';

const Poland = (props) => {
    React.useEffect(() => {
        var head = document.head;
        var link1 = document.createElement("link");
        link1.type = "text/css";
        link1.rel = "stylesheet";
        link1.href = window.location.origin +"/css/landing2.css";
        head.appendChild(link1);
        // document.getElementById('link10').disabled = true
        return () => { 
            head.removeChild(link1);
        }
    }, []);
  return (
    <>
      <div className="lp-container">
        <div className="lp-header">
            <div className="inner"><span className="lilly">Lilly</span><span className="play">|</span>Pro</div>
        </div>

        <div className="lp-mainContent">
            <div className="topNavBtn">
                <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonAA">Zarejestruj się</div></a>
                <a href name="landing-Login" className="cz-login" onClick={props.passportLogin}><div className="buttonBB">Zaloguj się</div></a>
            </div>
            <div className="clearfix"></div>
            <div className="title">
                <h1 className="font2 promotional-video-title" title="CZ LillyPlay">Poznaj nowe LillyPro!</h1>
                <h2 className="font3 promotional-video-title2">Serwis wideo dla lekarzy specjalistów</h2>
            </div>
            <div className="clearfix"></div>
            <div className="centerBtn">
                <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonA">Zarejestruj się</div></a>
            </div>
            <div className="clearfix"></div>
            <div className="biglogo">
                <p className="fon1"><span className="lilly">Lilly</span><span className="play">|</span><span className="colorBlack">Pro</span></p>
                <p className="fon4">merytorycznie dla specjalistów</p>
            </div>
            <div className="clearfix"></div>
            <div className="paddingbox">
                <p className="font3">Już <b>od 13 lat</b> dostarczamy treści edukacyjne w formie wykładów i szkoleń dla lekarzy specjalistów. Wiemy jak to robić, ponieważ pracujemy z najlepszymi ekspertami z Polski i zagranicy.</p>
            </div>
            <table>
                <tbody>
                    <tr>
                        <td className="height20">&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="fontbox2"><img className="img1" src={OneLogo} alt="" /><br />jest z nami<br /><span className="fontbox">2600</span><br/> lekarzy</td>
                        <td className="fontbox2"><img className="img2" src={TwoLogo} alt="" /><br /><span className="fontbox">9/10</span><br />lekarzy oceniło materiały wideo dobrze lub bardzo dobrze1</td>
                    </tr>
                    <tr>
                        <td className="height20">&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td className="fontbox2"><img className="img3" src={ThreeLogo} alt="" /><br /><span className="fontbox">najlepsi eksperci</span><br />polscy i zagraniczni</td>
                        <td className="fontbox2"><img className="img4" src={FourLogo} alt="" /><br /><span className="fontbox"> wideo</span><br />materiały w formie wideo</td>
                    </tr>
                </tbody>
            </table>
            <div className="height30"></div>
            <div className="centerBtn">
                <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonA">Zarejestruj się</div></a>
            </div>
            <div className="height50"></div>
        </div>

        <div className="lp-footer-content">
            <footer id="myFooter">
                <div className="footer-container float_left">
                    <div className="line1">
                        <p>1. Wyniki na podstawie ankiet dołączonych do wszystkich materiałów wideo, opublikowanych przez Lilly Polska w 2020 roku.</p>
                    </div>
                    <div className="line2">
                        <p>Strona jest dedykowana osobom powyżej 16 roku życia. Ta strona internetowa przeznaczona jest wyłącznie dla lekarzy.</p>
                    </div>
                    <div className="footer-copyright">
                        <div className="pullLeft">Copyright © 2022 Eli Lilly and Company.&nbsp;</div>
                        <div className="pullRight">&nbsp;PP-LILLY-PL-0053&nbsp;&nbsp;</div>
                    </div>
                </div>
                <div className="height40"></div>
                <div className="navLinks">
                    <ul>
                        <li><a href="/poland/prawa-autorskie.html" rel="noreferrer" target="_blank" >Prawa autorskie</a></li>
                        <li><a href="https://www.lilly.pl/accessibility-statement" rel="noreferrer" target="_blank" >Oświadczenie o dostępności</a></li>
                        <li><a href="/poland/regulamin-strony.html" rel="noreferrer" target="_blank" >Regulamin strony</a></li>
                        <li><a href="https://www.lillyprivacy.com/PL-pl/hcp" rel="noreferrer" target="_blank">Polityka prywatności i plików cookies</a></li>
                        <li><a href="https://www.lilly.pl/kontakt" rel="noreferrer" target="_blank">Kontakt</a></li>
                        <li><a href data-triggers-cookie-settings="true" onClick={props.cookieSettingBtnHandler}>Ustawienia plików cookie</a></li>
                    </ul>
                </div>
            </footer>
        </div>
    </div>
    </>
  )
}

export default Poland