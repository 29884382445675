import codeCopied1 from "../../../public/img/codeCopied.png";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { forwardRef, useEffect, useMemo, useState } from "react";
import KalturaPlayerWidget from "./KalturaPlayerWidget";

const PreviewEmbedVideo = ({ onClose, kalturaId, contentId, thumbnailURL }, ref) => {
  const [copyClicked, setCopyClicked] = useState(false);

  const embedUrl = useMemo(() => {
    return `<iframe src='${window.location.origin}/embed.html?kalturaId=${kalturaId}&vidId=${contentId}'/>`;
  }, [kalturaId, contentId]);

  const handleCopyCode = (url) => {
    navigator.clipboard.writeText(url);
    setCopyClicked(true);
  };

  return (
    <Grid className="paper modalstyle widthAuto" ref={ref} tabIndex={0}>
      <Grid className="embed-modal-body">
        <Grid container>
          <Grid item lg={6} xs={12}>
            <KalturaPlayerWidget
              kalturaId={kalturaId}
              thumbnailURL={thumbnailURL}
              playerWrapperId="kaltura_video_player_model"
              className="kaltura_video"
            />
          </Grid>
          <Grid item lg={6} xs={12} className="brkWrd">
            <Grid className="embedModal">
              Embed Video
              <button className="closeModal" onClick={onClose}></button>
            </Grid>
            <Grid className="modal-body posRel">
              {embedUrl}
              {!!copyClicked && (
                <Grid className="content_embed posAbs">
                  <img className="iconChild" src={codeCopied1} alt="Edit" />
                  Code Copied
                </Grid>
              )}
            </Grid>

            <Grid className="embedModalFooter">
              <Link
                className={copyClicked ? "codeCopyActive" : "codeCopy"}
                onClick={() => handleCopyCode(embedUrl)}
              >
                Copy Code
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default forwardRef(PreviewEmbedVideo);
