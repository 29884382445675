import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useForm } from 'react-hook-form';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ModalDialog = (props) => {
    const speakerData = props.speakerData;

    const handleClose = (event, reason) => {
        props.setPopupState(false);
        reset();
    };
    const handleBackdropClick = (event) => {
        event.stopPropagation();
        props.setPopupState(false);
        return false;
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
    });
    const handleOk = (data) => {
        const row = {
            id: data.speaker.speaker_id,
            name: data.speaker.name,
            title: data.speaker.name,
            description: data.speaker.curriculum
        }
        props.setPopupState(false);
        reset();
        let newArr = [];
        if (props.lillyplayspeakers) {
            newArr = [...props.lillyplayspeakers]
        }
        newArr.push(row)
        props.SetLillyplaySpeakers(newArr);
    };

    return (
        <Dialog open={props.popupState} scroll='body' maxWidth={false} TransitionComponent={Transition} onClose={handleClose} onBackdropClick={handleBackdropClick} disableEscapeKeyDown className='speaker-modal'>
            <form onSubmit={handleSubmit(handleOk)}>
            <DialogTitle className='modal-title-2'>
                {props.title}
                <button type="button" className='closeModal' onClick={handleClose}></button>
            </DialogTitle>
            <DialogContent className='modal-body'>
                <FormControl component="fieldset" className='formControl'>
                <Controller
                    name="speaker"
                    render={({ field }) => (
                        <Autocomplete
                        {...field}
                        options={speakerData}
                        value={field.value || null}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={(_, value) => field.onChange(value)}
                        renderInput={(params) => <TextField {...params} placeholder="Search by name of speaker" name="speaker" size="small" InputLabelProps={{ shrink: false }} variant="outlined" error={!!errors.speaker} helperText={!!errors.speaker && 'Speaker is required.'}/>}
                    />)}
                    rules={{ required: true }}
                    control={control}
                />
                    
                </FormControl>
            </DialogContent>
            <DialogActions className='modal-footer'>
                <button type="button" className="modalBtn" onClick={handleClose}>Cancel</button>
                <button type="submit" className="modalBtn">OK</button>
            </DialogActions>
            </form>
        </Dialog>
    )
}
export default ModalDialog