import React, { useEffect, useRef, useState } from "react";
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { BlackTriangleReplacer } from "../../shared/BlackTriangle"

const RenderHTML = ({ htmlContent, showMore }) => {
    const [redirectLink, setRedirectLink] = useState('');
    const [warningModal, setWarningModal] = useState(false);
    {/* LILLYPLAY-3571 */}
    const [contentActivated, setContentActivated] = useState(false);
    const containerRef = useRef(null);
    
    useEffect(() => {
        const container = containerRef.current;
        const handleClick = (event) => {
            event.preventDefault();
            if (event.target.tagName === 'A') {
                let value = event.target.className;
                let href = event.target.href;
                if (value.includes('openNewWinDow')) {
                    setRedirectLink(href);
                    setWarningModal(true);
                } else {
                    window.open(href, '_blank')
                }

            }
        }
        if (container) {
            container.addEventListener('click', handleClick);
        }

        return () => {
            if (container) {
                container.removeEventListener('click', handleClick);
            }
        }
    }, [])
    return (
        <>
            <Modal open={warningModal} onClose={() => { setWarningModal(false) }}>
                <Grid className='paper modalstyle redirectWarningModal'>
                    <Grid className="modal-body">
                        <button className='closeModal' onClick={() => setWarningModal(false)}></button>
                        <div className="m20">
                            <div className="text-center">
                                <h3>You are now leaving {window.location.origin}</h3>
                                <p>The link you clicked on will take you to a site maintained by a third party, which is solely responsible for its content. Eli Lilly Company and its affiliates do not control, influence, or endorse this site, and the opinions, claims, or comments expressed on this site should not be attributed to Eli Lilly Company and its affiliates. Eli Lilly Company and its affiliates are not responsible for the  privacy policy of any third-party websites. We encourage you to read the privacy policy of every website you visit.</p>
                                <p>Click "Continue" to leave site or "Return to Lilly Play" to close this window and return to {window.location.origin}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid className="modal-footer">
                        <button className='modalBtn' onClick={() => { setWarningModal(false) }}>Return to Lilly Play</button>
                        <button type="button" onClick={() => { window.open(redirectLink, '_blank'); setWarningModal(false); }} className="modalBtn">Continue</button>
                    </Grid>
                </Grid>
            </Modal>
            {/* LILLYPLAY-3571 */}
            {contentActivated?
                <div ref={containerRef} dangerouslySetInnerHTML={{ __html: BlackTriangleReplacer(showMore ? htmlContent.slice(0, 100) + `${htmlContent.slice(0, 100).split(' ')[10] ? `...` : ``}` : htmlContent) }}></div>
                :
                <div ref={containerRef} dangerouslySetInnerHTML={{ __html: BlackTriangleReplacer(htmlContent) }}></div>
            }

        </>
    )
}

export default RenderHTML;