import axios from 'axios';
import {setSession} from '../../../Session/sessionSlice';
import {AppHeaderService} from '../../../layout/Services/AppHeader';
import {GoogleAnalyticsService} from '../../../GA/GoogleAnalyticsService';
import countryListJson from '../../../CountryPassport/CountryPassport.json';
import config from '../../../../config/config.json';

class SessionService
{
    constructor(jwtToken=null)
    {
        this.passportUrl = process.env.REACT_APP_PASSPORT_TOKEN_URL;
        this.ssoURL = process.env.REACT_APP_SSO_TOKEN_URL;
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this._appHeaderService = jwtToken ? new AppHeaderService() : null;
        this.authHeader = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken && false?jwtToken:localStorage.getItem('jwtToken')
            }
        };
        this._gaService = new GoogleAnalyticsService();
    }
    PushCOuntrySelected(country){
        this._gaService.PushSingleVariables('continueCountry', country);
    }
    PushHCPId(hcpId){
        this._gaService.PushSingleVariables('gtm_customer_id', hcpId);
    }
    PushJanrainId(janrainId){
        this._gaService.PushSingleVariables('gtm_user_id', janrainId);
    }
    async GetPassportSession(decoded_accessToken) {
        let sessionDataAwaiter =  this.GetSessionValue(null, '?okta_id='+decoded_accessToken.id, decoded_accessToken);
        let sessionData = await sessionDataAwaiter;
        return sessionData;
    }
    getCountryDetails = (country_name) => {
        if (country_name !== undefined) {
            if (countryListJson.length) {
                for (var i = 0; i < countryListJson.length; i++) {
                    let cntName = countryListJson[i].country;
                    if (cntName == country_name) {
                        return countryListJson[i];
                    }
                }
            }
        }
        return '';
    }
    GetSessionCookie(name) {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          let _cookies = cookies[i].split('=');
          if (_cookies[0].trim() === name) {
            let signValue = _cookies[1].trim();
            return signValue;
          }
        }
    }
    async getPassportToken (email_Id, authHeader){
        let body = {
           'emailID': email_Id
        }
        return axios.post(this.passportUrl, body,authHeader);
     };
    async RefreshToken(){
        let token = await axios.post(process.env.REFRESH_TOKEN_URL,{},{ 'authorizationtoken' : localStorage.getItem('jwtToken')});
        localStorage.setItem('jwtToken', token.data);
    }
    async UpdateSessionObject(dispatch, newObject){
        let data = await axios.post(this.baseUrl+'/management/api/user/GetSessionHash'
            , {details: newObject}, this.authHeader);
        localStorage.setItem('sessionValue', data.data);
        dispatch(setSession(newObject));

    }
    async GetSessionValueFromHash(navigate, dispatch, setmainTranslation = null,store=null) {
        let cookie  = this.GetSessionCookie('sessioncookie');
        let indicator = localStorage.getItem('HCP');
        let blackListedCountries = ["GB","SE"]
        if(cookie!=null) {
            try {
                let data = await axios.post(this.baseUrl+'/management/api/user/GetDecryptedSessionDetails'
                , {key: 'session', value : cookie}, this.authHeader);
                if(data.data!==false) {
                    let sessionData = await this.DecryptSessionValue();
                    sessionData.token = localStorage.getItem('jwtToken');
                    if (blackListedCountries.includes(sessionData.decodedToken.countryCode)){
                        window.location.replace(window.location.origin + '/landing/'+localStorage.getItem('country').toLowerCase()+'/maintenance.html');
                        localStorage.clear();
                    } else {
                        if(setmainTranslation){
                            let translationData = await this._appHeaderService.getTranslationBtlocale({locale :sessionData.locale});
                            let result = {};
                            for (let i = 0; i < translationData.data.length; i++) {
                                result[translationData.data[i].key] = translationData.data[i].text
                            }
                            setmainTranslation(result);
                        }
                        dispatch(setSession(sessionData));
                    }   
                }
                else
                {
                    let redirect = localStorage.getItem('country').toLowerCase() ? localStorage.getItem('country').toLowerCase() : '';
                    if (localStorage.getItem('HCP') === 'true') {
                        sessionStorage.clear();
                        window.location.href = window.location.origin + '/sso';
                    }
                    else {
                        window.location.href = window.location.origin + '/' + localStorage.getItem('country').toLowerCase();
                    }
                }
            } catch (error){
                if(error.response){
                    let redirect = localStorage.getItem('country').toLowerCase() ? localStorage.getItem('country').toLowerCase() : '';
                    if(error.response.status === 403){
                        localStorage.clear()
                        if ( indicator === 'true') {
                            sessionStorage.clear();
                            window.location.href = window.location.origin + '/sso';
                        } else {
                            window.location.href = window.location.origin + '/' + redirect ;
                        }
                        
                    }
                } 
            }
        }
        else
        {
            let redirect = localStorage.getItem('country') ? localStorage.getItem('country').toLowerCase() : localStorage.getItem('previousRouteBeforeLogin');
            localStorage.clear()
            if (indicator === 'true') {
                sessionStorage.clear();
                window.location.href = window.location.origin + '/sso';
            } else {
                window.location.href = window.location.origin + '/' + redirect;
            }
        }
    }
    async DecryptSessionValue()
    {
        let data = await axios.post(this.baseUrl+'/management/api/user/GetDecryptedSessionDetails', {key: 'session', value : localStorage.getItem('sessionValue')}, this.authHeader);
        return JSON.parse(data.data.payload);
    }
    DeleteAllGACookies(){
        let gaCookiesList = ['_ga', '_ga_', '_gid', '_gat', 'AMP_TOKEN', '_gac_', '__utma', '__utmt', '__utmb',
         '__utmc', '__utmz', '__utmv', '__utmx', '__utmxx', '_gaexp', '_opt_awcid', '_opt_awmid', '_opt_awgid', '_opt_awkid',
        '_opt_utmc', '_opt_expid'];
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          let _cookies = cookies[i].split('=');
          for(let k=0;k<gaCookiesList.length;k++){
            if(_cookies[0].trim().includes(gaCookiesList[k])){
                
                try{
                    document.cookie = _cookies[0]+'='+ _cookies[1].trim()+';max-age='+1+';Path=/'+';Domain=.'+ window.location.host;

                    document.cookie = _cookies[0]+'='+ _cookies[1].trim()+';max-age='+1+';Path=/'+';Domain=.lilly.com';
                    
                }catch(err){
                    
                }
            }           
          }          
        }
        if(localStorage.getItem('ga_code')){
            localStorage.setItem('backup_ga_code', localStorage.getItem('ga_code'));
            localStorage.removeItem('ga_code');
            if(document.getElementById('gaIframe')){
                document.getElementById('gaIframe').src = "https://www.googletagmanager.com/ns.html?id=";
            }
        }
        document.getElementById('okta_id').value = '';
        document.getElementById('cust_id').value = '';
        
    }
    AcceptAllCookies(){
        if(localStorage.getItem('ga_code')){
            if(document.getElementById('gaIframe')){
                if(!document.getElementById('gaIframe').src.includes('GTM')){
                    document.getElementById('gaIframe').src = "https://www.googletagmanager.com/ns.html?id="+localStorage.getItem('ga_code');
                }
                
            }
        }else{
            if(localStorage.getItem('backup_ga_code')){
                localStorage.setItem('ga_code', localStorage.getItem('backup_ga_code'));
                if(document.getElementById('gaIframe')){
                    if(!document.getElementById('gaIframe').src.includes('GTM')){
                        document.getElementById('gaIframe').src = "https://www.googletagmanager.com/ns.html?id="+localStorage.getItem('backup_ga_code');
                    }
                    
                }
            }
        }
    }
    async SetSessionCookie()
    {
        let data = await axios.post(this.baseUrl+'/management/api/user/GetCookieContent', {}, this.authHeader);
        document.cookie = 'sessioncookie='+data.data.sign+';max-age='+data.data.time+';Path=/';
    }
    async CaptureLogs(err, query, decodedTOekn){
        try{
            let loadBalancerURL = config?.loadBalancerURL;
            let LogObject = {
                error : err,_query : query, _decodedToken : decodedTOekn
            }
            let data = await axios.post(this.baseUrl+'/management/api/user/LogDetails', {details : LogObject},
            this.authHeader);
        }catch(err){

        }
    }
    async SetSessionCookieLoadBalancer(){
        let loadBalancerURL = config?.loadBalancerURL;
        let data = await axios.post(loadBalancerURL+'/management/api/user/GetCookieContent', {}, this.authHeader);
        document.cookie = 'sessioncookie='+data.data.sign+';max-age='+data.data.time;
    }
    async MakeLoadBalancerHit(query, decodedTOekn, err){
        try{
            let loadBalancerURL = config?.loadBalancerURL;
            let data = await axios.post(loadBalancerURL+'/management/api/user/GetSessionDetails'+query, {decodedToken : decodedTOekn}, this.authHeader);
            if(data.data.affiliate && data.data.affiliate[0] && data.data.affiliate[0].country_name){
                localStorage.setItem("country",data.data.affiliate[0].country_name)
                let cName = data.data.affiliate[0].country_name
                let countryData = this.getCountryDetails(cName);
                // Catch for multi-language countries
                if (data.data?.user?.language){
                    localStorage.setItem('lang', data.data.user.language)
                } else if (localStorage.getItem('passportLanguage')){
                    localStorage.setItem('lang', localStorage.getItem('passportLanguage'));
                } else {
                    localStorage.setItem('lang', countryData.locale);
                }
            }
            localStorage.setItem('sessionValue', data.data.sessionHash);
            await this.SetSessionCookieLoadBalancer();
            await this.CaptureLogs(err);
            return data.data;
        }catch(err){
            setTimeout(()=> {
                let navigatePath = localStorage.getItem('CalculateNavigatingUrl');
                window.location.href= window.location.origin +  navigatePath;
            }, 3000)
        }
    }
    async getSSOToken(email_Id, authHeader){
        let body = {
           'emailID': email_Id
        }
        return axios.post(this.ssoURL, body,authHeader);
     };
    SetHCPIdFields(oktaId, hcpId){
        try{
            let oktaIdField = document.getElementById('okta_id');
            let hcpIdField = document.getElementById('cust_id');
            let marketingConsent = this.GetSessionCookie('consent_adv_and_mark');
            let gaCOnsent = this.GetSessionCookie('consent_google_analytics')
            if(hcpIdField){
                if(localStorage.getItem('country')){
                    if(this.CookieBannerCountries(localStorage.getItem('country'))){
                        if(marketingConsent === 'true'&& gaCOnsent === 'true'){
                            document.getElementById('cust_id').value = hcpId;
                        }
                    }else{
                        document.getElementById('cust_id').value = hcpId;
                    }
                }               
            }
            if(oktaIdField){
                if(localStorage.getItem('country')){
                    if(this.CookieBannerCountries(localStorage.getItem('country'))){
                        if(marketingConsent === 'true'&& gaCOnsent === 'true'){
                            document.getElementById('okta_id').value = oktaId;
                        }
                    }
                    else{
                        document.getElementById('okta_id').value = hcpId;
                    }
                }
                
                
            }
        }catch(err){

        }
    }
    CookieBannerCountries(countryName){
        //country
        let countryArray = [ 'Austria', 'Belgium','France','Finland', 'Czech',  'Germany', 'Netherlands','Italy','Poland','Portugal','Romania','Sweden','Slovakia','Spain','Switzerland','UK','Brazil'];
        let countryLocaleArray = ['de-CH', 'sv-SE'];
        return countryArray.includes(countryName);
        
    }
    async GetSessionValue(store, query, decodedTOekn)
    {
        try{       
            let data = await axios.post(this.baseUrl+'/management/api/user/GetSessionDetails'+query, {decodedToken : decodedTOekn}, this.authHeader);
            if(data.data.affiliate && data.data.affiliate[0] && data.data.affiliate[0].country_name){
                localStorage.setItem("country",data.data.affiliate[0].country_name)
                let cName = data.data.affiliate[0].country_name
                let countryData = this.getCountryDetails(cName);
                // Catch for multi-language countries

                if (data.data?.user?.language){
                    localStorage.setItem('lang', data.data.user.language)
                } else if (localStorage.getItem('passportLanguage')){
                    localStorage.setItem('lang', localStorage.getItem('passportLanguage'));
                } else {
                    localStorage.setItem('lang', countryData.locale);
                }
            }
            localStorage.setItem('sessionValue', data.data.sessionHash);
            await this.SetSessionCookie();
             this.CaptureLogs(null,  query, decodedTOekn);
            return data.data;
        }catch(err){
            this.CaptureLogs(err,  query, decodedTOekn);
            // setTimeout(()=> {
            //     let navigatePath = localStorage.getItem('CalculateNavigatingUrl');
            //     window.location.href= window.location.origin +  navigatePath;
            // }, 3000);
            // return await this.MakeLoadBalancerHit(query, decodedTOekn, err)
        }
    }
}

export {SessionService}
