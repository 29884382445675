import React from 'react';
import LillyLogo from '../../../public/img/event_LillyLogo.png';
import LillyPlayLogo from '../../../public/img/LogoLillyPlayWhite.png';
import Logo1 from '../../../public/img/es1.png';
import Logo2 from '../../../public/img/es2.png';
import Logo3 from '../../../public/img/es3.png';
import Logo4 from '../../../public/img/es4.png';
import Logo5 from '../../../public/img/es5.png';
import Logo6 from '../../../public/img/es6.png';

const Italy = (props) => {
    React.useEffect(() => {
        var head = document.head;
        var link1 = document.createElement("link");
        link1.type = "text/css";
        link1.rel = "stylesheet";
        link1.href = "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";
        head.appendChild(link1);

        var link2 = document.createElement("link");
        link2.type = "text/css";
        link2.rel = "stylesheet";
        link2.href = window.location.origin +"/css/landing5.css";
        head.appendChild(link2);
        // document.getElementById('link10').disabled = true
        return () => { 
            head.removeChild(link1); 
            head.removeChild(link2); 
        }
    }, []);
  return (
    <>
    <div class="header container-fluid">
        <div class="row">
        <div class="logo-pagina col-6 text-left"><img src={LillyPlayLogo} alt="" /></div>
        <div class="logo col-6 text-right"><img src={LillyLogo} alt="" /></div>
        </div>
    </div>
    <div class="testata container-fluid">
        <div class="row">
        <div class="col-md-10 offset-md-1 text-center text-white">
            <div class="title"><h1>Scopri Lilly Play,<br/>
                la piattaforma video dedicata ai medici.</h1>
            </div>
            <div class="button button-white"><a href class="janrain_sign-in it-login" name="landing-Login" onClick={props.passportLogin} searchurl="">ACCEDI ADESSO</a></div>
        </div>
        </div>
    </div>

    <div class="container">
        <div class="row text-center">
            <div class="col-md-10 offset-md-1">
                <div class="description">
                    <b>Scopri <span class="highlight">Lilly Play</span>, la piattaforma di video on-demand dedicata esclusivamente alla classe medica dove è possibile trovare tantissime risorse video per approfondire temi legati a Diabete, Reumatologia, Oncologia, Dermatologia e Osteoporosi. </b><br/>
                  Accedi e aggiornarti sulle ultime novità in campo scientifico, grazie ai video con interviste e approfondimenti di opinion leader sia nazionali che internazionali.
                </div>
                <div class="button button-red"><a href class="janrain_sign-in it-login" name="landing-Login" onClick={props.passportLogin} searchurl="">ACCEDI ADESSO</a></div>
            </div>
        </div>
        <div class="row text-center box">
            <div class="col-md-10 offset-md-1 subtitle">Ecco cosa puoi trovare su Lilly Play</div>
            <div class="col-md-4 borderbox">
                <img src={Logo1} alt="" />
                <div class="title-box"><h3>Reumatologia</h3></div>
                <div class="desc-box"><h4><b>PRO’s, Efficacia, Sicurezza</b> e molti altri temi trattati da opinion leader sia nazionali che internazionali in AR e PsA. Scopri anche i video-clip con le risposte degli esperti ai quesiti più comuni sul trattamento dell’AR</h4>
                </div>
                <div class="link-box"><a href class="janrain_sign-in it-login" onClick={props.passportLogin} searchurl="/search?category_id=15d5a940-9c18-43eb-90a9-f46fe36d09f5&amp;kw=&amp;tab=video&amp;utm_source=scopri_lillyplay&amp;utm_medium=landingpage&amp;utm_campaign=lillyplay">Scopri di più</a></div>
            </div>
            <div class="col-md-4 borderbox">
                <img src={Logo2} alt="" />
                <div class="title-box"><h3>Dermatologia</h3></div>
                <div class="desc-box"><h4>Accedi velocemente alle risorse video create in collaborazione con opinion leader nazionali e internazionali su temi legati alla <b>psoriasi</b> come efficacia, sicurezza e comorbidità</h4>
                </div>
                <div class="link-box"><a href class="janrain_sign-in it-login" onClick={props.passportLogin} searchurl="/search?category_id=93b12142-891a-4192-b549-77e74e99b6a1&amp;kw=&amp;tab=video&amp;utm_source=scopri_lillyplay&amp;utm_medium=landingpage&amp;utm_campaign=lillyplay">Scopri di più</a></div>
            </div>
            <div class="col-md-4 borderbox">
                <img src={Logo3} alt="" />
                <div class="title-box"><h3>Diabetologia</h3></div>
                <div class="desc-box"><h4>Efficacia, Sicurezza, Device, Insuline, <b>GLP-1 RAs</b>.. e ancora tanti altri approfondimenti con opinion leader sia <b>nazionali che internazionali</b> in Diabetologia.  </h4>
                </div>
                <div class="link-box"><a href class="janrain_sign-in it-login" onClick={props.passportLogin} searchurl="/search?category_id=d3d448cf-b539-4f92-a054-84500cc3f244&amp;kw=&amp;tab=video&amp;utm_source=scopri_lillyplay&amp;utm_medium=landingpage&amp;utm_campaign=lillyplay">Scopri di più</a></div>
            </div>
        </div>
        <div class="row text-center box">
            <div class="col-md-4 borderbox">
                <img src={Logo4} alt="" />
                <div class="title-box"><h3>Oncologia</h3></div>
                <div class="desc-box"><h4><b>Efficacia, sicurezza, farmaci biologici, approccio multidisciplinare</b> e molti altri temi trattati da opinion leader nazionali e internazionali in <b>oncologia</b>. Scopri subito tutti i contenuti <b>video</b> sviluppati da LillyOncologia per la classe medica!</h4>
                </div>
                <div class="link-box"><a href class="janrain_sign-in it-login" onClick={props.passportLogin} searchurl="/search?category_id=4a0c0f37-879c-48e2-8008-8997b4de5c8a&amp;kw=&amp;tab=video&amp;utm_source=scopri_lillyplay&amp;utm_medium=landingpage&amp;utm_campaign=lillyplay">Scopri di più</a></div>
            </div>
            <div class="col-md-4 borderbox">
                <img src={Logo5} alt="" />
                <div class="title-box"><h3>Osteoporosi</h3></div>
                <div class="desc-box"><h4>Contiene risorse video su prodotti e servizi nel campo dell’<b>osteoporosi</b> rivolti alla
                    classe medica.</h4>
                </div>
                <div class="link-box"><a href class="janrain_sign-in it-login" onClick={props.passportLogin} searchurl="/search?category_id=0888d7a6-2e96-4f79-9a0a-264fe0992155&amp;kw=&amp;tab=video&amp;utm_source=scopri_lillyplay&amp;utm_medium=landingpage&amp;utm_campaign=lillyplay">Scopri di più</a></div>
            </div>
            <div class="col-md-4">
                <img src={Logo6} alt="" />
                <div class="title-box"><h3>Lilly</h3></div>
                <div class="desc-box"><h4>Video e webcast <b>istituzionali</b> sui servizi offerti da Lilly Italia ai professionisti.</h4>
                </div>
                <div class="link-box"><a href class="janrain_sign-in it-login" onClick={props.passportLogin} searchurl="">Scopri di più</a></div>
            </div>
        </div>
    </div>

    <div class="banner_footer container-fluid">
        <div class="row text-center">
        <div class="col-md-6 offset-md-3 text-white">
            <div class="title_footer">
                Sei già iscritto ai portali Lilly?
            </div>
            <div class="desc_footer">
                Accedi con la stesse credenziali per guardare i video dedicati ai medici, resta aggiornato
            </div>
            <div class="button button-white"><a href class="janrain_sign-in it-login" onClick={props.passportLogin} name="landing-Login" searchurl="">ACCEDI ADESSO</a></div>
        </div>
        </div>
    </div>

    <div class="footer container-fluid">
        <div class="row">
        <div class="col-sm-6 text-left">
          <a href="http://minisite.lillysalute.it/lilly/copyright.html" rel="noreferrer" target="_blank">Copyright ©2022 Eli Lilly Italia S.p.A.-Società a socio unico Gruppo Eli Lilly and Company - Sede Legale: Via Gramsci 731/733 Sesto Fiorentino (FI) - PEC: direzione@pec.lilly.it Capitale Sociale Euro 69.921.731,00 i.v. – Reg. Imp. Trib. Firenze N. 14348 C.C.I.A.A. Firenze – Cod. Fisc. e Part. IVA 00426150488</a>
        </div>
        <div class="col-sm-6 text-right">
            <a href="https://www.lillyprivacy.com/it-it/hcp">Informativa sulla privacy</a> | 
            <a href="http://minisite.lillysalute.it/lilly/termini-d-uso.html" rel="noreferrer" target="_blank">Termini d'uso</a> | 
            <a href="http://minisite.lillysalute.it/lilly/accessibilita.html" rel="noreferrer" target="_blank">accessibilità</a> | 
            <a href data-triggers-cookie-settings="true" onClick={props.cookieSettingBtnHandler}>Impostazioni dei cookie</a> | PP-MG-IT-0234
        </div>
        </div>
    </div>
    </>
  )
}

export default Italy