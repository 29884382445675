import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ModalDialog from './ModalDialog';
import { EventService } from '../Services/event-service';

const ContentSpeaker = (props) => {

  React.useEffect(() => {
  }, [props.speakers]);

  const [popupState, setPopupState] = React.useState(false);
  let eventServices = new EventService();
  const handleAddSpeaker = () => {
    setPopupState(true);
  }
  const handleRemoveSpeaker = (index, add24_index) => {
    if (typeof add24_index != 'undefined') {
      document.getElementById('add24_' + add24_index).style.visibility = 'unset';
    }
    var array = [...props.speakers];
    if (index !== -1) {
      array.splice(index, 1);
      props.setLillyplaySpeakers(array);
    }
  }

  const [speakerData, setSpeakerData] = React.useState([]);
  React.useEffect(() => {
    eventServices.GetSpeakersByQuery().then((data) => {
      let jsondata = data.data;
      setSpeakerData(jsondata);
    }).catch((err) => {});
  }, []);
  return (

    <>
      <ModalDialog title='Select a Speaker' addLillyPlaySpeakers={props.addLillyPlaySpeakers} speakerData={speakerData} popupState={popupState} setPopupState={setPopupState} SetLillyplaySpeakers={props.setLillyplaySpeakers} lillyplayspeakers={props.speakers} />
      <Grid container className="content">
        <Grid item lg={12} xs={12}>
          <FormControl component="fieldset" >
            <FormLabel component="legend">Lilly Play Speaker</FormLabel>
            <table className="field_tbl">
              <thead>
                <tr>
                  <th width="80%">Speaker Name</th>
                  <th width="20%">Action</th>
                </tr>
              </thead>
              <tbody>
                {props.speakers && props.speakers.length > 0 ?
                  props.speakers.map((row, index) => (
                    <tr>
                      <td>{row.name}</td>
                      <td>
                        <Button className='removeIconBtn' onClick={() => handleRemoveSpeaker(index, row.index)}>
                          <img src={require('../../../public/img/CloseGrey@3x.png')} alt="Remove Speaker" />
                        </Button>
                      </td>
                    </tr>
                  )) : ""}
                <tr>
                  <td>
                    <Button className='addIconBtn' onClick={() => handleAddSpeaker()}>
                      <img src={require('../../../public/img/Add@3x.png')} alt="Add Speaker" />
                      Add Speaker
                    </Button>
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}
export default ContentSpeaker