import React, { useState, useEffect } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import '../content-management/calender.css';

function Day({ day, onClick, }) {
    let className = () => {
        if (day.isDisabled) {
            return 'disabled-day'
        } else if (day.isRange) {
            return 'range-day'
        } else if (day.isStart) {
            return 'selected-day start'
        } else if (day.isEnd) {
            return 'selected-day end'
        } else if (day.isCurrentDay) {
            return 'active-day'
        } else { return 'day' }
    }
    return (
        <li
            onClick={onClick}
            className={day.value === 'padding' ? '' : className()}>
            {day.value === 'padding' ? '' : day.value}
        </li>)
}

function CalendarHeader({ dateDisplay, onBack, onNext, dateLabel }) {
    return (
        <header>
            <div className='calander-name'>
                <p>{dateLabel}</p>
            </div>
            <div className="calendar-header">
                <span id="calendar-prev" onClick={onBack}><ArrowBackIosIcon /></span>
                <p>{dateDisplay}</p>
                <span id="calendar-next" onClick={onNext}><ArrowForwardIosIcon /></span>
            </div>
        </header>
    )
}
function isLeapYear(year) {
    if (
        year % 100 === 0 ? year % 400 === 0 : year % 4 === 0
    )
        return true
    else
        return false
}
export default function CustomCalendar({ onDateSelect, startDate, endDate, preselected, dateLabel, disabled }) {
    const [nav, setNav] = useState(preselected ? preselected : 0);
    const [days, setDays] = useState([]);
    const [dateDisplay, setDateDisplay] = useState('');
    const selectedDate = date => {
        // convert the current date
        let currentMonth = date.split('/')[0] - 1;
        let currentDate = date.split('/')[1];
        let currentYear = date.split('/')[2];
        let cdate = new Date(currentYear, currentMonth, currentDate);
        if (startDate !== '' && endDate !== '') {
            // convert the start date and end date
            let startingMonth = startDate.split('/')[0] - 1;
            let startingDate = startDate.split('/')[1];
            let startingYear = startDate.split('/')[2];
            let sdate = new Date(startingYear, startingMonth, startingDate);
            let endingMonth = endDate.split('/')[0] - 1;
            let endingDate = endDate.split('/')[1];
            let endingYear = endDate.split('/')[2];
            let edate = new Date(endingYear, endingMonth, endingDate);
            if (
                cdate > sdate && cdate < edate
            ) {
                return true;
            }
            return false
        }
        return false
    }

    useEffect(() => {
        let dt = new Date();
        const weekdays = [
            'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
        ]
        if (nav !== 0) {
            if (isLeapYear(dt.getFullYear()) && dt.getMonth() === 0 && dt.getDate() === 31) {
                dt = new Date(`${dt.getFullYear()}-02-01`)
                dt.setMonth(new Date().getMonth() + nav);
            } else {
                dt.setMonth(new Date().getMonth() + nav);
            }
        }
        const day = dt.getDate();
        const month = dt.getMonth();
        const year = dt.getFullYear();
        const firsrDayOfMonth = new Date(year, month, 1);
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const dateString = firsrDayOfMonth.toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        })
        setDateDisplay(`${dt.toLocaleDateString('en-us', { month: 'long' })} ${year}`);
        const paddingDays = weekdays.indexOf(dateString.split(', ')[0])
        const daysArr = [];
        for (let i = 1; i <= paddingDays + daysInMonth; i++) {
            const dayString = `${month + 1}/${i - paddingDays}/${year}`;

            if (i > paddingDays) {
                daysArr.push({
                    value: i - paddingDays,
                    isCurrentDay: i - paddingDays === day && nav === 0,
                    isStart: dayString === startDate,
                    isEnd: dayString === endDate,
                    date: dayString,
                    isRange: selectedDate(dayString),
                    isDisabled: disabled,
                });
            } else {
                daysArr.push({
                    value: 'padding',
                    isCurrentDay: false,
                    isStart: dayString === startDate,
                    isEnd: dayString === endDate,
                    date: dayString,
                    isRange: selectedDate(dayString),
                    isDisabled: disabled,
                });
            }
        }
        setDays(daysArr);
    }, [nav, startDate, endDate])
    return (
        <div className="calendar-container">
            <CalendarHeader
                dateDisplay={dateDisplay}
                onNext={() => setNav(nav + 1)}
                onBack={() => setNav(nav - 1)}
                dateLabel={dateLabel}
            />
            <div className="calendar-body">
                <ul className="calendar-weekdays">
                    <li>Mo</li>
                    <li>Tu</li>
                    <li>We</li>
                    <li>Th</li>
                    <li>Fr</li>
                    <li>Sat</li>
                    <li>Su</li>
                </ul>
                <ul class="calendar-dates">
                    {days && days.length > 0 ? days.map((d, index) => (
                        <Day
                            key={index}
                            day={d}
                            onClick={() => {
                                if (d.value !== 'padding') {
                                    onDateSelect(d.date)
                                }
                            }} />
                    )) : ''}
                </ul>
            </div>
        </div>
    )
}