import axios from 'axios';
import {GoogleAnalyticsService} from '../../../GA/GoogleAnalyticsService';
class SearchService {

    constructor(jwtToken) {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken ? jwtToken : localStorage.getItem('jwtToken')
            }
        };
        this._gaServce = new GoogleAnalyticsService();
    }

    async getContentList(searchTerm, orderValue, filterId,selectedCountry,selectedLibraryid,special,audiance,countryName, localeName,loginroute, model) {
        let list = await axios.post(process.env.REACT_APP_BASE_URL + `/home/api/search/SearchData/${orderValue}/0/100000/${searchTerm}?category_id=` + filterId +`&country=`+selectedCountry+`&library=`+selectedLibraryid+`&speciality=`+encodeURIComponent(special)+`&Audiance=`+encodeURIComponent(audiance)+`&countryName=`+countryName+`&localeName=`+localeName+ '&loginRoute='+loginroute, model, this.authHeaders);
        if(Array.isArray(list.data)){
            this._gaServce.PushSingleVariables('searchResult', list.data.length);//searchTermVariable
            this._gaServce.PushSingleVariables('searchTermVariable', searchTerm);
            let model = {
                event: 'searchCompletedCustomEvent',
                searchResult:list.data.length,// 'Content with id ' + videoId + ' and title ' + videoId + ' was selected',
                searchTermVariable : searchTerm
        
            };
            this._gaServce.PushObjectInDatalayer(model);
        }
         
        return list;
        };
    async getContentListCount(startCount, endCount, searchTerm, orderValue, filterId,selectedCountry,selectedLibraryid,special,audiance, countryName, localeName,loginroute, model) {
        
        return axios.post(process.env.REACT_APP_BASE_URL + `/home/api/search/SearchData/${orderValue}/${startCount}/${endCount}/${searchTerm}?category_id=` + filterId +`&country=`+selectedCountry+`&library=`+selectedLibraryid+`&speciality=`+encodeURIComponent(special)+`&Audiance=`+encodeURIComponent(audiance)+`&countryName=`+countryName+`&localeName=`+localeName+ '&loginRoute='+loginroute, model, this.authHeaders);
    };
    async getSearchSuggestions(filterId,selectedCountry,selectedLibraryid,special,audiance,countryName, localeName, loginroute, model) {
        return axios.post(process.env.REACT_APP_BASE_URL + `/home/api/search/SearchData/recent/0/1000?category_id=` + filterId +`&country=`+selectedCountry+`&library=`+selectedLibraryid+`&speciality=`+encodeURIComponent(special)+`&Audiance=`+encodeURIComponent(audiance)+`&countryName=`+countryName+`&localeName=`+localeName+ '&loginRoute='+loginroute, model, this.authHeaders);
    };

    async GetSpeakerByQuery(){
        return await axios.post(process.env.REACT_APP_BASE_URL +'/management/api/speaker/GetSpeakersByQueryPaginationCout', {}, this.authHeaders);
    };

    async GetSpeakerByQuery2(params){
        // let qs = "";
        let qs = '?name='+ params;

        return await axios.post(process.env.REACT_APP_BASE_URL +'/management/api/speaker/GetSpeakersByQuery'+ qs, {}, this.authHeaders);
    };

    async  GetAllTherapeuticAreas() {
        let data = await axios.post(process.env.REACT_APP_BASE_URL + '/management/api/category/GetTherapeuticAreaByQuery?del=false', {}, this.authHeaders);
        return data;
    }

}

export { SearchService }