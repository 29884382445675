
import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../home.css';
import Paper from '@material-ui/core/Paper';
import videoThumb from '../../../public/img/video.png';
import podcast from '../../../public/img/podcast.svg';
import newTagIcon from '../../../public/img/new-img/NEW_REDIndicate@2x.png';
import { useNavigate } from "react-router-dom";
import { BlackTriangleShowMore } from '../../shared/BlackTriangle';
import { HomeService } from '../Service/homeService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@mui/material/Tooltip';
import meetSpeakerIcon from '../../../public/img/meetSpeakerIcon.svg';
import PIInfo from '../../shared/PIInfo';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const Podcast = (props) => {
    const navigate = useNavigate();
    const [thumbnailImage, SetThumbnailImage] = React.useState('');
    const [isNewLabel, setIsNewLabel] = React.useState(true);
    const [speakerNames, setSpeakerNames] = React.useState([]);
    const [popupState, setPopupState] = React.useState(false);
    const [SpeakerArray, setSpeakerArray] = React.useState([]);
    const [imagearray, setimagearray] = React.useState([]);
    const titleElementRef = React.useRef();
    const speakerNameRef = React.useRef();
    const isTitleTruncated = titleElementRef.current && titleElementRef.current.scrollHeight > titleElementRef.current.clientHeight;
    const isSpeakerNameTruncated = speakerNameRef.current && speakerNameRef.current.scrollWidth > speakerNameRef.current.clientWidth;
    let _service = new HomeService();
    /**Search Filter Sidebar */
    const menuSearch = (value) => {
        navigate('/speaker-detail/' + value);
    }
    React.useEffect(() => {
        let watchingContent = [...props.newLabelWatchingContent];
        let watchedContent = [...props.newLabelWatchedContent];
        if (watchingContent) {
            for (let i = 0; i < watchingContent.length; i++) {

                if (watchingContent[i].content_id === props.podcast.content_id) {
                    setIsNewLabel(false);
                }
            }
        }
        if (watchedContent) {
            for (let i = 0; i < watchedContent.length; i++) {

                if (watchedContent[i].content_id === props.podcast.content_id) {
                    setIsNewLabel(false);
                }
            }
        }
    }, [props.newLabelWatchingContent, props.newLabelWatchedContent])

    React.useEffect(() => {
        let service = new HomeService();
        let newArr = {};

        if (props.podcast.user_thumbnail) {
            if (props.podcast.user_thumbnail.length > 0) {
                service.GetImageFromURL(props.podcast.user_thumbnail).then((data) => {
                    if (data) {
                        SetThumbnailImage(data);
                        newArr['image' + props.podcast.content_id] = data
                        newArr = JSON.parse(JSON.stringify(newArr))
                        setimagearray(...imagearray, newArr);
                    }

                })
            } else {
                if (props.podcast.thumbnail) {
                    SetThumbnailImage(props.podcast.thumbnail);
                }
            }
        } else {
            if (props.podcast.thumbnail) {
                SetThumbnailImage(props.podcast.thumbnail);
            }
        }
        let currDate = new Date();
        let contentDate = new Date(props.podcast.created_at);
        var date = currDate.getTime() - contentDate.getTime();
        var isWeekAgo = date / (1000 * 3600 * 24);
        if (isWeekAgo > 7) {
            setIsNewLabel(false)
        }
    }, []);

    React.useEffect(() => {

        let speaker = props.podcast.speakers_id
        let list = [];
        for (let i = 0; i < speaker.length; i++) {
            if (speaker[i]) {
                list.push(speaker[i])
            }
        }
        setSpeakerArray(list)
    }, [props.podcast.speakers_id]);

    return (
        <>
            <Dialog open={popupState} scroll='body' maxWidth='md' TransitionComponent={Transition} onClose={() => setPopupState(false)} disableEscapeKeyDown className='speakerModal'>
                <DialogTitle className='modal-top'>
                    <Grid className="modal-title border0 alignCenter">

                        <img src={meetSpeakerIcon} alt="speakerIcon" />

                        <button className='closeModal' onClick={() => setPopupState(false)}></button>
                    </Grid>

                </DialogTitle>
                <DialogContent className='modal-middle'>
                    <Grid>
                        <Paper className='speakerTitle'> {props.translations['Speakers'] ? props.translations['Speakers'] : 'Speakers:'}</Paper>

                        {speakerNames.length > 0 ? speakerNames.map((speaker, index) => {
                            return (<Paper className='upNameSpeaker' key={index} onClick={() => menuSearch(speaker.toString())}>{speaker}</Paper>
                            );
                        }) : null}

                    </Grid>
                </DialogContent>
                <DialogActions className='modal-bottom text-center border0'>
                    <button type="button" className="modalBtn" onClick={() => setPopupState(false)}>{props.translations['Close'] ? props.translations['Close'] : 'Close'}</button>
                </DialogActions>
            </Dialog>
            <Grid id='podcastElementId' className={props.slides === 3 ? 'sOuterlg' : 'sOuter'}>
                <Grid className='sCover' onClick={() => {
                    _service.VideoSelectedGAEvent(props.podcast.content_id, props.podcast.title);
                    _service.NavigatedToVideoEvent(props.podcast.content_id, 'Navigated to video from homepage', props.filter);
                    navigate(`/content-watch/${props.podcast.kaltura_id}/${props.podcast.content_id}`)}}>
                    {isNewLabel ? <img src={newTagIcon} className='newimg' alt='img' /> : null}
                    <Grid className='sCoverInnerlg'>
                        <img src={podcast} className={props.slides === 3 ? 'svgIconlg' : 'svgIcon'} alt='icon' />

                    </Grid>
                    {<img src={props.podcast.user_thumbnail ? imagearray['image'+props.podcast.content_id] : props.podcast.thumbnail?props.podcast.thumbnail : videoThumb} className='most-recent-image simg-lg' alt='img' />}
                    {parseInt(props.podcast.duration) > 0 && <span className="videTime">{(props.podcast.duration) / 3600 > 1 ? (new Date(props.podcast.duration * 1000)).toISOString().slice(11, 19) :
                        (new Date(props.podcast.duration * 1000)).toISOString().slice(14, 19)}</span>}
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <Paper className='podcastTag' onClick={() => {
                            _service.VideoSelectedGAEvent(props.podcast.content_id, props.podcast.title);
                            _service.NavigatedToVideoEvent(props.podcast.content_id, 'Navigated to video from homepage', props.filter);
                            navigate(`/content-watch/${props.podcast.kaltura_id}/${props.podcast.content_id}`)}}>{props.translations['Podcast'] ? props.translations['Podcast'] : 'Podcast'}</Paper>
                    </Grid>
                </Grid>
                <Tooltip title={isTitleTruncated ? props.podcast.title : ''} placement='bottom'>
                    <Paper ref={titleElementRef} id={props.podcast.content_id} className='cont' onClick={() => {
                        _service.VideoSelectedGAEvent(props.podcast.content_id, props.podcast.title);
                        _service.NavigatedToVideoEvent(props.podcast.content_id, 'Navigated to video from homepage', props.filter);
                        navigate(`/content-watch/${props.podcast.kaltura_id}/${props.podcast.content_id}`)}}><BlackTriangleShowMore title={props.podcast != null ? props.podcast.title : 'NA'} /></Paper>
                </Tooltip>
                {props.slides === 3 ?
                    <Paper className='contDate'>
                        {/* {props.podcast!=null?(new Date(props.podcast.created_at)).toLocaleDateString('en-US',{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}): ''} */}
                    </Paper> : null}
                <Paper className='upNameOuter'>
                    {SpeakerArray != null && SpeakerArray.length > 0 ?
                        SpeakerArray.length > 1 ? <Grid className='upName' onClick={() => { setSpeakerNames(SpeakerArray); setPopupState(true); }}>{props.translations['Meet the Speakers'] ? props.translations['Meet the Speakers'] : 'Meet the Speakers'}</Grid> : <Tooltip title={isSpeakerNameTruncated ? SpeakerArray[0] : ''} placement='bottom'><Paper ref={speakerNameRef} className='upName' onClick={() => menuSearch(SpeakerArray[0])}>{SpeakerArray[0]}</Paper></Tooltip>
                        : null}

                </Paper>

                <Paper className="cont-piinfo-home">
                    {props.podcast?.category_1_id?.length ? <PIInfo category1Ids={props.podcast.category_1_id} /> : ""}
                </Paper>

            </Grid>
        </>
    );
}
export default Podcast