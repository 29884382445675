import React from 'react';
import {useLocation} from 'react-router-dom';
import Australia from "./Components/Australia";
import Brazil from "./Components/Brazil";
import Czech from "./Components/Czech";
import Germany from "./Components/Germany";
import Spain from "./Components/Spain";
import UK from "./Components/UK";
import Italy from "./Components/Italy";
import NewZealand from "./Components/NewZealand";
import Poland from "./Components/Poland";
import Romania from "./Components/Romania";
import Sweden from "./Components/Sweden";
import Slovakia from "./Components/Slovakia";
import Taiwan from "./Components/Taiwan";
import Mea from "./Components/Mea";
import Lacam from "./Components/Lacam";

const Landing = (props) => {
    const location = useLocation();
    const [country, SetCountry] = React.useState("");
    const [countryLocal, SetCountryLocal] = React.useState("");
    const [widgetID, SetWidgetID] = React.useState("");
    const [domain, SetDomain] = React.useState("");

    React.useEffect(()=> {
        let uri = window.location.href;
        let param = uri.split('/')[uri.split('/').length - 1];
        SetCountry(param);
        let country_code = "";
        let wid = "";
        let domain_url = "";
        switch(param) {
            case 'spain':
                country_code = "es-ES";
                wid = 42;
                domain_url = "lillyplay.es";
                break;
            case 'italy':
                country_code = "it-IT";
                wid = 44;
                domain_url = "play.lilly.it";
                break;
            case 'poland':
                country_code = "pl-PL";
                wid = 45;
                domain_url = "lillypro.pl";
                break;
            case 'germany':
                country_code = "de-DE";
                wid = 55;
                domain_url = "lillyplay.de";
                break;
            case 'brazil':
                country_code = "pt-BR";
                wid = 56;
                domain_url = "lillyplay.com.br";
                break;
            case 'uk':
                country_code = "en-GB";
                wid = 57;
                domain_url = "lillyplay.co.uk";
                break;
            case 'czech':
                country_code = "cs-CZ";
                wid = 58;
                domain_url = "lillyplay.cz";
                break;
            case 'romania':
                country_code = "ro-RO";
                wid = 61;
                domain_url = "lillyplay.ro";
                break;
            case 'slovakia':
                country_code = "sk-SK";
                wid = 62;
                domain_url = "lillyplay.sk";
                break;
            case 'sweden':
                country_code = "sv-SE";
                wid = 63;
                domain_url = "lillyplay.se";
                break;
            case 'australia':
                country_code = "en-AU";
                break;
            case 'newzealand':
                country_code = "en-NZ";
                break;
            case 'taiwan':
                country_code = "zh-TW";
                break;
            case 'mea':
                country_code = "en-KW";
                break;
            case 'lacam':
                country_code = "es-MX";
                break;
            default:
                country_code = "";
                break;
        }
        SetCountryLocal(country_code);
        SetWidgetID(wid);
        SetDomain(domain_url);
    },[]);
    
    
    const cookieSettingBtnHandler = () => {
        window.scrollTo(0,0)
        window.Syrenis_OpenCookieOptionsModal();
    }
    const passportLogin = () => {
        let countryCode = "";
        if(location.state === null) {
            countryCode = countryLocal;
        } else {
            countryCode = location.state.countryCode;
        }
        window.open(`${process.env.REACT_APP_PASSPORT_BASE_URL}${countryCode}/signin/?url=${window.location.origin}/`, "_self");
    }
    const passportRegister = () => {
        let countryCode = "";
        if(location.state === null) {
            countryCode = countryLocal;
        } else {
            countryCode = location.state.countryCode;
        }
        window.open(`${process.env.REACT_APP_PASSPORT_BASE_URL}${countryCode}/register/?url=${window.location.origin}/`, "_self");
    }
    const renderSwitch = (country) => {
        switch(country) {
            case 'australia':
                return <Australia passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'brazil':
                return <Brazil passportLogin={passportLogin} passportRegister={passportRegister} cookieSettingBtnHandler={cookieSettingBtnHandler}/>;
            case 'czech':
                return <Czech passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'germany':
                return <Germany passportLogin={passportLogin} passportRegister={passportRegister} cookieSettingBtnHandler={cookieSettingBtnHandler}/>;
            case 'spain':
                return <Spain passportLogin={passportLogin} passportRegister={passportRegister} cookieSettingBtnHandler={cookieSettingBtnHandler}/>;
            case 'uk':
                return <UK passportLogin={passportLogin} passportRegister={passportRegister} cookieSettingBtnHandler={cookieSettingBtnHandler}/>;
            case 'italy':
                return <Italy passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'newzealand':
                return <NewZealand passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'poland':
                return <Poland passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'romania':
                return <Romania passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'sweden':
                return <Sweden passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'slovakia':
                return <Slovakia passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'taiwan':
                return <Taiwan passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'mea':
                return <Mea passportLogin={passportLogin} passportRegister={passportRegister} />;
            case 'lacam':
                return <Lacam passportLogin={passportLogin} passportRegister={passportRegister} />;
            default:
                return '';
        }
    }

    return (
        <>
            {renderSwitch(country)}
        </>
    )
}

export default Landing