import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';

const Customization = (props) => {

    const [openConfirmModal, setopenConfirmModal] = React.useState(false);
    const [MiniPlayerEnable, setMiniPlayerEnable] = React.useState(props.state.mini_player);
    
    const handleConfirmClose = () => {
        setopenConfirmModal(false);
    };

    const handleOK = () => {
        props.handleChange('mini_player',!props.state.mini_player);
        setMiniPlayerEnable(!props.state.mini_player);
        setopenConfirmModal(false);
    }

    React.useEffect(() => {
        
    }, [MiniPlayerEnable]);

  return (
    <>
    <Modal open={openConfirmModal} onClose={handleConfirmClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handleConfirmClose()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        { MiniPlayerEnable ?
                        <div className="m20">
                            <Alert severity="warning">
                            By deactivating, the users will no longer have the option to view content in a minimized manner for the selected country. Are you sure you want to deactivate?
                            </Alert>
                        </div> : 
                        <div className="m20">
                            <Alert severity="warning">
                            The minimized content has certain limitations. Given the sensitive and regulated nature of the content for health professionals, important information like product descriptions, characteristics, and relevant indices won't be available in the minimized format. Are you sure you want to activate?
                            </Alert>
                        </div>
                        }
                        
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" className="modalBtn" onClick={() => handleConfirmClose()}>Cancel</button>
                        <button type="button" className="modalBtn" onClick={() => handleOK()}>OK</button>
                    </Grid>
                </Grid>
    </Modal>
    <Grid container className='content'>
        <Grid container item xs={12} lg={12} md = {12} sm = {12} spacing={2}>
            <Grid item xs={6} lg={3} md = {3} sm = {3}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Like Feature</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={ <Checkbox name="is_like" color="primary" checked={props.state.is_like} onChange={(e) => props.handleChange('is_like',e.target.checked)}/> } label="Active" />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6} lg={3} md = {3} sm = {3}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Specialty Customization</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={ <Checkbox name="speciality" color="primary" checked={props.state.speciality_customization} onChange={(e) => props.handleChange('speciality_customization',e.target.checked)}/> } label="Active" />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6} lg={3} md = {3} sm = {3}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Up Next</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={ <Checkbox name="upnext" color="primary" checked={props.state.upnext} onChange={(e) => props.handleChange('upnext',e.target.checked)}/> } label="Active" />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6} lg={3} md = {3} sm = {3}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Show More</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={ <Checkbox name="show_more" color="primary" checked={props.state.show_more} onChange={(e) => props.handleChange('show_more',e.target.checked)}/> } label="Active" />
                    </FormGroup>
                </FormControl>
            </Grid>
            {/* <Grid item xs={12} lg={3}>
                <FormControl component="fieldset">
                    <FormLabel component="label">ON24</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={ <Checkbox name="on24" color="primary" checked={props.state.on24} onChange={(e) => props.handleChange('on24',e.target.checked)}/> } label="Active" />
                    </FormGroup>
                </FormControl>
            </Grid> */}
            <Grid item xs={6} lg={3} md = {3} sm = {3}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Footer</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={ <Checkbox name="footer" color="primary" checked={props.state.footer} onChange={(e) => props.handleChange('footer',e.target.checked)}/> } label="Active" />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6} lg={3} md = {3} sm = {3}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Mini Player</FormLabel>
                    <FormGroup>
                        <FormControlLabel control={ <Checkbox name="mini_player" color="primary" checked={props.state.mini_player} onClick={() => {setopenConfirmModal(true); setMiniPlayerEnable(props.state.mini_player)}}/> } label="Active" />
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    </Grid>
    </>
  )
}

export default Customization