/* eslint-disable no-unused-vars */
import withRouter from './withRouter';
import { useLocation } from 'react-router';
import React from 'react';

const AuthVerifyComponent = () => {

    const location = useLocation();
    const [jwtCode, setJwtCode] = React.useState(localStorage.getItem("jwtToken"));

    React.useEffect(() => {
    }, [location, setJwtCode]);
    return <div></div>;
};

export default withRouter(AuthVerifyComponent);