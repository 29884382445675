

import axios from 'axios'

class PrescribingInfoService {

    async getByCountryName(country) {
        let trimmed_country = country.split(' ');
        let final_country = country.includes(' ') ? trimmed_country[0]+"%20"+trimmed_country[1] : country;
        let data = axios.get(`${process.env.REACT_APP_CONTENTFUL_URL}spaces/${process.env.REACT_APP_CONTENTFUL_SPACES}/entries?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&content_type=countryName&fields.countryName=${final_country}`);
        return data;
    }

    async getByCountryId(countryId) {
        let data = axios.get(`${process.env.REACT_APP_CONTENTFUL_URL}spaces/${process.env.REACT_APP_CONTENTFUL_SPACES}/entries?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&content_type=productsOrBrands&fields.brandCountryName.sys.id=${countryId}`);
        return data;
    }

    getByBrandCountry(brand, countryId) {
        let data = axios.get(`${process.env.REACT_APP_CONTENTFUL_URL}spaces/${process.env.REACT_APP_CONTENTFUL_SPACES}/entries?access_token=${process.env.REACT_APP_ACCESS_TOKEN}&content_type=pdfMaterial&fields.brandName.sys.id=${brand}&fields.countryName.sys.id=${countryId}`);
        return data;
    }

}

export { PrescribingInfoService }