import React, { useState, useEffect } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ContentService } from '../Service/content-service';
import ExpirationDate from './ExpirationDate';
import Multiselect from './Multiselect';
import ExcelJS from "exceljs";

const ContentManagementReportModal = ({
    openSnackbar,
    created_by,
    countryList,
    yearList,
    categoryList,
    therapeuticAreaList,
    contentTypeList,
    ownerList,
    handleClose }) => {
    let contentService = new ContentService();
    const handleSubmit = () => {
        if (selectedFilters.country && selectedFilters.country.length == 0) {
            openSnackbar(true, 'Select Country', 'error')
            return;
        }
        let sday, smonth;
        if (Object.keys(selectedFilters.expiration_date).length > 0 && selectedFilters.expiration_date['from-date'] !== '') {
            sday = selectedFilters.expiration_date['from-date'].getDate() < 9 ? `0${selectedFilters.expiration_date['from-date'].getDate()}` : `${selectedFilters.expiration_date['from-date'].getDate()}`;
            smonth = selectedFilters.expiration_date['from-date'].getMonth() + 1 < 9 ? `0${selectedFilters.expiration_date['from-date'].getMonth() + 1}` : `${selectedFilters.expiration_date['from-date'].getMonth() + 1}`;

        }
        let eday, emonth;
        if (Object.keys(selectedFilters.expiration_date).length > 0 && selectedFilters.expiration_date['to-date'] !== '') {
            emonth = selectedFilters.expiration_date['to-date'].getMonth() + 1 < 9 ? `0${selectedFilters.expiration_date['to-date'].getMonth() + 1}` : `${selectedFilters.expiration_date['to-date'].getMonth() + 1}`;
            eday = selectedFilters.expiration_date['to-date'].getDate() < 9 ? `0${selectedFilters.expiration_date['to-date'].getDate()}` : `${selectedFilters.expiration_date['to-date'].getDate()}`;

        }
        let data = {
            "country_codes": selectedFilters.country.length > 0 ?
                selectedFilters['country'].map(x => x.key) : null,
            "created_by": selectedFilters.owner && selectedFilters.owner.length > 0 ?
                selectedFilters['owner'].map(x => x.key) : null,
            "year": selectedFilters.year.length > 0 ?
                selectedFilters['year'].map(x => x.key) : null,
            "startDate": Object.keys(selectedFilters.expiration_date).length > 0 && selectedFilters.expiration_date['from-date'] !== '' ?
                `${selectedFilters.expiration_date['from-date'].getFullYear()}-${smonth}-${sday}` : null,
            "endDate": Object.keys(selectedFilters.expiration_date).length > 0 && selectedFilters.expiration_date['to-date'] !== '' ?
                `${selectedFilters.expiration_date['to-date'].getFullYear()}-${emonth}-${eday}` : null,
            "therapeutic_area_id": selectedFilters.ta.length > 0 ? selectedFilters.ta.map(x => x.key) : null,
            "category_1_id": selectedFilters.cat.length > 0 ?
                selectedFilters['cat'].map(x => x.key) : null,
            "content_type": selectedFilters.content_type.length > 0 ? selectedFilters.content_type.map(x => x.key) : null
        };

        let reportdatascope = {
            "country_codes": selectedFilters.country && selectedFilters.country.length > 0 ?
                selectedFilters['country'].map(x => x.label) : [],
            "created_by": selectedFilters.owner && selectedFilters.owner.length > 0 ?
                selectedFilters['owner'].map(x => x.label) : [],
            "year": selectedFilters.year.length > 0 ?
                selectedFilters['year'].map(x => x.key) : [],
            "lilly_expiry_date": data.startDate === null || data.endDate === null ? [] : [`${data.startDate} - ${data.endDate} `],
            "category_1_id": selectedFilters.cat && selectedFilters.cat.length > 0 ?
                selectedFilters['cat'].map(x => x.label) : [],
            "content_type": selectedFilters.content_type.length > 0 ?
                selectedFilters['content_type'].map(x => x.label) : [],
        }

        contentService.GetContentReports('', data).then(async res => {

            const workbook = new ExcelJS.Workbook();
            const reportworksheet = workbook.addWorksheet("Report Data");
            const datascopeworksheet = workbook.addWorksheet("Data Scope");
            const metadataworksheet = workbook.addWorksheet("Metadata");

            const reportborderobj = {
                top: { style: 'thin', color: { argb: 'FFFFFF' } },
                left: { style: 'thin', color: { argb: 'FFFFFF' } },
                bottom: { style: 'thin', color: { argb: 'FFFFFF' } },
                right: { style: 'thin', color: { argb: 'FFFFFF' } }
            }
            const borderobj = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            }
            const headerfill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '538DD5' },
            }
            const reportheaderfont = { bold: true, color: { argb: 'FFFFFF' }, };
            const font = { bold: true };
            const alignment = { vertical: 'middle', horizontal: 'center' };
            const fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'C5D9F1' },
            };

            reportworksheet.columns = [
                { key: "title", width: 60 },
                { key: "content_id", width: 20 },
                { key: "content_type", width: 20 },
                { key: "therapeutic_area_name", width: 20 },
                { key: "category_1_name", width: 20 },
                { key: "category_2_name", width: 20 },
                { key: "taxonomy_products_name", width: 20 },
                { key: "taxonomy_indications_name", width: 30 },
                { key: "owner", width: 20 },
                { key: "vvpm_id", width: 20 },
                { key: "vvpm_expiry_date", width: 20 },
                { key: "lilly_expiry_date", width: 25 },
                { key: "content_approved_name", width: 15 },
                { key: "content_approved_status", width: 20 },
                { key: "year", width: 15 },
                { key: "speakers_name", width: 60 },
                { key: "second_owner", width: 20 },
                { key: "kaltura_id", width: 20 },
                { key: "created_at", width: 20 }
            ]
            datascopeworksheet.columns = [
                { key: "country_code", width: 80 },
                { key: "category_1_id", width: 80 },
                { key: "content_type", width: 80 },
                { key: "year", width: 80 },
                { key: "created_by", width: 80 },
                { key: "lilly_expiry_date", width: 80 }
            ]
            metadataworksheet.columns = [
                { key: "todays_date", width: 80 }
            ]

            const reportheader = [
                'Title',
                'Content ID',
                'Content Type',
                'TA',
                'TA | Category 1',
                'TA | Category 2',
                'Brand | IBU Taxonomy',
                'Indication | IBU Taxonomy',
                'Owner',
                'VVPM Code',
                'VVPM Expiration Date',
                'Lilly Play Expiration Date',
                'Country',
                'Content Status',
                'Year of Upload',
                'Speaker',
                'Secondary Owner',
                'Kaltura ID',
                'Created At'
            ]
            const datascopeheader = [
                'Country',
                'Brand',
                'Content Type',
                'Year',
                'Owner',
                'Lilly Play Expiration Date',
            ];
            const metadataheader = [
                'Report Generated Date',
            ];
            reportworksheet.getRow(1).values = reportheader;
            for (let i = 0; i < reportheader.length; i++) {
                reportworksheet.getCell(String.fromCharCode(65 + i) + '1').border = reportborderobj;
                reportworksheet.getCell(String.fromCharCode(65 + i) + '1').font = reportheaderfont;
                reportworksheet.getCell(String.fromCharCode(65 + i) + '1').alignment = alignment;
                reportworksheet.getCell(String.fromCharCode(65 + i) + '1').fill = headerfill;
            }

            datascopeworksheet.getRow(1).values = datascopeheader
            for (let i = 0; i < datascopeheader.length; i++) {
                datascopeworksheet.getCell(String.fromCharCode(65 + i) + '1').border = borderobj;
                datascopeworksheet.getCell(String.fromCharCode(65 + i) + '1').font = font;
                datascopeworksheet.getCell(String.fromCharCode(65 + i) + '1').alignment = alignment;
            }

            metadataworksheet.getRow(1).values = metadataheader
            for (let i = 0; i < metadataheader.length; i++) {
                metadataworksheet.getCell(String.fromCharCode(65 + i) + '1').border = borderobj;
                metadataworksheet.getCell(String.fromCharCode(65 + i) + '1').font = font;
                metadataworksheet.getCell(String.fromCharCode(65 + i) + '1').alignment = alignment;
            }
            if (res.data.length > 0) {
                res.data.map(x => {
                    let expirationdate = x.lilly_expiry_date ? new Date(x.lilly_expiry_date) : '';
                    const expirationDateMonth = x.lilly_expiry_date ? (expirationdate.getMonth() + 1 <= 9 ? `0${expirationdate.getMonth() + 1}` : `${expirationdate.getMonth() + 1}`) : '';
                    const expirationDateDay = x.lilly_expiry_date ? (expirationdate.getDate() <=9 ? `0${expirationdate.getDate()}` : `${expirationdate.getDate()}`) : '';
                    x.lilly_expiry_date = x.lilly_expiry_date ? (`${expirationdate.getFullYear()}-${expirationDateMonth}-${expirationDateDay}`) : '';
                    x.therapeutic_area_name = x.therapeutic_area_name && x.therapeutic_area_name !== null &&
                        x.therapeutic_area_name.length > 0 ? x.therapeutic_area_name.reduce(function (prevVal, currVal, idx) {
                            return idx == 0 ? currVal : prevVal + ', ' + currVal;
                        }, '') : '';
                    x.category_1_name = x.category_1_name && x.category_1_name !== null &&
                        x.category_1_name.length > 0 ? x.category_1_name.reduce(function (prev, curr, idx) {
                            return idx == 0 ? curr : prev + ', ' + curr;
                        }, '') : '';
                    x.category_2_name = x.category_2_name && x.category_2_name !== null &&
                        x.category_2_name.length > 0 ? x.category_2_name.reduce(function (prev, curr, idx) {
                            return idx == 0 ? curr : prev + ', ' + curr;
                        }, '') : '';
                    x.taxonomy_products_name = x.taxonomy_products_name && x.taxonomy_products_name !== null &&
                        x.taxonomy_products_name.length > 0 ? x.taxonomy_products_name.reduce(function (prev, curr, idx) {
                            return idx == 0 ? curr : prev + ', ' + curr;
                        }, '') : '';
                    x.taxonomy_indications_name = x.taxonomy_indications_name && x.taxonomy_indications_name !== null &&
                        x.taxonomy_indications_name.length > 0 ? x.taxonomy_indications_name.reduce(function (prev, curr, idx) {
                            return idx == 0 ? curr : prev + ', ' + curr;
                        }, '') : '';
                    x.speakers_name = x.speakers_name && x.speakers_name !== null &&
                        x.speakers_name.length > 0 ? x.speakers_name.reduce(function (prev, curr, idx) {
                            return idx == 0 ? curr : prev + ', ' + curr;
                        }, '') : '';
                    let createdAtDateFormat = new Date(x.created_at);
                    const createdAtDateMonth = createdAtDateFormat.getMonth() + 1 <= 9 ? `0${createdAtDateFormat.getMonth() + 1}` : `${createdAtDateFormat.getMonth() + 1}`;
                    const createdAtDateDay = createdAtDateFormat.getDate() <=9 ? `0${createdAtDateFormat.getDate()}` : `${createdAtDateFormat.getDate()}`
                    x.created_at = `${createdAtDateFormat.getFullYear()}-${createdAtDateMonth}-${createdAtDateDay}`;
                    reportworksheet.addRow(x);
                });
            }

            let datascoperows = [];
            let largest_arr = 0;
            if (reportdatascope['country_codes']
                && reportdatascope['country_codes'].length > 0
                && reportdatascope['country_codes'].length > largest_arr) {
                largest_arr = data['country_codes'].length
            }
            if (reportdatascope['created_by']
                && reportdatascope['created_by'].length > 0
                && reportdatascope['created_by'].length > largest_arr) {
                largest_arr = data['created_by'].length
            }
            if (reportdatascope['year']
                && reportdatascope['year'].length > 0
                && reportdatascope['year'].length > largest_arr) {
                largest_arr = data['year'].length
            }
            if (reportdatascope['category_1_id']
                && reportdatascope['category_1_id'].length > 0
                && reportdatascope['category_1_id'].length > largest_arr) {
                largest_arr = data['category_1_id'].length
            }
            if (reportdatascope['content_type']
                && reportdatascope['content_type'].length > 0
                && reportdatascope['content_type'].length > largest_arr) {
                largest_arr = data['content_type'].length
            }
            for (let i = 0; i < largest_arr; i++) {
                datascoperows = [...datascoperows, {
                    country_code: reportdatascope['country_codes'][i] ?? '',
                    category_1_id: reportdatascope['category_1_id'][i] ?? '',
                    content_type: reportdatascope['content_type'][i] ?? '',
                    year: reportdatascope['year'][i] ?? '',
                    lilly_expiry_date: reportdatascope['lilly_expiry_date'][i] ?? '',
                    created_by: reportdatascope['created_by'][i] ?? '',
                }]
            }

            datascoperows.map(x => {
                datascopeworksheet.addRow(x);
            });
            let metadatarows = [{ 'todays_date': new Date() }];
            metadatarows.map(x => {
                metadataworksheet.addRow(x);
            });
            for (let j = 1; j < res.data.length + 2; j++) {
                for (let i = 0; i < reportheader.length; i++) {
                    let letter = String.fromCharCode(65 + i);
                    if (j % 2 === 0) {
                        reportworksheet.getCell(letter + j).fill = fill
                    }
                }
            }
            const buffer = await workbook.xlsx.writeBuffer();
            let base64data = buffer.toString("base64");
            let file_format = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            const linkSource = `data:${file_format};base64,${base64data}`;
            const downloadLink = document.createElement("a");
            const fileName = 'Report';
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }).catch(err => {
        })
        openSnackbar(true, 'Reports downloaded successfully', 'success')
    }

    const [selectedFilters, setSelectFilters] = React.useState({
        'country': [],
        'ta': [],
        'cat': [],
        'content_type': [],
        'year': [],
        'owner': [],
        'expiration_date': [],
    });
    const handleSelected = (values) => {
        let modifiedkey = Object.keys(values)[0];
        let filters = selectedFilters;
        filters[modifiedkey] = values[Object.keys(values)[0]];
        setSelectFilters({ ...filters });
    }
    useEffect(() => {
        let modifiedkey = 'country';
        let filters = selectedFilters;
        filters[modifiedkey] = countryList && countryList.length > 0 ? countryList.map(x => ({ label: x.country_name, key: x.country_code })) : [];
        setSelectFilters({ ...filters });
    }, [countryList])
    return (
        <>
            <DialogContent className='modal-content'>
                <Multiselect
                    mandatory={true}
                    title={'Country'}
                    tags={selectedFilters['country']}
                    open={true}
                    list={countryList && countryList.length > 0 ? countryList.map(x => ({ label: x.country_name, key: x.country_code })) : []}
                    handleSelected={(value) => { handleSelected({ 'country': value }) }}
                />
                <Multiselect
                    title={'Therapeautic Area'}
                    tags={selectedFilters['ta']}
                    open={true}
                    list={therapeuticAreaList && therapeuticAreaList.length > 0 ? therapeuticAreaList.map(x => ({ label: x.therapeutic_area_name, key: x.therapeutic_area_id })) : []}
                    handleSelected={(value) => { handleSelected({ 'ta': value }) }}
                />
                <Multiselect
                    title={'Category 1'}
                    tags={selectedFilters['cat']}
                    open={true}
                    list={
                        categoryList &&
                            categoryList.length > 0 ? categoryList.map(x => ({ label: `${x.category_1_name} (${x.category_management_tum.therapeutic_area_name})`, key: x.category_1_id })) : []}
                    handleSelected={(value) => { handleSelected({ 'cat': value }) }}
                />
                <Multiselect
                    title={'Content Type'}
                    tags={selectedFilters['content_type']}
                    open={true}
                    list={contentTypeList && contentTypeList.length > 0 ? contentTypeList.map(x => ({ label: x.name, key: x.id })) : []}
                    handleSelected={(value) => { handleSelected({ 'content_type': value }) }}
                />
                <Multiselect
                    title={'Year of Upload'}
                    tags={selectedFilters['year']}
                    open={true}
                    list={yearList && yearList.length > 0 ? yearList : []}
                    handleSelected={(value) => { handleSelected({ 'year': value }) }}
                />
                <Multiselect
                    title={'Owner'}
                    tags={selectedFilters['owner']}
                    open={true}
                    list={ownerList && ownerList.length > 0 ? ownerList.map(x => ({ label: x.fullName, key: x.id })) : []}
                    handleSelected={(value) => { handleSelected({ 'owner': value }) }}
                />
                <ExpirationDate handleSelected={(value) => { handleSelected({ 'expiration_date': value }) }} />
            </DialogContent>

            <DialogActions>
                <div className='modal-actions'>
                    <button className="modal-cancel" onClick={() => { handleClose() }}>Cancel</button>
                    <button className="modal-download" onClick={() => { handleSubmit() }}>
                        <img className='actnBtn' src={require('../../../public/img/download-01-white.png')} alt='Download' />
                        Download Report
                    </button>
                </div>
            </DialogActions>
        </>
    )

}
export default ContentManagementReportModal;