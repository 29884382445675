import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Breadcrumb from '../../../layout/breadcrumb';
import Typography from '@material-ui/core/Typography';
import { useNavigate, useLocation } from "react-router-dom";
import EventDetails from '../Components/EventDetails';
import LillyPlaySpeaker from '../Components/LillyPlaySpeaker';
import On24Speaker from '../Components/On24Speaker';
import TherapeuticArea from '../Components/TherapeuticArea';
import Affiliates from '../Components/Affiliates';
import Library from '../Components/Library';
import Audience from '../Components/Audience';
import Specialities from '../Components/Specialties';
import { EventService } from '../Services/event-service';
import Modal from '@material-ui/core/Modal';
import { useSelector } from 'react-redux';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import useCustomForm from '../../../hooks/useCustomForm';
import { prepareCategoryTreeByCountries } from '../../../helpers/countryHelper';
import useCategoryTree from '../../../hooks/useCategoryTree';

const defaultSelectedCategories = {
    TAIds: [],
    cat1Ids: [],
    cat2Ids: [],
  }

const EventEdit = (props) => {
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [getSavedOwner, setgetSavedOwner] = React.useState();
    const [getCreated_at, setgetCreated_at] = React.useState();
    const [affiliateToPublish, setAffiliateToPublish] = React.useState({});
    const [isRolePublisher, setIsRolePublisher] = React.useState(false);
    const [avatar_attachment_id, setavatar_attachment_id] = React.useState('');
    const [speakerIdLocal, setSpeakerIdLocal] = React.useState([]);
    const [audience, SetAudience] = React.useState([]);
    const [specialties, SetSpecialties] = React.useState([]);
    const [audienceMap, SetAudienceMap] = React.useState({});
    const [countryName, setCountryName] = React.useState();
    const [countryChangePopup, setcountryChangePopup] = React.useState(false);
    const [detectChange, setdetectChange] = React.useState(false);
    const [changeCountryState, setchangeCountryState] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);

    let eventServices = new EventService();

    // Get Current Edited Event Data
    const { state } = useLocation();
    if(!state){
        document.location.href = '/home';
    }
    let event = state.data;
    if (state.action === 'live') {
        event.event_id = event.eventid;
        event.eventstart = event.livestart;
        event.eventend = event.liveend;
        event.eventduration = event.scheduledeventduration;
        event.on24_speakers = event.speakers;
        event.second_owner = "";
        event.status = "submitted";
        event.speakers_id = [];
        event.therapeutic_areas = [];
        event.countries = [];
    }
    React.useEffect(() => {
        if (state.action !== 'local') {
            eventServices.GetSpeakersByQuery().then((data) => {
                let jsondata = data.data
                let arr = [];
                for (let i = 0; i < event.on24_speakers.length; i++) {
                    for (let j = 0; j < jsondata.length; j++) {
                        if (event.on24_speakers[i].name === jsondata[j].name) {
                            document.getElementById('add24_' + i).style.visibility = 'hidden';
                            const row = {
                                id: jsondata[j].speaker_id,
                                name: jsondata[j].name,
                                title: jsondata[j].name,
                                description: jsondata[j].curriculum,
                                index: i
                            }
                            arr.push(row)
                        }
                    }
                }
                setLillyplaySpeakers(arr);
            }).catch((err) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event.on24_speakers])

    React.useEffect(() => {
        if (state.action === 'local') {
            let speakerId = [...speakerIdLocal]
            eventServices.GetSpeakersByQuery().then((data) => {
                let jsondata = data.data
                let arr = [];
                for (let i = 0; i < speakerId.length; i++) {
                    for (let j = 0; j < jsondata.length; j++) {
                        let indexOn24 = 0;
                        if(event.on24_speakers) {
                            for (let k = 0; k < event.on24_speakers.length; k++) {
                                if (event.on24_speakers[k].name === jsondata[j].name) {
                                    document.getElementById('add24_' + k).style.visibility = 'hidden';
                                    indexOn24 = k;
                                }
                            }
                        }
                        if(speakerId[i] === jsondata[j].speaker_id) {
                            const row = {
                                id: jsondata[j].speaker_id,
                                name: jsondata[j].name,
                                title: jsondata[j].name,
                                description: jsondata[j].curriculum,
                                index: indexOn24
                            }
                            arr.push(row)
                        }
                    }
                }
                setLillyplaySpeakers(arr);
            }).catch((err) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [speakerIdLocal])




    // Get Current Edited Event Data From DB
    React.useEffect(() => {
        eventServices.GetEventsByQuery({ event_id: event.event_id }).then((data) => {
            let jsondata = data.data;
            SetID(jsondata[0].lpevent_id);
            setSpeakerIdLocal(jsondata[0].speakers_id);
            SetSecondOwner(jsondata[0].second_owner)
            setgetSavedOwner(jsondata[0].user.user_name)
            setgetCreated_at(jsondata[0].user.id)
            SetLibraries(Array.isArray(jsondata[0].event_libraries) ? jsondata[0].event_libraries : [])
            setSelectedCategories({
                TAIds: jsondata[0].therapeutic_area_id,
                cat1Ids: jsondata[0].category_1_id,
                cat2Ids: jsondata[0].category_2_id
            });
            setAffiliateToPublish(jsondata[0].country_approved);
            setavatar_attachment_id(jsondata[0].avatar_attachment_id)
            SetAudience(jsondata[0].audience_mapping ? jsondata[0].audience_mapping : []);
            SetSpecialties(jsondata[0].specialities ? jsondata[0].specialities : []);
            setValue('affiliates', state.action === 'live' ? [] : jsondata[0].country_codes);
            //setLillyplaySpeakers(jsondata[0].speakers_id);
        }).catch((err) => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event.event_id]);

    // Define States
    const [id, SetID] = React.useState((state.action === 'local') ? event.id : 0);
    const [secondOwner, SetSecondOwner] = React.useState(event.second_owner);
    const [lillyplaySpeakers, setLillyplaySpeakers] = React.useState(event.speakers_id);
    const [libraries, SetLibraries] = React.useState([]);

    const {categoryTree, onCategorySelect, initializeCategoryTree, selectedTAIds, selectedCat1Ids, selectedCat2Ids} = useCategoryTree([]);
    const [selectedCategories, setSelectedCategories] = React.useState(defaultSelectedCategories);

    const activeUser = localStorage.getItem('userID');
    let selectorValue = useSelector(state => state.session);
    const [sessionId, SetsessionId] = React.useState(activeUser);
    const [checkAffiliates, SetCheckAffiliates] = React.useState(false);
    const [affiliatesName, SetAffiliatesName] = React.useState([]);
    const { formData, errors, handleChange, setValue, validateForm, inputRefs } = useCustomForm({
        affiliates: (state.action === 'live') ? [] : event.countries,
        selectedBrands: []
    }, {
        affiliates: ['required'],
        selectedBrands: ['required']
    });

    React.useEffect(() => {
        if (selectorValue) {
            if (selectorValue.sessionValue.payload) {
                if (selectorValue.sessionValue.payload.user) {
                    if (selectorValue.sessionValue.payload.user) {
                        SetsessionId(selectorValue.sessionValue.payload.user.id)
                    }
                }
                for (let i = 0; i < selectorValue.sessionValue.payload.roles.length; i++) {
                    if (selectorValue.sessionValue.payload.roles[i].role_name === "publisher") {
                        setIsRolePublisher(true);
                    }
                }
            }
        }

    }, [selectorValue, SetsessionId]);
    // Lilly Play Speakers State Change
    const addLillyPlaySpeakers = (row) => {
        if (lillyplaySpeakers) {
            setLillyplaySpeakers([...lillyplaySpeakers, row])
        } else {
            setLillyplaySpeakers(row)
        }
    }


    React.useEffect(() => {
        let countryNameArr = [];
        let publishCountriesObj = {};
        if (isRolePublisher) {
            for (let i = 0; i < affiliatesName.length; i++) {
                if (!publishCountriesObj[affiliatesName[i].country_code]) {
                    publishCountriesObj[affiliatesName[i].country_code] = {
                        name: affiliatesName[i].country_name,
                        status: "Active"
                    }
                }
                countryNameArr.push(affiliatesName[i].country_name)
            }
        }
        else {
            for (let i = 0; i < affiliatesName.length; i++) {
                if (!publishCountriesObj[affiliatesName[i].country_code]) {
                    publishCountriesObj[affiliatesName[i].country_code] = {
                        name: affiliatesName[i].country_name,
                        status: "Pending"
                    }
                }
                countryNameArr.push(affiliatesName[i].country_name)
            }
        }
        setCountryName(countryNameArr);
        setAffiliateToPublish(publishCountriesObj);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliatesName]);

    // Handle Submit Button
    const handleSubmit = () => {
        if (!validateForm()) {
            return;
        }

        let model = {
            id: id,
            event_id: event.event_id,
            name: event.event_id,
            title: event.description,
            description: event.description,
            audienceurl: event.audienceurl,
            reporturl: event.reporturl,
            previewurl: event.previewurl,
            audiencekey: event.audiencekey,
            eventtype: event.eventtype,
            status: 'submitted',
            photo_url: '',
            avatar_attachment_id: avatar_attachment_id,
            created_at: new Date(),
            created_by: (!sessionId || sessionId === '') ? activeUser : sessionId,
            updated_at: new Date(),
            updated_by: (!sessionId || sessionId === '') ? activeUser : sessionId,
            locale: JSON.stringify({}),
            second_owner: secondOwner,
            eventstart: event.eventstart,
            eventend: event.eventend,
            goodafter: event.goodafter,
            displaytimezonecd: event.displaytimezonecd,
            on24_speakers: event.on24_speakers,
            eventduration: event.eventduration,
            promotionalsummary: event.promotionalsummary ? event.promotionalsummary : "",
            lillyplay_speakers: lillyplaySpeakers,
            therapeutic_areas: selectedTAIds,
            countries: formData.affiliates,
            event_libraries: libraries,
            audience: audience,
            specialities: specialties,
            identifiers: "",
            therapeuticAreas: selectedTAIds,
            brands: selectedCat1Ids,
            indications: selectedCat2Ids,
            countries_approved: affiliateToPublish,
        };


        if (model.id === 0) {
            delete model.id
        }
        let countrySelected = [formData.affiliates]
        let userStr = "country_code=" + countrySelected;
        let publisherEmailSet = new Set();
        eventServices.GetPublishersByCountry(userStr).then((data) => {
            let jsondata = data.data;
            for (let i = 0; i < jsondata.length; i++) {
                publisherEmailSet.add(jsondata[i].user_email)
            }

        }).catch((err) => {});
        eventServices.UpdateEvents(model).then((data) => {
            if (state.action !== "local") {
                let contentUrl = window.location.href;
                let date = new Date();
                var hours = date.getHours();
                var minutes = date.getMinutes();
                var ampm = hours >= 12 ? 'pm' : 'am';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0' + minutes : minutes;
                var strTime = hours + ':' + minutes + ' ' + ampm;
                const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                let datestring = months[date.getMonth()] + " " + ("0" + date.getDate()).slice(-2) + " " + date.getFullYear() + ", " + strTime;

                eventServices.SendEmail(event.description, countryName, datestring, contentUrl, Array.from(publisherEmailSet));
            }
            
        }).catch((err) => {});
        navigate('/event-management');
        setIsDisabled(true)
    }
    let eventIdFromLink = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'Event Management', path: '/event-management' },
        { value: (state.action === 'local') ? 'Event Details' : 'Event Upload', path: '/event-edit/' + eventIdFromLink , state:{state}}
    ];

    const addAffiliates = (affiliate) => {
        SetAffiliatesName([...affiliatesName, affiliate])
    }
    const removeAffiliates = (affiliate_obj) => {
        var array = [...affiliatesName];
        const filteredArray = array.filter(item => item !== affiliate_obj);
        SetAffiliatesName(filteredArray);

        var filteredPublishArray = {};
        let selectedKeys = Object.keys(affiliateToPublish)
        for (let i=0;i<selectedKeys.length; i++) {
            let key = selectedKeys[i];
            if(affiliate_obj.country_code !== key) {
                filteredPublishArray[key] = {
                    name: affiliateToPublish[key].name,
                    status: affiliateToPublish[key].status,
                }
            }
        }
        setAffiliateToPublish(filteredPublishArray);
    }

    const handlecountryChangePopup = () => {
        setcountryChangePopup(false)
        setdetectChange(false)
    }
    React.useEffect(() => {
        if (id != 0 && detectChange) {
            setcountryChangePopup(true)
        }
    }, [detectChange]);
    const handlecountryChangeCondition = () => {
        setSelectedCategories(defaultSelectedCategories);
        setchangeCountryState(true)
        setcountryChangePopup(false)
        setdetectChange(false)
    }

    React.useEffect(() => {
      const tree = prepareCategoryTreeByCountries(props.allcategory, affiliatesName);
      initializeCategoryTree(tree, selectedCategories);
    }, [affiliatesName, props.allcategory, selectedCategories, initializeCategoryTree])
  
    React.useEffect(() => {
      handleChange('selectedBrands', selectedCat1Ids)
    }, [selectedCat1Ids, handleChange]);

    return (
        <>
            <Modal open={countryChangePopup} onClose={handlecountryChangePopup}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={handlecountryChangePopup}></button>
                    </Grid>
                    <Grid className='box'>
                        {/* <Alert severity="warning">{urltextPopup}</Alert> */}
                        <Grid className='attenSec attenSecvalidationImage'>
                            <span className='attenIcnSec'>
                                <ReportProblemIcon className='attenIcn' />
                                Attention
                            </span>
                            <p className='attenTxt'>
                                All the previously saved configurations that are country specific will be lost, Are you sure you want to continue?
                            </p>
                        </Grid>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" className="modalBtn" onClick={handlecountryChangePopup}>Cancel</button>
                        <button type="button" className="modalBtn" onClick={handlecountryChangeCondition}>Ok</button>
                    </Grid>
                </Grid>
            </Modal>
            <Grid>
                <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
                <Grid className="main-wrp">
                    <Grid container className='outer'>
                        <Grid container item xs={12} lg={12} className='header'>
                            <Grid container className="headerContent"><Typography variant="h6">Event Details</Typography></Grid>
                        </Grid>
                        <EventDetails avatar_attachment_id={avatar_attachment_id} setavatar_attachment_id={setavatar_attachment_id} activeUser={sessionId} owner={getCreated_at} eventDetails={event} action={state.action} SetSecondOwner={SetSecondOwner} secondOwner={secondOwner} />
                        <Grid className="blankDiv"></Grid>
                        <On24Speaker addLillyPlaySpeakers={addLillyPlaySpeakers} speakers={event.on24_speakers} />
                        <LillyPlaySpeaker addLillyPlaySpeakers={addLillyPlaySpeakers} setLillyplaySpeakers={setLillyplaySpeakers} speakers={lillyplaySpeakers} on24Speakers={event.on24_speakers} />
                        
                        <Affiliates 
                            removeAffiliates={removeAffiliates} addAffiliates={addAffiliates} 
                            handleChange={handleChange} affiliates={formData.affiliates} 
                            SetCheckAffiliates={SetCheckAffiliates} checkAffiliates={checkAffiliates} 
                            SetAffiliatesName={SetAffiliatesName} affiliatesName={affiliatesName} 
                            id={state.action === 'local'? 1:0 } 
                            setchangeCountryState={setchangeCountryState} changeCountryState={changeCountryState}  
                            setdetectChange={setdetectChange} errors={errors} ref={inputRefs}
                        />
                        
                        <TherapeuticArea errors={errors} ref={inputRefs} categoryTree={categoryTree} onCategorySelect={onCategorySelect}/>

                        {
                            (state.action === 'local') ? (
                                <>
                                    <Library affiliates={affiliatesName} libraries={libraries} SetLibraries={SetLibraries} />
                                    <Audience affiliates={affiliatesName} audience={audience} SetAudience={SetAudience} SetAudienceMap={SetAudienceMap} audienceMap={audienceMap} />
                                    <Specialities affiliates={affiliatesName} specialties={specialties} SetSpecialties={SetSpecialties} />
                                </>
                            ) : ""
                        }
                         {
                            (state.action === 'live') ? (
                                <>
                                    <Library affiliates={affiliatesName} libraries={libraries} SetLibraries={SetLibraries} />
                                    <Audience affiliates={affiliatesName} audience={audience} SetAudience={SetAudience} SetAudienceMap={SetAudienceMap} audienceMap={audienceMap} />
                                    <Specialities affiliates={affiliatesName} specialties={specialties} SetSpecialties={SetSpecialties} />
                                 </>
                            ) : ""
                        }

                        <Grid container className="content grey_row">
                            <Grid container className="row_inner_box">
                                <Grid item lg={6} xs={12} className="text-left">
                                    &nbsp;
                                </Grid>
                                <Grid item lg={6} xs={12} className="text-right">

                                    {(getCreated_at === sessionId || (secondOwner && secondOwner === sessionId) || state.action === 'live') &&
                                        <Button variant="outlined" type="submit" className='createBtn'  disable= {isDisabled} onClick={handleSubmit}>Save</Button>
                                    }
                                    {/* <Button variant="outlined" type="submit" className='createBtn' onClick={handleSubmit}>Save</Button>  */}
                                    &nbsp;&nbsp;
                                    <Button variant="outlined" className='createBtn' onClick={() => navigate('/event-management')}>Back</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default EventEdit;






