/* eslint-disable eqeqeq */




import axios from 'axios';

function DeleteSessionCookie() {
  try{
    var d = new Date();
    d.setTime(d.getTime() + 1);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = 'sessioncookie' + '=' + '' + ';' + expires + ';path=/';
    return true;
  }catch(err){
    return false;
  }
}
function GetSessionCookie(name) {
  let cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let _cookies = cookies[i].split('=');

    if (_cookies[0].trim() === name) {
      //sessionUserCurrent
      let signValue = _cookies[1].trim();
      return signValue;
    }
  }
}

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    if(error){
      if(error.response){
        if(error.response.status == 403){
          let hcpDirectCookie = GetSessionCookie('hcpDirect');
          if( !hcpDirectCookie){
            let accessToken = localStorage.getItem('jwtToken');
            if(accessToken || true){
              // let routeForAccess = window.location.pathname;
              // localStorage.setItem('previousRouteBeforeLogin', routeForAccess);
              // DeleteSessionCookie();
              // localStorage.clear();
              // window.location.href='/';
            }
            
          }
          
        }
      }
    }
    // Do something with response error
    return Promise.reject(error);
  });