


class SaveAudit
{
    constructor()
    {
        this.content_id = '';
        this.kaltura_id = '';
        this.content_type = '';
        this.action = '';
        this.created_by = '';
        this.speaker_ids=[];
    }
}
export {SaveAudit}