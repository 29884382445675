import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { useSelector } from 'react-redux';

const PIInfo = (props) => {
    let selectorValue = useSelector(state => state.session);
    const [countryPIInfo, SetCountryPIInfo] = React.useState([]);

    React.useEffect(() => {
        let pi_info = [];
        if (selectorValue) {
           if (selectorValue.sessionValue.payload) {
               if(Array.isArray(selectorValue.sessionValue.payload.affiliate)){
                    if(selectorValue.sessionValue.payload.affiliate.length>0){
                        pi_info = selectorValue.sessionValue.payload.affiliate[0].pi_info;
                        if (pi_info) {
                            let productinfo_links = [];
                            if(pi_info.length) {
                                for(let i=0; i<pi_info.length;i++) {
                                    if(props.category1Ids.includes(pi_info[i].brand_id)) {
                                        productinfo_links.push({ 
                                            "pi_url" : pi_info[i].pi_url,
                                            "target" : pi_info[i].is_warning
                                        });
                                    }
                                }
                            }
                            SetCountryPIInfo(productinfo_links);
                        }
                    }
                }
            }
        }
    }, [selectorValue, props.category1Ids]);

    const [redirectLink, SetRedirectLink] = React.useState('');
    const [redirectWaringModal, SetRedirectWaringModal] = React.useState(false);

    const handlePILinkClick = (href) => {
        SetRedirectLink(href);
        SetRedirectWaringModal(true);
    }
    
    return (
        <>
        <Modal open={redirectWaringModal} onClose={() => { SetRedirectWaringModal(false) }}>
            <Grid className='paper modalstyle redirectWarningModal'>
                <Grid className="modal-body">
                    <button className='closeModal' onClick={() => SetRedirectWaringModal(false)}></button>
                    <div className="m20">
                        <div className="text-center">
                            <h3>You are now leaving {window.location.origin}</h3>
                            <p>The link you clicked on will take you to a site maintained by a third party, which is solely responsible for its content. Eli Lilly Company and its affiliates do not control, influence, or endorse this site, and the opinions, claims, or comments expressed on this site should not be attributed to Eli Lilly Company and its affiliates. Eli Lilly Company and its affiliates are not responsible for the  privacy policy of any third-party websites. We encourage you to read the privacy policy of every website you visit.</p>
                            <p>Click "Continue" to leave site or "Return to Lilly Play" to close this window and return to {window.location.origin}</p>
                        </div>
                    </div>
                </Grid>
                <Grid className="modal-footer">
                    <button className='modalBtn' onClick={() => { SetRedirectWaringModal(false) }}>Return to Lilly Play</button>
                    <button type="button" onClick={() => { window.open(redirectLink, '_blank'); SetRedirectWaringModal(false); }} className="modalBtn">Continue</button>
                </Grid>
            </Grid>
        </Modal>
        {countryPIInfo.length ? 
            countryPIInfo.length === 1 ? (
                <div className="pi_tooltip">
                    {countryPIInfo[0].target ? 
                        <Button className="pi_tooltip" onClick={() => handlePILinkClick(countryPIInfo[0].pi_url)}>Click here for prescribing information</Button>
                        : <Button className="pi_tooltip" onClick={() => window.open(countryPIInfo[0].pi_url, '_blank') }>Click here for prescribing information</Button>
                    }
                </div>
            ) : (
                <Tooltip className="pi_tooltip"
                title={
                    <Typography>
                        <ul className='pi_linkslist'> 
                            {countryPIInfo.map((product, index) => {
                                return product.target ? 
                                    <li><a key={index} href onClick={() => handlePILinkClick(product.pi_url)} rel="noreferrer">{product.pi_url}</a></li>
                                    : <li><a key={index} href={product.pi_url} target="_blank" rel="noreferrer">{product.pi_url}</a></li>
                            })} 
                        </ul>
                    </Typography>
                } placement="right" interactive arrow>
                    <Button>Click here for prescribing information</Button>
                </Tooltip>
            )
        : ''}
        </>
    )
}

export default PIInfo