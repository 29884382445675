import axios from 'axios';

// const auth_headers = {
//     headers: {
//         'Content-Type': 'application/json',
//         'authorizationtoken': localStorage.getItem('jwtToken')
//     }
// };

// export const getContentList = (type, filter) => {
//     const userID = localStorage.getItem('userID');
//     return axios.post(process.env.REACT_APP_BASE_URL +`/home/api/content/ShowMoreDetails/0/100?category=${type}&category_id=${filter}&user_id=${userID}`, {}, auth_headers);
// };

// export const getContentListCount = (startCount, endCount, type, filter) => {
//     const userID = localStorage.getItem('userID');
//     return axios.post(process.env.REACT_APP_BASE_URL +`/home/api/content/ShowMoreDetails/${startCount}/${endCount}?category=${type}&category_id=${filter}&user_id=${userID}`, {}, auth_headers);
// };


class ContentService {

    constructor(jwtToken) {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken ? jwtToken : localStorage.getItem('jwtToken')
            }
        };
    }

    getContentList(type, filter, userID, selectedCountry, selectedLibraryid, model) {
        //const userID = localStorage.getItem('userID');
        if (selectedCountry == undefined) {
            selectedCountry = ''
        }
        if (selectedLibraryid == undefined) {
            selectedLibraryid = ''
        }
        model['user_id'] = userID

        model['category_id'] = filter;
        if (!localStorage.getItem('jwtToken')) {
            throw new Error('Access Token Not Available');
        }
        return axios.post(process.env.REACT_APP_BASE_URL + `/home/api/content/ShowMoreDetails/0/10000?category=${type}&category_id=${filter}&user_id=${userID}&country=${selectedCountry}&selectedLibraryid=${selectedLibraryid}`, model, this.authHeaders);
    };

    getContentListCount(startCount, endCount, type, filter, userID, selectedCountry, selectedLibraryid, model) {
        if (selectedCountry == undefined) {
            selectedCountry = ''
        }
        if (selectedLibraryid == undefined) {
            selectedLibraryid = ''
        }
        //const userID = localStorage.getItem('userID');
        model['user_id'] = userID
        model['category_id'] = filter
        if (!localStorage.getItem('jwtToken')) {
            throw new Error('Access Token Not Available');
        }
        return axios.post(process.env.REACT_APP_BASE_URL + `/home/api/content/ShowMoreDetails/${startCount}/${endCount}?category=${type}&category_id=${filter}&user_id=${userID}&country=${selectedCountry}&selectedLibraryid=${selectedLibraryid}`, model, this.authHeaders);
    };

    async GetLiveEvents(category_id, country, library) {
        if (country == undefined) {
            country = ''
        }
        if (library == undefined) {
            library = ''
        }
        if (!localStorage.getItem('jwtToken')) {
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/event/GetLiveEvents?category_id=' + category_id + '&country=' + country + "&library=" + library, { category_id: category_id, country: country, library: library }, this.authHeaders);
        return data.data;
    }
    async GetAllSpeakerByQuery() {
        return await axios.post(this.baseUrl + '/management/api/speaker/GetSpeakersByQueryPaginationCout', {}, this.auth_Header);
    };

}

export { ContentService }