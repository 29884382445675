import React from 'react';
import OneLogo from '../../../public/img/1.png';
import TwoLogo from '../../../public/img/2.png';
import ThreeLogo from '../../../public/img/3.png';
import FourLogo from '../../../public/img/4.png';

const Slovakia = (props) => {
    React.useEffect(() => {
        var head = document.head;
        var link1 = document.createElement("link");
        link1.type = "text/css";
        link1.rel = "stylesheet";
        link1.href = window.location.origin +"/css/landing2.css";
        head.appendChild(link1);
        // document.getElementById('link10').disabled = true
        return () => { 
            head.removeChild(link1);
        }
    }, []);
  return (
    <>
    <div className="lp-container">
      <div className="lp-header">
          <div className="inner"><span className="lilly">Lilly</span><span className="play">|</span>Play</div>
      </div>

      <div className="lp-mainContent">
          <div className="topNavBtn">
              <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonAA">Registrácia</div></a>
              <a href name="landing-Login" className="cz-login" onClick={props.passportLogin}><div className="buttonBB">Prihlásenie</div></a>
          </div>
          <div className="clearfix"></div>
          <div className="title">
              <h1 className="font2 promotional-video-title" title="CZ LillyPlay">Vitajte na Lilly Play</h1>
              <h2 className="font3 promotional-video-title2">Ponuka odborných videí pre lekára</h2>
          </div>
          <div className="clearfix"></div>
          <div className="centerBtn">
              <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonA">Registrácia</div></a>
          </div>
          <div className="clearfix"></div>
          <div className="biglogo">
              <p className="fon1"><span className="lilly">Lilly</span><span className="play">|</span><span className="colorBlack">Play</span></p>
              <p className="fon4">Natočené pre Vás</p>
          </div>
          <div className="clearfix"></div>
          <div className="paddingbox">
              <p className="font3">Pripravili sme si pre Vás zaujímavý obsah- rozhovory, video prezentácie, informačné či inštruktážne videá a záznamy diskusií. Spolupracujeme s odborníkmi z domova i zo zahraničia.</p>
          </div>
          <table>
              <tbody>
                  <tr>
                      <td className="height20">&nbsp;</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td className="fontbox2"><img className="img1" src={OneLogo} alt="" /><br /><span className="fontbox">Aktuálne témy</span><br />z Vášho odboru</td>
                      <td className="fontbox2"><img className="img2" src={TwoLogo} alt="" /><br /><span className="fontbox">Všetko na jednom mieste</span><br />jednoducho a pohodlne</td>
                  </tr>
                  <tr>
                      <td className="height20">&nbsp;</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td className="fontbox2"><img className="img3" src={ThreeLogo} alt="" /><br /><span className="fontbox">Najlepší odborníci</span><br />z domova i zo zahraničia</td>
                      <td className="fontbox2"><img className="img4" src={FourLogo} alt="" /><br /><span className="fontbox"> Videá</span><br />odborné materiály vo forme videí</td>
                  </tr>
              </tbody>
          </table>
          <div className="height30"></div>
          <div className="centerBtn">
              <a href name="landing-Register" className="cz-signup" onClick={props.passportRegister}><div className="buttonA">Zaregistrujte sa na Lilly Play</div></a>
          </div>
          <div className="height50"></div>
      </div>

      <div className="lp-footer-content">
          <footer id="myFooter">
              <div className="footer-container float_left">
                  <div className="spaceDiv"><br />&nbsp;</div>
                  <div className="footer-copyright">
                      <div className="pullLeft">Copyright © 2022 Eli Lilly and Company.&nbsp;
                        <br/>
                        Eli Lilly Slovakia s.r.o. Svätoplukova II. 18892/2 A, Bratislava - mestská časť Ružinov 821 08.
                        Táto adresa sa vzťahuje na všetky materiály prezentované na týchto stránkach a vytvorené pred zmenou sídla 1.7.2022 
                        </div>
                      <div className="pullRight">Dátum schválenia: 6/2022.&nbsp;PP-LILLY-SK-0023.&nbsp;&nbsp;</div>
                  </div>
              </div>
              <div className="height40"></div>
              <div className="navLinks">
                  <ul>
                      <li><a href="https://www.lillyprivacy.com/SK-sk/hcp" rel="noreferrer" target="_blank" >OCHRANA OSOBNÝCH ÚDAJOV A COOKIES</a></li>
                      <li><a href="https://www.lilly.sk/obchodne-podmienky" rel="noreferrer" target="_blank" >ZMLUVNÉ PODMIENKY</a></li>
                      <li><a href="https://www.lilly.sk/autorske-prava" rel="noreferrer" target="_blank" >AUTORSKÉ PRÁVA</a></li>
                      <li><a href="https://www.lilly.sk/vyhlasenie-o-pristupnosti" rel="noreferrer" target="_blank">VYHLÁSENIE O PRÍSTUPNOSTI</a></li>
                      <li><a href="https://www.lilly.sk/kontakt" rel="noreferrer" target="_blank">KONTAKT</a></li>
                      <li><a href data-triggers-cookie-settings="true" onClick={props.cookieSettingBtnHandler}>NASTAVENIA SÚBOROV COOKIE</a></li>
                  </ul>
              </div>
          </footer>
      </div>
    </div>
    </>
  )
}

export default Slovakia