import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';

const Profession = (props) => {
    const handleInput = (field, value, audience, index, profession_obj) => {
        let newProfessionsDict = {...props.professionsDict};
        newProfessionsDict[audience][index] = profession_obj;
        if(field === "profession_name") {
            profession_obj.profession_name = value;
        }
        props.SetProfessionsDict(newProfessionsDict);
    }
    const addProfession = (audience) => {
        let newProfessionsDict = {...props.professionsDict};
        let list = newProfessionsDict[audience];
        list.push({profession_name: ""});
        newProfessionsDict[audience] = list;
        props.SetProfessionsDict(newProfessionsDict);
    }
    const removeProfession = (audience, index) => {
        let newProfessionsDict = {...props.professionsDict};
        let audienceArr = newProfessionsDict[audience];
        const filteredAudienceArr = audienceArr.filter((item,i) => { return i !== index });
        newProfessionsDict[audience] = filteredAudienceArr;
        props.SetProfessionsDict(newProfessionsDict);
    }
  return (
    <>
    <Grid container spacing={2} className='content'>
        <Grid container item xs={12} lg={12} spacing={2}>
            <Grid item xs={12} lg={12}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Audience Profession Mapping</FormLabel>
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Grid container spacing={2} className='white_row audAreaOuter p-10-0'>
                    {Object.keys(props.professionsDict).length > 0 ? (Object.keys(props.professionsDict)).map((audience, i) => {
                        return (
                            <Grid item lg={2} className='audArea' key={i}>
                                <Paper className='audTxt'>{audience}</Paper>
                                {props.professionsDict[audience].length > 0 ? props.professionsDict[audience].map((profession, index) => {
                                    return(
                                        <Grid container spacing={2} key={index}>
                                            <Grid item xs={9} lg={9}>
                                                <TextField fullWidth value={profession.profession_name} onChange={(e) => handleInput('profession_name', e.target.value, audience, index, profession)} placeholder="profession name" size="small" variant="outlined" className='ml-10' InputLabelProps={{ shrink: false }} />
                                            </Grid>
                                            <Grid item xs={3} lg={3}>
                                                <button className='iconImg removeIcon' onClick={() => removeProfession(audience, index)}></button>
                                            </Grid>
                                        </Grid>
                                    )
                                }) : ""}
                                <Grid className='itemAdd' onClick={() => addProfession(audience)}>
                                    <img src={require('../../../public/img/Add@3x.png')} className='' alt='img' />
                                    <span>Add a Profession</span>
                                </Grid>
                            </Grid>
                        )
                    }) : ""}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    </>
  )
}

export default Profession