import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {CountryService} from '../Services/country-service';
const CountryDetails = (props) => {
    let countryService = new CountryService();
    React.useEffect(() => {
        if(props.country_logo_affiliate !== "" && props.country_logo_preview) {
            countryService.GetS3File(props.country_logo_affiliate, 'Banner').then((data) => {
                setImgPreview(data);
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.country_logo_affiliate, props.country_logo_preview])
    
    // Image Upload Preview 
    const [imgPreview, setImgPreview] = React.useState('');
    const imgPreviewHandler = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileName =  'Country/Banner/'+ (new Date()).getTime().toString()+"-"+file.name;
                countryService.UploadFileToS3(fileName, reader.result).then((data) => {
                    props.setcountry_logo_preview(false);
                    setImgPreview(reader.result);
                    props.setcountry_logo_affiliate(fileName)
                });
            }
        }
    }
  return (
    <>
    <Grid container className='content grey_row'>
        <Grid container item xs={12} lg={12} spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <input accept="image/*" className='hidden' id="contained-button-file" name="logo" type="file" onChange={(e) => { props.handleChange('country_logo', e.target.files[0].name); imgPreviewHandler(e.target.files[0])} }/>
                <label htmlFor="contained-button-file">
                    <Button className="upload_btn" variant="contained" color="primary" component="span">
                        {(imgPreview) ? <div className="text-center p-3" style={{display:"contents"}}><img src={imgPreview} alt="imgpreview" style={{width:"100%", height:"100%"}}/></div> : <div><div style={{textTransform:"none",fontSize:"1.1rem",fontWeight:"700",textAlign:"center"}}>Upload Country Logo</div><div style={{textTransform:"none"}}>Please upload a picture in 124px * 34px.</div></div>}
                    </Button>
                </label>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Country</FormLabel>
                    <TextField fullWidth value={props.state.country_name} onChange={(e) => props.handleChange('country_name', e.target.value)} size="small" variant="outlined" InputLabelProps={{ shrink: false }} disabled />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Domain URL</FormLabel>
                    <TextField fullWidth value={props.state.domain_url} onChange={(e) => props.handleChange('domain_url', e.target.value)} size="small" variant="outlined" InputLabelProps={{ shrink: false }} />
                </FormControl>
            </Grid>
        </Grid>
    </Grid>
    </>
  )
}

export default CountryDetails