import React from 'react';
import Breadcrumb from '../../layout/breadcrumb';


const AudienceAge = (props) => {
  const BreadcrumbData = [
    { value: 'Home', path: '/home' },
    { value: 'audience-age', path: '/audience-age' }
  ];

  return (
    <>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />

      {/* <Breadcrumb BreadcrumbData={BreadcrumbData} /> */}
      <div className='contentsArea'>
        <div className='staticPage shortWidth3'>
          <h2>Audience Age Disclaimer</h2>
          <p>This site is intended for Healthcare Professionals in the Kingdom of Saudi Arabia. It is not intended for, or designed, to attract individuals under the age of 18 years.</p>
        </div>
      </div>
    </>
  )
}

export default AudienceAge;