import React from 'react';

import LillyPlayLogo from '../../../public/img/logo-lilyplay.png';
import LillyPassportLogo from '../../../public/img/lilly-passport.jpg';
import LillyFuturoLogo from '../../../public/img/lilly-futuro.jpg';
import Carousal1Logo from '../../../public/img/carrossel-01-demand-mobile-texto.jpg';
import Carousal2Logo from '../../../public/img/carrossel-02-passport-mobile-texto.jpg';

const Germany = (props) => {
    React.useEffect(() => {
        var head = document.head;
        var link1 = document.createElement("link");
        link1.type = "text/css";
        link1.rel = "stylesheet";
        link1.href = window.location.origin +"/css/webflow.css";
        head.appendChild(link1);

        var link2 = document.createElement("link");
        link2.type = "text/css";
        link2.rel = "stylesheet";
        link2.href = window.location.origin +"/css/lilyplay.webflow.css";
        head.appendChild(link2);

        var link3 = document.createElement("link");
        link3.type = "text/css";
        link3.rel = "stylesheet";
        link3.href = window.location.origin +"/css/landing4.css";
        head.appendChild(link3);
        // document.getElementById('link10').disabled = true
        return () => { 
            head.removeChild(link1); 
            head.removeChild(link2); 
            head.removeChild(link3); 
        }
    }, []);
  return (
    <>
        <div className="navbar fixed-top">
            <div className="custom-container">
                <div className="w-row">
                    <div className="w-col w-col-2 w-col-medium-6 w-col-small-6 w-col-tiny-6">
                        <img className="logo-navbar" alt="" src={LillyPlayLogo} />
                    </div>
                    <div className="w-clearfix w-col w-col-10 w-col-medium-6 w-col-small-6 w-col-tiny-6">
                        <div className="button-list-nav"><a href className="botao-nav de-login" onClick={props.passportLogin}>KOSTENLOS ANMELDEN</a></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="header">
            <div className="custom-container video-container"></div>
        </div>
        <div className="bem-vindo">
            <div className="custom-container bem-vindo-container">
                <h1 className="titulo-bem-vindo">Seien Sie dabei - schnell und einfach.<br className="visible-xs" /> </h1>
                <p className="subtitulo-bem-vindo w-hidden-small w-hidden-tiny">Greifen Sie mit Lilly Play jederzeit und kostenlos auf exklusive und relevante Inhalte zu.</p>
                <p className="subtitulo-bem-vindo visible-xs">Greifen Sie mit Lilly Play jederzeit und kostenlos auf exklusive und relevante Inhalte zu.</p>
            </div>
        </div>
        <div className="conteudo">
            <div className="custom-container conteudo-container w-hidden-small w-hidden-tiny">
                <p className="texto-conteudo">Ausgewählte medizinische Inhalte, verschiedene Themen.</p>
                <a href className="botao-nav botao-conteudo de-login" onClick={props.passportLogin}>Kostenlos anmelden</a>
            </div>
        </div>
        <div className="ondemand w-hidden-small w-hidden-tiny">
            <div className="custom-container ondemand-container w-clearfix">
                <div className="descricao-ondemand">
                    <h1 className="titulo-ondemand">Entdecken Sie mit Lilly Play exklusive Videos auf Abruf.</h1>
                    <p className="subtitulo-ondemand">Vorträge von Experten, Diskussionen, Videos zu Studien und <br /> Forschung die immer aktuell zur Verfügung stehen.</p>
                </div>
            </div>
        </div>
        <div className="vantagens w-hidden-small w-hidden-tiny">
            <div className="custom-container vantagens-container">
                <div className="w-row">
                    <div className="coluna-passport w-col w-col-6">
                        <img className="image-2" alt="" src={LillyPassportLogo} />
                        <h1 className="titulo-vantagens">Einfach mit Ihrem Lilly Benutzerkonto anmelden.</h1>
                        <p className="subtitulo-vantagens">Ihr Lilly Benutzerkonto ist Ihr Zugang zu allen Lilly Angeboten.<br />Wählen Sie Ihre Wunschthemen aus und legen Sie los!</p>
                    </div>
                    <div className="coluna-play w-col w-col-6">
                        <img className="image-2" alt="" src={LillyFuturoLogo} />
                        <h1 className="titulo-vantagens">Lilly Play ist Ihre Quelle für Informationen.</h1>
                        <p className="subtitulo-vantagens">Nutzen Sie Lilly Play überall: Zuhause, im Büro und Unterwegs.<br /></p>
                    </div>
                </div>
            </div>
        </div>
        <div className="ondemand-vantagens visible-xs">
            <div className="slider-lilyplay">
                <div className="item-slider"><img alt="" src={Carousal1Logo} /></div>
                <div className="item-slider"><img alt="" src={Carousal2Logo} /></div>
            </div>
            <div>
                <img className="image" alt="" src={LillyFuturoLogo} />
                <h1 className="titulo-vantagens">Lilly Play ist Ihre Quelle für Informationen</h1>
                <p className="subtitulo-vantagens">Nutzen Sie Lilly Play überall: Zuhause, im Büro und Unterwegs.</p>
            </div>
        </div>
        <div className="lp-footer">
            <div className="custom-container">
                <div className="w-row">
                    <div className="w-col w-col-4 w-col-sm-3 w-hidden-small w-hidden-tiny coluna-logo-footer">
                        <img className="logo-footer" alt="" src={LillyPlayLogo} />
                    </div>
                    <div className="coluna-textos w-col w-col-8 w-col-sm-9">
                        <p className="disclaimer"></p>
                        <div className="copyright w-clearfix">
                            <p className="copyright-text">Copyright ©2019 Lilly Deutschland GmbH<br />Alle Rechte vorbehalten.</p>
                            <p className="termos">
                                <a className="link-2" href="https://www.lilly-pharma.de/impressum" rel="noreferrer" target="_blank"><span className="white">Impressum</span></a>
                                <span className="white"> | </span>
                                <a className="link" href="https://www.lillyprivacy.com/DE-de/hcp" rel="noreferrer" target="_blank"><span className="white">Datenschutz</span></a>
                                <span className="white"> | </span>
                                <a className="link-2" href="https://www.lilly-pharma.de/nutzungsbedingungen" rel="noreferrer" target="_blank"><span className="white">Nutzungsbedingungen</span></a>
                                <span className="white"> | </span>
                                <a className="link-2" href="https://www.lilly-pharma.de/barrierefreiheits" rel="noreferrer" target="_blank"><span className="white">Erklärung zur Barrierefreiheit</span></a>
                                <span className="white"> | </span>
                                <a className="link-2" href data-triggers-cookie-settings="true" onClick={props.cookieSettingBtnHandler}><span className="white"> Cookie Einstellungen</span></a><br />PP-LILLY-DE-XYZ
                            </p>
                        </div>
                    </div>
                    <div className="w-col w-col-4 w-col-sm-3 visible-xs">
                        <img className="logo-footer center-block" alt="" src={LillyPlayLogo} />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Germany