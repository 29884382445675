import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TodayIcon from '@material-ui/icons/Today';
import Calander from './Calander';

const ExpirationDate = ({ handleSelected }) => {
    const [openCalander, setOpenCalander] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [rangeDates, setRangeDates] = useState({
        'from-date': '',
        'to-date': ''
    });
    const monthsInShort = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
    ]
    useEffect(() => {
        let sdate = '';
        let edate = '';
        if (startDate != '') {
            let startingMonth = startDate.split('/')[0] - 1;
            let startingDate = startDate.split('/')[1];
            let startingYear = startDate.split('/')[2];
            sdate = new Date(startingYear, startingMonth, startingDate);
        }
        if (endDate != '') {
            let endingMonth = endDate.split('/')[0] - 1;
            let endingDate = endDate.split('/')[1];
            let endingYear = endDate.split('/')[2];
            edate = new Date(endingYear, endingMonth, endingDate);
        }
        setRangeDates({
            'from-date': sdate,
            'to-date': edate
        })
    }, [startDate, endDate])
    const comparedates = (current, compared) => {
        let sdate = '';
        let currentMonth = current.split('/')[0] - 1;
        let currentDate = current.split('/')[1];
        let currentYear = current.split('/')[2];
        let startingMonth = compared.split('/')[0] - 1;
        let startingDate = compared.split('/')[1];
        let startingYear = compared.split('/')[2];
        sdate = new Date(startingYear, startingMonth, startingDate);
        let cdate = new Date(currentYear, currentMonth, currentDate);
        return cdate < sdate
    }
    const handleDate = (date) => {
        if (startDate !== '' && endDate !== '') {
            let sdate = '';
            let edate = '';
            if (startDate != '') {
                let startingMonth = startDate.split('/')[0] - 1;
                let startingDate = startDate.split('/')[1];
                let startingYear = startDate.split('/')[2];
                sdate = new Date(startingYear, startingMonth, startingDate);
            }
            if (endDate != '') {
                let endingMonth = endDate.split('/')[0] - 1;
                let endingDate = endDate.split('/')[1];
                let endingYear = endDate.split('/')[2];
                edate = new Date(endingYear, endingMonth, endingDate);
            }
            let currentMonth = date.split('/')[0] - 1;
            let currentDate = date.split('/')[1];
            let currentYear = date.split('/')[2];
            let cdate = new Date(currentYear, currentMonth, currentDate);
            if (cdate < sdate) {
                setStartDate(date);
            } else if (cdate > edate) {
                setEndDate(date);
            } else {
                setEndDate(date);
            }
        }
    }
    const handleSubmit = () => {
        setOpenCalander(false);
        handleSelected(rangeDates);
    }
    return (
        <>
            <div>
                <div>
                    <label className='expiration-label' >Expiration Date</label>
                    <div className='expiration-modal-button' onClick={() => setOpenCalander(true)}>
                        <TodayIcon />
                        {rangeDates['from-date'] === '' || rangeDates['to-date'] === '' ?
                            <span>
                                Select Expiration Date
                            </span> :
                            `${monthsInShort[rangeDates['from-date'].getMonth()]},${rangeDates['from-date'].getDate()} ${rangeDates['from-date'].getFullYear()}
                                - ${monthsInShort[rangeDates['to-date'].getMonth()]},${rangeDates['to-date'].getDate()} ${rangeDates['to-date'].getFullYear()}`
                        }
                    </div>
                </div>
            </div>
            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "700px"
                        },
                    },
                }}
                open={openCalander}>
                <DialogContent>
                    <div className='expiration-dialog'>
                        <Calander
                            startDate={startDate}
                            endDate={endDate}
                            onDateSelect={(value) => {
                                startDate === '' ? setStartDate(value) :
                                    (endDate === '' ? (comparedates(value, startDate) ?
                                        setStartDate(value) : setEndDate(value)) : handleDate(value))
                            }}
                            dateLabel="From Date"
                            disabled={false}
                        />
                        <Calander
                            preselected={1}
                            startDate={startDate}
                            endDate={endDate}
                            onDateSelect={(value) => {
                                startDate === '' ? setStartDate(value) :
                                    (endDate === '' ? (comparedates(value, startDate) ?
                                        setStartDate(value) : setEndDate(value)) : handleDate(value))
                            }}
                            dateLabel="To Date"
                            disabled={startDate === ''}
                        />
                    </div>

                </DialogContent>
                <DialogActions className='expiration-dialog-actions'>
                    <div className='selected-dates'>
                        <div>  {rangeDates['from-date'] === '' ? '' :
                            `${rangeDates['from-date'].getDate()} ${monthsInShort[rangeDates['from-date'].getMonth()]} ${rangeDates['from-date'].getFullYear()}`
                        } </div>
                        <div>  {rangeDates['to-date'] === '' ? '' :
                            `${rangeDates['to-date'].getDate()} ${monthsInShort[rangeDates['to-date'].getMonth()]} ${rangeDates['to-date'].getFullYear()}`
                        } </div>
                    </div>
                    <div className='action-buttons'>
                        <button
                            className='modal-cancel'
                            onClick={() => {
                                setStartDate('');
                                setEndDate('');
                            }}> Reset Date </button>
                        <button
                            className='modal-cancel'
                            onClick={() => {
                                setStartDate('');
                                setEndDate('');
                                setOpenCalander(false);
                            }}> Cancel </button>
                        <button onClick={() => { handleSubmit() }}> Apply </button>
                    </div>
                </DialogActions>
            </Dialog>
        </>

    )
}

export default ExpirationDate