import React, { forwardRef } from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Multiselect from './Multiselect';
import CustomAccordain, { CustomCheckbox } from './Accordion';
import { ContentService } from '../Service/content-service';
import TherapeuticArea from './TherapeuticArea';

const ContentCategory = forwardRef(({categoryTree, onCategorySelect, handleChange, errors, brandIndicationErrorMessage, updateBrandIndicationErrorMessage, brandIndicationRef, ...props}, ref) => {
    const [allLibrary, SetallLibrary] = React.useState([]);
    const [selectedlibrary, Setselectedlibrary] = React.useState([]);
    const [allBrandIndications, SetAllBrandIndications] = React.useState([]);
    const [brands, SetBrands] = React.useState([]);
    const [selectedBrands, setSelectedBrands] = React.useState([]);
    const [indications, SetIndications] = React.useState([]);
    const [selectedIndications, setSelectedIndications] = React.useState([]);
    const [flagBrandIndication, setflagBrandIndication] = React.useState(false)

    const handlerLibraryCheckbox = (event, data) => {
        if (event.target.checked) {
            props.SetLibraries([...props.libraries, event.target.value])
        } else {
            var array = [...props.libraries];
            const filteredArray = array.filter((item) => {
                if (item === event.target.value) {
                    return false;
                }
                return true;
            });
            props.SetLibraries(filteredArray);
        }
    }
    React.useEffect(() => {
        let service = new ContentService();
        service.GetAllLibrary().then((data) => {
            SetallLibrary(data.data)
        })
        GetAllDetails();
    }, []);



    React.useEffect(() => {
        let list = []
        if (allLibrary.length > 0 && props.affiliates.length > 0) {
            for (let j = 0; j < props.affiliates.length; j++) {
                let key = [];
                let arr = [];
                key['name'] = props.affiliates[j].country_name
                if (props.affiliates[j].library_active === 3) {
                    for (let i = 0; i < allLibrary.length; i++) {
                        if (allLibrary[i].status) {
                            if (allLibrary[i].country_code === props.affiliates[j].country_code) {
                                const obj = { id: allLibrary[i].library_id, name: allLibrary[i].library_name }
                                arr.push(obj)
                            }
                            key['list'] = arr
                        }
                    }
                }
                list.push(key)
            }
            Setselectedlibrary(list)
        } else {
            Setselectedlibrary([])
        }

    }, [props.affiliates, allLibrary]);

    React.useEffect(() => {
        // This changes is to empty selected brands on country change
        if(props.selectedBrands.length === 0 && selectedBrands.length !== 0) {
            setSelectedBrands([]);
        }
    }, [props.selectedBrands]);

    React.useEffect(() => {
        // This changes is to empty selected indications on country change
        if(props.selectedIndications.length === 0 && selectedIndications.length !== 0) {
            setSelectedIndications([]);
        }
    }, [props.selectedIndications]);

    React.useEffect(() => {
        if (!flagBrandIndication && props.id) {
            GetAllDetails();
        }
    }, [props.taxBrandInd]);

    const GetAllDetails = () => {
        let service = new ContentService();
        service.GetAllDetails().then((data) => {
            let jsondata = data.data;
            let unique = [...new Map(jsondata.map((item) => [item["ibu_brand_l1"], item])).values()];
            SetAllBrandIndications(jsondata)
            SetBrands(unique)
            if (props.taxBrandInd.length && jsondata.length) {
                let matchingItems = [];
                let matchingItemsIndication = []
                props.taxBrandInd.forEach(item1 => {
                    jsondata.forEach(item2 => {
                        if (item1.product_id === item2.product_group_id && item1.indication_id === item2.indication_id) {
                            matchingItems.push(item2)
                        }
                        if (item1.product_id === item2.product_group_id) {
                            matchingItemsIndication.push(item2)
                        }
                    })
                })
                let unique_brands = [];
                for (let i = 0; i < matchingItems.length; i++) {
                    let item = matchingItems[i];
                    if (!(unique_brands.some(x => x.product_group_id == item.product_group_id))) {
                        unique_brands = [...unique_brands, matchingItems[i]]
                    }
                }
                setSelectedBrands(unique_brands.map(x => ({ label: x.ibu_brand_l1, key: x.product_group_id })))
                props.setCheckBrand(unique_brands.map(x => ({ label: x.ibu_brand_l1, key: x.product_group_id })));
                let unique_indications = [];
                for (let i = 0; i < matchingItemsIndication.length; i++) {
                    let item = matchingItemsIndication[i];
                    if (!(unique_indications.some(x => x.indication_id == item.indication_id))) {
                        unique_indications = [...unique_indications, matchingItemsIndication[i]]
                    }
                }
                SetIndications(unique_indications)
                setSelectedIndications(matchingItems.map(x => ({ label: x.ibu_indication_l1, key: x.indication_id, product_id: x.product_group_id })))
                props.setCheckIndication(matchingItems.map(x => ({ label: x.ibu_indication_l1, key: x.indication_id, product_id: x.product_group_id })))
                setflagBrandIndication(true)
            }
        }).catch((err) => {});
    }

    const handleSelectedBrands = (value) => {
        setSelectedBrands(value)
        handleChange('selectedBrands', value)
        props.setCheckBrand(value)
        const selectedValueLabel  = value.map((e) => e.label)
        const allBrandIndicationsCopy = JSON.parse(JSON.stringify(allBrandIndications))
        const filtered = allBrandIndicationsCopy.filter((e) => selectedValueLabel.includes(e.ibu_brand_l1))
        SetIndications(filtered)
        const filrr = selectedIndications.filter((e) => (filtered.map((e) => e.product_group_id).includes(e.product_id)))
        setSelectedIndications(filrr)
        handleChange('selectedIndications', filrr)
        props.setCheckIndication(filrr);
        const list = JSON.parse(JSON.stringify(props.taxBrandInd));
        const listJson = list.filter((e) => value.map((e) => e?.key !== undefined ? e.key : e.product_id).includes(e.product_id));
        handleChange('taxBrandInd', listJson);
        updateBrandIndicationErrorMessage('');
    }

    const handleSelectedIndications = (value) => {
        setSelectedIndications(value)
        props.setCheckIndication(value);
        handleChange('selectedIndications', value)
        handleChange('taxBrandInd', value.map(item => ({
            product_id: item.product_id,
            indication_id: item.key,
        })));
        updateBrandIndicationErrorMessage('');
    }

    return (
        <>
            <Grid container className="content-accordian">
                <Grid className='content-field header_taxanomy' item md={12} sm={12}>
                    <FormControl component="fieldset">
                        <label className='input-label mandatory' ref={brandIndicationRef} tabIndex={-1}>
                            IBU Taxonomy Brand and Indications (Not visible for External Users)</label>
                    </FormControl>
                </Grid>
                <Grid className='content-field' item md={6} sm={12}>
                    <Multiselect
                        title={'Brand'}
                        disabled={!props.enableFields}
                        tags={selectedBrands}
                        open={true}
                        list={brands.length > 0 ? brands.map(x => ({ label: x.ibu_brand_l1, key: x.product_group_id })) : []}
                        handleSelected={(value) => { handleSelectedBrands(value) }}
                        error={!!errors.selectedBrands}
                        ref={(el) => (ref.current['selectedBrands'] = el)} />
                    {!!errors.selectedBrands && <FormHelperText error>Brand is required.</FormHelperText>}
                </Grid>
                <Grid className='content-field' item md={6} sm={12}>
                    <Multiselect
                        title={'Indication'}
                        disabled={!props.enableFields}
                        tags={selectedIndications}
                        open={true}
                        list={indications.length > 0 ? indications.map(x => ({ label: x.ibu_indication_l1, key: x.indication_id, product_id: x.product_group_id })) : []}
                        handleSelected={(value) => { handleSelectedIndications(value) }}
                        error={!!errors.selectedIndications}
                        ref={(el) => (ref.current['selectedIndications'] = ref.current['taxBrandInd'] = el)}  />
                    {(!!errors.selectedIndications || !!errors.taxBrandInd) && <FormHelperText error>Indication is required.</FormHelperText>}
                    {!!brandIndicationErrorMessage && <FormHelperText error>{brandIndicationErrorMessage}</FormHelperText>}
                </Grid>
                <TherapeuticArea categoryTree={categoryTree} errors={errors} onCategorySelect={onCategorySelect} enableFields={props.enableFields} ref={ref}/>
                <Grid className='content-field' item md={12} sm={12}>
                    <label className='input-label'>
                        Select Library
                    </label>
                    {selectedlibrary.length > 0 ? selectedlibrary.map((item, index) => (
                        <CustomAccordain disabled={!item.list} name={item.name} key={item.name}>
                            <div className='accordian-content'>
                                {item.list && item.list.map((data, id) => (
                                    <FormControlLabel
                                        key={data.id}
                                        control={<CustomCheckbox
                                            className='checkbox'
                                            checked={props?.libraries?.includes(data.id)}
                                            onChange={(e) => handlerLibraryCheckbox(e, data)}
                                            disabled={!props.enableFields}
                                            value={data.id}
                                            name={data.name} />}
                                        label={data.name}
                                    />
                                ))}
                            </div>
                        </CustomAccordain>
                    )) : ""}
                </Grid>
            </Grid>
        </>
    )
});

export default ContentCategory