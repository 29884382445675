import axios from 'axios';

class CateogryService {
    constructor(jwtToken) {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.key = '';
        this.auth_Header = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken ? jwtToken : localStorage.getItem('jwtToken')
            }
        };
    }
    async GetAllCategory() {
        return axios.post(this.baseUrl + '/management/api/category/GetAllCategory?del=false', {}, this.auth_Header);
    }
    async UpdateCategory(data) {
        return axios.post(this.baseUrl + '/management/api/category/UpdateCategory', data, this.auth_Header);
    };
    async DeleteCategory(data) {
        return axios.post(this.baseUrl + '/management/api/category/DeleteCategory', data, this.auth_Header);
    };
}

export { CateogryService }