import React from 'react';
import videoThumb from '../../public/img/video.png';
import { HomeService } from '../home/Service/homeService';

const Thumbnail = (props) => {
    const validURL = (str) => {
        //     let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        //         '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        //         '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        //         '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        //         '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        //         '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        //     return !!pattern.test(str);
        try {
            new URL(str);
            // console.log("URL:",URL)
            return true;
        }
        catch (err) {
            return false;
        }

    }

    const [thumbnailImage, SetThumbnailImage] = React.useState('');
    React.useEffect(() => {
        let service = new HomeService();
        let content_thumbnail = props.content.user_thumbnail;
        if (props.content.content_type === 'playlist') {

            console.log("thumbnail", validURL(props.content.thumbnail))
            if (!validURL(`'${props.content.thumbnail}'`)) {

                content_thumbnail = props.content.thumbnail;
            }
        }
        if (content_thumbnail) {
            if (content_thumbnail.length > 0) {
                service.GetImageFromURL(content_thumbnail).then((data) => {
                    if (data) {
                        SetThumbnailImage(data);
                    }
                })
            } else {
                if (props.content.thumbnail) {
                    SetThumbnailImage(props.content.thumbnail);
                }
            }
        } else {
            if (props.content.thumbnail) {
                SetThumbnailImage(props.content.thumbnail);
            }
        }
    }, [props.content.user_thumbnail, props.content.thumbnail, props.content.content_type]);
    return (
        <>
            {thumbnailImage && <img src={thumbnailImage.length > 0 ? thumbnailImage : videoThumb} 
            className={props?.page == 'related-content' ? 'related-content-image simg-lg'
                : props.page == 'carousel-slider' ? 'carousel-img simg-lg'
                    : props.page == 'content-list' ? 'content-list simg-lg' : 'simg-lg'} alt='img' />}
        </>
    )
}
export default Thumbnail