import React, { useState, useEffect } from 'react';
import Link from '@material-ui/core/Link';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TelegramIcon from '../../../public/img/send-01-red.svg';
import DoneIcon from '@material-ui/icons/Done';
import { useNavigate } from "react-router-dom";
import { ContentService } from '../Service/content-service';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


const ContentPublishModal = ({ props, open, handleClose, content, onCloseRefresh, onSubmitApi, handleMainSubmit, handlebtnInactivate }) => {
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarType, setSnackbarType] = React.useState('');
    const [snackbarMsg, setSnackbarMsg] = React.useState('');

    let service = new ContentService();

    const GetUsers = (country) => {
        let emailList = [];
        let contentUrl = window.location.origin + '/content-update/' + content.id;
        service.GetAllUsers().then((data) => {
            let jsondata = data.data;
            let arr = []
            for (let i = 0; i < jsondata.length; i++) {
                if (jsondata[i].lilly_id != null && jsondata[i].lilly_id !== ''
                    && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
                    jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
                    jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name}(${jsondata[i].lilly_id})`
                    arr.push(jsondata[i])
                }
            }

            let ownerId = content.owner;
            let secondOwnerId = content.secondary_owner;
            for (let i = 0; i < jsondata.length; i++) {
                if (jsondata[i].id === ownerId) {
                    emailList.push(jsondata[i].user_email)
                }
                if (jsondata[i].id === secondOwnerId) {
                    emailList.push(jsondata[i].user_email)
                }
            }
            let model = {
                email_list: emailList,
                title: content.title,
                status: 'Published',
                country: content.content_approved[country]?.name,
                created: content.created,
                link: contentUrl,
            }
            service.SendEmailToOwnerSecondaryOwner(model);
        }).catch((err) => {});
    }
    const handleSubmit = () => {
        if (content && content.vvpm_details) {
            if (Object.keys(content.vvpm_details).length > 0) {
                const keylist = Object.keys(content.content_approved);
                
                // loop through keylist in content.vvpm_details
                let validation = true;
                let datevaladition = true;
                // let vvpmvalidationarray = keylist.map(countrycode => content.vvpm_details[countrycode].status);
                keylist.forEach(countrycode => {
                    if (content.vvpm_details[countrycode]) {
                        // if existing country has rows
                        if (content.vvpm_details[countrycode]?.rows.length === 0) {
                            validation = false;
                        } else {
                            if (new Date(content.vvpm_details[countrycode]?.from) > new Date(content.vvpm_details[countrycode]?.to))
                                datevaladition = false;
                        }
                    } else {
                        validation = false;
                    }
                })
                if (!validation) {
                    setSubmitted(false);
                    setSnackbarMsg(`There are some mandatory fields missing: VVPM Document`);
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                    return
                }
                if (!datevaladition) {
                    setSnackbarMsg(`VVPM Document invalid: Date Expired`);
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                    return
                }
            } else {
                setSubmitted(false);
                setSnackbarMsg(`There are some mandatory fields missing: VVPM Document`);
                setSnackbarType('error');
                setSnackbarOpen(true);
                return
            }
        }
        if (onSubmitApi) {
            // send email
            Object.keys(content.content_approved).forEach(x => {
                GetUsers(x);
            })
            // LILLYPLAY-3579 start
            let model_data = content.content_approved;
            // Iterate through each country object
            for (let countryCode in model_data) {
                if (model_data.hasOwnProperty(countryCode)) {
                    // Remove the 'prevstatus' property from each country object
                    delete model_data[countryCode].prevstatus;
                }
            }
            content.content_approved = model_data;
            // LILLYPLAY-3579 end
            service.UpdateContentStatus({
                'content_id': content.id,
                'country_code': Object.keys(content.content_approved)[0],
                'content_approved': JSON.stringify(content.content_approved),
                'status': 'Active', // Ensure that backed only recieves Active code never published
                'updated_on': new Date()
            }).then((data) => {
                if (data.status == 200) {
                    setSubmitted(true)
                    handlebtnInactivate && handlebtnInactivate(true)
                }
            }).catch((err) => {
                handlebtnInactivate && handlebtnInactivate(false)
            });
        } else {
            // if content does not have vvpm details 
            handleMainSubmit()
        }
    }
    useEffect(() => {
        if (submitted) {
            // const modaltimeout = 
            setTimeout(() => {
                closeModal();
            }, 3000);
            // clearTimeout(modaltimeout)
        }
    }, [submitted]);

    const closeModal = () => {
        setSubmitted(false);
        handleClose(false);
        if (onCloseRefresh)
            window.location.reload(true);
    }
    return (
        <>
            <Snackbar
                open={snackbarOpen}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={6000}
                onClose={() => { setSnackbarOpen(false) }}>
                <Alert
                    className={`snackbar ${snackbarType}`}
                    onClose={() => { setSnackbarOpen(false) }}
                    severity={`${snackbarType}`}>
                    {snackbarMsg}
                </Alert>
            </Snackbar>
            <Dialog open={open} className='status-modal content-publish-modal' >
                {submitted ?
                    <div className='post-submission-header'>
                        <div className='content-submitted-check'>
                            <DoneIcon color="action" />
                        </div>
                        <div className='content-submitted'> Content published!</div>
                    </div> :
                    <div className='modal-heading'>
                        <div className='info-modal'>
                            <img src={TelegramIcon} alt='publosh-icon' />
                        </div>
                        <div className='modal-heading-text'>
                            <div>Publish content</div>
                            {content.country === 'single' ?
                                <div className='modal-heading-subtext'>Do you want to <span className='modal-heading-subtext-bold'>Publish</span> the following content?</div>
                                :
                                <div className='modal-heading-subtext'>To <span className='modal-heading-subtext-bold'>Publish</span> the following content proceed to Edit</div>
                            }
                        </div>
                        <div className='close-modal'>
                            <IconButton
                                aria-label="close"
                                onClick={() => { handleClose(false) }}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>}
                <DialogContent className='modal-content'>
                    {submitted ?
                        <div className='grey-text centered' >
                            <div><strong>{content.title ? content.title : ''}</strong></div>
                            <div>{content.type ? content.type.charAt(0).toUpperCase() + content.type.slice(1) : ''}</div>
                        </div> :
                        <div className='grey-text' >
                            <div><strong>{content.title ? content.title : ''}</strong></div>
                            <div>{content.type ? content.type.charAt(0).toUpperCase() + content.type.slice(1) : ''}</div>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    {content.country === 'single' ?
                        (submitted ?
                            <div className='modal-actions post-submit-actions'>
                                {/* <button onClick={() => {
                            setSubmitted(false);
                            handleClose(false);
                            window.location.reload(true);
                        }}>
                            <img src={BuildingIcon} alt='goto-content' />
                            Go to content management
                        </button> */}
                            </div>
                            :
                            <div className='modal-actions'>
                                <button className="modal-cancel" onClick={() => { handleClose(false) }}>Cancel</button>
                                <button onClick={() => { handleSubmit(true) }}>
                                    Publish
                                </button>
                            </div>
                        ) :
                        <Link className='modal-edit' onClick={() => navigate('/content-update/' + content.id)}>
                            Edit
                        </Link>
                    }
                </DialogActions>
            </Dialog>
        </>
    )

}
export default ContentPublishModal;