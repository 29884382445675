import axios from 'axios';

class userCreateService {
    constructor(jwtToken) {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken ? jwtToken : localStorage.getItem('jwtToken')
            }
        };
    }

    async GetRolesByQuery() {
        return axios.post(this.baseUrl + `/management/api/user/GetRolesByQuery`, {}, this.authHeaders);
    };

    async CreateNewUser(modal) {
        return axios.post(this.baseUrl + `/management/api/user/CreateNewUser`, modal, this.authHeaders);
    };
}

export { userCreateService }
