/* eslint-disable eqeqeq */
/* eslint-disable linebreak-style */
/* eslint-disable import/no-named-as-default */
/* eslint-disable linebreak-style */
import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import './layout.css';
import { useNavigate } from "react-router-dom";

const AppBreadcrumbs = (props) => {
    const navigate = useNavigate();
    const handleRedirect = (url,value,state) => {
        
        if(value == 'Home'){
            if(props.setFilter('All')){
                navigate('/home');
            }
            navigate('/home');

        }
        else if(state) {
            navigate(url, { state: { action: state.state.action, data: state.state.data } })
        }
        else{
            navigate(url);
        }
    }
    return (
        <div className='breadcrumb'>
            {/* Material UI Breadcrumb Section */}
            <Breadcrumbs className='brd' aria-label="breadcrumb">
                    {props.BreadcrumbData ? props.BreadcrumbData.map((data, index) => {
                        return (<Link style={{pointerEvents: index == props.disableLinkIndex ? 'none' : 'auto'}}  onClick={() => { handleRedirect(data.path,data.value,data.state); }} className='bTxt' key={index} color="textPrimary"  aria-current="page">
                           {props.translations[data.value] ? props.translations[data.value] : data.value}
                        </Link>)
                    }) : null}
            </Breadcrumbs>
            {/* End Material UI Breadcrumb Section */}
        </div>
    );
};

export default AppBreadcrumbs;


