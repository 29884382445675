import * as msal from "@azure/msal-browser";
import axios from 'axios';

const msalConfig = {
   auth: {
      clientId: process.env.REACT_APP_FEDERATE_CLIENT_ID,
      authority: process.env.REACT_APP_SSO_AUTHORITY,
      redirectUri: "/login/startlogin",
   }
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);

export const getPassportToken = (email_Id, authHeader) => {
   let body = {
      'emailID': email_Id
   }
   return axios.post( process.env.REACT_APP_PASSPORT_TOKEN_URL , body,authHeader);
};

export const getSSOToken = (email_Id, authHeader) => {
   let body = {
      'emailID': email_Id
   }
   return axios.post(process.env.REACT_APP_SSO_TOKEN_URL , body,authHeader);





































   
};
