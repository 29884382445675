import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { CountryService } from '../Services/country-service';
import EditIcon from '../../../public/img/BlueEdit@3x.png';
import AddIcon from '../../../public/img/Add@3x.png';

const Category = ({categoryTree, onCategorySelect, ...props}) => {

    // Handle Propduct Popup
    const [openProductInfo, setopenProductInfo] = React.useState(false);
    const handleOpenProductInfo = (ta_id, brand_id) => {
        setopenProductInfo(true);
        setPopupTAID(ta_id);
        setPopupBrandID(brand_id);
        let selectedPIInfo = props.piInfo.filter((el) => el.therapeutic_area_id === ta_id && el.brand_id === brand_id);
        if(selectedPIInfo.length > 0) {
            setPopupPIURL(selectedPIInfo[0].pi_url);
            setPopupISWarning(selectedPIInfo[0].is_warning);
        } else {
            setPopupPIURL('');
            setPopupISWarning('');
        }
    };
    const handleCloseProductInfo = () => {
        setopenProductInfo(false);
    };
    const [popupTAID, setPopupTAID] = React.useState('');
    const [popupBrandID, setPopupBrandID] = React.useState('');
    const [popupPIURL, setPopupPIURL] = React.useState('');
    const [popupISWarning, setPopupISWarning] = React.useState(false);
    const handleProductInfoSave = () => {
        let piInfoArr = [...props.piInfo];
        let piInfoArrNew = piInfoArr.filter((el) => {
            return (
                (el.therapeutic_area_id === popupTAID && el.brand_id !== popupBrandID) || 
                (el.therapeutic_area_id !== popupTAID && el.brand_id === popupBrandID) ||
                (el.therapeutic_area_id !== popupTAID && el.brand_id !== popupBrandID)
            )
        });
        let popupPIURLTxt = popupPIURL;
        var _CHECK_REG = /^(http:\/\/|https:\/\/|mailto:)/i;
        if (!_CHECK_REG.test(popupPIURLTxt) && popupPIURLTxt) {
            popupPIURLTxt = 'http://' + popupPIURLTxt;
        }
        let newEl = { 
            therapeutic_area_id: popupTAID,
            brand_id: popupBrandID, 
            pi_url: popupPIURLTxt, 
            is_warning: popupISWarning
        }
        piInfoArrNew.push(newEl);
        props.SetPIInfo(piInfoArrNew);
        handleCloseProductInfo();
    }
  return (
    <>
    <Modal open={openProductInfo} onClose={handleCloseProductInfo}>
        <Grid className='paper modalstyle'>
            <Grid className="modal-title">
                Product Information
                <button className='closeModal' onClick={() => handleCloseProductInfo()}></button>
            </Grid>
            <Grid class="modal-body">
                <label className='modalLabel'>PI URL :</label>
                <TextField variant="outlined" value={popupPIURL} className='modalTxt' onChange={(e) => setPopupPIURL(e.target.value)} />
                <label className='modalLabel'>Warning Message Required :</label>
                <FormControlLabel className='labelFont' control={<Checkbox name="upnext" checked={popupISWarning} color="primary" onChange={(e) => setPopupISWarning(e.target.checked)} />} />
            </Grid>
            <Grid className="modal1FoBtn modal-footer">
                <button type="button" onClick={() => handleCloseProductInfo()} className="modalBtn">Cancel</button>
                <button type="button" className="modalBtn" onClick={() => handleProductInfoSave()}>Save Changes</button>
            </Grid>
        </Grid>
    </Modal>
    <Grid container className='content'>
        <Grid container item xs={12} lg={12} spacing={2}>
            <Grid item xs={12} lg={12}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Select the Category 1 and Category 2 that will be available for your users</FormLabel>
                </FormControl>
            </Grid>
            {categoryTree.length > 0 ? categoryTree.map((category) => {
                return (
                    <Fragment key={category.id}>
                        {category?.children?.length > 0 ?
                            <Grid item xs={12} lg={3} key={category.id}>
                                <FormControl component="fieldset">
                                    {category.children.length != 0 ?
                                        <FormLabel component="label">{category.name}</FormLabel>
                                        : null}
                                    {category.children.length > 0 ? category.children.map((category1) => {
                                        let piURL = '';
                                        let selectedPIInfo = props.piInfo.filter((el) => el.therapeutic_area_id === category.id && el.brand_id === category1.id);
                                        if(selectedPIInfo.length > 0) {
                                            piURL = selectedPIInfo[0].pi_url;
                                        }
                                        return (
                                            <FormGroup key={category1.id}>
                                                <FormControlLabel className='labelFont'
                                                    control={
                                                        <Checkbox
                                                            name="upnext"
                                                            color="primary"
                                                            checked={category1.isChecked === true}
                                                            onChange={(e) => {
                                                                onCategorySelect(category1.id, e.target.checked);
                                                            }}
                                                        />
                                                    }
                                                    label={category1.name}
                                                />
                                                {category1.isChecked === true ?
                                                    <Grid className='inner_bx'>
                                                        <Grid>
                                                            <div className="piInfo">
                                                            {piURL ? 
                                                                <>
                                                                <span>{piURL}</span>
                                                                <img onClick={() => handleOpenProductInfo(category.id, category1.id)} className='editIcon' src={EditIcon} title="Edit PI Info" alt='Edit PI Info' />
                                                                </> 
                                                            :  <img onClick={() => handleOpenProductInfo(category.id, category1.id)} className='addIcon' src={AddIcon} title="Add PI Info" alt='Add PI Info' />
                                                            }
                                                            </div>
                                                        </Grid>
                                                        {category1.children.length > 0 ? category1.children.map((category2) => {
                                                            return (
                                                                <FormControlLabel className='labelFont'
                                                                    control={
                                                                        <Checkbox
                                                                            name="upnext"
                                                                            color="primary"
                                                                            checked={category2.isChecked === true}
                                                                            onChange={(e) => {
                                                                                onCategorySelect(category2.id, e.target.checked);
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={category2.name}
                                                                    key={category2.id}
                                                                />
                                                            )
                                                        }) : ""}
                                                    </Grid>
                                                    : null}
                                            </FormGroup>
                                        )
                                    }) : ""}
                                </FormControl>
                            </Grid>
                            : ""}
                    </Fragment>
                )
            }) : ""}
        </Grid>
    </Grid>
    </>
  )
}

export default Category