import { useCallback, useMemo, useState } from 'react';
import { mapSelection, updateSelection } from '../helpers/categoryTreeHelper';

const useCategoryTree = (initialState) => {
  const [categoryTree, setCategoryTree] = useState(initialState);

  // Memoize selected IDs based on the current state of the category tree
  const { selectedTAIds, selectedCat1Ids, selectedCat2Ids } = useMemo(() => {
    const selectedTAIds = [];
    const selectedCat1Ids = [];
    const selectedCat2Ids = [];

    categoryTree.forEach((item) => {
      if (item.isChecked) {
        selectedTAIds.push(item.id);
      }
      item.children?.forEach((child) => {
        if (child.isChecked) {
          selectedCat1Ids.push(child.id);
        }
        child.children?.forEach((grandchild) => {
          if (grandchild.isChecked) {
            selectedCat2Ids.push(grandchild.id);
          }
        });
      });
    });

    return {
      selectedTAIds,
      selectedCat1Ids,
      selectedCat2Ids,
    };
  }, [categoryTree]);

  // Initialize the category tree with selected IDs
  const initializeCategoryTree = useCallback((treeData, { TAIds, cat1Ids, cat2Ids }) => {
    const updatedCategoryTree = mapSelection(treeData, [
      TAIds,
      cat1Ids,
      cat2Ids,
    ]);
    setCategoryTree(updatedCategoryTree);
  }, []);

  // Handle category selection updates
  const onCategorySelect = useCallback(
    (id, isChecked) => {
      const updatedData = updateSelection(categoryTree, id, isChecked);
      setCategoryTree(updatedData);
    },
    [categoryTree]
  );

  return {
    categoryTree,
    selectedTAIds,
    selectedCat1Ids,
    selectedCat2Ids,
    initializeCategoryTree,
    onCategorySelect,
  };
};

export default useCategoryTree;
