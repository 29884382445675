import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        token: '',// localStorage.getItem('jwtToken') != null ? localStorage.getItem('jwtToken') : '',
        sessionValue: {},
        country:''// localStorage.getItem('country') != null ? localStorage.getItem('country') : '',
    },
    reducers: {
        setSession: (state, action) => {
            state.sessionValue = action;
        },
        setCountry: (state, action) => {
            state.country = action;
        }
    }
});
export const { setSession, setCountry } = sessionSlice.actions

export default sessionSlice.reducer;