import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Alert from '@material-ui/lab/Alert';
import podcastIcon from '../../../public/img/podcast.svg';
import videoThumb from '../../../public/img/video.png';
import videoplayIcon from '../../../public/img/videoPlay.svg';
import playIcon from '../../../public/img/playbtn.png';
import { useNavigate } from "react-router-dom";

const PlayList = (props) => {
    const [validationPopup, SetValidationPopup] = React.useState(false);

    const handlePlaylistVideoClick = (e, url) => {
        let element = e.target.closest('.playlist-row').getAttribute('status');
        if(element === "nextContent" && props.isSeries) {
            SetValidationPopup(true);
        } else {
            navigate(url);
        }
    }
    
    const navigate = useNavigate();
    let disabledInteractionClass = (props.isSeries) ? "disabledInteraction" : "enableInteraction";
    let active = false;
    return (
        <>
        <Modal open={validationPopup} onClose={() => SetValidationPopup(false)}>
            <Grid className='paper modalstyle validationPopup'>
                <Grid className="modal-title">
                    <button className='closeModal' onClick={() => SetValidationPopup(false)}></button>
                </Grid>
                <Grid className="modal-body">
                    <div className="m20">
                        <Alert severity="warning">This is a series and can only be played in sequence.</Alert>
                    </div>
                </Grid>
                <Grid className="modal1FoBtn modal-footer ">
                    <button type="button" onClick={() => SetValidationPopup(false)} className="modalBtn validationpopupFooter">Close</button>
                </Grid>
            </Grid>
        </Modal>
        <Grid className={disabledInteractionClass}>
        <Grid className="playlist_content-wrapper custom-playlist-title">
        <Paper className='playlist-title'>Playlist - {props.playlistTitle}</Paper>
        </Grid>
            <Grid className="playlist_content-wrapper">
                {/* <Paper className='playlist-title'>Playlist - {props.playlistTitle}</Paper> */}
                <Grid className='inner-playlist-wrapper'>
                    {props.playlistVideos ? props.playlistVideos.map((content, index) => {
                        let contentIcon = content.content_type === 'video' ? videoplayIcon : 
                                            content.content_type === 'podcast' ? podcastIcon : null;
                                           
                        let contentSpeakers = content.speakers_id ? content.speakers_id.join('') : '';
                        if(props.kalturaId === content.kaltura_id) {
                            active = true;
                        }
                        return (
                            <Grid key={index} kalturaId={content.kaltura_id} status={active ? 'nextContent' : 'prevContent'} className={props.kalturaId === content.kaltura_id ? 'playlist-row activeContent' : 'playlist-row'}>
                                <Grid className='custom-playlist-container' container spacing={2} onClick={(e)=> handlePlaylistVideoClick(e, `/content-watch/${content.kaltura_id}-${props.playlistID}/${props.playlistID}`)}>
                                    <Grid item xs={1} lg={2} sm={1}>
                                        <Grid className="playlist-serial">
                                            {props.kalturaId === content.kaltura_id ? <img src={playIcon} className='playIcon' alt='icon' width="15"/> : parseInt(index+1)}
                                        </Grid>
                                    </Grid>
                                    <Grid className='custom-image-container' item xs={6} lg={5} sm={6}>
                                        <Grid className='shared-sCover-content'>
                                            <Grid className='shared-sCoverInner-Content'>
                                                <img src={contentIcon} className='custom-shared-playlist-icon shared-svgIcon-content' alt='icon' />
                                            </Grid>
                                            <img src={content.user_thumbnail ? props.imagearray['image'+content.content_id] : content.thumbnail?(content.thumbnail.length > 0 ? content.thumbnail : videoThumb) : videoThumb} className='custom-shared-playlist-image shared-simg' alt='img' />
                                            {/* <img src={content.user_thumbnail && props.imagearray? props.imagearray['image'+content.content_id] : content.thumbnail?(content.thumbnail.length > 0 ? content.thumbnail : videoThumb) : videoThumb} className='most-recent-image simg-lg' alt='img' /> */}
                                            {/* <img src={content.thumbnail ? (content.thumbnail.length > 0 ? content.thumbnail : videoThumb) : videoThumb} className='custom-shared-playlist-image shared-simg' alt='img' /> */}
                                            {/* <img src={content.kaltura_id.user_thumbnail ? imagearray['image'+props.video.content_id] : props.video.thumbnail ? props.video.thumbnail : videoThumb} className='most-recent-image simg-lg' alt='img' /> */}

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5} lg={5} sm={5} className='shared-pl10'>
                                        <Grid><Paper className={'custom-shared-video-tag shared-'+content.content_type + 'Tag-content'}>{content.content_type}</Paper></Grid>
                                        <Grid className='custom-shared-content shared-cont-content clickableLink'>{content.title}</Grid>
                                        <Grid className='custom-shared-upName shared-upName-content clickableLink'>{contentSpeakers}</Grid>
                                        <Grid className='desc-content'>{content.descending}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }) : <Grid className='shared-cont'>No Data Found</Grid>}
                </Grid>
            </Grid>
        </Grid>
        </>
    )
}

export default PlayList