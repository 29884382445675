import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MinusIcon from '../../../public/img/minus-circle-red.svg';
import DoneIcon from '@material-ui/icons/Done';
import Link from '@material-ui/core/Link';
import { useNavigate } from "react-router-dom";
import { ContentService } from '../Service/content-service';
import { useSelector } from 'react-redux';


const ContentInactiveModal = ({ open, handleClose, content, onCloseRefresh, onSubmitApi, handleMainSubmit }) => {
    const navigate = useNavigate();
    const [submitted, setSubmitted] = useState(false);
    let selectorValue = useSelector(state => state.session);
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    let service = new ContentService();
    const GetUsers = (country) => {
        let emailList = [];
        let contentUrl = window.location.origin + '/content-update/' + content.id;
        service.GetAllUsers().then((data) => {
            let jsondata = data.data;
            let arr = []
            for (let i = 0; i < jsondata.length; i++) {
                if (jsondata[i].lilly_id != null && jsondata[i].lilly_id !== ''
                    && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
                    jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
                    jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name}(${jsondata[i].lilly_id})`
                    arr.push(jsondata[i])
                }
            }

            let ownerId = content.owner;
            let secondOwnerId = content.secondary_owner;
            for (let i = 0; i < jsondata.length; i++) {
                if (jsondata[i].id === ownerId) {
                    emailList.push(jsondata[i].user_email)
                }
                if (jsondata[i].id === secondOwnerId) {
                    emailList.push(jsondata[i].user_email)
                }
            }
            let model = {
                email_list: emailList,
                title: content.title,
                status: 'Inactive',
                country: content.content_approved[country]?.name,
                created: content.created,
                link: contentUrl,
            }
            service.SendEmailToOwnerSecondaryOwner(model);
        }).catch((err) => {});
    }
    const handleSubmit = () => {
        if (onSubmitApi) {
            Object.keys(content.content_approved).forEach(x => {
                GetUsers(x);
                if (!sessionState.sessionValue?.payload?.user.role_ids.includes(3)) {
                    var publisherEmail = [];
                    var publisherEmailFinal = [];
                    let userStr = "country_code=" + x;
                    service.GetPublishersByCountriesCodes(userStr)
                        .then((data) => {
                            let jsondata = data.data;
                            for (let i = 0; i < jsondata.length; i++) {
                                publisherEmail = [...publisherEmail, jsondata[i].user_email];
                            }
                            publisherEmailFinal = Array.from(new Set(publisherEmail))
                            if (publisherEmailFinal.length > 0) {
                                let contentUrl = window.location.origin + '/content-update/' + content.id;
                                let date = new Date();
                                var hours = date.getHours();
                                var minutes = date.getMinutes();
                                var ampm = hours >= 12 ? 'pm' : 'am';
                                hours = hours % 12;
                                hours = hours ? hours : 12; // the hour '0' should be '12'
                                minutes = minutes < 10 ? '0' + minutes : minutes;
                                var strTime = hours + ':' + minutes + ' ' + ampm;
                                const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                                let datestring = months[date.getMonth()] + " " + ("0" + date.getDate()).slice(-2) + " " + date.getFullYear() + ", " + strTime;
                                if (sessionState.sessionValue?.payload?.user) {
                                    service.SendEmail(content.title, [content.content_approved[x]?.name], datestring, contentUrl, publisherEmailFinal)
                                };
                            }
                        })
                        .catch(err =>{})
                }
                // LILLYPLAY-3579 start
                let model_data = content.content_approved;
                // Iterate through each country object
                for (let countryCode in model_data) {
                    if (model_data.hasOwnProperty(countryCode)) {
                        // Remove the 'prevstatus' property from each country object
                        delete model_data[countryCode].prevstatus;
                    }
                }
                content.content_approved = model_data;
                // LILLYPLAY-3579 end
                service.UpdateContentStatus({
                    'content_id': content.id,
                    'country_code': Object.keys(content.content_approved)[0],
                    'content_approved': JSON.stringify(content.content_approved),
                    'status': 'Inactive',
                    'updated_on': new Date()
                }).then((data) => {
                    if (data.status == 200) {
                        setSubmitted(true)
                    }
                }).catch((err) => {});
            })
        } else {
            handleMainSubmit()
        }
    }
    useEffect(() => {
        if (submitted) {
            setTimeout(() => {
                closeModal();
            }, 3000);
        }
    }, [submitted]);

    const closeModal = () => {
        setSubmitted(false);
        handleClose(false);
        if (onCloseRefresh) {
            window.location.reload(true);
        }
    }
    return (
        <Dialog open={open} className='status-modal content-publish-modal' >
            {submitted ?
                <div className='post-submission-header'>
                    <div className='content-submitted-check'>
                        <DoneIcon color="action" />
                    </div>
                    <div className='content-submitted'> Content inactivated!</div>
                </div> :
                <div className='modal-heading'>
                    <div className='info-modal'>
                        <img src={MinusIcon} alt='reject-icon' />
                    </div>
                    <div className='modal-heading-text'>
                        <div>Inactivate content</div>
                        <div className='modal-heading-subtext'>Do you want to <span className='modal-heading-subtext-bold'>Inactivate</span> the following content?</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => { handleClose(false) }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>}
            <DialogContent className='modal-content'>
                {submitted ?
                    <div className='grey-text centered' >
                        <div><strong>{content.title ? content.title : ''}</strong></div>
                        <div>{content.type ? content.type.charAt(0).toUpperCase() + content.type.slice(1) : ''}</div>
                    </div> :
                    <div className='grey-text' >
                        <div><strong>{content.title ? content.title : ''}</strong></div>
                        <div>{content.type ? content.type.charAt(0).toUpperCase() + content.type.slice(1) : ''}</div>
                    </div>
                }
            </DialogContent>
            <DialogActions>
                {content.country === 'single' ?
                    (submitted ?
                        <div className='modal-actions post-submit-actions' />
                        :
                        <div className='modal-actions'>
                            <button className="modal-cancel" onClick={() => { handleClose(false) }}>Cancel</button>
                            <button onClick={() => { handleSubmit() }}>
                                Inactive
                            </button>

                        </div>) :
                    <Link className='modal-edit' onClick={() => navigate('/content-update/' + content.id)}>
                        Edit
                    </Link>
                }
            </DialogActions>
        </Dialog>
    )

}
export default ContentInactiveModal;