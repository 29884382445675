/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import './home.css';
import CarouselSlider from '../shared/carousel';
import Breadcrumb from '../../layout/breadcrumb';
import Paper from '@material-ui/core/Paper';
import { useNavigate } from "react-router-dom";
import { HomeService, HomeServiceBaseMatchingModel } from './Service/homeService';
import MostRecent from './Components/MostRecent';
import Link from '@material-ui/core/Link';
import loaderNewGif from '../../public/img/new-loader.png';
import BannerSlider from './Components/Banner';
import Modal from '@material-ui/core/Modal';
import { GoogleAnalyticsService } from '../../GA/GoogleAnalyticsService';
import { useSelector } from 'react-redux';
import { UserProfileService } from '../user/Services/user-profile-service';
import { UserBrandService } from './Service/userBrandService';
import countryListJson from '../../CountryPassport/CountryPassport.json';
import * as jose from 'jose';
const Home = (props) => {
  let userBrandService = new UserBrandService();
  let userProfileService = new UserProfileService();
  const navigate = useNavigate();
  const slidesBanner = 3;
  const slidesVideo = 5;
  const [liveEvents, SetLiveEvents] = React.useState([]);
  const [liveEventsLoad, SetLiveEventsLoad] = React.useState(false);
  const [recentContents, SetRecentContents] = React.useState([]);
  const [recentLoad, SetRecentLoad] = React.useState(false);
  const [favoriteContents, SetFavoriteContents] = React.useState([]);
  const [populerContents, SetPopulerContents] = React.useState([]);
  const [populerSpeakersContents, SetPopulerSpeakersContents] = React.useState([]);
  const [keepWatchingContents, SetKeepWatchingContents] = React.useState([]);
  const [watchedContents, SetWatchedContents] = React.useState([]);
  const [favoriteLoad, SetFavoriteLoad] = React.useState(false);
  const [populerLoad, SetPopulerLoad] = React.useState(false);
  const [populerSpeakersLoad, SetPopulerSpeakersLoad] = React.useState(false);
  const [keepWatchingLoad, SetKeepWatchingLoad] = React.useState(false);
  const [watchedLoad, SetWatchedLoad] = React.useState(false);
  const [bannerMap, SetBannerMap] = React.useState({});
  const [bannerDictionaryOrder, SetBannerDictionaryOrder] = React.useState({});
  const [bannercheck, Setbannercheck] = React.useState(false);
  const [newLabelWatchingContent, setNewLabelWatchingContent] = React.useState([]);
  const [newLabelWatchedContent, setNewLabelWatchedContent] = React.useState([]);

  const [isPreferenceSet, SetIsPreferenceSet] = React.useState(true);
  const [sessionId, SetsessionId] = React.useState('');
  const [userId, SetUserId] = React.useState(1);

  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [taIdsArrData, SetTaIdsArrData] = React.useState([]);
  const [brandIdsArrData, SetBrandIdsArrData] = React.useState([]);
  const [indIdsArrData, SetIndIdsArrData] = React.useState([]);
  const [userBrandFilterData, SetUserBrandFilterData] = React.useState(false);
  const [brandCompletion, SetBrandCompletion] = React.useState(false);
  const [imagearray, setimagearray] = React.useState([]);

  React.useEffect(() => {
    SetSessionState(selectorValue);
  }, [selectorValue])

  /*
    Created BY: Shubham Yadav
    Created On: 16/05/2024
    @Function: getCountryDetails
    @Parameters: country_name: String
    @Return: JSON
    @statement: This function fetches the country JSON based on country name
    @API-return: Fetch all the relevant country details such as country code, name, domain url, etc. based on the country name that has been passed.
    */
  const getCountryDetails = (country_name) => {
    if (country_name !== undefined) {
        if (countryListJson.length) {
            for (var i = 0; i < countryListJson.length; i++) {
                let cntName = countryListJson[i].country_code;//.split(" ").join("").toLowerCase();
                if (cntName == country_name) {
                    localStorage.setItem('ga_code',countryListJson[i].gtm_code );
                    return countryListJson[i];
                }
            }
        }
    }
    return '';
}
  React.useEffect(() => {
    if (!localStorage.getItem('sessionValue')) {
      let uri = new URL(window.location.href);
      if (uri.pathname.includes('home')) {
        document.cookie = 'hcpDirect='+'hcpDirect'+';max-age='+5000 + ';httpOnly='+ 'true' + ';secure='+ 'true';
          let params = uri.searchParams;
          if (params) {
              let passportToken = params.get('access_token') ? params.get('access_token') : '';
              if (passportToken) {
                  document.cookie = 'hcpDirect='+'hcpDirect'+';max-age='+5000 + ';httpOnly='+ 'true'+ ';secure='+ 'true';
                  let decoded_accessToken = jose.decodeJwt(passportToken);
                        getCountryDetails(decoded_accessToken.countryCode);
                  let redirectURL = uri.pathname //+ uri.hash; // 
                  let anchorPath = params.get('ta')? params.get('ta') : null;
                  if(anchorPath){
                    redirectURL = redirectURL + '#'+anchorPath;
                  }
                  let navigatePath = '/?access_token=' + passportToken + '&url=' + redirectURL;
                  navigate(navigatePath)
                  return;
                  navigate(navigatePath)
              }
          }
      }
  } else {
    document.cookie = 'hcpDirect='+'hcpDirect'+';max-age='+5000 + ';httpOnly='+ 'true'+ ';secure='+ 'true';
      let params = (new URL(window.location.href)).searchParams;
      let hcpDirectLink = params.get('url') ? params.get('url') : '';
      if (hcpDirectLink.length > 0) {
          navigate(hcpDirectLink);
          return;
      }
  }
  let gaService = new GoogleAnalyticsService();
  let dataLayerPushModel = gaService.CreateVirtualPageViewEventModel();
  gaService.PushObjectInDatalayer(dataLayerPushModel);
    function getUserSelectedBrandData() {
      let userBrandService = new UserBrandService();
      userBrandService.getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData).then((data) => {
        SetBrandCompletion(true);
      }).catch((err) => {});
    }
    getUserSelectedBrandData()
  }, [])

  React.useEffect(() => {
    
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (props.selectorValueSession.sessionValue.payload.user) {
          if (props.selectorValueSession.sessionValue.payload.user) {
            let speciality_customization = props.selectorValueSession.sessionValue.payload?.affiliate[0]?.speciality_customization;
           
            if (speciality_customization) {
              let userTA = props.selectorValueSession.sessionValue.payload.user.therapeutic_area;
              let categories = userTA && Array.isArray(userTA) ? userTA : [];
              
              if (categories && categories?.length <= 0) {
                
                SetIsPreferenceSet(false);
              }
            }
            SetsessionId(props.selectorValueSession.sessionValue.payload.user.id)
          }
        }
      }
    }
  }, [props.selectorValueSession]);

  /*
    Created BY: Shubham Yadav
    Created On: 16/05/2024
    @Function: dateSorted
    @Parameters: value: String
    @Return: String[]
    @statement: This function fetches sorts the content array base on the updated_at date.
    @API-return: The content on the home page should be sorted based on the latest changes made to the content i.e The updated content should be visible first on the most recent page.
    */
  const dateSorted = (value) => {
    let list = [];
    let uniquer = []
    for (let i = value.length - 1; i >= 0; i--) {
      if (!uniquer.includes(value[i].content_id)) {
        list.push(value[i])
        uniquer.push(value[i].content_id)
      }

    }
    if (Array.isArray(list) && list?.length > 0) {
      const newArray = list.sort(function (first, second) {
        let a = new Date(first.updated_at);
        let b = new Date(second.updated_at);

        if (a < b) {
          return 1;
        } else if (a > b) {
          return -1;
        } else {
          return 0;
        }
      });
      return newArray
    } else {
      return list;
    }
  }

  /*
    Created BY: Shubham Yadav
    Created On: 16/05/2024
    @Function: dateSortedMostRecent
    @Parameters: value: String
    @Return: String[]
    @statement: This function fetches sorts the content array base on the created_at date.
    @API-return: The content on the home page should be sorted based on the new content that has been created to be visible first.
    */
  const dateSortedMostRecent = (value) => {
    if (Array.isArray(value) && value?.length > 0) {
      const newArray = value.sort(function (first, second) {
        let a = new Date(first.created_at);
        let b = new Date(second.created_at);

        if (a > b) {
          return -1;
        } else if (a < b) {
          return 1;
        } else {
          return 0;
        }
      });
      return newArray
    } else {
      return value;
    }
  }

  /*
    Created BY: Shubham Yadav
    Created On: 16/05/2024
    @Function: dateSortedWatch
    @Parameters: value: String
    @Return: String[]
    @statement: This function fetches sorts the content array base on the created_at date for the content that is currently being watched by the user but not completed.
    @API-return: The KEEP WATCHING section should have all the content in the order of the new created content first i.e. it should be sorted based on the created_at date.
    */
  const dateSortedWatch = (value) => {
    let list = [];
    let uniquer = []
    for (let i = value.length - 1; i >= 0; i--) {
      if (!uniquer.includes(value[i].content_id)) {
        value[i].watched_on = new Date(value[i].user_experience.created_at);
        let orderredData = value[i];
        list.push(orderredData);
        uniquer.push(value[i].content_id)
      }

    }
    list.sort((a, b) => ((b.watched_on ? new Date(b.watched_on) : new Date(8640000000000000)).getTime() - (a.watched_on ? new Date(a.watched_on) : new Date(8640000000000000)).getTime()));

    return list
  }

  const checksubmittedContent = (value) => {
    let list = [];
    if (value.length !== 0){
      for (let i = 0; i < value.length; i++){
        if(value[i].status === 'submitted'){
          list.push(value[i])
        }
      }
    }
    return list;
  }

  /*
    Created BY: Shubham Yadav
    Created On: 16/05/2024
    @Function: sortLiveEvent
    @Parameters: value: String
    @Return: String[]
    @statement: This function fetches sorts the live event array base on the event start date for the content.
    @API-return: The Live Event should be visible on the home page based on the event start date. The latest event should be visible first followed by the rest of them
    */
  const sortLiveEvent = (value) => {
    if (Array.isArray(value) && value?.length > 0) {
      const newArray = value.sort(function (first, second) {
        let a = new Date(first.eventstart);
        let b = new Date(second.eventstart);

        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        } else {
          return 0;
        }
      });
      return newArray
    } else {
      return value;
    }
  }

  /*
    Created BY: Shubham Yadav
    Created On: 16/05/2024
    @Function: GetAffiliateCodesArray
    @Parameters: value: N/A
    @Return: N/A
    @statement: This function fetches the country code based on the access level of the user.
    @API-return: N/A
    */
  const GetAffiliateCodesArray = () => {
    let list = []
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)) {
          let affiliates = props.selectorValueSession.sessionValue.payload.affiliate
          for (let i = 0; i < affiliates.length; i++) {
            if (affiliates[i]) {
              list.push(affiliates[i].country_code);
            }
          }
        }
      }
    }
    return list;
  }

  /*
    Created BY: Shubham Yadav
    Created On: 16/05/2024
    @Function: GetHomeServiceModel
    @Parameters: specialty: String,profession: String
    @Return: N/A
    @statement: Fetches the content that are only visible to the user if the user's metadata such as speaciality, profession, etc. matches with that of the content.
    @API-return: Fetches the content that are only visible to the user if the user's metadata such as speaciality, profession, etc. matches with that of the content.
    */
  const GetHomeServiceModel = (specialty = '', profession = '') => {
    let countryName = localStorage.getItem('country') ?? '';
    let localeName = localStorage.getItem('lang') ?? '';
    let loginROute = '';
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if(props.selectorValueSession.sessionValue.payload.loginroute){
          loginROute = props.selectorValueSession.sessionValue.payload.loginroute
        }
        if (props.selectorValueSession.sessionValue.payload.simulationCheck) {
          localeName = localStorage.getItem('languageSimulation') ?? '';
        }
      }
    }
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (props.selectorValueSession.sessionValue.payload.decodedToken) {
          if (props.selectorValueSession.sessionValue.payload.decodedToken) {
            let _spec = props.selectorValueSession.sessionValue.payload.decodedToken.specialty ? props.selectorValueSession.sessionValue.payload.decodedToken.specialty : "";
            let _prof = props.selectorValueSession.sessionValue.payload.decodedToken.profession ? props.selectorValueSession.sessionValue.payload.decodedToken.profession : props.selectorValueSession.sessionValue.payload.decodedToken.profession;
            let brandIdsArr = [];
            let taIdsArr = [];
            let indIdsArray = [];
            if (props.selectorValueSession.sessionValue.payload.user) {
              let userTA = props.selectorValueSession.sessionValue.payload.user.therapeutic_area;
              let categories = userTA && Array.isArray(userTA) ? userTA : [];
              let countryTAList = [];
              let countryCat1Ids = [];
              let countryCat2Ids = [];
              if(Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)){
                for(let k=0;k<props.selectorValueSession.sessionValue.payload.affiliate.length;k++){
                  if(Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].therapeutic_area_ids)){
                    countryTAList = countryTAList.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].therapeutic_area_ids);
                  }
                  if(props.selectorValueSession.sessionValue.payload.affiliate[k].speciality_customization){
                    if(Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].category_1_ids)){
                      countryCat1Ids = countryCat1Ids.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].category_1_ids);
                    }
                    if(Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].category_2_ids)){
                      countryCat2Ids = countryCat2Ids.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].category_2_ids);
                    }
                  }
                  

                }
              }
              if (categories?.length > 0) {
                for (let i = 0; categories?.length > i; i++) {
                  let ta_id = categories[i].therapeutic_area_id;
                  if(countryTAList.includes(ta_id)){
                    taIdsArr.push(ta_id);
                  }
                 
                  let brand_id = categories[i].brand_id;
                  if(countryCat1Ids.includes(brand_id)){
                    brandIdsArr.push(brand_id);
                  }
                 
                  let ind_id = categories[i].indication_id;
                  if(countryCat2Ids.includes(ind_id)){
                    if (ind_id !== null) {
                      indIdsArray.push(ind_id);
                    }
                  }
                  
                }
              }
            }
            let taArray = [];
            if (props.filterId.includes(',')) {
              taArray = props.filterId.split(',');
            } else {
              taArray.push(props.filterId);
            }
            if (props.selectorValueSession?.sessionValue?.payload?.user?.language) {
              localeName = props.selectorValueSession?.sessionValue?.payload?.user?.language;
              if (countryName == 'Switzerland') {
                if (localeName == 'de-CH') {
                  localeName = 'German'
                } else if (localeName == 'fr_CH') {
                  localeName = 'French'
                }
              } else {
                if (localeName == 'nl-BE') {
                  localeName = 'Dutch'
                } else if (localeName == 'fr-BE') {
                  localeName = 'French'
                }
              }
            }
            if (props.selectorValueSession.sessionValue.payload.simulationCheck) {

                localeName = localStorage.getItem('languageSimulation')
                if (countryName == 'Switzerland') {
                  if (localeName == 'de-CH') {
                    localeName = 'German'
                  } else if (localeName == 'fr_CH') {
                    localeName = 'French'
                  }
                } else {
                  if (localeName == 'nl-BE') {
                    localeName = 'Dutch'

                  } else if (localeName == 'fr-BE') {
                    localeName = 'French'
                  }
                }
            }

            let affiliateArray = GetAffiliateCodesArray();
            let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
            let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, _spec, _prof, taArray, 'Active', brandIdsArr, indIdsArray, '', countryName, localeName, loginROute);
            
            return model;
          }
        }
      }
    }
    let taFilter = props.filterId;
    if (typeof taFilter == 'string') {
      taFilter = taFilter.split(',')
    }
    let affiliateArray = GetAffiliateCodesArray();
    let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
    let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, specialty, profession, taFilter, 'Active', brandIdsArrData, indIdsArrData, '', countryName, localeName, loginROute);
    return model;

  }

  let homeService = new HomeService();
  React.useEffect(() => {
    async function getUserSelectedBrandData() {
      await userBrandService.getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData);
    }
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (props.selectorValueSession.sessionValue.payload.user) {
          if (props.selectorValueSession.sessionValue.payload.user && props.filterId!=''&&  props.selectedCountry!='') {
            SetPopulerLoad(false);
            SetPopulerSpeakersLoad(false);
            SetLiveEventsLoad(false);
            SetRecentLoad(false);
            SetRecentContents([]);
      
            let url = new URL(window.location.href);
            let urlHash = decodeURIComponent(url.hash);
            if (!urlHash.includes('#')) {
              let filterData = GetHomeServiceModel();
              homeService.GetMostRecentData(GetHomeServiceModel()).then((videosData) => {
                SetRecentContents(videosData);
                SetRecentLoad(false);
                SetRecentLoad(true);
              }).catch((err) => {  SetRecentLoad(true) });
            } else {
              let filteredTA = GetHomeServiceModel().ta;
              if(filteredTA.length === 1) {
                homeService.GetMostRecentData(GetHomeServiceModel()).then((videosData) => {
                  SetRecentContents(videosData);
                  SetRecentLoad(false);
                  SetRecentLoad(true);
                }).catch((err) => {  SetRecentLoad(true) });
              }
            }
      
            /**This fetches all data videos of live events contents */
            SetLiveEvents([]);
      
            homeService.GetLiveEvents(props.filterId, props.selectedCountry, props.selectedLibraryid).then((liveEventsData) => {
              SetLiveEvents(sortLiveEvent(liveEventsData));
              SetLiveEventsLoad(false);
              SetLiveEventsLoad(true);
            }).catch((err) => { SetLiveEventsLoad(true)})
      
            /**This fetches all data videos of popular speakers contents */
            SetPopulerSpeakersContents([]);
            homeService.GetPopularSpeakersContentData(GetHomeServiceModel()).then((videosData) => {
              SetPopulerSpeakersContents(dateSorted(videosData));
              SetPopulerSpeakersLoad(false);
              SetPopulerSpeakersLoad(true);
            }).catch((err) => { SetPopulerSpeakersLoad(true) });
      
            /**This fetches all data videos of popular contents */
            SetPopulerContents([]);
            homeService.GetPopularContentData(GetHomeServiceModel()).then((videosData) => {
              SetPopulerContents(dateSorted(videosData));
              SetPopulerLoad(false);
              SetPopulerLoad(true);
            }).catch((err) => { SetPopulerLoad(true); });
          }
        }
      }
    }
    getUserSelectedBrandData();
    if (props.filterId !== '') {
     

    } else {
      SetPopulerContents([]);
      SetPopulerSpeakersContents([]);
      SetLiveEvents([]);
      SetRecentContents([]);
      SetPopulerLoad(true);
      SetPopulerSpeakersLoad(true);
      SetLiveEventsLoad(true);
      SetRecentLoad(true);
    }
  }, [props.filterId, props.selectedCountry, props.selectedLibraryid, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData,props.selectorValueSession.sessionValue]);


  React.useEffect(() => {
    /**This fetches all data videos of favorite contents */
    let user_id = userId
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (props.selectorValueSession.sessionValue.payload.user) {
          if (props.selectorValueSession.sessionValue.payload.user && props.filterId!=''&&  props.selectedCountry!='') {
            user_id = props.selectorValueSession.sessionValue.payload.user.id;
            SetWatchedLoad(false);
      SetKeepWatchingLoad(false);
      SetFavoriteLoad(false);
      SetFavoriteContents([]);
      /**This fetches all data videos of Favourite contents */
      homeService.GetFavoriteContentData(props.filterId, user_id, props.selectedCountry, props.selectedLibraryid, GetHomeServiceModel()).then((videosData) => {
        SetFavoriteContents(dateSorted(checksubmittedContent(videosData)));
        SetFavoriteLoad(false);
        SetFavoriteLoad(true);
      }).catch((err) => {  SetFavoriteLoad(true); console.log('HomeTOutsideFavorite',err)});

      /**This fetches all data videos of keep watching contents */
      SetKeepWatchingContents([]);
      homeService.GetKeepWatchingContentData(props.filterId, user_id, props.selectedCountry, props.selectedLibraryid, GetHomeServiceModel()).then((videosData) => {
        SetKeepWatchingContents(dateSortedWatch(checksubmittedContent(videosData)));
        SetKeepWatchingLoad(false);
        SetKeepWatchingLoad(true);
      }).catch((err) => {  SetKeepWatchingLoad(true) });

      /**This fetches all data videos of watched contents */
      SetWatchedContents([]);

      homeService.GetWatchedContentData(props.filterId, user_id, props.selectedCountry, props.selectedLibraryid, GetHomeServiceModel()).then((videosData) => {
        SetWatchedContents(dateSortedWatch(checksubmittedContent(videosData)));
        SetWatchedLoad(false);
        SetWatchedLoad(true);
      }).catch((err) => { SetWatchedLoad(true); });
          }
        }
      }
    }
    /*
    Created BY: Shubham Yadav
    Created On: 16/05/2024
    @Function: getUserSelectedBrandData
    @Parameters: N/A
    @Return: JSON[]
    @statement: Fetched the content based on the categories such as 'ta','category1' and 'category2' that have been selected by the user in the edit profile page
    @API-return: The categories that are selected by the users in the edit profile page should reflect on the home page by the showing the users the same content as that of the categories selected.
    */
    async function getUserSelectedBrandData() {
      await userBrandService.getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData);
    }
    getUserSelectedBrandData();
    if (user_id !== 1 && props.filterId !== '') {
      
    } else {
      SetWatchedContents([]);
      SetWatchedLoad(true);
      SetKeepWatchingContents([]);
      SetKeepWatchingLoad(true);
      SetFavoriteContents([]);
      SetFavoriteLoad(true);
    }

  }, [props.filterId, props.selectorValueSession, props.selectedCountry, props.selectedLibraryid, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData,props.selectorValueSession.sessionValue])
  // To set new tag to most recent content
  React.useEffect(() => {
    let watched = [...watchedContents];
    let keepWatching = [...keepWatchingContents];
    let ifWatchedContent = [];
    let ifWatchingContent = []
    if (recentContents && recentContents.length > 0) {
      ifWatchedContent = recentContents.filter(o1 => watched.some(o2 => o1.content_id === o2.content_id));
      ifWatchingContent = recentContents.filter(o1 => keepWatching.some(o2 => o1.content_id === o2.content_id));
    }
    setNewLabelWatchedContent(ifWatchedContent);
    setNewLabelWatchingContent(ifWatchingContent);
  }, [watchedContents, keepWatchingContents]);

  React.useEffect(() => {
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (props.selectorValueSession.sessionValue.payload.affiliate) {
          if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)) {
            if (props.selectorValueSession.sessionValue.payload.affiliate.length > 0) {
              homeService.GetCountryBanner(props.selectedCountry,
                SetBannerMap, SetBannerDictionaryOrder).then((data) => {
                  Setbannercheck(true)
                }).catch((err) => {});
            }
          }
        }
      }
    }
  }, [props.selectorValueSession, props.selectedCountry])

  /**Breadcrumb for Home section */
  const [BreadcrumbData, SetBreadcrumbData] = React.useState([
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
  ]);
  const IdCreator = (id) => {
    if (id) {
      if (typeof id == 'string') {
        if (id.split(',').length > 0) {
          if (props.filter !== 'All') {
            return (id.split(',')[0]);
          } else {
            return "TA0";
          }
        }
      }
      return (id);
    }
  }
  React.useEffect(() => {
    let filterBredcrumb = [{ value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' }];
    if (props.filter !== 'All') {
      filterBredcrumb.push({
        value: props.filter, path: '/home'
      })
      SetBreadcrumbData(filterBredcrumb);
    } else {
      SetBreadcrumbData(filterBredcrumb);
    }
  }, [props.filter, props.filterId, props.translations]);

  React.useEffect(() => {
    let newArr = {};
    for (let i = 0; i < props.libraryData.length; i++) {
      if (!props.libraryData[i].staticimage) {
        // eslint-disable-next-line no-loop-func
        homeService.GetS3File(props.libraryData[i].thumbnail_url).then((data) => {
          newArr['image' + props.libraryData[i].library_id] = data;
          newArr = JSON.parse(JSON.stringify(newArr));
          setimagearray(...imagearray, newArr);
        }).catch((err) => {})
      }
    }
  }, [props.libraryData]);


  return (
    <Grid>
      <Modal open={props.openLibraryModal} >
        <Grid className='libModal paper modalstyle'>
          <Grid className="modal-title libraryTitle">
            {props.translations['Select a Library'] ? props.translations['Select a Library'] : 'Select a Library'}:
          </Grid>
          <Grid className="modal-body">
            <Grid container>

              {props.libraryData.map(text => {
                return (<>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div>
                      <ul className='uploadLiblist'>
                        <li className='uploadLiblistInner text-center'>
                          <div>
                            <div>
                              {text.staticimage ? <img onClick={(event) => {
                                props.SetselectedLibrary(text.library_id)
                              }} src={text.thumbnail_url} alt='education icon' /> : <img onClick={(event) => {
                                props.SetselectedLibrary(text.library_id)
                              }} src={imagearray['image' + text.library_id]} alt='education icon' />}
                            </div>
                            <div>
                              <label>{text.library_name}</label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </>)
              })}
            </Grid>

          </Grid>


        </Grid>
      </Modal>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
      <Grid className='outer-wrapper'>
        {/* Banner Section*/}
        {bannerDictionaryOrder !== null && bannercheck && <BannerSlider bannercheck={bannercheck} filterId={props.filterId} filter={props.filter} dictionary={bannerMap[IdCreator(props.filterId)]}
          bannerDictionaryOrder={typeof bannerDictionaryOrder[IdCreator(props.filterId)] !== 'undefined' ? bannerDictionaryOrder[IdCreator(props.filterId)] : [0, 1, 2, 3]} />
        }

        {/** Carousel Most Recent Section  */}
        <Grid className='carousel'>
          <Grid container className='home_sec_header_extra'>
            <Grid item lg={6} xs={6} sm={6}>
              <Paper className='textHd'>
                {props.translations['Most Recent Contents'] ? props.translations['Most Recent Contents'] : 'Most Recent Contents'}
              </Paper>
            </Grid>
            <Grid item lg={6} xs={6} sm={6} className='pdLft'>
              {isPreferenceSet ?
                <span onClick={() => navigate('/content-show')} className='showBtn'>
                  {props.translations['Show more'] ? props.translations['Show more'] : 'Show more'}
                </span>
                : ''}
            </Grid>
          </Grid>
          {isPreferenceSet ? recentLoad ? <MostRecent slides={slidesBanner} contentData={recentContents} liveEvents={liveEvents} filter={props.filter} newLabelWatchedContent={newLabelWatchedContent} newLabelWatchingContent={newLabelWatchingContent} translations={props.translations} />
            : <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>
            : <Grid className='notDataFound'>Oops! Looks like you haven't selected any content preferences. Please visit your <a href onClick={(e) => { navigate('/my-profile'); }}>profile</a> to make a selection</Grid>}
        </Grid>
        {/** Carousel Popular Speaker Section  */}
        {props.selectedCountry && props.selectedCountry !== 'FR' && <Grid className='carousel'>
          <Grid container className='home_sec_header'>
            <Grid item lg={6} xs={6} sm={6}>
              <Paper className='textHdSm'>
                {props.translations['Popular Speakers'] ? props.translations['Popular Speakers'] : 'Popular Speakers'}
              </Paper>
            </Grid>
            <Grid item lg={6} xs={6} sm={6} className='pdLft'>
              {isPreferenceSet ?
                <Link onClick={() => navigate('/home/popularspeaker')} className='showBtn'>
                  {props.translations['Show more'] ? props.translations['Show more'] : 'Show more'}
                </Link>
                : ''}
            </Grid>
          </Grid>
          {isPreferenceSet ? populerSpeakersLoad ? <CarouselSlider isPreferenceSet={isPreferenceSet} slides={slidesVideo} contentData={populerSpeakersContents} filter={props.filter} translations={props.translations} />
            : <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>
            : <Grid className='notDataFound'>Oops! Looks like you haven't selected any content preferences. Please visit your <a href onClick={(e) => { navigate('/my-profile'); }}>profile</a> to make a selection</Grid>}
        </Grid>}
        {/** Carousel Popular Contents  */}
        {props.selectedCountry && props.selectedCountry !== 'FR' && <Grid className='carousel'>
          <Grid container className='home_sec_header'>
            <Grid item lg={6} xs={6} sm={6}>
              <Paper className='textHdSm'>
                {props.translations['Popular Content'] ? props.translations['Popular Content'] : 'Popular Content'}
              </Paper>
            </Grid>
            <Grid item lg={6} xs={6} sm={6} className='pdLft'>
              {isPreferenceSet ?
                <Link onClick={() => navigate('/home/popular')} className='showBtn'>
                  {props.translations['Show more'] ? props.translations['Show more'] : 'Show more'}
                </Link>
                : ''}
            </Grid>
          </Grid>
          {isPreferenceSet ? populerLoad ? <CarouselSlider isPreferenceSet={isPreferenceSet} slides={slidesVideo} contentData={populerContents} filter={props.filter} translations={props.translations} />
            : <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>
            : <Grid className='notDataFound'>Oops! Looks like you haven't selected any content preferences. Please visit your <a href onClick={(e) => { navigate('/my-profile'); }}>profile</a> to make a selection</Grid>}
        </Grid>}
        {/** Carousel My Favorite Section  */}
        <Grid className='carousel'>
          <Grid container className='home_sec_header'>
            <Grid item lg={6} xs={6} sm={6}>
              <Paper className='textHdSm'>
                {/* My Favorites */}
                {props.translations['My Favorites'] ? props.translations['My Favorites'] : 'My Favorites'}
              </Paper>
            </Grid>
            <Grid item lg={6} xs={6} sm={6} className='pdLft'>
              <Link onClick={() => navigate('/home/favorite')} className='showBtn'>
                {/* Show More */}
                {props.translations['Show more'] ? props.translations['Show more'] : 'Show more'}
              </Link>
            </Grid>
          </Grid>
          {favoriteLoad ? <CarouselSlider isPreferenceSet={isPreferenceSet} slides={slidesVideo} contentData={favoriteContents} filter={props.filter} translations={props.translations} />
            : <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>}
        </Grid>
        {/** Carousel Keep Watching Section  */}
        <Grid className='carousel'>
          <Grid container className='home_sec_header'>
            <Grid item lg={6} xs={6} sm={6}>
              <Paper className='textHdSm'>
                {/* Keep Watching */}
                {props.translations['Keep Watching'] ? props.translations['Keep Watching'] : 'Keep Watching'}
              </Paper>
            </Grid>
            <Grid item lg={6} xs={6} sm={6} className='pdLft'>
              <Link onClick={() => navigate('/home/keepwatching')} className='showBtn'>
                {/* Show More */}
                {props.translations['Show more'] ? props.translations['Show more'] : 'Show more'}
              </Link>
            </Grid>
          </Grid>
          {keepWatchingLoad ? <CarouselSlider isPreferenceSet={isPreferenceSet} slides={slidesVideo} contentData={keepWatchingContents} filter={props.filter} translations={props.translations} />
            : <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>}
        </Grid>
        {/** Carousel Watched Content Section  */}
        <Grid className='carousel'>
          <Grid container className='home_sec_header'>
            <Grid item lg={6} xs={6} sm={6}>
              <Paper className='textHdSm'>
                {/* Watched Contents */}
                {props.translations['Watched Contents'] ? props.translations['Watched Contents'] : 'Watched Contents'}
              </Paper>
            </Grid>
            <Grid item lg={6} xs={6} sm={6} className='pdLft'>
              <Link onClick={() => navigate('/home/watched')} className='showBtn'>
                {/* Show More */}
                {props.translations['Show more'] ? props.translations['Show more'] : 'Show more'}
              </Link>
            </Grid>
          </Grid>
          {watchedLoad ? <CarouselSlider isPreferenceSet={isPreferenceSet} slides={slidesVideo} contentData={watchedContents} filter={props.filter} translations={props.translations} />
            : <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Home;
