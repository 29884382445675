
import React from 'react';






const EmbedWatch = (props)=> {

    React.useEffect(()=> {
        console.log('---------------EmbedWatch----------------------');
        let params = new URLSearchParams(window.location.search);
        let kalturaId = params.get('kalturaId');
        let mediaFrom = 0;
        let widgetHeight = '400px';
            if (window.innerWidth <= 520) {
                widgetHeight = '200px';
            }
            let ks_player_localisation = {
                'mwe-embedplayer-timed_text': 'Closed Captions',
                'mwe-timedtext-no-subtitles': 'No Subtitles',
                'mwe-embedplayer-player_fullscreen': 'Full screen',
                'mwe-embedplayer-player_closefullscreen': 'Exit full screen',
                'mwe-embedplayer-share': 'Share',
                'mwe-embedplayer-volume-mute': 'Mute',
                'mwe-embedplayer-volume-unmute': 'Unmute',
                'mwe-embedplayer-play_clip': 'Play clip',
                'mwe-embedplayer-pause_clip': 'Pause clip',
                'mwe-embedplayer-replay': 'Replay',
            };
            let flashvars = { // flashvars allows you to set runtime uiVar configuration overrides.
                'autoMute': false,
                // 'autoPlay': true,
                'strings': ks_player_localisation,
                'controlBarContainer': {
                    'hover': true
                },
                "share": {
                    "plug": false,
                    "plugin": false
                },
                "mediaProxy.mediaPlayFrom": mediaFrom,
            };

            let widgetSetting = {
                'targetId': 'palyer-widget',
                'wid': '_1759891',
                'uiconf_id': '48704833',
                'entry_id': kalturaId,
                'width': '100%',
                'height': widgetHeight,
                'flashvars': flashvars,
                'params': { 'wmode': 'transparent' }
            };
            widgetSetting.readyCallback = function (playerId) {
                let kdp = document.getElementById(playerId);
                kdp.kBind( 'mediaReady', function(){
                    // kdp.sendNotification("doPlay", mediaFrom);
                });
                kdp.kBind('playerUpdatePlayhead.myPluginName', function (data) {
                    // timeCurrent = parseInt(data);
                    // currentPercentage = parseInt((kdp.evaluate("{video.buffer.percent}") * 100).toFixed(2));
                });
                kdp.kBind('playerPlayed', function (data) {
                    // props.Setplay(prev => ({ ...prev, state: false, startTime: 0, kalturaID: '' }));
                });
            };
            window.kWidget.embed(widgetSetting);
    },[]);
    return (
        <>
            <div id='palyer-widget'></div>
        </>
    );
}
export default EmbedWatch