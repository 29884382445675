import React, { forwardRef } from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CustomAccordain, { CustomCheckbox } from './Accordion';
const TherapeuticArea = forwardRef(({categoryTree, onCategorySelect, enableFields, errors}, ref) => {
  return (
    <Grid className='content-field' item md={12} sm={12}>
      <label
        className='input-label mandatory'
        ref={(el) => (ref.current['selectedCategory'] = el)}
        tabIndex={-1}
      >
        Therapeutic Areas, Category 1 and Category 2
      </label>
      {!!errors.selectedCategory ? (
        <FormHelperText error>
          Please select at least one category 1
        </FormHelperText>
      ) : (
        <label className='helper-text'>Category 1 is mandatory field</label>
      )}
      {categoryTree.map((area, id) => (
        <CustomAccordain name={area.name} key={area.id} defaultExpanded={area.isChecked}>
          {area?.children && <div className='heading'>Category 1</div>}
          <div className='accordian-content'>
            {area?.children &&
              area.children.map((category1, id) => (
                <FormControlLabel
                  key={category1.id}
                  control={
                    <CustomCheckbox
                      className='checkbox'
                      checked={category1.isChecked === true}
                      onChange={(e) =>
                        onCategorySelect(category1.id, e.target.checked)
                      }
                      disabled={!enableFields}
                      value={category1.id}
                      name={category1.name}
                    />
                  }
                  label={category1.name}
                />
              ))}
          </div>
          {area?.children &&
            area.children.map((category1, id) => {
              if (!category1.isChecked) return null;
              return (
                <div key={category1.id}>
                  {category1.children.length > 0 && (
                    <div className='subcategory'>
                      <div className='heading'>
                        {category1.name} - Category 2
                      </div>
                      <div className='accordian-content'>
                        {category1.children.map((category2, i) => (
                          <FormControlLabel
                            key={category2.id}
                            control={
                              <CustomCheckbox
                                className='checkbox'
                                disabled={!enableFields}
                                checked={category2.isChecked === true}
                                onChange={(e) =>
                                  onCategorySelect(
                                    category2.id,
                                    e.target.checked
                                  )
                                }
                                value={category2.id}
                                name={category2.name}
                              />
                            }
                            label={category2.name}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </CustomAccordain>
      ))}
    </Grid>
  );
});

export default TherapeuticArea;
