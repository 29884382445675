
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import infoIcon from '../../../public/img/info-circle.svg';


const ContentTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #c7c7c7',
        padding: 0
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #c7c7c7"
        },
        color: `#c7c7c7 !important`,

    }
}));

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        border: '1px solid #ffffff',
        padding: 0,
        margin: 0
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #ffffff"
        },
        color: `#ffffff !important`,

    }
}));


const ContentStatus = ({ open, content }) => {
    const [showStatus, setShowStatus] = useState('');
    const [showTooltip, setShowTooltip] = useState(open);
    useEffect(() => {
        if (Object.keys(content).length > 0) {
            if (Object.keys(content).length > 1) {
                setShowStatus('Multiple Countries')
            } else {
                if (content[Object.keys(content)[0]].status === 'Active') {
                    setShowStatus('Published')
                } else {
                    setShowStatus(content[Object.keys(content)[0]].status)
                }
            }
        }
    }, [content])
	
    return (
        <div className='content-status'>
            {showStatus === 'Multiple Countries' ?
                <ContentTooltip
                    title={
                        <React.Fragment>
                            <div className='custom-tooltip'>
                                <div className='tooltip-headers'>
                                    <div>Country</div>
                                    <div>Status</div>
                                </div >
                                {Object.keys(content).map((x, i) => {
                                    let display = ''
                                    if (content[Object.keys(content)[i]].status === 'Active') {
                                        display = 'Published'
                                    } else {
                                        display = content[Object.keys(content)[i]].status
                                    }
                                    return (
                                        <div className='tooltip-body content-status'>
                                            <div>
                                                {content[Object.keys(content)[i]].name}
                                            </div>
                                            <div className={`single-content ${display}`}>
                                                <div className='dot' />
                                                <div className='text'>
                                                    {display}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </React.Fragment>
                    }
                    open={showTooltip}
                    onOpen={() => setShowTooltip(true)}
                    onClose={() => setShowTooltip(false)}
                    arrow
                    placement="bottom"
                >
                    <div className='multiple-content'
                        onClick={() => setShowTooltip(!showTooltip)}
                    >
                        <div className='text'>
                            Multiple Countries
                        </div>
                        <img src={infoIcon} alt='info-circle' />
                    </div>
                </ContentTooltip>
                :
                <div className={`single-content ${showStatus}`}>
                    <div className='dot' />
                    <div className='text'>
                        {showStatus}
                    </div>
                </div>}
        </div>
    )
}
export { ContentStatus, CustomTooltip }