import React from 'react';

import BannerLogo from '../../../public/img/responsive_banner.png';
import LillyPlayLogo from '../../../public/img/logo-lilyplay.png';

const Brazil = (props) => {
    React.useEffect(() => {
        let head = document.head;
        let link1 = document.createElement("link");
        link1.type = "text/css";
        link1.rel = "stylesheet";
        link1.href = window.location.origin +"/css/lilyplay.webflow.css";
        head.appendChild(link1);
    
        let link2 = document.createElement("link");
        link2.type = "text/css";
        link2.rel = "stylesheet";
        link2.href = window.location.origin +"/css/webflow.css";
        head.appendChild(link2);
        // document.getElementById('link10').disabled = true
        let link3 = document.createElement("link");
        link3.type = "text/css";
        link3.rel = "stylesheet";
        link3.href = window.location.origin +"/css/landing1.css";
        head.appendChild(link3);

        return () => { 
            head.removeChild(link1); 
            head.removeChild(link2); 
            head.removeChild(link3);
        }
    }, []);

  return (
    <>
        <div className="navbar">
            <div className="custom-container">
                <div className="w-row">
                    <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
                        <img src={LillyPlayLogo} alt="Lilly Play" className="logo-navbar" />
                        <p className="newLogo">Lilly <span>| Play</span></p>
                        <small>O Conteúdo da Ciência</small>
                    </div>
                    <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
                        <div className="button-list-nav">
                            <a href name="landing-Login" className="botao-nav au-login" onClick={props.passportLogin}>Acessar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="mainContent">
            <div className="mainBanner">
                <div className="lp-content">
                    <h3>Bem-vindo(a) a uma forma rápida e prática de se atualizar.</h3>
                    <img className="banner_mobile" src={BannerLogo} alt="banner_mobile" />
                    <p><span>Lilly Play</span> é uma plataforma de vídeos com conteúdos científicos, para acessar em <span>qualquer lugar, a qualquer hora, e tudo gratuito.</span></p>
                    <div className="button-list-nav">
                        <a href name="landing-Register" className="botao-nav br-signup" onClick={props.passportRegister}>Cadastre-se Já</a>
                    </div>
                    <span className="login">Já tem cadastro? <a href name="landing-Login" className="br-login loginLink" onClick={props.passportLogin}>Acesse aqui</a></span>
                </div>
                <div className="banner"><img src={BannerLogo} alt="banner" /></div>
            </div>
            <div className="featureList">
                <div className="feature first">
                    <h3>Uma plataforma on demand para acessar vídeos exclusivos.</h3>
                    <p>Palestras com especialistas, debates, estudos e pesquisas, com conteúdo que será sempre atualizado e sem
                    publicidade.</p>
                </div>
                <div className="feature second">
                    <h3>LillyPlay é a sua conexão com o futuro da informação.</h3>
                    <p>Assista de onde quiser: celular, tablet ou computador.</p>
                </div>
            </div>
        </div>

        <div className="lp-footer">
            <div className="custom-container">
                <div className="w-row">
                    <div className="w-col w-col-4 w-col-sm-3 w-hidden-small w-hidden-tiny coluna-logo-footer">
                        <img src={LillyPlayLogo} className="logo-footer" alt="Lilly Play" />
                    </div>
                    <div className="coluna-textos w-col w-col-8 w-col-sm-9">
                        <p className="disclaimer">Você deve estar ciente que este site não pretende, nem foi desenhado para atrair indivíduos abaixo de 18 anos. Nós não coletamos informações pessoais identificáveis de qualquer pessoa abaixo de 18 anos de idade.</p>
                        <div>
                            <p className="copyright-text">
                                <span className="white">Copyright ©2019-2021 Eli Lilly and Company.<br />Todos os direitos reservados.</span>
                            </p>
                            <p className="termos">
                                <a href="https://www.lilly.com.br/termos-de-uso" className="link-2" rel="noreferrer" target="_blank"><span className="white">Termos de Uso</span></a>
                                <span className="white"> | </span>
                                <a href="https://www.lillyprivacy.com/BR-pt/hcp" className="link" rel="noreferrer" target="_blank"><span className="white">Política de Privacidade</span></a>
                                <span className="white"> | </span>
                                <a href="https://www.lilly.com.br/declaracao-de-acessibilidade" className="link" rel="noreferrer" target="_blank"><span className="white">Declaração de Acessibilidade</span></a>
                                <span className="white"> | </span>
                                <a href className="link" data-triggers-cookie-settings="true" onClick={props.cookieSettingBtnHandler}><span className="white">Ajustes de cookie</span></a>              
                                <br />
                                PP-LILLY-BR-0314 - 11/2021
                            </p>
                        </div>
                    </div>
                    <div className="w-col w-col-4 w-col-sm-3 visible-xs">
                        <img src={LillyPlayLogo} className="logo-footer center-block" alt="Lilly Play" />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Brazil