import axios from 'axios';

class QuizService {

    constructor(jwtToken)
    {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken?jwtToken:localStorage.getItem('jwtToken')
            }
        };
    }
 
    async GetAllQuiz(obj) {
        if(obj){
            return axios.post(this.baseUrl +`/management/api/quiz/GetAllQuiz`, obj, this.authHeaders);
        }
        else{
            return axios.post(this.baseUrl +`/management/api/quiz/GetAllQuiz`, {}, this.authHeaders);
        }
    };

    async DeleteQuiz(data) {
        return axios.post(this.baseUrl +`/management/api/quiz/DeleteQuiz`,{ quiz_id:data }, this.authHeaders);
    };
    
    async UpdateQuiz(data) {
        return axios.post(this.baseUrl +`/management/api/quiz/UpdateQuiz`,data, this.authHeaders);
    };
    async GetAllContent(param) {
        if(param) {
            return await axios.post(this.baseUrl + '/content/api/video/GetVideosByQueryCountData?'+param, {}, this.authHeaders);
        }
        else {
            return await axios.post(this.baseUrl + '/content/api/video/GetVideosByQueryCountData?category_id=All', {}, this.authHeaders);
        }
        
    };
}

export {QuizService}