/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { ContentService } from '../Service/ContentService';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import QuizPopupModal from '../components/QuizPopupModal';
import './AttemptQuiz.css';

function AttemptQuiz(props) {
    const modalFunc = React.useRef(null);
    const [QnA,SetQnA] = React.useState([]);
    const [staticQnA,SetstaticQnA] = React.useState([]);
    const [quizTitle,SetquizTitle] = React.useState('');
    const [scoreToPass,SetscoreToPass] = React.useState(0);
    const [successMessage,SetsuccessMessage] = React.useState('');
    const [failureMessage,SetfailureMessage] = React.useState('');
    const [sendDataToModal, setSendDataToModal] = React.useState("");
    const [startedDate, SetstartedDate] = React.useState(new Date());
    const [quizDescription,SetquizDescription] = React.useState('');
    const [words,setWords] = React.useState(["A","B","C","D","E","F","G","H"]);
    const [affiliates, SetAffiliates] = React.useState([]);
    const [anySelection, SetAnySelection] = React.useState(false);
  React.useEffect(() => {
        let service = new ContentService();
        let obj = {"quiz_id":props.quizId}
        service.GetAllQuiz(obj).then((data) => {
                let result = data.data[0];
                SetquizTitle(result.quiz_title);
                SetquizDescription(result.quiz_description);
                SetscoreToPass(result.passing_score);
                SetsuccessMessage(result.success_msg);
                SetfailureMessage(result.error_msg);
                //stati = result.questions
                SetstaticQnA(result.questions);
                //SetQnA(result.questions);
                // let mainData = [...QnA];
            
                
                //SetstaticQnA(result.questions);
                
           

            })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        let checkMainData = [...QnA];
        let isQuizEmpty = true;
        var count = 0;
        if(checkMainData) {
            for(let i=0; i<checkMainData.length; i++){
                if (checkMainData[i].answers) {
                    for (let mainQuizData of checkMainData[i].answers) {
                        if (mainQuizData.correct === true) {
                            count +=1;
                        }
                    }
                }
            }
        }
        if (count === checkMainData.length){
            isQuizEmpty = false;
        }
        SetAnySelection(!isQuizEmpty)
    }, [QnA]);

    const editCorrect = (index,subindex,newValue) => {
       
        let mainData = [...QnA];
        mainData[index].answers[subindex].correct = newValue;
        SetQnA(mainData);
    }

    const handlePassFail = async (mainSet,answeredSet) => {
        let service = new ContentService();
        let totalCorrect = 0;
        let totalMarks = Number.parseInt(mainSet.length);
        let msg = "";
        for(let i=0;i<mainSet.length;i++){
            let correctAns = false;
            for(let j=0;j<mainSet[i].answers.length;j++){
            if(mainSet[i].answers[j].correct === answeredSet[i].answers[j].correct )
                    correctAns = true;
            else {
                    correctAns = false;
                    break;
            }
        }   
        if(correctAns === true)
            totalCorrect += 1;
        }
        let finalScore = Math.round((totalCorrect/totalMarks)*100);

        let model = {
            quiz_id: props.quizId,
            quiz_title: quizTitle,
            okta_id: props.oktaId,
            content_id: props.contentID,
            quiz_score: finalScore,
            total_questions: totalMarks,
            correct_ans: totalCorrect,
            started_at: startedDate
        }
        if(props.isPlaylist){
            model.module_name = "Playlist";
        }
        else{
            model.module_name = "Content";
        }
        if(finalScore>=scoreToPass){
            service.UpdateQuizAttempts(model);
            msg = successMessage
            let date_of_completion = startedDate;
            let quiz_title = quizTitle;
            let content_title = props.contentTitle;
            let okta_id = props.oktaId;
            let country_name = '';
            if (localStorage.getItem('country')) {
                country_name = localStorage.getItem('country');
            }
            let hcp_email = props.hcpEmail;
            let full_name = props.fullName;
            let countrySelected = props.hcpCC;
            if (!countrySelected){
                let sessionVariables = localStorage.getItem('user_info');
                let sessionCountry = sessionVariables.countryCode;
                countrySelected = sessionCountry;
            }
            let userStr = "country_code=" + countrySelected;
            let publisherEmailSet = new Set();
            service.GetPublishersByCountry(userStr).then((data) => {
                let jsondata = data.data;
                for (let i = 0; i < jsondata.length; i++) {
                    if(jsondata[i]){
                        publisherEmailSet.add(jsondata[i].user_email)
                    }
                    
                }
                service.SendEmail(date_of_completion,quiz_title,content_title,okta_id,full_name,country_name,hcp_email,Array.from(publisherEmailSet));

            }).catch((err) => {});
            
        }
        else{
            service.UpdateQuizAttempts(model);
            msg = failureMessage
        }
        modalFunc.current();
        setSendDataToModal(msg);
    }
    React.useEffect(() => {
        // let mainData = Object.assign({},staticQnA);
        let mainData = staticQnA;
        for(let i=0;i<mainData.length;i++){
            for(let j=0;j<mainData[i].answers.length;j++){
            mainData[i].answers[j].correct = false;
            }
        }
        SetQnA(mainData);
    }, [staticQnA]);

  

    const handleSubmit = () => {
        let service = new ContentService();
        let obj = {"quiz_id":props.quizId}
        service.GetAllQuiz(obj).then((data) => {
            handlePassFail(data.data[0].questions,QnA);
        })
        props.handleClose();
    }

  return (
    <div>
      {/* <Button onClick={true}>Open modal</Button> */}
      <Modal
        open={props.openModal} onClose={props.handleClose}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid className='paper modalstyle attemptquiz'>
                    <Grid className="modal-title3">
                        <span>
                            <button className='closeModal' onClick={() => props.handleClose()}></button>
                        </span>
                        <div>
                            <Typography className="quiz-title">
                                {quizTitle}
                            </Typography>
                        </div>
                        {quizDescription!==undefined?(quizDescription?.length!==0?
                        <Grid className="modal-title3">
                            {quizDescription}
                        </Grid> 
                        :null): null}
                    </Grid>
                    

                    <Grid className="modal-body quizbody">
                        {QnA.map((qa,index) => {
                        return (
                            <FormGroup className="form-question"> 
                            Q{index + 1}:    {qa.question}
                            {qa.answers.map((ans,subindex) => {
                                return (
                                    <Grid container>
                                        {words[subindex]}: <FormControlLabel control={<Checkbox checked={ans.correct} onChange={() => { editCorrect(index,subindex,!ans.correct)}}/>} label= {ans.reveal}/> 
                                    </Grid>
                                )
                            })}
                            </FormGroup>
                        )
                        })}
                    </Grid>
                    
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" className="modalBtn" onClick={() => props.handleClose()}>{props.translations['Clear'] ? props.translations['Clear'] : 'Clear'}</button>
                        <button type="button" className="modalBtn" disabled={!anySelection} onClick={() => {handleSubmit()}}>{props.translations['Submit'] ? props.translations['Submit'] : 'Submit'}</button>
                    </Grid>
                    <QuizPopupModal modalFunc={modalFunc} sendDataToModal={sendDataToModal} />
                </Grid>
      </Modal>
    </div>
  );
}

export default  AttemptQuiz;