import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en-US/translation.json';
import translationFN from './locales/fn/translation.json';

const resources = {
  en: {
    translations: translationEN
  },
  fn: {
    translations: translationFN
  }
};

i18n.use(LanguageDetector).init({
  resources,
  fallbackLng: "en",
  keySeparator: false,
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true,
  },
});

export default i18n;