
import React from 'react';
import { ContentService } from './Service/ContentService';
import { useNavigate, useLocation } from "react-router-dom";
import loaderNewGif from '../../public/img/new-loader.png';
import countryListJson from '../../CountryPassport/CountryPassport.json';
import * as jose from 'jose';
import { LocalHospitalTwoTone } from '@material-ui/icons';
const ContentWatchOldNonHCPDirect = (props)=> {
    let contentService = new ContentService();
    const navigate = useNavigate();
    const getCountryDetails = (country_name) => {
        if (country_name !== undefined) {
            if (countryListJson.length) {
                for (var i = 0; i < countryListJson.length; i++) {
                    let cntName = countryListJson[i].country_code;
                    if (cntName == country_name) {
                        localStorage.setItem('ga_code',countryListJson[i].gtm_code );
                        return countryListJson[i];
                    }
                }
            }
        }
        return '';
    }
    const URLMaker = async (urlIncoming)=> {
        if(localStorage.getItem('jwtToken')){
            let uri = new URL(urlIncoming);
            let params = uri.searchParams;
            if(params){
                let qParam = params.get('q') ? params.get('q') : null;
                if(qParam){
                    let Isplaylist = params.get('playlist') ? params.get('playlist') : false;
                    let urlModified = await contentService.GetOldVIdeosHcpDirect(qParam, Isplaylist, qParam);
                    return urlModified;
                }
            }
        }
        
        return false;
    }
    React.useEffect(()=> {
        localStorage.setItem('oldUrl',window.location.href);
        if (!localStorage.getItem('sessionValue')) {
            contentService.SetHCPDirectCookie(5000);
            let uri = new URL(window.location.href);
            if (uri.pathname.includes('home/watch')) {
                let params = uri.searchParams;
                if (params) {
                    if (true) {
                        contentService.SetHCPDirectCookie(5000);
                        let redirectURL = window.location.href;
                        let subredirectURI = redirectURL.replace(window.location.origin, '');
                        URLMaker(redirectURL).then((data)=> {
                            if(data){
                                navigate(data)
                            }else{
                                let navigatePath = '/';
                                navigate(navigatePath)
                            }
                            
                            
                        })
                        
                    }
                }
            }
        } else {
            contentService.SetHCPDirectCookie(5000);
            let params = (new URL(window.location.href)).searchParams;
            let hcpDirectLink = window.location.href;
            if (hcpDirectLink.length > 0) {
                URLMaker(hcpDirectLink).then((data)=> {
                    navigate(data)
                })
            }
        }
    },[]);
    return (
        <>
        <div style={{textAlign:'center', marginTop:'10%'}}>
        <img src={loaderNewGif} className='loadSvg rotate' alt='icon' /> 
        </div>
        </>
    )
}

export default ContentWatchOldNonHCPDirect;
