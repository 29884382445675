import React, { } from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import removeVideo from '../../../public/img/rejectEnabled.png';
import dndIcon from '../../../public/img/dndIcon.svg';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from "@material-ui/core";


const VideoSelected = (props) => {
    const formatDuration = (sec) => {
        var hours = Math.floor(sec/3600);
        (hours >= 1) ? sec = sec - (hours*3600) : hours = '00';
        var min = Math.floor(sec/60);
        (min >= 1) ? sec = sec - (min*60) : min = '00';
        (sec < 1) ? sec='00' : void 0;
        (min.toString().length === 1) ? min = '0'+min : void 0;    
        (sec.toString().length === 1) ? sec = '0'+sec : void 0;
        return hours+':'+min+':'+sec;
    }

    const removeVideos = (data) => {
        var array = [...props.videoDetails];
        const filteredArray = array.filter(item => item !== data);
        props.setVideoDetails(filteredArray);
    }

    const handleDragEnd = (results) => {
        if (!results.destination) return;
        let tempDetail = [...props.videoDetails];
        let [selectedRow] = tempDetail.splice(results.source.index, 1);
        tempDetail.splice(results.destination.index, 0, selectedRow);
        props.setVideoDetails(tempDetail);
    }

    return (
        <Grid container className=" content grey_row">
            <Grid item lg={12} xs={12} className="overFlowAuto">
                <FormControl component="fieldset">
                    <FormLabel component="legend"></FormLabel>
                    <Grid>
                        {props.videoDetails.length > 0 ?
                            <>
                                <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                                    <table className="videoTbl">
                                        <thead>
                                            <tr>
                                                <th className='text-center'>Content Title</th>
                                                <th className='text-center'>Content Type</th>
                                                <th className='text-center'>Upload Time</th>
                                                <th className='text-center'>Duration</th>
                                                <th className='text-center'>Published Country</th>
                                                <th className='text-center'>Pending Country</th>
                                                <th className='text-center'>Rejected Country</th>
                                                <th className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId="table-body">
                                            {(provided) => (
                                                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                                    {props.videoDetails.map((data, index) => {
                                                        let countriesStats = data.content_approved;
                                                        let pendingCountry = [];
                                                        let publishedCountry = [];
                                                        let rejectedCountry = [];
                                                        if(countriesStats) {
                                                          if(Object.keys(countriesStats).length > 0) {
                                                            Object.keys(countriesStats).map((countryId,i) => {
                                                              if(countriesStats[countryId].status === "Pending"){
                                                                pendingCountry.push(countriesStats[countryId].name);
                                                              }
                                                              if(countriesStats[countryId].status === "Active"){
                                                                publishedCountry.push(countriesStats[countryId].name);
                                                              }
                                                              if(countriesStats[countryId].status === "Inactive"){
                                                                rejectedCountry.push(countriesStats[countryId].name);
                                                              }
                                                              return true;
                                                            });
                                                          }
                                                        }
                                                        return (
                                                            <Draggable draggableId={data.title} key={data.title}
                                                            index={index}>
                                                                {(provided) => (
                                                                    <tr ref={provided.innerRef} {...provided.draggableProps}>
                                                                        <td className='text-left'>{data.title}</td>
                                                                        <td className='text-center'>{data.content_type}</td>
                                                                        <td className='text-left'>{(new Date(data.created_at)).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                                                        <td className='text-center'>{formatDuration(data.duration)}</td>
                                                                        <td className='text-center'>
                                                                            <Tooltip title={publishedCountry.join(',')}><Button>{publishedCountry.length}</Button></Tooltip>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <Tooltip title={pendingCountry.join(',')}><Button>{pendingCountry.length}</Button></Tooltip>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <Tooltip title={rejectedCountry.join(',')}><Button>{rejectedCountry.length}</Button></Tooltip>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <Grid container item lg={12}>
                                                                                <Grid item lg={6} xs={12} >
                                                                                    <img src={removeVideo} className="approvedIcon" onClick={() => removeVideos(data)} alt="remove Video" />
                                                                                </Grid>
                                                                                <Grid item lg={6} xs={12} {...provided.dragHandleProps} >
                                                                                    <img src={dndIcon} className="approvedIcon" alt="drag row" />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </table>
                                </DragDropContext>
                            </>
                            : null}
                    </Grid>
                </FormControl>
            </Grid>
        </Grid>
    )
}
export default VideoSelected