
import React from 'react';


const useRouteChangeHook = ()=> {
    const [path, SetPath] = React.useState(window.location.pathname);

    const ChangeLocaitonEvent = ()=> {
        let pathName = window.location.pathname;
        SetPath(pathName);
    }
    React.useEffect(()=> {
        window.addEventListener("popstate",()=>{
            ChangeLocaitonEvent()} );
        window.addEventListener("pushstate",()=>{
            ChangeLocaitonEvent()} );
        window.addEventListener('locationChange',()=>{
            ChangeLocaitonEvent()} );
    },[]);
    return path;
}
export default useRouteChangeHook;