import React from 'react';
import Grid from '@material-ui/core/Grid';



const Forbidden = () => {
    return (
        <>
            <Grid className="forbiddenText"><h3>Forbidden</h3></Grid>
        </>
    );

};

export default Forbidden;