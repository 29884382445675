/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */

import React from 'react';




const VideoPlayer = (props)=> {

  const [videos, SetVideos] = React.useState(true);
  React.useEffect(()=> {
    const ks_player_localisation = {
      'mwe-embedplayer-timed_text':'Closed Captions',
      'mwe-timedtext-no-subtitles':'No Subtitles',
      'mwe-embedplayer-player_fullscreen':'Full screen',
      'mwe-embedplayer-player_closefullscreen':'Exit full screen',
      'mwe-embedplayer-share':'Share',
      'mwe-embedplayer-volume-mute':'Mute',
      'mwe-embedplayer-volume-unmute':'Unmute',
      'mwe-embedplayer-play_clip':'Play clip',
      'mwe-embedplayer-pause_clip':'Pause clip',
      'mwe-embedplayer-replay':'Replay',
    } 
    const flashvars = { // flashvars allows you to set runtime uiVar configuration overrides.
      'autoPlay': true ,
      // 'autoPlay':(isAutoPlay == 'true' || self.props.isUpnext =='true') ? true:false ,
      'strings': ks_player_localisation,
      'controlBarContainer': {
        'hover': true
      },
      // 'localizationCode':'tr',
      // 'ks': ksclient.config.KALTURA_SESSION,  // TODO: Is it necessary.????
      "share" : {
        "plug":false,
        "plugin":false
      },
      'contentBarContainer.plugin': false,
      'largePlayBtn.plugin': false,
      'loadingSpinner.plugin': false,
      // 'largePlayBtn.plugin': !self.state.upnext,
    }
    let x =  {'targetId': 'palyer-widget','wid': '_' + '1759891', 'uiconf_id': '48704833', 'entry_id': '1_3wznjt7c',  'width': '100px', 
    'height': '100px','flashvars': flashvars,'params': { 'wmode':'transparent'}}
    console.log(x)
  //   let kWidget = kWidget;
    window.kWidget.embed({'targetId': 'palyer-widget','wid': '_' + '1759891', 'uiconf_id': '48704833', 'entry_id': '1_3wznjt7c',  'width': '100px', 
    'height': '100px','flashvars': flashvars,'params': { 'wmode':'transparent'}})
    // SetVideos(true);
  },[]);
   
    return ( videos?
        <>
        <div id='palyer-widget' >
           <script type="text/javascript" src="https://cdnapisec.kaltura.com/p/1759891/embedPlaykitJs/uiconf_id/48704833"></script>
           </div>
        </> : ('')
    );
}
export default VideoPlayer;