/* eslint-disable eqeqeq */
import axios from 'axios';
import { SaveAudit } from '../models/ServiceModels';
import {GoogleAnalyticsService} from '../../../GA/GoogleAnalyticsService';
import config from '../../../../config/config.json';
class ContentService {

  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.authHeader = {
      headers: {
        'Content-Type': 'application/json',
        'authorizationtoken': localStorage.getItem('jwtToken')
      }
    };
    this._gaService = new GoogleAnalyticsService();
  }
  CopyLinkButtonClick(videoId){
    let model = {
      event : 'CopyLinkButtonClick',
      eventCategory : 'Content page',
      eventDescription :'Copy link button clicked',// 'Navigated to video from TA Homepage',
      videoSelectedID : videoId,
      eventAction: 'Click to copy share link'
  };
  this._gaService.PushObjectInDatalayer(model);
  }
  SHareButtonClick(videoId){
    let model = {
      event : 'SHareButtonClick',
      eventCategory : 'Content page',
      eventDescription :'Share button clicked',// 'Navigated to video from TA Homepage',
      videoSelectedID : videoId,
      eventAction: 'Click to share content'
  };
  this._gaService.PushObjectInDatalayer(model);
  }
  NavigatedToVideoFromContentPageEvent(videoId, message, ta){
    let taSplit = ta.split(',');
    if(Array.isArray(taSplit) && taSplit.length>1){
        let model = {
            event : 'NavigatedToVideoFromContentPageEvent',
            eventCategory : 'Navigated to video',
            eventDescription :message,// 'Navigated to video from TA Homepage',
            videoSelectedID : videoId,
            eventAction: 'Content page origin',
            eventLabel: ta
        };
        this._gaService.PushObjectInDatalayer(model);
    }else{
        let model = {
            event : 'NavigatedToVideoFromContentPageEvent',
            eventCategory : 'Navigated to video',
            eventDescription :message,// 'Navigated to video from TA Homepage',
            videoSelectedID : videoId,
            eventAction: 'Content page origin',
            eventLabel: ta

        };
        this._gaService.PushObjectInDatalayer(model);
    }
    
  }
  MaterialsSelectedGAEvent(_videoId, filename) {
    let model = {
        event: 'MaterialsSelected',
        MaterialsSelectedTitle:_videoId,// 'Content with id ' + videoId + ' and title ' + videoId + ' was selected',
        resourceName : filename,
        videoId : _videoId

    };
    this._gaService.PushObjectInDatalayer(model);
}
  PushVideoNumberOfViewsDataGA(videoViews){
    this._gaService.PushSingleVariables('videoViews', videoViews);
  }
  PushVideoDataGA(videoDescription){
    // this._gaService.PushSingleVariables('videoDescription', videoDescription);
  }
  PushKDPEvents(model){
    this._gaService.PushObjectInDatalayer(model);
  }
  
  VideoSelectedGAEvent(videoId, videoTitle= null) {
    let model = {
        event: 'video selected',
        videoId: videoId,
        videoSelectedID: videoId//'Content with id ' + videoId + ' and title ' + videoId + ' was selected',


    };
    this._gaService.PushObjectInDatalayer(model);
}
  PushLikeData(contentId, like){
    if(like){
      let model = {
        event: 'likeSelected',
        videoId:contentId,// 'Content with id ' + videoId + ' and title ' + videoId + ' was selected',
        videoRating : 'liked',

    };
    this._gaService.PushObjectInDatalayer(model);
    }
    else{
      let model = {
        event: 'likeSelected',
        videoId:contentId,// 'Content with id ' + videoId + ' and title ' + videoId + ' was selected',
        videoRating : 'unliked',

    };
    this._gaService.PushObjectInDatalayer(model);
    }
  }
  PushFavouriteData(videoSelectedTitle, action ){
    if(action){
      let model = {
        event: 'favouriteSelected',
        videoId:videoSelectedTitle,// 'Content with id ' + videoId + ' and title ' + videoId + ' was selected',
        favouriteCheck : 'Favourite',

    };
    this._gaService.PushObjectInDatalayer(model);
    }
    else{
      let model = {
        event: 'favouriteSelected',
        videoId:videoSelectedTitle,// 'Content with id ' + videoId + ' and title ' + videoId + ' was selected',
        favouriteCheck : 'Un-Favourite',

    };
    this._gaService.PushObjectInDatalayer(model);
    }
  }
  async GetPlaylistbyId(id) {
    let response = await axios.post(this.baseUrl + '/content/api/playlist/GetAllPlaylist?id=' + id, {}, this.authHeader);
    return response.data;
}
async GetPlaylistByOldId(_old_playlist_id){
  let response = await axios.post(this.baseUrl + '/content/api/playlist/GetAllPlaylistRaw?old_playlist_id=' + _old_playlist_id, {}, this.authHeader);
  return response.data;
}
 async GetPlaylistByVideoIds(_videoIds){
  let body = {videoIds : _videoIds};
  let response = await axios.post(this.baseUrl + '/content/api/playlist/GetPlaylistsByVIdeoIds?id=', body, this.authHeader);
    return response.data;
 }
  async GetOldVIdeosHcpDirect(oldVIdeoId, isPlaylist, playlistId){
    
    if(isPlaylist === 'true'){
      let playlists = await this.GetPlaylistByOldId(playlistId)
      if(Array.isArray(playlists)){
        for(let i =0;i< playlists.length;i++){
          if(Array.isArray(playlists[i].content_ids)){
            if(playlists[i].content_ids.length>0){
              let contentId = playlists[i].content_ids[0];
              let firstVideoPlaylist = await this.GetCOntentDataRaw(contentId);
              if(Array.isArray(firstVideoPlaylist)){
                if(firstVideoPlaylist.length>0){
                  let url = '/content-watch/'+ firstVideoPlaylist[0].kaltura_id+'-'+playlists[i].playlist_id +'/'+playlists[i].playlist_id;
                  return url;
                }
              }
              
            }
            
          }
          
        }
      }
    }else{
      let video = await this.GetOldCOntentDataRaw(oldVIdeoId);
      if(Array.isArray(video)){
        if(video.length>0){
          let url = '/content-watch/'+ video[0].kaltura_id+ '/'+video[0].content_id;
          return url;
        }
        
      }
    }
    
    
    
  }
  PushVideoID(kalturaId){
    this._gaService.PushSingleVariables('videoId', kalturaId)
  }
  async GetAllQuiz(obj) {
    if(!localStorage.getItem('jwtToken')){
      throw new Error('Access Token Not Available');
  }
    if(obj){
        return axios.post(this.baseUrl +`/management/api/quiz/GetAllQuiz`, obj, this.authHeader);
    }
    else{
        return axios.post(this.baseUrl +`/management/api/quiz/GetAllQuiz`, {}, this.authHeader);
    }
  };

  async UpdateQuizAttempts(data) {

    return axios.post(this.baseUrl +`/management/api/user/UpdateQuizAttempts`,data, this.authHeader);
  };
  async UpdateLibrary(userId, libraryId){
    let data = {library : libraryId, user_id : userId}
    return await axios.post(this.baseUrl +`/management/api/user/UpdateUserActiveLibrary`,data, this.authHeader)
  }
  async GetOldCOntentDataRaw(contentId){
    if(!localStorage.getItem('jwtToken')){
      throw new Error('Access Token Not Available');
  }
    let data = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/video/GetVideosByQueryRaw?old_video_id=' + contentId, {}, this.authHeader);
    return data.data;
  }
  async GetS3FileLink(key){
    try{
      if(key !=null && key.length>0){
          let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', {key : key.trim()}, this.authHeader);
          return data.data;
          let _data = await axios.get(data.data);
          return _data.data;
      } else{
          return null;
      }
  }catch(err){
      return null;
  }
  }
  async GetCOntentDataRaw(contentIdOfPlaylist){
    if(!localStorage.getItem('jwtToken')){
  }
    let data = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/video/GetVideosByQueryRaw?content_id=' + contentIdOfPlaylist, {}, this.authHeader);
    return data.data;
  }
  // async GetContentData(kalturaId, contentId, navigate) {
  //   try{
  //   if(!localStorage.getItem('jwtToken')){
  //     return null;
  // }
  //   let data = contentId.includes('PL')? await this.GetPlaylistbyId(contentId) : await axios.post(this.baseUrl + '/content/api/video/GetVideosByQueryRaw?content_id=' + contentId, {}, this.authHeader);
  //   if(contentId.includes('PL')){
  //     data = {data : data};
  //   }
  //   if(Array.isArray(data.data)){
  //     for (let i=0;i<data.data.length;i++){
  //       let video = data.data[i];
  //       if(contentId.includes('PL')){
  //         let playListData = await this.GetPlaylistData(contentId);
  //         let playListVideos = await this.GetPlayListVideos(playListData.content_ids);
  //         if(Array.isArray(playListVideos)){
  //           for(let k =0;k<playListVideos.length;k++ ){
  //             let playListVideo = playListVideos[k];
  //             if(playListVideo && playListVideo.kaltura_id == kalturaId){
  //               return playListVideo
                
  //             }
  //           }
  //         }
          
  //       }else{
  //         if(video.content_id == contentId){
  //           if(video.status != 'deleted' && video.status != 'draft'){
  //             return video;
  //           }
            
  //         }
  //       }
        
  //     }
  //   }
  //   window.location.href = '/home';
  //   return null;
  // }catch(err){return null}
  // }
  async UpdateLogViews(obj) {
    if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
    let response = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/video/UpdateLogViews', obj, this.authHeader);
    return response.data;
  }
  async GetPlaylistData(playlistId) {
    if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
    let data = await axios.post(this.baseUrl + '/content/api/playlist/GetAllPlaylist?id=' + playlistId, {}, this.authHeader);
    return data.data[0];
  }
  async GetVideoStats(contentId) {
    if(!localStorage.getItem('jwtToken') ){
            throw new Error('Access Token Not Available');
        }
    let data = await axios.post(this.baseUrl + '/content/api/videostatistics/GetVideoLikes/' + contentId, {}, this.authHeader);
    return data.data;
  }
  // async GetS3FileLink(key){
  //   try{
  //     if(key !=null && key.length>0){
  //         let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', {key : key.trim()}, this.authHeader);
  //         return data.data;
  //         let _data = await axios.get(data.data);
  //         return _data.data;
  //     } else{
  //         return null;
  //     }
  // }catch(err){
  //     return null;
  // }
  // }
  async GetS3File(key){
    try{
        if(key !=null && key.length>0){
            let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', {key : key.trim()}, this.authHeader);
            return (await axios.get(data.data)).data//data.data.binarySring;
        } else{
            return null;
        }
    }catch(err){
        return null;
    }
}
  async GetSpeaker(speakerId) {
    if(!localStorage.getItem('jwtToken')){
      throw new Error('Access Token Not Available');
  }
    let data = await axios.post(this.baseUrl + '/management/api/speaker/GetSpeakersByQuery?speaker_id=' + speakerId, {}, this.authHeader);
    return data.data[0];
  }
  async GetSpeakersById(ids) {
    if(!localStorage.getItem('jwtToken')){
      throw new Error('Access Token Not Available');
  }
    let data = await axios.post(this.baseUrl +'/management/api/speaker/GetSpeakersById', ids, this.authHeader);
    return data.data;
  };
  async GetBrandName(brandId){
    if(!localStorage.getItem('jwtToken')){
      throw new Error('Access Token Not Available');
  }
    let data = await axios.post(this.baseUrl + '/management/api/category/GetCategory1ByQuery?category_1_id=' + brandId, {}, this.authHeader);
    return data.data[0];
  }
  // async GetAffiliateId(affiliateName, dispatch, selector, setAffiliate){
  //   if(affiliateName){
  //     let trimmed_country = affiliateName.split(' ');
  //     let final_country = affiliateName.includes(' ') ? trimmed_country[0]+"%20"+trimmed_country[1] : affiliateName;
  //     if(selector){
  //       if( selector.affiliates){
  //         if(Array.isArray(selector.affiliates.payload)){
  //           if(selector.affiliates.payload.length>0){
  //             if(selector.affiliates.payload[0].country_name == final_country){
  //               return selector.affiliates.payload[0]
  //             }
  //           }
             
  //         }
  //       }
  //     }
      
  //     let data = await axios.post(this.baseUrl + '/management/api/user/GetAffiliateByQuery?country_name=' + final_country, {}, this.authHeader);

      
  //     return data.data[0];
  //   }else{
  //     return null;
  //   }
    
  // }
  async AddToFavorite (model) {
    return axios.post(this.baseUrl + '/management/api/favorite/AddToFavorites', model, this.authHeader);
  };
  async GetUpNextVideos(videoId, specialty, profession, countryCode, affiliateId, userId, taId, cat1Id, cat2Id, libId,category_id){
    let data = await axios.post(this.baseUrl + '/content/api/video/UpNextVideo', {
      videoId : videoId, specialty : specialty, profession : profession, countryCode : countryCode, affiliateId : affiliateId,
      userId : userId, libraryId : libId,category_id:category_id}
      , this.authHeader);
    let list = [];
    let countryArray = Array.isArray(countryCode)? countryCode : [countryCode]
    for(let i = 0; i< data.data.length;i++){
      let video = data.data[i];
      let contentApproved = video.content_approved;
      for(let k =0;k<countryArray.length;k++ ){
        if(contentApproved[countryArray[k]]){
          if(contentApproved[countryArray[k]].status == 'Active'){
            if(video.status == "submitted"){
              list.push(video);
            }
          }
          
        }
      }
     
      
    }
    return list;
  }
  async GetUpnextPlaylist(videoId, specialty, profession, countryCode, affiliateId, userId, taId, cat1Id, cat2Id, libId, playlist_id){
    let data = await axios.post(this.baseUrl + '/content/api/playlist/GetUpnextPlaylist', {
      content_id : videoId, specialty : specialty, profession : profession, countryCode : countryCode, affiliateId : affiliateId,
      userId : userId, therapeutic_area_id: taId, category1_id: cat1Id, category2_id: cat2Id, libraryId : libId, playlist_id : playlist_id}
      , this.authHeader);
    return data.data;
  }
  
  async RemoveFromFavorite (id) {
    return axios.post(this.baseUrl + '/management/api/favorite/RemoveFromFavorites?user_stat_id=' + id, {}, this.authHeader);
  };
  
  async GetAllFavoriteData (userId)  {
    return axios.post(this.baseUrl + '/management/api/favorite/GetAllFavoriteData/'+userId, {}, this.authHeader);
  };

  async GetAllContentAudit (query) {
    let queryString = '?content_id=' + query.content_id;
    return axios.post(this.baseUrl + '/content/api/contentAudit/GetAllContentAudit'+ queryString, query, this.authHeader);
  }

  async getLatestContentAudit (query, bodyData) {
    let queryString = '?content_id=' + query.content_id;
    return axios.post(this.baseUrl + '/content/api/contentAudit/getLatestContentAudit'+ queryString, bodyData, this.authHeader);
  }

  SetHCPDirectCookie(time)
  {
    
    document.cookie = 'hcpDirect='+'hcpDirect'+';max-age='+time;
  }
  
  async PostWatchedContent (watchedBody) {
    return axios.post(this.baseUrl + '/content/api/videoWatch/AddVideoWatch', watchedBody, this.authHeader);
  };
  async SaveAction(content_id, kaltura_id, content_type, action, created_by, speaker_ids, country_code) {
    let model = new SaveAudit();
    model.content_id = content_id;
    model.kaltura_id = kaltura_id;
    model.content_type = content_type;
    model.action = action;
    model.user_id = created_by;
    model.speaker_ids = speaker_ids;
    model.country_code = country_code;
    let data = await axios.post(this.baseUrl + '/content/api/contentAudit/CreateAuditNew', model, this.authHeader)
    return data.data;
  }
  
  async getContentList() {
    if(!localStorage.getItem('jwtToken')){
      throw new Error('Access Token Not Available');
  }
    return axios.post(this.baseUrl + '/home/api/content/ShowMoreData/1/10', {category_id: "TA1"}, this.authHeader);
  };
  async GetPlayListVideos(videoIds) {
    if(!localStorage.getItem('jwtToken')){
      throw new Error('Access Token Not Available');
  }
    let data = await axios.post(this.baseUrl + '/content/api/video/GetVideoByIds', videoIds, this.authHeader);
    return data.data;
  }
  
  SeekVideo(playerId, time){
    let kdp = document.getElementById(playerId);
    // kdp.sendNotification("doSeek", time); // pausong due to GA problems
    return true;
  }
  GetTimeStamp(userExperience, duration){
    let watchPercentage = userExperience.watch_percentage;
    if(!isNaN(watchPercentage) && !isNaN(duration)){
      let time = (watchPercentage * duration)* 0.01;
      return time;
    }
    return 0;
  }

  async SendEmail(date_of_completion,quiz_title,content_title,okta_id,full_name,country_name,hcp_email,publisherEmail) {
    let obj = {
        'mail_type': 'cme_credit_template',
        'to': publisherEmail,
        'quiz_title': quiz_title,
        'content_title': content_title,
        'country_name': country_name,
        'okta_id': okta_id,
        'hcp_email': hcp_email,
        'date_of_completion': date_of_completion,
        'subject': 'CME_Credit',
        'full_name': full_name
    }
    return axios.post(this.baseUrl +'/content/api/aws/SendEmail', obj, this.authHeader);
};

async GetPublishersByCountry(param) {
  return axios.post(this.baseUrl + '/management/api/user/GetPublishersByCountry?' + param, {}, this.authHeader);
};

  async getKalturaCaptions(kalturaId)
  {
      let uri = config?.kalturaUrl + '/caption_captionasset/action/list';
      let formData = new FormData();
      formData.append('ks',localStorage.getItem('ks'));
      formData.append('filter[entryIdEqual]',kalturaId);
      formData.append('filter[objectType]','KalturaAssetFilter');
      formData.append('format', 1);
      let data = await axios.post(uri, formData);
      let captionsArr = [];
      if(data.data) {
        let captionsObj = data.data.objects;
        if(captionsObj){
          for(let i=0;i<captionsObj.length;i++) {
            captionsArr.push(await this.getCaptionURL(captionsObj[i].id));
          }
        }
        
      }
      return captionsArr;
  }
  async getCaptionURL(id)
  {
      let uri = config?.kalturaUrl + '/caption_captionasset/action/getUrl';
      let formData = new FormData();
      formData.append('ks',localStorage.getItem('ks'));
      formData.append('id',id);
      formData.append('format', 1);
      let data = await axios.post(uri, formData);
      return data.data;
  }

  async GetImageFromURL(uri){
    try{
      let uriNew = new URL(uri);
      if(uriNew.host == 'cfvod.kaltura.com'){
        return uri;
      } else {
        let data = await axios.get(uri);
        return data.data;
      }
    }catch(err){
      return await this.GetImage(uri);
    }
  }
  async GetImage(key){
    try{
      if(key !=null && key.length>0){
          let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet' , {key : key} , this.authHeader);
          return (await axios.get(data.data)).data//data.data.binarySring;
      } else{
          return null;
      }
    } catch(err){
    }
  }
}
export { ContentService }
