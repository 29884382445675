/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable linebreak-style */
/* eslint-disable import/no-named-as-default */
/* eslint-disable linebreak-style */
import React from 'react';
import AppSidebar from './AppSidebar';
import CookieBanner from "./CookieBanner";
import Grid from '@material-ui/core/Grid';
import LillyLogo from '../public/img/LogoLillyPlayDesktop.png'
import HeaderSearchIconM from '../public/img/search_icon_m.png'
import { makeStyles, alpha } from '@material-ui/core/styles';
import './layout.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import search from '../public/img/search.png';
import IconButton from '@material-ui/core/IconButton';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { SearchService } from '../modules/home/Service/search-service';
import { AppHeaderService } from './Services/AppHeader';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import { SimulationService } from './Services/SimulationService';
import Simulation from './simulation';
import countryListJson from '../CountryPassport/CountryPassport.json';
import { SessionService } from '../modules/login/Services/SessionService';
import useRouteChangeHook from '../router/RouteChangeHook';
import { setAffiliate } from '../Session/AffiliateSlice';
import { setCategories } from '../Session/CategorySlice';
import { HomeServiceBaseMatchingModel } from '../modules/home/Service/homeService';
import { UserBrandService } from '../modules/home/Service/userBrandService';
const useStyles = makeStyles((theme) => ({

  menuButton: {
    marginRight: theme.spacing(2),
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 1),
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '60ch'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
    right: 0,
    top: 0,
  },

  autoCompleteInput: {
    padding: '6px 8px 8px 8px !important',
    fontSize: '14px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px) !important`,
    transition: theme.transitions.create('width'),
    width: '54ch !important',
    letterSpacing: '-0.062em',
    fontWeight: '600',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      width: '54ch !important',
      padding: '6px 8px 8px 8px !important',
      '&:focus': {
        width: '54ch !important',
      },
    },
  },

  inputInput: {
    padding: '6px 8px 8px 8px',
    fontSize: '14px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '62ch',
    letterSpacing: '-0.062em',
    fontWeight: '600',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      width: '62ch',
      padding: '6px 8px 8px 8px',
      '&:focus': {
        width: '62ch',
      },
    },
  },
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    overflow: 'hidden',
    padding: theme.spacing(0.5),
    margin: 0,
    marginLeft: '-6px',
    [theme.breakpoints.down('xs')]: {
      width: 'max-content',
      overflow: 'scroll',
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    color: 'white',
    border: `2px solid #fff`,
    height: '100%',
    padding: '0px 4px 3px 4px',
    cursor: 'pointer',
    alignItems: 'center',
    // fontSize: '12px',
    fontSize: 'calc(10px + 0.2vw)',
    borderRadius: '40px',
    textTransform: 'capitalize',
    '&:hover': {
      color: '#D52B1E',
      backgroundColor: '#fff !important',
      border: `2px solid #fff`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 1% 2px 1%',
      fontSize: '14px',
      height: '30px',
    },
  },
  slideSec: {
    background: '#d52b1e',
    // marginTop: '75px',
    // padding: '0 6% 0 2%',
    // top: '0',
    // left: 'auto',
    // right: '0',
    // position: 'fixed',
    zIndex: '1000',
    // "@media (max-width:767px)": {
    //   display: 'none',
    // },
    // "@media (max-width:960px)": {
    //   display: 'none',
    // }
  },
  slideSecInn: {
    background: '#d52b1e',
    width: '100%',
    margin: '0 auto',
    padding: '0 4%',
    "@media (max-width:960px)": {
      width: '100%',
    }
  }
}));

const AppHeader = React.memo((props) => {
  let selectorValue = useSelector(state => state.session);
  const translationReduxState = useSelector(state => state.translation);
  let affiliateSelector = useSelector(state => state.affilaiteSlice);
  let categoriesSelector = useSelector(state => state.categorySlice);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [role, setRole] = React.useState([]);
  const [isRoleHCP, setIsRoleHCP] = React.useState(false);
  const [isRoleInternal, setIsRoleInternal] = React.useState(false);
  const [isRolePublisher, setIsRolePublisher] = React.useState(false);
  const [isRoleAdmin, setIsRoleAdmin] = React.useState(false);
  const [isHCP, setHCPValue] = React.useState(localStorage.getItem('HCP'));
  const [isLacam, SetisLacam] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);
  const customPathHook = useRouteChangeHook();
  const [jsonObj, SetjsonObj] = React.useState(countryListJson);
  const [speciality, setSpeciality] = React.useState('');
  const [audiance, setaudiance] = React.useState('');
  const [brandCompletion, SetBrandCompletion] = React.useState(false);
  const [taIdsArrData, SetTaIdsArrData] = React.useState([]);
  const [userBrandFilterData, SetUserBrandFilterData] = React.useState(false);

  React.useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true);
    }
    window.addEventListener("scroll", () => {
      if (isMobile) {
        setScroll(window.scrollY > 50);
      }
    });
    SetSessionState(selectorValue);
  }, [selectorValue])
  React.useEffect(() => {

  }, [categoriesSelector, affiliateSelector])
  React.useEffect(() => {
    let lacam = ['Argentina', 'Aruba', 'Bahamas', 'Barbados', 'Belize', 'Bolivia', 'Chile', 'Colombia', 'Costa Rica', 'Curacao', 'Dominican Republic', 'Ecuador', 'El Salvador', 'Guatemala', 'Guyana', 'Honduras', 'Jamaica', 'Mexico', 'Nicaragua', 'Panama', 'Paraguay', 'Peru', 'Trinidad and Tobago', 'Uruguay', 'Venezuela', 'Curacao'];
    if (sessionState.sessionValue?.payload?.loginroute) {
      let userRoles = [];
      let sessionService = new SessionService(localStorage.getItem('jwtToken'));
      sessionService.PushCOuntrySelected(sessionState.sessionValue.payload?.decodedToken?.countryCode)
      if (sessionState.sessionValue.payload.affiliate.length != 0) {
        let lacamBool = lacam.includes(sessionState.sessionValue.payload.affiliate[0]?.country_name);
        SetisLacam(lacamBool);
      }
      for (let i = 0; i < sessionState.sessionValue.payload.roles.length; i++) {
        if (sessionState.sessionValue.payload.roles[i].role_name == "hcp") {
          userRoles.push("hcp");
          setIsRoleHCP(true);
        }
        if (sessionState.sessionValue.payload.roles[i].role_name == "internal") {
          userRoles.push("internal");
          setIsRoleInternal(true);
        }
        if (sessionState.sessionValue.payload.roles[i].role_name == "publisher") {
          userRoles.push("publisher");
          setIsRolePublisher(true);
        }
        if (sessionState.sessionValue.payload.roles[i].role_name == "admin") {
          userRoles.push("admin");
          setIsRoleAdmin(true);
        }
      }
      setRole(userRoles);
    }
  }, [sessionState]);



  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trigger = useScrollTrigger({ threshold: 0 });
  const [searchArea, setSearchArea] = React.useState(false);
  const [chipSelect, setChipSelect] = React.useState('All');
  const [idData, setidData] = React.useState([]);
  const [chipData, setChipData] = React.useState([]);
  const [allThAreaData, setallThAreaData] = React.useState([]);
  const [brandIdsArrData, SetBrandIdsArrData] = React.useState([]);
  const [indIdsArrData, SetIndIdsArrData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openMob, setOpenMob] = React.useState(false);
  const [openMobLib, setOpenMobLib] = React.useState(false);
  const [openLib, setOpenLib] = React.useState(false);
  const [HCPBool, setHCPBool] = React.useState(false);
  const [AllLibId, setAllLibId] = React.useState([]);
  const [tocheckLib, settocheckLib] = React.useState(false);
  const [toshowLibDropdown, settoshowLibDropdown] = React.useState(false);
  const [lillylogo, setlillyLogo] = React.useState(LillyLogo);
  const [countryNameTranslation, setcountryNameTranslation] = React.useState(localStorage.getItem('country'));
  const [preventPropogation, SetPreventPropogation] = React.useState(false);

  let menuRef = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
  }, [])
  React.useEffect(() => {
    setFilterByUrlCategory();
  }, [chipData, props.selectedLibrary]);

  const setFilterByUrlCategory = () => {
    let url = new URL(window.location.href);

    let urlHash = decodeURIComponent(url.hash);

    let searchCatTerm;

    if (urlHash.includes('#')) {
      searchCatTerm = urlHash.replace("#", "");
      if (chipData.length > 0) {
        let checkKey = chipData.filter(function (singleChip) { return singleChip.label == searchCatTerm });
        let checkKey2 = chipData.filter(function (singleChip) { return singleChip.label == searchCatTerm.charAt(0).toUpperCase() + searchCatTerm.slice(1) });

        if (checkKey[0] || checkKey2[0]) {
          let id = checkKey[0]?.key;
          let label = checkKey[0]?.label
          if (!id) {
            id = checkKey2[0]?.key;
            label = checkKey2[0]?.label
          }
          let dataName = label;
          setChipSelect(dataName.toString());
          props.setFilter(dataName);
          props.SetFilterId(id)
        }
        else {
          navigate('/home');
        }
      }
    }
  }

  React.useEffect(() => {
    let headerService = new AppHeaderService();
    let simulationService = new SimulationService();
    if (sessionState.sessionValue && sessionState.sessionValue.payload) {


      if (sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.simulationCheck) {
        let resData = { locale: sessionState.sessionValue.payload.locale }
        simulationService.getTranslationBtlocale(resData).then((data) => {
          if (Array.isArray(data.data)) {
            let result = {};
            for (let i = 0; i < data.data.length; i++) {
              result[data.data[i].key] = data.data[i].value
            }
            props.setmainTranslation(result)
          }
        }).catch((err) => {})
      } else {
        let countryName = localStorage.getItem('country');
        if (countryName == "Switzerland" || countryName === "Belgium") {
          if (sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.user && sessionState.sessionValue.payload.user.id) {
            let userId = sessionState.sessionValue.payload.user.id
            headerService.GetUsersById({ id: userId }).then((data) => {
              if (Array.isArray(data.data) && data.data.length > 0 && data.data[0]['language'] && data.data[0]['language'] != '') {
                let resData = { locale: data.data[0]['language'] }
                headerService.getTranslationBtlocale(resData).then((data) => {
                  if (Array.isArray(data.data)) {
                    let result = {};
                    for (let i = 0; i < data.data.length; i++) {
                      result[data.data[i].key] = data.data[i].value
                    }
                    props.setmainTranslation(result)
                  }
                }).catch((err) => {})
              } else {
                let resData = { locale: props.country }
                if (localStorage.getItem("passportLanguage")) {

                  resData = { locale: localStorage.getItem("passportLanguage") }
                  if ('fr-CH' == localStorage.getItem("passportLanguage")) {
                    resData = { locale: 'fr-FR' }
                  }
                }
                headerService.getTranslationBtlocale(resData).then((data) => {
                  if (Array.isArray(data.data)) {
                    let result = {};
                    for (let i = 0; i < data.data.length; i++) {
                      result[data.data[i].key] = data.data[i].value
                    }
                    props.setmainTranslation(result)
                  }
                }).catch((err) => {})
              }
            }).catch((err) => {})
          } else {
            let resData = { locale: props.country }
            if (localStorage.getItem("passportLanguage")) {
              resData = { locale: localStorage.getItem("passportLanguage") }
            }
            headerService.getTranslationBtlocale(resData).then((data) => {
              if (Array.isArray(data.data)) {
                let result = {};
                for (let i = 0; i < data.data.length; i++) {
                  result[data.data[i].key] = data.data[i].value
                }
                props.setmainTranslation(result)
              }
            }).catch((err) => {})
          }
        } else if (countryName == "UK" || countryName =='Sweden' ||  countryName == 'Finland') {
          localStorage.clear()
          window.location.replace(window.location.origin + '/landing/'+ countryName.toLowerCase() +'/maintenance.html');
        } else {
          let resData = { locale: props.country }
          let localCountry = localStorage.getItem('country')
          let localLocale
          for (let i = 0; i < jsonObj.length; i++) {
            if (jsonObj[i].country == localCountry) {
              localLocale = jsonObj[i].locale
            }
          }
          resData = { locale: localLocale }

          if (!props.country && localStorage.getItem('country')) {
            let countryData = jsonObj.find(o => o.country == localStorage.getItem('country'));
            resData = { locale: countryData.locale }
          }
          // if (localStorage.getItem("passportLanguage")) {
          //   resData = { locale: localStorage.getItem("passportLanguage") }
          // }
          if (!isRoleHCP) {
            resData = { locale: "" }
          }
          headerService.getTranslationBtlocale(resData).then((data) => {
            if (Array.isArray(data.data)) {
              let result = {};
              for (let i = 0; i < data.data.length; i++) {
                result[data.data[i].key] = data.data[i].value
              }
              props.setmainTranslation(result)
            }
          }).catch((err) => {})
        }
      }
    }
  }, [props.country, props.setmainTranslation, sessionState, countryNameTranslation, isRoleHCP]);


  const handleClickOutside = (e) => {
    let closestItem = e.target.closest('.menuItem');
    if (closestItem == null) {
      if (menuRef?.current) {
        if (!menuRef?.current?.contains(e.target)) {
          setOpen(false);
        }
      } else {
        setOpen(false);
      }
    }
  }


  /**Check on therpatic area if data exists */
  React.useEffect(() => {
    let country_name = localStorage.getItem('country')
    if (country_name) {
      getTherapeuticAreasWithCountry(country_name);
    }
  }, [setChipData, props.SetFilterId]);


  const getTherapeuticAreasWithCountry = (country) => {
    let headerService = new AppHeaderService();
    let resData = { affiliate_id: country, _dispatch: dispatch, _categoriesSelector: categoriesSelector, _affiliateSelector: affiliateSelector, _setAffiliate: setAffiliate }
    headerService.GetAffiliateByQuery(resData).then((data) => {
      if (Array.isArray(data.data)) {
        let countries = data.data;
        if (countries[0].avatar_attachment_id && countries[0].avatar_attachment_id != '') {
          headerService.GetS3File(countries[0].avatar_attachment_id).then((data) => {
            setlillyLogo(data)
          }).catch((err) => {})
        }
        if (countries.length == 0) {
          getTherapeuticAreasWithCountryWithoutCategory()
          return;
        }
        props.SetselectedCountry(countries[0].country_code)

        if (countries[0].library_active == 3 && sessionState.sessionValue.payload.loginroute != 'federate') {
          getLibraryData(countries[0]);
          return;
        }
        let resData = countries[0].country_code
        props.SetselectedCountry(countries[0].country_code)

        let list = [];
        let inArray = [];
        list.push({ key: 0, label: 'All', status: true })
        let idArray = [];
        let country_fixed_menu = countries[0].fixed_filter_ids
        let country_th_area = countries[0].therapeutic_area_ids

        let allth = []
        headerService.GetAllTherapeuticAreas().then((data) => {
          if (!Array.isArray(data.data)) {
            return;
          }
          for (let i = 0; i < data.data.length; i++) {
            if (inArray.indexOf(data.data[i].therapeutic_area_id) <= -1) {
              if (country_fixed_menu.includes(data.data[i].therapeutic_area_id)) {
                if (list.length <= 4) {
                  //props.translations[mainData[i].content_type] ? props.translations[mainData[i].content_type] : mainData[i].content_type
                  let label = (data.data[i].therapeutic_area_name).charAt(0).toUpperCase() + (data.data[i].therapeutic_area_name).slice(1)
                  label = props.translations[label] ? props.translations[label] : label
                  list.push({ key: data.data[i].therapeutic_area_id, label: label, status: true })
                }
                // allth.push({ key: data.data[i].therapeutic_area_id, label: (data.data[i].therapeutic_area_name).charAt(0).toUpperCase() + (data.data[i].therapeutic_area_name).slice(1), status: true })
                // idArray.push(data.data[i].therapeutic_area_id)
              }
              if (country_th_area.includes(data.data[i].therapeutic_area_id)) {
                let label = (data.data[i].therapeutic_area_name).charAt(0).toUpperCase() + (data.data[i].therapeutic_area_name).slice(1)
                label = props.translations[label] ? props.translations[label] : label
                allth.push({ key: data.data[i].therapeutic_area_id, label: label, status: true })
                // inArray.push(data.data[i].therapeutic_area_id)
                idArray.push(data.data[i].therapeutic_area_id)
              }
              inArray.push(data.data[i].therapeutic_area_id)

            }

          }
          props.SetFilterId(idArray.toString())
          setallThAreaData(allth)
          let list2 = [];
          list2.push({ key: 0, label: 'All', status: true })

          for (let j = 0; j < country_fixed_menu.length; j++) {
            for (let i = 0; i < list.length; i++) {
              if (country_fixed_menu[j] == list[i].key) {
                list2.push(list[i])
              }
            }
          }

          setChipData(list2)
          setidData(idArray)
          props.SetallThArea(idArray)
        }).catch((err) => {})
      }
    }).catch((error) => {
      if(error.response){
        let indicator = localStorage.getItem('HCP');
        let redirect = localStorage.getItem('country').toLowerCase();
        if(error.response.status === 403){
          localStorage.clear();
          if (indicator === 'true') {
            sessionStorage.clear();
            window.location.href = window.location.origin + '/sso';
          } else {
            window.location.href = window.location.origin + '/' + redirect;
          }
        }
      }
    })
  }
  const getTherapeuticAreasWithoutCountry = () => {
    let headerService = new AppHeaderService();
    let list = [];
    let inArray = [];
    list.push({ key: 0, label: 'All', status: true })
    let idArray = [];
    headerService.GetAllTherapeuticAreas().then((data) => {
      if (Array.isArray(data.data)) {
        for (let i = 0; i < data.data.length; i++) {
          if (inArray.indexOf(data.data[i].therapeutic_area_id) <= -1) {
            if (i <= 3) {
              let label = (data.data[i].therapeutic_area_name).charAt(0).toUpperCase() + (data.data[i].therapeutic_area_name).slice(1)
              label = props.translations[label] ? props.translations[label] : label
              list.push({ key: data.data[i].therapeutic_area_id, label: label, status: true })
            }
            inArray.push(data.data[i].therapeutic_area_id)
            idArray.push(data.data[i].therapeutic_area_id)
          }
          // props.SetFilterId(idArray.toString())
        }
        props.SetFilterId(idArray.toString())
        setChipData(list)
        setidData(idArray)
        props.SetallThArea(idArray)
      }
    }).catch((err) => {})
    setChipData(list)
  }

  const getTherapeuticAreasWithCountryWithoutCategory = () => {
    let headerService = new AppHeaderService();
    let list = [];
    let inArray = [];
    list.push({ key: 0, label: 'All', status: true })
    let idArray = [];
    headerService.GetAllTherapeuticAreas().then((data) => {
      if (Array.isArray(data.data)) {
        for (let i = 0; i < data.data.length; i++) {
          if (inArray.indexOf(data.data[i].therapeutic_area_id) <= -1) {
            inArray.push(data.data[i].therapeutic_area_id)
            idArray.push(data.data[i].therapeutic_area_id)
          }
          // props.SetFilterId(idArray.toString())
        }
        props.SetFilterId(idArray.toString())
        setChipData(list)
        setidData(idArray)
        props.SetallThArea(idArray)
      }
    }).catch((err) => {})
    setChipData(list)
  }
  const CHeckLibraryUpdatedData = (libData) => {
    if (libData.length != AllLibId.length) {
      return false;
    }
    for (let i = 0; i < libData.length; i++) {
      if (!AllLibId.includes(libData[i])) {
        return false;
      }
    }
    return true;
  }
  const getLibraryData = (country) => {
    let headerService = new AppHeaderService();
    headerService.getLibrary({ affiliate_id: country.country_code }).then((data) => {
      let librarydata = data.data;
      let active = 0
      let list = []
      let liblist = []
      for (let i = 0; i < librarydata.length; i++) {
        if (librarydata[i].status) {
          active = 1;
          if (!librarydata[i].staticimage) {
            // headerService.GetS3File("Country/library/" + librarydata[i].thumbnail_url).then((data) => {
            list.push({ library_name: librarydata[i].library_name, library_id: librarydata[i].library_id, thumbnail_url: librarydata[i].thumbnail_url, status: librarydata[i].status, staticimage: librarydata[i].staticimage, categories: librarydata[i].library_categories, image: '', fixedmenu: librarydata[i].library_fixed_filter ? JSON.parse(librarydata[i].library_fixed_filter) : [] })
            // });
          } else {
            list.push({ library_name: librarydata[i].library_name, library_id: librarydata[i].library_id, thumbnail_url: librarydata[i].thumbnail_url, status: librarydata[i].status, staticimage: librarydata[i].staticimage, categories: librarydata[i].library_categories, image: '', fixedmenu: librarydata[i].library_fixed_filter ? JSON.parse(librarydata[i].library_fixed_filter) : [] })
          }
          liblist.push(librarydata[i].library_id)

        }
      }
      if (!CHeckLibraryUpdatedData(liblist) && !tocheckLib) {
        setAllLibId(liblist)
        if (active == 1) {
          settocheckLib(true);
          props.SetlibraryData(list)
        }
      }


      if (active == 0) {
        let resData = country.affiliate_id
        headerService.GetCountryFixedMenus(resData).then((data) => {
          if (Array.isArray(data.data)) {
            let list = [];
            let inArray = [];
            list.push({ key: 0, label: 'All', status: true })
            let idArray = [];
            for (let i = 0; i < data.data.length; i++) {
              if (inArray.indexOf(data.data[i]['cat_therapeutic_area'].therapeutic_area_id) <= -1) {
                let label = (data.data[i]['cat_therapeutic_area'].therapeutic_area_name).charAt(0).toUpperCase() + (data.data[i]['cat_therapeutic_area'].therapeutic_area_name).slice(1)
                label = props.translations[label] ? props.translations[label] : label
                list.push({ key: data.data[i]['cat_therapeutic_area'].therapeutic_area_id, label: label, status: true })
                inArray.push(data.data[i]['cat_therapeutic_area'].therapeutic_area_id)
                idArray.push(data.data[i]['cat_therapeutic_area'].therapeutic_area_id)
              }
              // props.SetFilterId(idArray.toString())
            }
            props.SetFilterId(idArray.toString())
            if (idArray.length == 0) {
              getTherapeuticAreasWithCountryWithoutCategory()
              return;
            }
            setChipData(list)
            setidData(idArray)
            props.SetallThArea(idArray)
          }
        }).catch((err) => {})
      }
    }).catch((err) => {})
  }
  const simulationThCHeck = (country) => {
    let headerService = new AppHeaderService();
    let resData = { affiliate_id: localStorage.getItem('country'), _dispatch: dispatch, _categoriesSelector: categoriesSelector, _affiliateSelector: affiliateSelector, _setAffiliate: setAffiliate };

  }
  React.useEffect(() => {
    let userId = 1
    if (sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.simulationCheck) {
      let country = sessionState.sessionValue.payload.affiliate[0].country_name
      localStorage.setItem('country', country)
      simulationThCHeck(country)
    }
    if (sessionState) {
      if (sessionState.sessionValue.payload) {
        if (sessionState.sessionValue.payload.affiliate) {
          if (Array.isArray(sessionState.sessionValue.payload.affiliate)) {
            if (sessionState.sessionValue.payload.affiliate.length > 0) {
              getTherapeuticAreasWithCountry(sessionState.sessionValue.payload.affiliate[0].country_name)

            }
          }
        }
      }
    }
    if ((sessionState && sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.user && sessionState.sessionValue.payload.user.id && sessionState.country != '') || localStorage.getItem('country')) {
      if (sessionState.sessionValue.payload && sessionState.sessionValue.payload.user?.active_library && AllLibId.includes(sessionState.sessionValue.payload.user?.active_library)) {
        settoshowLibDropdown(true)
        let active_lib = sessionState.sessionValue.payload.user?.active_library
        let lid = localStorage.getItem('active_library');
        if (lid) {
          props.SetselectedLibrary(lid)
          getDataWithLibraryAndCountry(lid)
        } else {
          getDataWithLibraryAndCountry(sessionState.sessionValue.payload.user?.active_library)
          props.SetselectedLibrary(active_lib)
          localStorage.setItem('active_library', active_lib);
        }
      }
      else {
        if (tocheckLib) {
          settoshowLibDropdown(true)
          if (localStorage.getItem('active_library')) {
            let lid = localStorage.getItem('active_library');
            props.SetselectedLibrary(lid)
            getDataWithLibraryAndCountry(lid)
          } else {
            props.SetopenLibraryModal(true)
          }
          settocheckLib(false)
        }
      }
      return;
    }


  }, [sessionState, AllLibId, tocheckLib]);


  React.useEffect(() => {
    if (props.selectedLibrary && props.selectedLibrary != '') {
      let headerService = new AppHeaderService();
      let userId = 1;
      if (sessionState && sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.user && sessionState.sessionValue.payload.user.id) {
        userId = sessionState.sessionValue.payload.user.id
      }
      headerService.UpdateUserActiveLibrary({ library: props.selectedLibrary, user_id: userId }).then((da) => {
        getDataWithLibraryAndCountry(props.selectedLibrary)
        if (props.selectedLibrary != '') {
          localStorage.setItem("active_library", props.selectedLibrary)
          settoshowLibDropdown(true)
        }
      }).catch((err) => {})
      props.SetopenLibraryModal(false)
    }
  }, [props.selectedLibrary]);

  const getDataWithLibraryAndCountry = (library_id) => {
    props.SetselectedLibraryid(library_id)
    let headerService = new AppHeaderService();
    let resData = { affiliate_id: localStorage.getItem('country'), _dispatch: dispatch, _categoriesSelector: categoriesSelector, _affiliateSelector: affiliateSelector, _setAffiliate: setAffiliate };
    headerService.GetAffiliateByQuery(resData).then((data) => {
      if (Array.isArray(data.data)) {
        let countries = data.data;
        let country_th_area = countries[0].therapeutic_area_ids

        headerService.getLibraryById({ library_id: library_id }).then((da) => {
          let thArea = []
          let allth = []

          if (da.data.length > 0 && da.data[0].library_fixed_filter) {
            thArea = JSON.parse(da.data[0].library_fixed_filter)
          }
          headerService.GetAllTherapeuticAreas().then((data) => {
            if (Array.isArray(data.data)) {
              let list = [];
              let inArray = [];
              list.push({ key: 0, label: 'All', status: true })
              let idArray = [];
              for (let i = 0; i < data.data.length; i++) {
                for (let j = 0; j < thArea.length; j++) {
                  if (thArea[j] == data.data[i].therapeutic_area_id) {
                    let label = (data.data[i].therapeutic_area_name).charAt(0).toUpperCase() + (data.data[i].therapeutic_area_name).slice(1)
                    label = props.translations[label] ? props.translations[label] : label
                    list.push({ key: data.data[i].therapeutic_area_id, label: label, status: true })
                    // inArray.push(data.data[i].therapeutic_area_id)
                    // idArray.push(data.data[i].therapeutic_area_id)
                  }
                  if (country_th_area.includes(data.data[i].therapeutic_area_id)) {

                    allth.push({ key: data.data[i].therapeutic_area_id, label: (data.data[i].therapeutic_area_name).charAt(0).toUpperCase() + (data.data[i].therapeutic_area_name).slice(1), status: true })
                    // inArray.push(data.data[i].therapeutic_area_id)
                    idArray.push(data.data[i].therapeutic_area_id)
                  }
                  inArray.push(data.data[i].therapeutic_area_id)
                }
              }
              setallThAreaData(allth)

              props.SetFilterId(idArray.toString());//floriane requirement comments
              setChipSelect("All");//floriane requirement comments
              setChipData(list)
              setidData(idArray)
              props.SetallThArea(idArray)
            }
          }).catch((err) => {})
        }).catch((err) => {})

      }
    }).catch((err) => {})
  }

  React.useEffect(() => {
    let url = new URL(window.location.href);

    let urlHash = decodeURIComponent(url.hash);
    if (!urlHash.includes('#')) {
      let country_name = localStorage.getItem('country')
      if (country_name) {
        props.SetFilterId(idData.toString())
      } else {
        props.SetFilterId(idData.toString())
      }
    }

  }, [props.SetFilterId, idData]);


  React.useEffect(() => {
    if (sessionState && sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.token) {
      let localtoken = localStorage.getItem('jwtToken')
      if (localtoken == sessionState.sessionValue.payload.token) {
       
      } else {

      }
    }

  }, [sessionState]);

  /**Function changes selected chip */
  const handleFilterChange = (value, id) => {
    (new AppHeaderService()).TALinkCLickEvent(value);
    setChipSelect(value.toString());
    props.setFilter(value);
    if (value == 'All') {
      props.SetFilterId('')
      navigate('/home')
    }
    if (id == 0) {
      props.SetFilterId(idData.toString())
    } else {
      props.SetFilterId(id)
      navigate('/home')
    }
  }

  const [searchTerm, setSearchTerm] = React.useState(props.search);
  const [openDP, setOpenDP] = React.useState(false);
  const [filteredTerms, setFilteredTerms] = React.useState([]);
  const [newSearchTerm, setNewSearchTerm] = React.useState('');
  const [simulationPopUp, SetSimulationPopUp] = React.useState(false);
  const [simulationPopUpTest, SetSimulationPopUpTest] = React.useState(false);
  const [searchSuggestions, setSearchSuggestions] = React.useState([]);

  let searchService = new SearchService();
  const searchItem = (e) => {
    if(searchTerm){
      props.setSearch(((searchTerm.split('___')[0]).toString()));
      for (let i = 0; i < searchSuggestions.length; i++) {
        if (searchSuggestions[i].type == 'Speaker' && searchSuggestions[i].title == ((searchTerm.split('___')[0]).toString())) {
          navigate('/search/' + searchSuggestions[i].id + '?speaker_name=' + ((searchTerm.split('___')[0]).toString()));
          return;
        }
      }
      navigate('/search/' + encodeURIComponent(searchTerm.split('___')[0]));
    }
  };
  const handleSearchTerm = (e) => {
    if (e.keyCode == 13) {
      let search_term = e.target.value.split("___")[0];
      setSearchTerm(search_term.split("___")[0]);
      props.setSearch(search_term.toString());
      for (let i = 0; i < searchSuggestions.length; i++) {
        if (searchSuggestions[i].type == 'Speaker' && searchSuggestions[i].title == search_term) {
          navigate('/search/' + searchSuggestions[i].id + '?speaker_name=' + search_term);
          return;
        }
      }
      navigate('/search/' + encodeURIComponent(search_term));
    }
  }

  React.useEffect(() => {
    if (idData.length > 0 && sessionState && sessionState.sessionValue && sessionState.sessionValue.payload && sessionState.sessionValue.payload.affiliate && sessionState.sessionValue.payload.affiliate[0] && sessionState.sessionValue.payload.affiliate[0].country_code && Array.isArray(allThAreaData) && allThAreaData.length > 0 && Array.isArray(idData) && idData.length > 0 && props.selectedCountry != '') {
      if (preventPropogation) {
        return;

      }
      SetPreventPropogation(true);
      let special = sessionState.sessionValue.payload.decodedToken.specialty ? sessionState.sessionValue.payload.decodedToken.specialty : ""
      let audiance = sessionState.sessionValue.payload.decodedToken.profession ? sessionState.sessionValue.payload.decodedToken.profession : ""

      let countryName = localStorage.getItem('country') ?? '';
      let localeName = localStorage.getItem('lang') ?? '';
      let loginROute = '';
      if (props.selectorValueSession?.sessionValue?.payload?.loginroute) {
        loginROute = props.selectorValueSession.sessionValue.payload.loginroute
      }
      if (sessionState?.sessionValue?.payload?.user?.language) {
        localeName = sessionState?.sessionValue?.payload?.user?.language;
        if (countryName == 'Switzerland') {
          if (localeName == 'de-CH') {
            localeName = 'German'
          } else if (localeName == 'fr_CH') {
            localeName = 'French'
          }
        } else {
          if (localeName == 'nl-BE') {
            localeName = 'Dutch'
          } else if (localeName == 'fr-BE') {
            localeName = 'French'
          }
        }
      }
      let homebaseModel = GetHomeServiceModel();
      searchService.getSearchSuggestions(idData.toString(), props.selectedCountry, props.selectedLibraryid, special, audiance, countryName, localeName, homebaseModel.loginRoute, homebaseModel).then(respMain => {
        let country_code = sessionState.sessionValue.payload.affiliate[0].country_code;
        let countryCOdeArray = homebaseModel.country;
        let mainData = respMain.data
        let list = []
        for (let i = 0; i < mainData.length; i++) {
          let approved = false;
          if (mainData[i].content_approved || mainData[i].countries_approved) {
            for (const key in mainData[i].content_approved) {
              if (key == country_code || countryCOdeArray.includes(key)) {
                let content_approved = mainData[i].content_approved[key].status
                if (content_approved == 'Active') {
                  approved = true
                }
              }
            }
            for (const key in mainData[i].countries_approved) {
              if (key == country_code || countryCOdeArray.includes(key)) {
                let content_approved = mainData[i].countries_approved[key].status
                if (content_approved == 'Active') {
                  approved = true
                }
              }
            }
            if (approved) {
              mainData[i].title = mainData[i].title + "___" + mainData[i].description
              if (mainData[i].content_tags) {
                mainData[i].title = mainData[i].title + "___" + mainData[i].content_tags.join("___")
              }
              for (let j = 0; j < allThAreaData.length; j++) {
                if (mainData[i].therapeutic_area_id && mainData[i].therapeutic_area_id.includes(allThAreaData[j].key)) {
                  mainData[i].title = mainData[i].title + "___" + allThAreaData[j].label
                }
              }
              if (mainData[i].content_type == 'video') {
                mainData[i].content_type = 'videos'
              }
              if (mainData[i].content_type == 'playlist') {
                mainData[i].content_type = 'Playlists'
              }
              if (mainData[i].content_type == 'podcast') {
                mainData[i].content_type = 'Podcast'
              }
              mainData[i].content_type = props.translations[mainData[i].content_type] ? props.translations[mainData[i].content_type] : mainData[i].content_type
              mainData[i].type = mainData[i].content_type

              list.push(mainData[i])
            }
          }
        }
        setSearchSuggestions(list);
        searchService.GetSpeakerByQuery().then(resp => {
          let tags = []
          for (let i = 0; i < resp.data.length; i++) {
            list.push({ title: resp.data[i].name, id: resp.data[i].speaker_id, type: "Speaker", content_type: props.translations['Speakers'] ? props.translations['Speakers'] : 'Speakers', description: resp.data[i].name })
          }
          setSearchSuggestions(list);
          SetPreventPropogation(false);
        }).catch(err => {  SetPreventPropogation(false); });
      }).catch(err => {  SetPreventPropogation(false); });
    }
  }, [idData, props.selectedCountry, props.selectedLibraryid, sessionState.sessionValue, allThAreaData]);

  const GetHomeServiceModel = (specialty = '', profession = '') => {
    let countryName = localStorage.getItem('country') ?? '';
    let localeName = localStorage.getItem('lang') ?? '';
    let loginROute = '';
    let countryArray = []
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (props.selectorValueSession.sessionValue.payload.loginroute) {
          loginROute = props.selectorValueSession.sessionValue.payload.loginroute;
        }
        if (props.selectorValueSession.sessionValue.payload.decodedToken) {
          if (props.selectorValueSession.sessionValue.payload.decodedToken) {
            let _spec = props.selectorValueSession.sessionValue.payload.decodedToken.specialty ? props.selectorValueSession.sessionValue.payload.decodedToken.specialty : "";
            let _prof = props.selectorValueSession.sessionValue.payload.decodedToken.profession ? props.selectorValueSession.sessionValue.payload.decodedToken.profession : "";
            setSpeciality(_spec)
            setaudiance(_prof)
            let brandIdsArr = [];
            let taIdsArr = [];
            let indIdsArray = [];
            if (props.selectorValueSession.sessionValue.payload.user) {
              let userTA = props.selectorValueSession.sessionValue.payload.user.therapeutic_area;
              let categories = userTA && Array.isArray(userTA) ? userTA : [];
              let countryTAList = [];
              let countryCat1Ids = [];
              let countryCat2Ids = [];
              if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)) {
                for (let k = 0; k < props.selectorValueSession.sessionValue.payload.affiliate.length; k++) {
                  if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].therapeutic_area_ids)) {
                    countryTAList = countryTAList.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].therapeutic_area_ids);
                  }
                  if (props.selectorValueSession.sessionValue.payload.affiliate[k].speciality_customization) {
                    if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].category_1_ids)) {
                      countryCat1Ids = countryCat1Ids.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].category_1_ids);
                    }
                    if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate[k].category_2_ids)) {
                      countryCat2Ids = countryCat2Ids.concat(props.selectorValueSession.sessionValue.payload.affiliate[k].category_2_ids);
                    }
                  }

                }
              }
              if (categories?.length > 0) {
                for (let i = 0; categories?.length > i; i++) {
                  let ta_id = categories[i].therapeutic_area_id;
                  if (countryTAList?.includes(ta_id)) {
                    taIdsArr.push(ta_id);
                  }

                  let brand_id = categories[i].brand_id;
                  if (countryCat1Ids?.includes(brand_id)) {
                    brandIdsArr.push(brand_id);
                  }

                  let ind_id = categories[i].indication_id;
                  if (countryCat2Ids?.includes(ind_id)) {
                    if (ind_id !== null) {
                      indIdsArray.push(ind_id);
                    }
                  }

                }
              }
            }
            let taArray = [];
            if (props.allThArea?.includes(',')) {
              taArray = props.allThArea.split(',');
            } else {
              taArray.push(props.allThArea);
            }
            if (props.selectorValueSession?.sessionValue?.payload?.user?.language) {
              localeName = props.selectorValueSession?.sessionValue?.payload?.user?.language;
              if (countryName == 'Switzerland') {
                if (localeName == 'de-CH') {
                  localeName = 'German'
                } else if (localeName == 'fr_CH') {
                  localeName = 'French'
                }
              } else {
                if (localeName == 'nl-BE') {
                  localeName = 'Dutch'
                } else if (localeName == 'fr-BE') {
                  localeName = 'French'
                }
              }
            }
            let affiliateArray = GetAffiliateCodesArray();
            let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
            let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, _spec, _prof, taArray, 'Active', brandIdsArr, indIdsArray, '', countryName, localeName, loginROute);
            return model;
          }
        }
      }
    }
    let taFilter = props.allThArea;
    if (typeof taFilter == 'string') {
      taFilter = taFilter.split(',')
    }
    let affiliateArray = GetAffiliateCodesArray();
    let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
    let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, specialty, profession, taFilter, 'Active', brandIdsArrData, indIdsArrData, '', countryName, localeName, loginROute);
    return model;
  }

  const GetAffiliateCodesArray = () => {
    let list = []
    if (props.selectorValueSession) {
      if (props.selectorValueSession.sessionValue.payload) {
        if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)) {
          let affiliates = props.selectorValueSession.sessionValue.payload.affiliate
          for (let i = 0; i < affiliates.length; i++) {
            if (affiliates[i]) {
              list.push(affiliates[i].country_code);
            }
          }
        }
      }
    }
    return list;
  }

  React.useEffect(() => {
    function getUserSelectedBrandData() {
      let userBrandService = new UserBrandService();
      userBrandService.getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData).then((data) => {
        
        SetBrandCompletion(true);
      });
    }
    getUserSelectedBrandData();
  }, []);

  React.useEffect(() => {
    if (props.filter == 'All') {
      setChipSelect("All");
      GetHomeServiceModel();
      if (idData.length > 0) {
        props.SetFilterId(idData.toString())
      }

    }
  }, [props]);// [props.filter,chipSelect, props.filterId]);

  const handleRedirect = () => {
    setChipSelect("All");
    props.setFilter("All")
    props.setFilter("All")
    props.SetFilterId(idData.toString())
    navigate('/home')
  }

  React.useEffect(() => {
    if (localStorage.getItem('HCP') == 'true') {
      setHCPBool(true)
    } else {
      setHCPBool(false)
    }
  }, [localStorage.getItem('HCP'), HCPBool]);

  const UserDropdown = () => {
    const prevOpen = React.useRef(open);
    const prevOpenMob = React.useRef(openMob);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
      if (prevOpenMob.current === true && openMob === false) {
        anchorRefMob.current.focus();
      }
      prevOpen.current = open;
      prevOpenMob.current = openMob;
    }, [open, openMob]);

    /**Toggle function toogle dropdown*/
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    /**Function close event for dropdown */
    const handleClose = (event) => {
      setOpen(false);
    };

    /**Tab event of keydown of management dropdown */
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }


    const anchorRef = React.useRef(null);
    const anchorRefMob = React.useRef(null);
    return (
      <div className='mangDropdownSec vv'>
        <Modal open={simulationPopUp}>
          <Simulation closePopUp={() => { SetSimulationPopUp(false) }} />
        </Modal>
        {!isRoleHCP ?
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className='custom-btn-font-size userBtn'
          >
            {props.translations['Management'] ? props.translations['Management'] : 'Management'}
          </Button> : null}
        {sessionState?.sessionValue?.payload?.simulationCheck && <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={() => {
            let service = new SimulationService();
            service.ExitSimulationSession(navigate, dispatch);
          }}
          className='custom-btn-font-size userBtn'
        >
          <span style={{ whiteSpace: 'nowrap' }}>{props.translations['ExitSimulator'] ? props.translations['ExitSimulator'] : 'Exit Simulator'}</span>
        </Button>}
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className='mangDropdown'>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <MenuList ref={menuRef} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} style={{ padding: '0px' }}>
                  <MenuItem className='menuItem' onClick={() => { navigate('/content-update/reload'); handleClose() }}>
                    <span className='menulistItem' >
                      {props.translations['Upload a video'] ? props.translations['Upload a video'] : 'Upload a content'}
                    </span>
                  </MenuItem>
                  <MenuItem className='menuItem' onClick={() => { navigate('/content-management'); handleClose() }}>
                    <span className='menulistItem' >
                      {props.translations['Video Management'] ? props.translations['Video Management'] : 'Content Management'}
                    </span>
                  </MenuItem>
                  <MenuItem className='menuItem' onClick={() => { navigate('/playlist-management'); handleClose() }}>
                    <span className='menulistItem' >
                      {props.translations['Playlist Management'] ? props.translations['Playlist Management'] : 'Playlist Management'}
                    </span>
                  </MenuItem>
                  {isRoleAdmin || isRolePublisher ?
                    <MenuItem className='menuItem' onClick={() => { navigate('/speaker-management'); handleClose() }}>
                      <span className='menulistItem' >
                        {props.translations['Speaker Management'] ? props.translations['Speaker Management'] : 'Speaker Management'}
                      </span>
                    </MenuItem> : ("")}
                  {isRoleAdmin ?
                    <MenuItem className='menuItem' onClick={() => { navigate('/category-management'); handleClose() }}>
                      <span className='menulistItem' >
                        {props.translations['Category Management'] ? props.translations['Category Management'] : 'Category Management'}
                      </span>
                    </MenuItem>
                    : ("")}
                  <MenuItem className='menuItem' onClick={() => { navigate('/quiz-management'); handleClose() }}>
                    <span className='menulistItem' >
                      {props.translations['Quiz Management'] ? props.translations['Quiz Management'] : 'Quiz Management'}
                    </span>
                  </MenuItem>
                  {isRoleAdmin ?
                    <MenuItem className='menuItem' onClick={() => { navigate('/user-management'); handleClose() }}>
                      <span className='menulistItem' >
                        {props.translations['User Management'] ? props.translations['User Management'] : 'User Management'}
                      </span>
                    </MenuItem> : ("")}
                  {isRoleAdmin ?
                    <MenuItem className='menuItem' onClick={() => { navigate('/translation-management'); handleClose() }}>
                      <span className='menulistItem' >
                        {props.translations['Translation Management'] ? props.translations['Translation Management'] : 'Translation Management'}
                      </span>
                    </MenuItem> : ("")}
                  {isRolePublisher ?
                    <MenuItem className='menuItem' onClick={() => { navigate('/country-management'); handleClose() }}>
                      <span className='menulistItem' >
                        {props.translations['Country Management'] ? props.translations['Country Management'] : 'Country Management'}
                      </span>
                    </MenuItem> : ("")}
                  {isRolePublisher ?
                    <MenuItem className='menuItem' onClick={() => { navigate('/live-event'); handleClose() }}>
                      <span className='menulistItem' >
                        {props.translations['Add On24 Events'] ? props.translations['Add On24 Events'] : 'Add On24 Events'}
                      </span>
                    </MenuItem>
                    : ("")}
                  {isRolePublisher ?
                    <MenuItem className='menuItem' onClick={() => { navigate('/event-management'); handleClose() }}>
                      <span className='menulistItem' >
                        {props.translations['Event Management'] ? props.translations['Event Management'] : 'Event Management'}
                      </span>
                    </MenuItem>
                    : ("")}
                  {!isRoleHCP || true ?
                    <MenuItem className='menuItem' onClick={() => { setOpen(false); SetSimulationPopUp(true) }}>
                      <span className='menulistItem' >
                        {props.translations['Simulation'] ? props.translations['Simulation'] : 'Simulation'}
                      </span>
                    </MenuItem> : ("")}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
  const TestLabel = () => {
    const prevOpenLib = React.useRef(openLib);
    const prevOpenMobLib = React.useRef(openMobLib);
    React.useEffect(() => {
      if (setOpenLib.current === true && openLib === false) {
        anchorRef.current.focus();
      }
      if (prevOpenMobLib.current === true && openMobLib === false) {
        anchorRefMob.current.focus();
      }
      prevOpenLib.current = openLib;
      prevOpenMobLib.current = openMobLib;
    }, [openLib, openMobLib]);


    /**Toggle function toogle dropdown*/
    const handleToggleTest = () => {
      setOpenLib((prevOpenLib) => !prevOpenLib);
    };

    /**Function close event for dropdown */
    const handleCloseTest = (event) => {
      setOpenLib(false);
    };

    const getLibraryName = (selectedLibrary, libraryData) => {
      for (let i = 0; i < libraryData.length; i++) {
        if (libraryData[i].library_id == selectedLibrary) {
          return libraryData[i].library_name
        }
      }
    }

    const anchorRef = React.useRef(null);
    const anchorRefMob = React.useRef(null);
    return (
      <>
        {toshowLibDropdown ? <div className='mangDropdownSec'>
          {toshowLibDropdown && <Button
            ref={anchorRef}
            aria-controls={openLib ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggleTest}
            className='userBtn'
          >{getLibraryName(props.selectedLibrary, props.libraryData)}
          </Button>}

          {toshowLibDropdown && <Popper open={openLib} anchorEl={anchorRef.current} role={undefined} transition disablePortal className='mangDropdown'>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseTest}>
                    <MenuList autoFocusItem={openLib} id="menu-list-grow" >
                      {props.libraryData.map(text => {
                        return (<>
                          <MenuItem onClick={(event) => {
                            props.Setplay(false);
                            props.SetPlayerShow(false);
                            props.SetselectedLibrary(text.library_id)
                            setOpenLib(false);
                            navigate('/home')
                          }} className='menuItem'>
                            <span className='menulistItem' >
                              {text.library_name}
                            </span>
                          </MenuItem>
                        </>)
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>}
        </div> : ""}
      </>
    )
  }


  const [countryLocale, SetCountryLocale] = React.useState(localStorage.getItem('lang'));
  const [countryWidgetID, SetCountryWidgetID] = React.useState("");
  const [countryDomainURL, SetCountryDomainURL] = React.useState("");
  const [displayOnTop, SetDisplayOnTop] = React.useState(false);

  React.useEffect(() => {
    let locale = localStorage.getItem('lang');
    if (sessionState?.sessionValue?.payload?.user?.language) {
      locale = sessionState?.sessionValue?.payload?.user?.language
    }

    let countryName = localStorage.getItem('country');
    let countryLocale = "";
    let countryWidgetID = "";
    let countryDomainURL = "";
    let dot = false;

    switch (countryName) {
      case 'Spain':
        countryLocale = "es-ES";
        countryWidgetID = 42;
        countryDomainURL = "lillyplay.es";
        break;
      case 'Italy':
        countryLocale = "it-IT";
        countryWidgetID = 44;
        countryDomainURL = "play.lilly.it";
        break;
      case 'Poland':
        countryLocale = "pl-PL";
        countryWidgetID = 45;
        countryDomainURL = "lillypro.pl";
        break;
      case 'Germany':
        countryLocale = "de-DE";
        countryWidgetID = 55;
        countryDomainURL = "lillyplay.de";
        break;
      case 'Brazil':
        countryLocale = "pt-BR";
        countryWidgetID = 56;
        countryDomainURL = "lillyplay.com.br";
        break;
      case 'UK':
        countryLocale = "en-GB";
        countryWidgetID = 57;
        countryDomainURL = "lillyplay.co.uk";
        break;
      case 'Czech':
        countryLocale = "cs-CZ";
        countryWidgetID = 58;
        countryDomainURL = "lillyplay.cz";
        break;
      case 'Romania':
        countryLocale = "ro-RO";
        countryWidgetID = 61;
        countryDomainURL = "lillyplay.ro";
        break;
      case 'Slovakia':
        countryLocale = "sk-SK";
        countryWidgetID = 62;
        countryDomainURL = "lillyplay.sk";
        break;
      case 'Sweden':
        countryLocale = "sv-SE";
        countryWidgetID = 63;
        countryDomainURL = "lillyplay.se";
        break;
      case 'Switzerland':
        if (locale) {
          if (locale == 'de-CH') {
            countryLocale = "de-CH";
          } else {
            countryLocale = 'fr-CH';
          }
        }
        countryWidgetID = 33;
        countryDomainURL = "lillyplay.ch";
        dot = true;
        break;
      case 'Portugal':
        countryLocale = "pt-PT";
        countryWidgetID = 60;
        countryDomainURL = "lillyplay.pt";
        break;
      case 'Finland':
        countryLocale = "fi-FI";
        countryWidgetID = 59;
        countryDomainURL = "lillyplay.fi";
        break;
      case 'Netherlands':
        countryLocale = "nl-NL";
        countryWidgetID = 54;
        countryDomainURL = "play.lilly.nl";
        break;
      case 'Austria':
        countryLocale = "de-AT";
        countryWidgetID = 53;
        countryDomainURL = "lillymed.at";
        break;
      case 'Belgium':
        if (locale) {
          if (locale == 'nl-BE') {
            countryLocale = "nl-BE";
          } else {
            countryLocale = 'fr-BE';
          }
        }
        countryWidgetID = 16;
        countryDomainURL = "play.lilly.be";
        dot = true;
        break;
      case 'France':
        countryLocale = "fr-FR";
        countryWidgetID = 49;
        countryDomainURL = "play.lilly.fr";
        break;
      default:
        countryLocale = "";
        break;
    }
    SetCountryLocale(countryLocale);
    SetCountryWidgetID(countryWidgetID);
    SetCountryDomainURL(countryDomainURL);
    SetDisplayOnTop(dot);
  }, [sessionState]);
  React.useEffect(() => {
  }, [searchArea, toshowLibDropdown, chipData, isHCP, isLacam, HCPBool])
  return (
    <>
      {/* Header Section */}
      {/* <AppBar position="sticky" className={scroll ? 'appbarBck scroll' : 'appbarBck'}> */}
      <AppBar position="sticky" className='appbarBck'>
        <Grid id='cookieBannerSec'>
          {
            (localStorage.getItem('HCP') === 'false' || localStorage.getItem('simulationAdmin') !== null) ?
              countryWidgetID !== '' ?
                <CookieBanner displayOnTop={displayOnTop} countryDomainURL={countryDomainURL} countryLocale={countryLocale} countryWidgetID={countryWidgetID} />
                : null
              : null
          }
        </Grid>
        <Grid id='logoBrandingSec' className='hdOuter'>
          {searchArea === false ?
            <Toolbar className='containerAlign'>
              <Typography className='title' variant="h6" noWrap>
                <img src={lillylogo} onClick={() => { handleRedirect(); }} className='header_lillylogo' alt='lilly_logo' width={180} height={'auto'} />
              </Typography>
              <div className={classes.search}>
                <Autocomplete
                  PopperComponent={(props) => {
                    return <Popper id='desktop_searchbar' {...props} />;
                  }}
                  open={openDP}
                  onOpen={() => { if (searchTerm) { setOpenDP(true); } }}
                  onClose={() => setOpenDP(false)}
                  inputValue={searchTerm.split('___')[0]}
                  onInputChange={(e, value) => {
                    setSearchTerm(value);
                    if (value == null) {
                      setFilteredTerms([]);
                    } else {
                      let newSuggestionsData = searchSuggestions.filter(function (item) {
                        let title = item.title
                        title = title.toLowerCase();
                        return (title.includes(value.toLowerCase()));
                      });
                      setFilteredTerms(newSuggestionsData);
                      const found = searchSuggestions.some(el => (el.title.toLowerCase() === value.toLowerCase()));
                      if (!found) { setNewSearchTerm(value); } else { setNewSearchTerm(''); }
                    }
                    if (!value) { setOpenDP(false); }
                  }}
                  value={props.search}
                  classes={{ root: 'inputRoot', input: classes.autoCompleteInput }}
                  onChange={handleSearchTerm}
                  onKeyDown={handleSearchTerm}
                  freeSolo
                  options={filteredTerms.length > 0 || newSearchTerm != '' ? filteredTerms.sort((a, b) => b.content_type.toString().localeCompare(a.content_type.toString())) : searchSuggestions.length > 0 ? searchSuggestions.sort((a, b) => b.content_type.toString().localeCompare(a.content_type.toString())) : []}
                  groupBy={(option) => option.content_type.charAt(0).toUpperCase() + option.content_type.slice(1)}
                  getOptionLabel={(option) => typeof option === 'string' ? option : option.title}
                  renderOption={(option) => typeof option === 'string' ? option : option.title.split('___')[0]}
                  renderInput={(params) =>
                    <TextField {...params} placeholder={props.translations['Search contents'] ? props.translations['Search contents'] : 'Search contents'} className='search-textfield' name="search_name" size="small" type='string'
                      InputLabelProps={{ shrink: false }}
                      {...params.InputProps.disableUnderline = true}
                      {...params.InputProps.endAdornment = <InputAdornment position="end" onClick={() => searchItem()}><IconButton className="searchDesktopIcn"><img src={search} className='searchRootIcn' alt='Search Icon' /></IconButton></InputAdornment>}
                    // InputProps={{ 
                    //   disableUnderline: true, 
                    //   endAdornment: <InputAdornment position="end" onClick={() => searchItem()}><IconButton className="searchDesktopIcn"><img src={search} className='searchRootIcn' alt='Search Icon' /></IconButton></InputAdornment>
                    // }}
                    />}
                />
              </div>
              <IconButton aria-label="search" color="inherit" className='mobilesearchIcon' onClick={() => setSearchArea(true)} >
                <img src={HeaderSearchIconM} className='header_searchIconM' alt='Search Icon' />
              </IconButton>
              <AppSidebar Setallcategory={props.Setallcategory} allcategory={props.allcategory} sessionValue={sessionState.sessionValue} category_id={idData} country={props.selectedCountry} selectedLibrary={props.selectedLibrary} translations={props.translations} chipData={chipData} />
            </Toolbar>
            : null}
          <React.Fragment>
            {searchArea === true ?
              <Autocomplete
                PopperComponent={(props) => {
                  return <Popper id='mobile_searchbar' {...props} />;
                }}
                open={openDP}
                onOpen={() => { if (searchTerm) { setOpenDP(true); } }}
                onClose={() => setOpenDP(false)}
                inputValue={searchTerm.split('___')[0]}
                onInputChange={(e, value) => {
                  setSearchTerm(value);
                  if (value == null) {
                    setFilteredTerms([]);
                  } else {
                    let newSuggestionsData = searchSuggestions.filter(function (item) {
                      let title = item.title.toLowerCase();
                      return title.includes(value.toLowerCase());
                    });
                    setFilteredTerms(newSuggestionsData);
                    const found = searchSuggestions.some(el => el.title.toLowerCase() === value.toLowerCase());
                    if (!found) { setNewSearchTerm(value); } else { setNewSearchTerm(''); }
                  }
                  if (!value) { setOpenDP(false); }
                }}
                value={props.search}
                className='searchTxt'
                onChange={handleSearchTerm}
                onKeyDown={handleSearchTerm}
                freeSolo
                options={filteredTerms.length > 0 || newSearchTerm != '' ? filteredTerms.sort((a, b) => b.content_type.toString().localeCompare(a.content_type.toString())) : searchSuggestions.length > 0 ? searchSuggestions.sort((a, b) => b.content_type.toString().localeCompare(a.content_type.toString())) : []}
                groupBy={(option) => option.content_type.charAt(0).toUpperCase() + option.content_type.slice(1)}
                getOptionLabel={(option) => typeof option === 'string' ? option : option.title}
                renderOption={(option) => typeof option === 'string' ? option : option.title.split('___')[0]}
                renderInput={(params) =>
                  <TextField {...params} placeholder={props.translations['Search contents'] ? props.translations['Search contents'] : 'Search contents'} name="search_name" size="small" type='string'
                    InputLabelProps={{ shrink: false }}
                    {...params.InputProps.disableUnderline = true}
                    {...params.InputProps.startAdornment = <InputAdornment position="start"><KeyboardArrowLeftIcon onClick={() => setSearchArea(false)} /></InputAdornment>}
                    {...params.InputProps.endAdornment = <InputAdornment position="end" onClick={() => searchItem()}><IconButton><img src={search} className='searchRootIcn' alt='Search Icon' /></IconButton></InputAdornment>}
                  // InputProps={{ 
                  //   disableUnderline: true,
                  //   startAdornment: <InputAdornment position="start"><KeyboardArrowLeftIcon onClick={() => setSearchArea(false)} /></InputAdornment>,
                  //   endAdornment: <InputAdornment position="end" onClick={() => searchItem()}><IconButton><img src={search} className='searchRootIcn' alt='Search Icon' /></IconButton></InputAdornment>
                  // }}
                  />}
              />
              : null}
          </React.Fragment>
        </Grid>
        {/* <Grid id='quickLinksSec' container className={classes.slideSec} style={scroll ? { display: 'none' } : { display: 'block' }}> */}
        <Grid id='quickLinksSec' container className={classes.slideSec}>
          <Grid container className={classes.slideSecInn} justifyContent="space-between">
            {toshowLibDropdown ?
              <Grid item lg={6} xs={12} sm={8} md={8}>
                {/* Chip Section */}
                <div className='navigation'>
                  <Grid className='tag-list listSec'>
                    <Box component="ul" className={classes.paper}>
                      {chipData.map((data) => {
                        return (
                          data.status === true ?
                            <li key={data.key} className='disLine'>
                              <Chip variant="outlined"
                                label={props.translations[data.label] ? props.translations[data.label] : data.label}
                                className={(chipSelect === data.label ? classes.chip + ' chip_selected' : classes.chip)}
                                onClick={() => handleFilterChange(data.label, data.key)} />
                            </li> : null
                        )
                      })}
                    </Box>
                  </Grid>
                </div>
                {/* End Chip Section */}
              </Grid>
              : <Grid item lg={8} xs={12} sm={8} md={8}>
                {/* Chip Section */}
                <div className='navigation'>
                  <Grid className='tag-list listSec'>
                    <Box component="ul" className={classes.paper}>
                      {chipData.map((data) => {
                        return (
                          data.status === true ?
                            <li key={data.key} className='disLine'>
                              <Chip variant="outlined"
                                label={props.translations[data.label] ? props.translations[data.label] : data.label}
                                className={(chipSelect === data.label ? classes.chip + ' chip_selected' : classes.chip)}
                                onClick={() => handleFilterChange(data.label, data.key)} />
                            </li> : null
                        )
                      })}
                    </Box>
                  </Grid>
                </div>
                {/* End Chip Section */}
              </Grid>
            }
            <Grid item lg={4} xs={12} sm={4} md={4} className='textRight'>
              {toshowLibDropdown ? <TestLabel /> : null}

              {
                (isHCP == 'false' && !isLacam) ? <Button disabled
                  // ref={anchorRef}
                  aria-haspopup="true"
                  className='custom-btn-font-size userBtn'
                  style={{ visibility: 'hidden' }}
                >Hello World</Button> : null
              }
              {(isHCP == 'false' || sessionState?.sessionValue?.payload?.simulationCheck) && isLacam &&
                <Button onClick={() => { navigate('/informacion-para-prescribir'); }} className='userBtn'>
                  {props.translations['informacion-para-prescribir'] ? props.translations['informacion-para-prescribir'] : 'Informacion Para Prescribir'}
                </Button>
              }
              <span className='desktop-only'>
                {HCPBool ? <UserDropdown /> : null}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
      {/* End Header Section */}
    </>
  );
});

export default AppHeader;