/* eslint-disable no-unused-vars */
// import React from 'react';
import { ContentService } from '../Service/content-service';
import { Grid } from '@material-ui/core';
import './content-update.css';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Breadcrumb from '../../../layout/breadcrumb';
// import detailsIcon from '../../../public/img/details_icon.png';
import doneIcon from '../../../public/img/check.svg';
// import uploadIcon from '../../../public/img/RedUpload.png';
import { useNavigate } from "react-router-dom";
import detailsIcon from '../../../public/img/details_icons.svg';
// import redDoneSmall from '../../../public/img/RedDoneSmall.png';
import Stepper from '@material-ui/core/Stepper';
import { KalturaService } from '../Service/KalturaService';
import uploadIcon from '../../../public/img/upload-1x.png';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DoneStep from '../Components/DoneStep';
import UploadStep from '../Components/UploadStep';
import ContentUpdate from './content-update';
import React from 'react';
function ColorlibStepIcon(props) {
  const icons = {
    1: <img src={uploadIcon} className='content_uploadIcon active' alt='upload icon' />,
    2: <img src={detailsIcon}
      className={(props.active === true || props.completed === true) && props.icon === 2 ?
        'content_uploadIcon active' : 'content_uploadIcon'}
      alt='details icon' />,
    3: < img
      src={doneIcon}
      className={(props.active === true || props.completed === true) && props.icon === 3 ?
        'content_uploadIcon active' : 'content_uploadIcon'}
      alt='done icon' />,
  };
  return (
    <div>
      {icons[String(props.icon)]}
    </div>
  );
}

let contentId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
const ContentUpdateStepper = (props) => {
  let contentService = new ContentService();
  // const ContentCreate = (props) => {
  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'Content Management', path: '/content-management' },
    { value: 'Upload a Podcast or Video - Upload', path: '/content-update/' + contentId }
  ]
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [data, setData] = React.useState({});
  const [steps, setSteps] = React.useState(['Upload Kaltura ID', 'Content Details', 'Done!']);
  const [content_data, setContentData] = React.useState({});
  // const[kalturaId, SetKalturaId] = React.useState('');
  // const[thumbnailUrl, SetThumbnailUrl] = React.useState('');
  const [secondPageData, SetSecondPageData] = React.useState({});
  const [kalturaId, SetKalturaId] = React.useState('');
  const [thumbnailUrl, SetThumbnailUrl] = React.useState('');
  const [uploadPercentage, SetUploadPercentage] = React.useState(0);
  const [duration, SetDuration] = React.useState(0);
  const [showPreviewOfVideo, SetShowPreviewOfVideo] = React.useState(false);
  const [IskalturaUploaded, setIskalturaUploaded] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarType, setSnackbarType] = React.useState('');
  const [snackbarMsg, setSnackbarMsg] = React.useState('')
  // Function to go to specific step
  const goToStep = (index, data = {}) => {
    setData(data);
    setActiveStep(index);
  };
  React.useEffect(() => {
    if (kalturaId.length > 0) {
      setActiveStep(1);
    }
  }, [kalturaId])
  React.useEffect(() => {
    let model = new KalturaService();
    let uri = window.location.href;
    let videoId = uri.split('/')[uri.split('/').length - 1];
    if (videoId !== "content-update") {
      Promise.all([
        model.GetKalturaSession(),
        contentService.GetContent(videoId)
      ]).then((response) => {
        const data = response[1];
        SetSecondPageData(data);
        if (data.length > 0) {
          SetKalturaId(data[0].kaltura_id);
          setActiveStep(1, data);

          model.CheckValidKalturaID(data[0].kaltura_id, SetDuration).then((mediaModel) => {
            if (mediaModel.totalCount > 0) {
              SetThumbnailUrl(mediaModel.thumbnailUrl);
            }
            if (Array.isArray(mediaModel?.objects)) {
              for (let i = 0; i < mediaModel?.objects.length; i++) {
                let duration = mediaModel?.objects[i].duration;
                if (parseInt(duration) > 0) {
                  SetDuration(duration);
                  break;
                }

              }
            }
          });
        } else {
          setSnackbarMsg(`The Data that you are accessing has been deleted. Redirecting to create a new content`);
          setSnackbarType('error');
          setSnackbarOpen(true);
          setTimeout(() => { navigate('/content-update/reload') }, 5000);
        }
      })
    } else {
      model.GetKalturaSession().then((data) => {}).catch((err) => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <UploadStep allcategory={props.allcategory} goToStep={goToStep} data={data} kalturaId={kalturaId}
          SetDuration={SetDuration} SetShowPreviewOfVideo={SetShowPreviewOfVideo}
          SetKalturaId={SetKalturaId} SetThumbnailUrl={SetThumbnailUrl} SetUploadPercentage={SetUploadPercentage} setIskalturaUploaded={setIskalturaUploaded} />;
      case 1:
        return <ContentUpdate allcategory={props.allcategory} goToStep={goToStep} data={data} kalturaId={kalturaId} SetShowPreviewOfVideo={SetShowPreviewOfVideo} SetDuration={SetDuration}
          contentData={secondPageData} duration={duration} showPreviewOfVideo={showPreviewOfVideo}
          thumbnailUrl={thumbnailUrl} uploadPercentage={uploadPercentage}
          setContentData={setContentData}
          SetKalturaId={SetKalturaId} SetThumbnailUrl={SetThumbnailUrl} SetUploadPercentage={SetUploadPercentage} IskalturaUploaded={IskalturaUploaded} />;
      case 2:
        return <DoneStep goToStep={goToStep} content_data={content_data} data={data} />;
      default:
        return 'Unknown Step';
    }
  }
  return (<>
    <Snackbar
      open={snackbarOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
      onClose={() => { setSnackbarOpen(false) }}>
      <Alert
        className={`snackbar ${snackbarType}`}
        onClose={() => { setSnackbarOpen(false) }}
        severity={`${snackbarType}`}>
        {snackbarMsg}
      </Alert>
    </Snackbar>
    {kalturaId.length >= 0 ?
      <Grid>
        <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
        <Grid className="content_alignCenter">
          <Grid item className="stepper_container">
            <div className="stepper_steps">
              <Stepper alternativeLabel activeStep={activeStep} className="content_updateStepperCss" >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel className='content-update-step-image content_labelColor' StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="stepper_content ds_form">
              {activeStep === steps.length ? "All Steps Set." : getStepContent(activeStep)}
            </div>
          </Grid>
        </Grid>
      </Grid> : ('')
    }
  </>
  );

}
export default ContentUpdateStepper