/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import InputAdornment from '@material-ui/core/InputAdornment';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FilterListIcon from '@material-ui/icons/FilterList';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
import Breadcrumb from '../../../layout/breadcrumb';
import Switch from '@material-ui/core/Switch';
import '../playlist-management/playlist-management.css';
import { useNavigate } from "react-router-dom";
import { PlaylistService } from '../Service/playlist-service';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import { CSVLink } from "react-csv";
import FormLabel from '@material-ui/core/FormLabel';
import {ListboxComponent, filterOptions} from '../../shared/SearchOptimize';

// Define Prototype
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
 
// Define Data Table Header
const headCells = [
  { id: 'playlistName', numeric: false, disablePadding: false, label: 'Playlist Name', sorting: true },
  { id: 'creationDate', numeric: false, disablePadding: false, label: 'Creation Date', sorting: true },
  { id: 'updatedDate', numeric: false, disablePadding: false, label: 'Last Modfication Date', sorting: true },
  { id: 'views', numeric: false, disablePadding: false, label: 'Number Of Views', sorting: true },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', sorting: true },
  { id: 'published', numeric: true, disablePadding: false, label: 'Published Country', sorting: true },
  { id: 'pending', numeric: true, disablePadding: false, label: 'Pending Country', sorting: true },
  { id: 'rejected', numeric: true, disablePadding: false, label: 'Rejected Country', sorting: true },
  { id: 'action', numeric: true, disablePadding: false, label: 'Action', sorting: false }
];

// To Create Data Table Rows
function createData(id, playlistName, countries, created_by, creationDate, updatedDate, views, status, published, pending, rejected, action, content_ids, createdBY) {
  return { id, playlistName, countries, created_by, creationDate, updatedDate, views, status, published, pending, rejected, action, content_ids, createdBY };
}

// Functions for Apply Sorting of Data Table
/**Decending comparator on header */
function descendingComparator(a, b, orderBy) {
  if(orderBy === 'creationDate' || orderBy === 'updatedDate'){
    let aDate = new Date(a[orderBy]);
    a[orderBy] = aDate.getTime();
    let bDate = new Date(b[orderBy]);
    b[orderBy] = bDate.getTime();
  }
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

/**Fetches comparator of order on header */
function getComparator(order, orderBy) {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**Sort function on header */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={!headCell.sorting}
              class={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className='visuallyHidden'>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Content Management Component
export default function EnhancedTable(props) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, SetRows] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [userData, setUserData] = React.useState([]);
  const [openFilterModal, setopenFilterModal] = React.useState(false);
  const [filterArr, SetfilterArr] = React.useState([]);
  const [csvData, SetcsvData] = React.useState([]);
  const [filterCountry, seFilterCountry] = React.useState('');
  const [filterUserId, seFilterUserId] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [fetchDataStatus, setFetchDataStatus] = React.useState(false);
  const [newContentTerm, setNewContentTerm] = React.useState('');
  const [filteredContents, setFilteredContents] = React.useState([]);
  const [setBlueColor, setFilteredBluecolor] = React.useState(false);
  const [setRedColor, setFilteredRedcolor] = React.useState(false);
  const [activeId, setActiveId] = React.useState('');
  const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
  const [selectedContent, setselectedContent] = React.useState('');
  const [selrowID, setselrowID] = React.useState('');
  const [toggleBool, setToggleBool] = React.useState(false);
  const [limit, setLimit] = React.useState(50);
  const [offSet, setoffSet] = React.useState(0);

  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [playlistview, Setplaylistview] = React.useState([]);
  const [reRenderCondition, SetReRenderCondition] = React.useState(1)
  let playlistService = new PlaylistService();

  React.useEffect(() => {
    SetSessionState(selectorValue);
  }, [selectorValue]);

  React.useEffect(() => {
    if (sessionState.sessionValue?.payload?.user) {
      let roleData = sessionState.sessionValue?.payload?.user.role_ids;
      if (roleData.includes(2) || roleData.includes(4)) {
        let allData = filterArr;
        const filteredData = allData.filter(elem => {
          if (elem.created_by && elem.created_by !== null) {
            return elem.created_by = (sessionState.sessionValue?.payload?.user?.id);
          }
        });
        if (toggleBool) {
          const filteredPending = filteredData.filter(elem => {
            if (elem.status && elem.status !== null) {
              return elem.status === 'draft';
            }
          });
          SetRows(filteredPending);
        } else {
          SetRows(filteredData);
        }
      }
      if (roleData.includes(3)) {
        if (toggleBool) {
          let allData = filterArr;
          const filteredPending = allData.filter(elem => {
            if (elem.status && elem.status !== null) {
              return elem.status === 'draft';
            }
          });
          SetRows(filteredPending);
        } else {
          SetRows(filterArr);
        }
      }
      if (roleData.length === 0) {
        SetRows([]);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectorValue, sessionState, filterArr]);

  function getDateTime(obj) {
    let d = new Date(obj);
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let datestring = ("0" + d.getDate()).slice(-2) + " " + months[d.getMonth()] + " " + d.getFullYear() + ", " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    return datestring;
  }

  function getCountryCountByStatus(main_status,country_approved, status) {
    let countriesStats = country_approved;
    let pendingCountry = [];
    let publishedCountry = [];
    let rejectedCountry = [];
    if(countriesStats) {
      if(Object.keys(countriesStats).length > 0) {
        Object.keys(countriesStats).map((countryId,i) => {
          if(countriesStats[countryId].status === "Pending" && main_status==='submitted'){
            pendingCountry.push(countriesStats[countryId].name);
          }
          if(countriesStats[countryId].status === "Active" && main_status==='submitted'){
            publishedCountry.push(countriesStats[countryId].name);
          }
          if(countriesStats[countryId].status === "Inactive" && main_status==='submitted'){
            rejectedCountry.push(countriesStats[countryId].name);
          }
          return true;
        });
      }
    }
    let countries = [];
    if(status === "Published") {
      countries = publishedCountry;
    } else if(status === "Pending") {
      countries = pendingCountry;
    } else if(status === "Rejected") {
      countries = rejectedCountry; 
    }
    return countries;
  }

  const GetAllPlaylistViews =async  (playlists) => {
    let list = [];
    for(let i =0;i<playlists.length;i++ ){
      list.push(playlists[i].playlist_id)
    }
    let data  = await playlistService.GetPlaylistViews(list)//.then(()=> {
      Setplaylistview(data);
      let x = reRenderCondition ;
      SetReRenderCondition(x+1);
    return data;
  }
  React.useEffect(()=> {
  },[reRenderCondition])
  // To Get All Contents Data
  async function getSetContents(){
    if (sessionState.sessionValue?.payload?.user) {
      let roleData = sessionState.sessionValue?.payload?.user.role_ids;
      
      if ((roleData.includes(2) || roleData.includes(4)) && !roleData.includes(3)) {
        let created_by = "created_by=" + sessionState.sessionValue.payload.user.id + "&category_id=All&start=" + offSet + "&end=" +limit
        playlistService.GetAllPlaylist(created_by).then(async (data) => {
          let contents = data.data;
          let list = []
          if (contents.length > 0) {
          let _playlistViews =  await GetAllPlaylistViews(contents);
            for (let i = 0; i < contents.length; i++) {
              let content = contents[i];
              if (content.created_by == sessionState.sessionValue.payload.user.id) {
                let updated_at = new Date(content.updated_at).getTime();
                let created_at = new Date(content.created_at).getTime();
                let viewsCount = _playlistViews[content.playlist_id] ? _playlistViews[content.playlist_id] : 0;
                let publishedCountryCount = getCountryCountByStatus(content.status,content.countries_approved,'Published').length;
                let pendingCountryCount = getCountryCountByStatus(content.status,content.countries_approved,'Pending').length;
                let rejectedCountryCount = getCountryCountByStatus(content.status,content.countries_approved,'Rejected').length;
                let value = createData(content.playlist_id, content.title, content.country_code, content.created_by, created_at, updated_at, viewsCount, content.status, publishedCountryCount, pendingCountryCount, rejectedCountryCount, content, content.content_ids, content.created_by);
                list.push(value);
              }
            }
            list.sort((a, b) => ((b.updatedDate ? new Date(b.updatedDate) : new Date(8640000000000000)) - (a.updatedDate ? new Date(a.updatedDate) : new Date(8640000000000000))));
            SetfilterArr(list);
            setoffSet(offSet + 50);
            setFetchDataStatus(true);
          }
        }).catch((err) => {});
      }

      if (roleData.includes(3)) {
        let userStr =  "country="+sessionState.sessionValue.payload.user.country_code+"&created_by="+ sessionState.sessionValue.payload.user.id +"&start="+ offSet +"&end="+ limit;
        playlistService.GetAllPlaylist(userStr).then(async (data) => {
          let contents = data.data;
          let _playlistViews =  await GetAllPlaylistViews(contents);
          let list = []
          if (contents.length > 0) {
            for (let i = 0; i < contents.length; i++) {
              let content = contents[i];
              if (content.status === "submitted" || content.created_by == sessionState.sessionValue.payload.user.id) {
                let updated_at = new Date(content.updated_at).getTime();
                let created_at = new Date(content.created_at).getTime();
                let viewsCount =_playlistViews[content.playlist_id] ? _playlistViews[content.playlist_id] : 0;
                let publishedCountryCount = getCountryCountByStatus(content.status,content.countries_approved,'Published').length;
                let pendingCountryCount = getCountryCountByStatus(content.status,content.countries_approved,'Pending').length;
                let rejectedCountryCount = getCountryCountByStatus(content.status,content.countries_approved,'Rejected').length;
                let value = createData(content.playlist_id, content.title, content.country_code, content.created_by, created_at, updated_at, viewsCount, content.status, publishedCountryCount, pendingCountryCount, rejectedCountryCount, content,content.content_ids, content.created_by);
                list.push(value);
              }
            }
            list.sort((a, b) => ((b.updatedDate ? new Date(b.updatedDate) : new Date(8640000000000000)) - (a.updatedDate ? new Date(a.updatedDate) : new Date(8640000000000000))));
            SetfilterArr(list);
            setoffSet(offSet + 50);
            setFetchDataStatus(true);
          }
        }).catch((err) => {});
      }
    }
  }

  React.useEffect(() => {
    getSetContents();
  },[]);
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
    // To Get All Users
    playlistService.GetAllUsers().then((data) => {
      let jsondata = data.data;
      let arr = []
      for (let i = 0; i < jsondata.length; i++) {
        if (jsondata[i].lilly_id != null && jsondata[i].lilly_id !== ''
        && jsondata[i].first_name !== null && jsondata[i].first_name !== '' &&
          jsondata[i].last_name !== null && jsondata[i].last_name !== '') {
          jsondata[i].fullName = `${jsondata[i].first_name} ${jsondata[i].last_name}(${jsondata[i].lilly_id})`
          arr.push(jsondata[i])
        }
      }
      setUserData(arr);
    }).catch((err) => {});

    playlistService.GetAllAffiliates().then((data) => {
      setCountries(data.data);
    }).catch((err) =>{});

    playlistService.GetAllQuizAttempts().then((data) => {
      let data1 = data.data;
      const csv = [
        ["quiz_id", "quiz_title", "okta_id", "quiz_score", "total_questions", "correct_ans", "started_at", "completed_at"]
      ]
      for(let i = 0 ; i < data1.length ; i++){
        csv.push([data1[i].quiz_id, data1[i].quiz_title, data1[i].okta_id, data1[i].quiz_score,data1[i].total_questions,data1[i].correct_ans, data1[i].started_at, data1[i].completed_at])
      }
      SetcsvData(csv)
      }).catch((err) => {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSwitch = (event) => {
    if (!toggleBool) {
      let allData = filterArr;
      if(rows.length > 0) {
        allData = rows;
      }
      const filteredPending = allData.filter(elem => {
        if (elem.status && elem.status !== null) {
          return elem.status === 'draft';
        }
      });
      SetRows(filteredPending);
    } else {
      SetRows(filterArr);
    }
    setToggleBool(!toggleBool);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterClose = () => {
    setopenFilterModal(false);
  };
  const handleFilterBtn = () => {
    setopenFilterModal(true)
  };

  const resetFilter = () => {
    if (toggleBool) {
      let allData = filterArr;
      const filteredPending = allData.filter(elem => {
        if (elem.status && elem.status !== null) {
          return elem.status === 'draft';
        }
      });
      SetRows(filteredPending);
    } else {
      SetRows(filterArr);
    }
  }

  const handleFilterApply = () => {
    // LILLYPLAY-3587
    setPage(0)
    let allData = filterArr;
    let filteredCountry = filterArr
    if (filterCountry.toString().length > 0) {
      filteredCountry = allData.filter(elem => {
        if (elem.countries && elem.countries !== null) {
          return elem.countries.includes(filterCountry);
        }
      });
    }
    if (filterUserId.toString().length > 0) {
      const filteredUsers = filteredCountry.filter(elem => {
        if (elem.createdBY && elem.createdBY !== null) {
          return (elem.createdBY).toString() === filterUserId?.id.toString();
        }
      });
      let finalFilteredUsers = filteredUsers;
      if(toggleBool) {

        finalFilteredUsers = filteredUsers.filter(elem => {
          if (elem.status && elem.status !== null) {
            return elem.status === 'draft';
          }
        });
      }

      SetRows(finalFilteredUsers);
    }else{
      let finalFilteredCountry = filteredCountry;
      if(toggleBool) {
        finalFilteredCountry = filteredCountry.filter(elem => {
          if (elem.status && elem.status !== null) {
            return elem.status === 'draft';
          }
        });
      }

      SetRows(finalFilteredCountry);
    }
    // SetRows(filteredCountry);
    handleFilterClose();
  };

  const handleFilterReset = () => {
    seFilterCountry('');
    seFilterUserId('');
  };

  const searchItem = event => {
    if (searchTerm.toString().length > 0) {
      // LILLYPLAY-3587
      setPage(0)
      let allData = filterArr;
      const filteredSearch = allData.filter(elem => {
        if (elem.playlistName && elem.playlistName !== null) {
          return elem.playlistName.includes(searchTerm);
        }
      });
      SetRows(filteredSearch);
    }
  };

  const handleSearchTerm = (e, value) => {
    // LILLYPLAY-3587
    setPage(0)
    if (e.keyCode == 13) {
      let term = e.target.value;
      if (term.length > 0) {
        if (value != null) {
          term = value;
        }
        let allData = filterArr;
        if (term.toString().length > 0) {
          const filteredSearch = allData.filter(elem => {
            if (elem.playlistName && elem.playlistName !== null) {
              return elem.playlistName.includes(term);
            }
          });
          SetRows(filteredSearch);
        }
        setSearchTerm(term)
      } else {
        SetRows(filterArr);
      }
    }
  }

  const handleTextInputChange = event => {
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const BreadcrumbData = [
    { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
    { value: 'Playlist Management', path: '/playlist-management' }
  ];

  const handleDelete = () => {
    if (selrowID.length !== 0) {
      playlistService.deleteContentById(selrowID).then((data) => {
        if (data.status === 200) {
          handleDelConfirmClose();
          getSetContents();
          setSearchTerm('');
        }
      }).catch((err) => {});
    }
  }

  const handleDelConfirmClose = () => {
    setopenDelConfirmModal(false);
  };

  return (
    <>
      <Modal open={openFilterModal} onClose={handleFilterClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            Choose Filter
            <button className='closeModal' onClick={() => handleFilterClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <form className='filter_form'>
            <FormLabel component="legend">Select Country</FormLabel>
              <FormControl variant="outlined" className='formControl' size="small">
                <InputLabel id="demo-simple-select-outlined-label">Select Country</InputLabel>
                <Select
                  value={filterCountry}
                  onChange={(e) => { seFilterCountry(e.target.value) }}
                  label="Country">
                  {countries.map((country, i) => <MenuItem key={i} value={country.country_code}>{country.country_name}</MenuItem>)}
                </Select>
              </FormControl>
              <FormControl component="fieldset" className='formControl'>
                <FormLabel component="legend">Select User</FormLabel>
                <Autocomplete
                  ListboxComponent={ListboxComponent}
                  filterOptions={filterOptions}
                  options={userData}
                  value={filterUserId}
                  onChange={(event, newValue) => { 
                    seFilterUserId(newValue); 
                  }}
                  getOptionLabel={(option) => option.fullName || ""}
                  renderInput={(params) => <TextField {...params} placeholder="Search by name of user" name="user" size="small" InputLabelProps={{ shrink: false }} variant="outlined" />}
                />
              </FormControl>
            </form>
          </Grid>
          <Grid className="modal1FoBtn modal-footer text-right">
            <button type="button" onClick={() => handleFilterClose()} className="modalBtn closeBtn">Close</button>
            <button type="button" onClick={() => handleFilterReset()} className="modalBtn resetBtn">Reset Filter</button>
            <button type="button" onClick={() => handleFilterApply()} className="modalBtn applyBtn">Apply Filter</button>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openDelConfirmModal} onClose={handleDelConfirmClose}>
        <Grid className='paper modalstyle'>
          <Grid className="modal-title">
            <button className='closeModal' onClick={() => handleDelConfirmClose()}></button>
          </Grid>
          <Grid className="modal-body">
            <div className="m20">
              <Alert severity="warning">Playlist <b>{selectedContent}</b> will be deleted, are you sure?</Alert>
            </div>
          </Grid>
          <Grid className="modal1FoBtn modal-footer">
            <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button>
            <button type="button" onClick={() => handleDelete()} className="modalBtn">OK</button>
          </Grid>
        </Grid>
      </Modal>
      <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
      <div className='content-mgmt'>
        <div className="main-wrp">
          <Grid className="mgmt_header">
            <Grid container className="row">
              <Grid item lg={8} sm={6} xs={12}>
                <label className="label-hd">
                  Playlist Management <span className='user'> {rows.length} playlists</span>
                </label>
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <div className="switch-hd">
                  <Switch onChange={(e) => handleChangeSwitch(e)} name="checkedA" inputProps={{ 'aria-label': 'secondary checkbox' }} />
                  <span className='hideTxt'>Pending Playlists Only</span>
                </div>
              </Grid>
            </Grid>
            <Grid container className="row">
              <Grid item lg={7} sm={7} xs={12}>
                <div className="action_buttons">
                  <Button className='createBtn newplaylist' onClick={() => navigate('/playlist-update')}>
                    <PublishIcon /> New Playlist
                  </Button>
                  {/* <Button className='content-grn-Btn'>
                    <GetAppIcon /> Export HCP Watching Report
                  </Button> */}
                  <CSVLink filename={"quiz-report.csv"} data={csvData}>
                    <Button className='content-grn-Btn'>
                      <GetAppIcon /> Playlist Quiz Summary Report
                    </Button>
                  </CSVLink>
                </div>
              </Grid>
              <Grid item lg={2} sm={2} xs={12}>
                <div className="filter_icons text-center">
                  <button className='singleBtn' onClick={resetFilter}><AutorenewIcon /></button>
                  <button className='singleBtn' onClick={handleFilterBtn}><FilterListIcon /></button>
                </div>
              </Grid>
              <Grid item lg={3} sm={3} xs={12}>
                <div className='search_bar searchBx'>
                  <Autocomplete
                    open={open}
                    onOpen={() => { if (searchTerm) { setOpen(true); } }}
                    onClose={() => setOpen(false)}
                    inputValue={searchTerm}
                    onInputChange={(e, value) => {
                      setSearchTerm(value);
                      if (value == null) {
                        setFilteredContents([]);
                      } else {
                        let newSpeakersData = filterArr.filter((item) => {
                          if (item.playlistName != null) {
                            let title = item.playlistName.toLowerCase();
                            return title.includes(value.toLowerCase());
                          }
                        });
                        setFilteredContents(newSpeakersData);
                        const found = filterArr.some(el => { if (el.playlistName != null) { return el.playlistName.toLowerCase() === value.toLowerCase() } });
                        if (!found) { setNewContentTerm(value); } else { setNewContentTerm(''); }
                      }
                      if (!value) { setOpen(false); }
                    }}
                    value={searchTerm}
                    onChange={handleSearchTerm}
                    onKeyDown={handleSearchTerm}
                    freeSolo
                    options={filteredContents.length > 0 || newContentTerm != '' ? filteredContents : filterArr}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.playlistName}
                    renderInput={(params) =>
                      <TextField {...params} placeholder="Search playlist name" onInput={handleTextInputChange} name="search_name" size="small"
                        InputLabelProps={{ shrink: false }}
                        {...params.InputProps.disableUnderline = true}
                        {...params.InputProps.endAdornment = <InputAdornment onClick={() => searchItem()} position="end"><IconButton className="mp0" ><SearchIcon /></IconButton></InputAdornment>}
                      />}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer>
            <Table className='table'>
              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
              <TableBody>
                {reRenderCondition>1 && stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    let publishedCountry = getCountryCountByStatus(row.action.status,row.action.countries_approved,'Published');
                    let pendingCountry = getCountryCountByStatus(row.action.status,row.action.countries_approved,'Pending');
                    let rejectedCountry = getCountryCountByStatus(row.action.status,row.action.countries_approved,'Rejected');                  
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                      >
                        <TableCell align="left">{row.playlistName}</TableCell>
                        <TableCell align="left">{getDateTime(row.creationDate)}</TableCell>
                        <TableCell align="left">{getDateTime(row.updatedDate)}</TableCell>
                        <TableCell align="left">{row.views}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="center">
                          <Tooltip title={publishedCountry.join(',')}><Button>{row.published}</Button></Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={pendingCountry.join(',')}><Button>{row.pending}</Button></Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={rejectedCountry.join(',')}><Button>{row.rejected}</Button></Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {setBlueColor && activeId == row.id ? (
                            <Link to={"/playlist-update/" + row.id}>
                              <img onMouseLeave={() => { setFilteredBluecolor(false); setActiveId('') }} className='actnBtn' src={require('../../../public/img/BlueEdit@3x.png')} alt='Edit' />
                            </Link>
                          ) : (
                            <Link to={"/playlist-update/" + row.id}>
                              <img onMouseEnter={() => { setFilteredBluecolor(true); setActiveId(row.id) }} className='actnBtn' src={require('../../../public/img/GreyEdit@3x.png')} alt='Edit' />
                            </Link>
                          )}

                          {setRedColor && activeId == row.id ? (
                            <img onMouseLeave={() => { setFilteredRedcolor(false); setActiveId(''); }} onClick={() => { setselectedContent(row.playlistName); setopenDelConfirmModal(true); setselrowID(row.id) }} className='actnBtnDel' src={require('../../../public/img/RedClose@3x.png')} alt='Delete' />
                          ) : (
                            <img onMouseEnter={() => { setFilteredRedcolor(true); setActiveId(row.id); }} onClick={() => { setselectedContent(row.playlistName); setopenDelConfirmModal(true); setselrowID(row.id) }} className='actnBtnDel' src={require('../../../public/img/CloseGrey@3x.png')} alt='Delete' />
                          )}
                        </TableCell>

                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    {rows.length <= 0 ?
                      <TableCell align="center" colSpan={9}>{fetchDataStatus ? 'No Records Found.' : 'Loading...'}</TableCell> :
                      <TableCell colSpan={9} />
                    }
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
