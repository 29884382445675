import React from "react";
import Paper from '@material-ui/core/Paper';

const BlackTriangle = (props) => {
    if(props.title===undefined){
        return null
    } else {
        if(props.title.includes('▼')) {
            let sp = props.title.split('▼');
            return (<>{sp[0]}<span className='black-triangle-title'>▼</span>{sp[1]}</>)
        } else {
            return (<>{props.title}</>);
        }
    }
}

const BlackTriangleBrand = (props) => {
    if(props.brd_name.includes('▼')){
        let sp = props.brd_name.split('▼');
        return (<><Paper className='watch-tags'><a href={props.brd_url} target="_blank" rel="noreferrer">{sp[0]}<span className='black-triangle'>▼</span>{sp[1]}</a></Paper></>);
    }
    return (<><Paper className='watch-tags'><a href={props.brd_url} target="_blank" rel="noreferrer">{props.prdText}</a></Paper></>);
}

const BlackTriangleVideoTitle = (props) => {
    if(props.title && props.title.includes('▼')){
        let sp = props.title.split('▼');
        return (<Paper className='watch-title'>{sp[0]}<span className='black-triangle-title'>▼</span>{sp[1]}</Paper>)
    }
    else
        return (<Paper className='watch-title'>{props.title}</Paper>);
}

const BlackTriangleHome = (props) => {
    if(props.title && props.title.includes('▼')){
        let sp = props.title.split('▼');
        return (<div>{sp[0]}<span className='black-triangle-home'>▼</span>{sp[1]}</div>)
    }
    else
        return (<div>{props.title}</div>);
}

const BlackTriangleUser = (props) => {
    if(props.brand_name.includes('▼')){
        let sp = props.brand_name.split('▼');
        return (<div>{sp[0]}<span className='black-traingle-user'>▼</span>{sp[1]}</div>)
    }
    else
        return (<div>{props.brand_name}</div>);
}

const BlackTriangleShowMore = (props) => {
    if(props.title===undefined){
        return null
    }
    else {
    if(props.title.includes('▼')){
        let sp = props.title.split('▼');
        return (<div>{sp[0]}<span className='black-triangle-showmore'>▼</span>{sp[1]}</div>)
    }
    else
        return (<div>{props.title}</div>);
    }
}

const BlackTriangleReplacer = (str) => {
    if(str && str.indexOf('▼')!==-1){
        var re = /▼/gi;
        str = str.replace(re,"<span class='black-triangle-showmore'>▼</span>");
    }
    return str;
}

export {BlackTriangle, BlackTriangleBrand, BlackTriangleVideoTitle, BlackTriangleHome, BlackTriangleUser, BlackTriangleShowMore, BlackTriangleReplacer}