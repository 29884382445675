import { createSlice } from '@reduxjs/toolkit';

const CategorySlice = createSlice({

    name : 'category',
    initialState : {
        categories : []
    },
    reducers : {
        setCategories : (state, action)=> {
            state.categories = action;
        }
    }
});

export const {setCategories} = CategorySlice.actions;
export default CategorySlice.reducer;