const customPlayListDurationValidation = (timeRangeData) => {
  // Check if timeRangeData is empty
  if (Object.keys(timeRangeData).length === 0) {
    return false;
  }

  for (let key in timeRangeData) {    
    if (!timeRangeData[key]?.exp_date || !timeRangeData[key]?.start_date) {
      return false;
    }
  }

  return true;
}

const cleanContent = (text) => text.replace(/<[^>]*>/g, ' ')
.replace(/\s{2,}/g, ' ')
.trim()
// Helper function to handle different types of validation rules
const validationRules = {
  required: (value) => (value && value.length > 0) || 'This field is required',
  contentRequired: (value) => (value && cleanContent(value).length > 0) || 'This field is required',
  minLength: (value, length) => (value.length >= length) || `Minimum length is ${length}`,
  maxLength: (value, length) => (value.length <= length) || `Maximum length is ${length}`,
  contentMaxLength: (value, length) => (cleanContent(value).length <= length) || `Maximum length is ${length}`,
  pattern: (value, regex) => regex.test(value) || 'Invalid format',
  customPlayListDuration: (value) => customPlayListDurationValidation(value) || 'Invalid playlist date range',
  customContentSubtitle: (value) => value.every(subtitle => subtitle.language !== '' && subtitle.file !== '') || 'Subtitle language and file are required',
  quizAtleastTwoAns: (value) => value.every(question => question.answers?.length >= 2) || 'Atleast two answers required',
  quizAtleastOneCorrectAns: (value) => value.every(question => question.answers?.filter(answer => answer.correct).length >= 1) || 'Atleast one correct answer required',
  // You can add more rules here like `maxLength`, `email`, etc.
};

// Generalized validateField function with support for multiple rules
const validateField = (name, value, rules = []) => {
  const errors = {};

  for (let rule of rules) {
    if (typeof rule === 'string') {
      const validationFn = validationRules[rule];
      if (validationFn) {
        const errorMessage = validationFn(value);
        if (errorMessage !== true) {
          errors[name] = { type: rule, message: errorMessage };
          break;  // Stop on first validation failure
        }
      }
    } else if (typeof rule === 'object') {
      // For rules with parameters, like minLength, pattern, etc.
      const { ruleName, param } = rule;
      const validationFn = validationRules[ruleName];
      if (validationFn) {
        const errorMessage = validationFn(value, param);
        if (errorMessage !== true) {
          errors[name] = { type: ruleName, message: errorMessage };
          break;
        }
      }
    }
  }

  return errors;
};

export default validateField;