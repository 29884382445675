import axios from 'axios';
import {GoogleAnalyticsService} from '../../GA/GoogleAnalyticsService';
class AppHeaderService {

    constructor(jwtToken=null) {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeader = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken?jwtToken:localStorage.getItem('jwtToken')
            }
        };
        this._gaService = new GoogleAnalyticsService();
    }
    TALinkCLickEvent(value){
        let model = {
            event : 'taLinkClickEvent',
            eventCategory : 'Tags',
            eventDescription :'Tags clicks',// 'Navigated to video from TA Homepage',
            // videoSelectedID : videoId,
            eventAction:value 
        };
        this._gaService.PushObjectInDatalayer(model);
    }
    async GetVideosData() {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/content/api/video/GetVideosByQuery?category_id=All', {}, this.authHeader);
        return data.data;
    }

    async GetPlaylistData() {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/content/api/playlist/GetAllPlaylist?category_id=All', {}, this.authHeader);
        return data.data;
    }

    async GetAffiliateByQuery(params) {
        let qs = "?status=Active";
        if(params) {
            qs += "";
            if(params.affiliate_id) {
                qs += '&country_name='+ params.affiliate_id;
            }
        }
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        if( params._affiliateSelector.affiliates){
            if(Array.isArray(params._affiliateSelector.affiliates.payload)){
                return {data : params._affiliateSelector.affiliates.payload}
            }
        }
        let data = await axios.post(this.baseUrl + '/management/api/user/GetAffiliateByQuery' + qs, {}, this.authHeader);
        if(Array.isArray(data.data)){
            if(data.data.length > 0){
                if(params._dispatch &&params._setAffiliate ){
                    params._dispatch(params._setAffiliate(data.data));
                }
                
            }
            
        }
        return data;
    
    }

    async GetCountryTAByQuery(params) {
        let qs = '?affiliate_id='+ params.affiliate_id;
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/country/GetCountryIndicationWithTherapeuticByQuery' + qs, {}, this.authHeader);
        return data;
    
    }

    async GetCountryFixedMenus(affiliate_id) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/country/GetCountryIndicationWithTherapeuticByQuery?affiliate_id='+ affiliate_id, {}, this.authHeader);
        return data;
    }

    async  GetAllTherapeuticAreas() {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/category/GetTherapeuticAreaByQuery?del=false', {}, this.authHeader);
        return data;
    }

    async getTranslationBtlocale(query) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = {};
        if(localStorage.getItem('HCP') == 'false'){
            data = await axios.post(this.baseUrl + '/management/api/translation/getFilteredTranslation', query, this.authHeader);
        } else {
            data = await axios.post(this.baseUrl + '/management/api/translation/getFilteredTranslation', {} , this.authHeader);
        }
        return data;
    }
    async GetUsersById(query) {
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/user/GetUsersByQuery?id='+query.id, query, this.authHeader);
        return data;
    }
    
    async getLibrary(obj){
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/library/getLibrary', obj, this.authHeader);
        return data;
    }
    async getLibraryById(obj){
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/library/getLibraryById', obj, this.authHeader);
        return data;
    }
    async GetS3File(key){
        try{
            if(key !=null && key.length>0){
                if(!localStorage.getItem('jwtToken')){
                    throw new Error('Access Token Not Available');
                }
                let _key = "";
                let mainArray = key.split('/');
                if(mainArray?.length > 1) {
                    _key = key;
                } else {
                    _key = 'Country/Banner/'+key;
                }
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', {key : _key.trim()}, this.authHeader);
                return (await axios.get(data.data)).data;//.binarySring;
            } else{
                return null;
            }
        }catch(err){
            return null;
        }
    }
    async UpdateUserActiveLibrary(obj){
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        let data = await axios.post(this.baseUrl + '/management/api/user/UpdateUserActiveLibrary', obj, this.authHeader);
        return data;
    }

}
export { AppHeaderService }