/* eslint-disable no-unused-vars */
/* eslint-disable linebreak-style */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { VideoPlayerService } from './Service/video-player';
import styles from './video-player.module.css';
import videoResizeIcon from '../../public/img/videoplayer/resize.png';
import videoCloseIcon from '../../public/img/videoplayer/close.png';
import videoNextIcon from '../../public/img/videoplayer/nextIcon.png';
import videoPreviousIcon from '../../public/img/videoplayer/backIcon.png';
import { useNavigate } from "react-router-dom";
import { ContentService } from '../content-watch/Service/ContentService';
import { useSelector, useDispatch } from 'react-redux';

const VideoPlayer = (props) => {
    const navigate = useNavigate();
    let serviceMain = new VideoPlayerService();
    let selectorValue = useSelector(state => state.session);
    const [sessionState, SetSessionState] = React.useState(selectorValue);

    const [isMute, SetIsMute] = React.useState(false);
    const [videoLoad, setVideoLoad] = React.useState(false);
    const [videoName, setVideoName] = React.useState('');
    const [videoAuth, setVideoAuth] = React.useState('');
    const [progressValue, setProgressValue] = React.useState(0);
    const [isPlaying, SetIsPlaying] = React.useState(false);
    const [playerId, SetPlayerId] = React.useState('');
    const [duration, SetDuration] = React.useState(0);
    const [position, setPosition] = React.useState(props.positionMainPlayer);
    const [contentType, SetContentType] = React.useState('');
    const [intervalCanvas, SetIntervalCanvas] = React.useState();
    const [nextKalturaId, SetNextKalturaId] = React.useState('');
    const [previousKalturaId, SetPreviousKalturaId] = React.useState('');
    const [speakerName, SetspeakerName] = React.useState([]);
    const formatDurationEnd = (duration, position) => {
        let value = duration - position;
        const minute = Math.floor(value / 60);
        const secondLeft = Math.floor(value - minute * 60);
        return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
    }
    const formatDuration = (value) => {
        const minute = Math.floor(value / 60);
        const secondLeft = Math.floor(value - minute * 60);
        return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
    }
    const getDateTime = () => {
        var d = new Date();
        let datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
            d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
        return datestring;
    }
    const playerEndFunciton = async () => {
        props.SetPositionMainPlayer(0);
        let obj = {
            log_action: "Video Complete",
            content_id: props.contentId,
            country_code: props.countryCode,
            okta_id: props.hcpId,
            log_event_label: document.baseURI,
            log_event_date_time: getDateTime()
        };
        let _contentservice = new ContentService();
        await _contentservice.UpdateLogViews(obj);
        if (localStorage.getItem('interval')) {
            clearInterval(localStorage.getItem('interval'));
        }
        let playObject = Object.assign({}, props.play);
        let _nextKalturaId = localStorage.getItem('nextKalturaId');
        if (_nextKalturaId) {
            if (_nextKalturaId.length > 0) {
                playObject.kalturaID = _nextKalturaId;
                props.Setplay(playObject);
                props.SetPositionMainPlayer(0.01);
            }
        }
    }
    const videoCloseBtnHandler = () => {
        setPosition(0);
        props.SetPositionMainPlayer(oldValue => { return 0});
        props.Setplay(false);
        props.SetPlayerShow(false);
        setTimeout(() => {
            setPosition(0);
            props.SetPositionMainPlayer(0);
            props.SetPositionMainPlayer(oldValue => {
                console.log("-----",oldValue)
                return oldValue
            })
        }, 500);
    }
    const videoResizeBtnHandler = () => {
        if (props.contentId.includes('PL')) {
            navigate(`/content-watch/${props.play.kalturaID}-${props.contentId}/${props.contentId}`)
        } else {
            navigate(`/content-watch/${props.play.kalturaID}/${props.contentId}`)
        }
    }
    const videoNextBtnHandler = () => {
        let playObject = Object.assign({}, props.play);
        if (nextKalturaId) {
            if (nextKalturaId.length > 0) {
                playObject.kalturaID = nextKalturaId;
                props.Setplay(playObject);
                props.SetPositionMainPlayer(0.01);
            }
        }
    }
    const videoPreviousBtnHandler = () => {
        let playObject = Object.assign({}, props.play);
        if (previousKalturaId) {
            if (previousKalturaId.length > 0) {
                playObject.kalturaID = previousKalturaId;
                props.Setplay(playObject);
            }
        }
    }
    const videoPlayBtnHandler = () => {
        serviceMain.PausePlayVideo(playerId, 'play');
        SetIsPlaying(true);
        let intervalId = setInterval(() => {
            serviceMain.Draw(serviceMain.NormalizedData(serviceMain.CreateFilteredData()))
        }, 1000);
        if (intervalId) {
            SetIntervalCanvas(intervalId);
            localStorage.setItem('interval', intervalId)
        }
    }
    const videoPauseBtnHandler = () => {
        serviceMain.PausePlayVideo(playerId, 'pause');
        clearInterval(intervalCanvas);
        SetIntervalCanvas(null);
        localStorage.removeItem('interval')
        SetIsPlaying(false)
    }
    const videoUnmuteBtnHandler = () => {
        serviceMain.MuteUnmuteVideo(playerId, 1);
        SetIsMute(false);
    }
    const videoMuteBtnHandler = () => {
        serviceMain.MuteUnmuteVideo(playerId, 0);
        SetIsMute(true);
    }
    React.useEffect(() => {
        localStorage.setItem('nextKalturaId', nextKalturaId);
    }, [nextKalturaId]);

    function getCommonSpeakers(str1) {
        let finalString = 'N/A';
        let str = [];
        for (let i = 0; i < str1.length; i++) {
            str.push(str1[i].name);
        }
        let i = 0;
        if (Array.isArray(str) && str.length) {
            finalString = '';
            for (i = 0; i < str.length - 1; i++) {
                finalString += str[i] + ", ";
            }
            finalString += str[i];
        }
        return finalString;
    }

    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    React.useEffect(() => {
        if (props.play.kalturaID && props.playerShow) {
            setPosition(props.positionMainPlayer);
            const element = document.getElementById('miniutreplayer');
            if(element){
                element.innerHTML = '';
            }
            let sessionUserInfo = parseJwt(localStorage.getItem('sessionValue'));
            console.log("--quizMandatoryStatus", props.autoPlay, props.quizMandatoryStatus);
            serviceMain.InitializePlayer(props.play.kalturaID, parseFloat(props.positionMainPlayer), setProgressValue, props.countryCode, props.hcpId, SetPlayerId, props.SetPositionMainPlayer, props.SetMiniPlayerId, SetIsPlaying, props.contentId, SetNextKalturaId, SetPreviousKalturaId, playerEndFunciton, props.playerShow, props.autoPlay, props.percentageElapsed, sessionUserInfo.user.id, props.quizMandatoryStatus);
            serviceMain.GetVideoInformation(props.play.kalturaID, SetDuration);
            let intervalId = setInterval(() => {
                serviceMain.Draw(serviceMain.NormalizedData(serviceMain.CreateFilteredData()))
            }, 1000);
            if (intervalId) {
                SetIntervalCanvas(intervalId)
                localStorage.setItem('interval', intervalId)
            }
        }
        serviceMain.GetContentData(props.play.kalturaID, props.contentId, SetNextKalturaId, SetPreviousKalturaId).then(async (data) => {
            setVideoName(data?.title);
            let contentService = new ContentService();
            let speakerList = await contentService.GetSpeakersById(data?.speakers_id);
            setVideoAuth((speakerList.length > 0) ? speakerList[0]?.name : '');
            setVideoLoad(true);
            SetContentType(data?.content_type);
        }).catch((err) => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.play, props.countryCode, props.hcpId, props.isPlaylist, props.renderCheckMiniPlayer, props.playerShow]);

    return (
        <div id="miniPlayerIdOuter">
            {sessionState?.sessionValue?.payload?.affiliate[0]?.mini_player || !sessionState.sessionValue?.payload?.user.role_ids.includes(1) && props.playerShow ?
                (<>
                    <Grid className={styles.videoplayerDesktop}>
                        <Grid id="mini_player_lp" className={props.contentId.includes('PL') ? styles.podcastplayer : styles.videoplayer}>
                            <Grid className={styles.videoplayerinner}>
                                <img className={styles.videoplayclose} src={videoCloseIcon} onClick={videoCloseBtnHandler} alt='close' />
                                <img className={styles.videoplayresize} src={videoResizeIcon} onClick={videoResizeBtnHandler} alt='resize' />
                            </Grid>
                            <Grid id='miniutreplayer' className={styles.mobCustomVideoPlayer}></Grid>
                            <Grid container className={styles.videoSeekbarContainer}>
                                <Grid item xs={2} lg={2} className='text-center'>
                                    {props.contentId.includes('PL') && <img className={styles.videoprevious} src={videoPreviousIcon} onClick={videoPreviousBtnHandler} alt="img" />}
                                </Grid>
                                <Grid item xs={8} lg={8} className='text-center'>
                                    <Grid item xs={12} lg={12} className='text-center'>
                                        {videoName?.length > 27 ? `${videoName.slice(0, 26)}...` : videoName}</Grid>
                                    <Grid item xs={12} lg={12} className='text-center'>
                                        {videoAuth?.length > 0 &&
                                            <>
                                                {props.translations['by'] ? props.translations['by'] : 'by'} {videoAuth?.length > 20 ? ` ${videoAuth.slice(0, 19)}...` : ` ${videoAuth}`}
                                            </>}
                                    </Grid>
                                </Grid>
                                <Grid item xs={2} lg={2} className='text-center'>
                                    {props.contentId.includes('PL') && <img className={styles.videonext} src={videoNextIcon} onClick={videoNextBtnHandler} alt="img" />}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>) : ('')}
        </div>
    );
};

export default VideoPlayer;
