import { filterTreeByIds } from './categoryTreeHelper';

//It will return common ids available in each array from list of arrays
const getCommonIds = (idsArray) =>
    idsArray.length > 0 ? idsArray.reduce((a, b) => a.filter((c) => b.includes(c))) : [];

export const prepareCategoryTreeByCountries = (categoryTree, countries) => {
  const countryData = {
    therapeuticAreaIds: [],
    category1Ids: [],
    category2Ids: [],
  };

  countries.forEach((country) => {
    countryData.therapeuticAreaIds.push(country.therapeutic_area_ids);
    countryData.category1Ids.push(country.category_1_ids);
    countryData.category2Ids.push(country.category_2_ids);
  });

  const selectedIdsByLevels = [
    getCommonIds(countryData.therapeuticAreaIds),
    getCommonIds(countryData.category1Ids),
    getCommonIds(countryData.category2Ids),
  ];

  return filterTreeByIds(categoryTree, selectedIdsByLevels);
};
