import React from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const ContentSpeaker = (props)=> {
    let on24speakers = props.speakers;
    const handleAddBtn = (event, row, index) => {
      row.id = 0;
      row.index = index;
      props.addLillyPlaySpeakers(row);
      event.currentTarget.style.visibility = 'hidden';
    }
    return (    
        <Grid container className="content">
          <Grid item lg={12} xs={12}>
            <FormControl component="fieldset" >
              <FormLabel component="legend"><p>ON24 Speaker</p>
                <p>(Instructions : The list below presents the speakers(s) registered in the ON24 event. If the same speaker already exists in Lilly Play, the system will automatically select Lilly Play speaker. Otherwise, select the plus icon under the action section of ON24 Speaker.)</p>
              </FormLabel>
                <>
                  <table className="field_tbl">
                    <thead>
                      <tr>
                        <th width="20%">Speaker Name</th>
                        <th width="60%">Speaker Description</th>
                        <th width="20%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {on24speakers && on24speakers.length > 0 ?
                        on24speakers.map((row, index) => {
                          return (
                            <tr>
                              <td>{row.name}</td>
                              <td>{row.description}</td>
                              <td><img id={`add24_`+index} src={require('../../../public/img/Add@3x.png')} alt="Add Speaker" onClick={(e) => handleAddBtn(e, row, index)} /></td>
                            </tr>
                          )
                        }) 
                      : "No Data Found"}
                    </tbody>
                  </table>
                </>
            </FormControl>
          </Grid>
        </Grid>
     
    )
}
export default ContentSpeaker