import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Breadcrumb from '../../../layout/breadcrumb';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import editImg from '../../../public/img/new-img/GreyEdit@3x.png';
import editImgBlue from '../../../public/img/new-img/BlueEdit@3x.png';
import deleteImg from '../../../public/img/CloseGrey@3x.png';
import deleteImgBlue from '../../../public/img/new-img/RedClose@3x.png';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../translation.css';
import { ExcelRenderer } from "react-excel-renderer";
import Modal from '@material-ui/core/Modal';
import TranslationExport from '../translation-export/translation-export';
import TranslationEdit from '../translation-edit/translation-edit';
import TranslationCreate from '../translation-create/translation-create';
import { TranslationService } from '../services/translation-service';
import Alert from '@material-ui/lab/Alert';

function createData(locale, key, text, action) {
    return { locale, key, text, action };
}

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    /**First page click event on pagination */
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    /**Back click event on pagination */
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    /**Handle next click event on pagination */
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    /**Last page click event on pagination */
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

/**Decending comparator on header */
/**Decending comparator on header */
function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string' && a[orderBy].localeCompare(b[orderBy], undefined, { sensitivity: 'accent' }) < 0) {
      return -1;
    }
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string' && a[orderBy].localeCompare(b[orderBy], undefined, { sensitivity: 'accent' }) > 0) {
      return 1;
    }
    return 0;
  }
  
  /**Fetches comparator of order on header */
  function getComparator(order, orderBy) {
    return order === 'asc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  /**Sort function on header */
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  

const headCells = [
    { id: 'locale', numeric: true, disablePadding: false, label: 'Locale', sorting: true },
    { id: 'key', numeric: true, disablePadding: false, label: 'Key', sorting: true },
    { id: 'text', numeric: true, disablePadding: false, label: 'Text', sorting: true },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action', sorting: false },
];

/**Function provides shorting of header section  */
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={!headCell.sorting}
                            class={((!headCell.sorting) ? "nonClickable MuiButtonBase-root MuiTableSortLabel-root" : "MuiButtonBase-root MuiTableSortLabel-root")}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className='visuallyHidden'>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    videobuttonedit: {
        backgroundImage: `url(${editImg})`,
        height: '20px',
        width: '20px',
        border: '0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '0 1px',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        '&:hover': {
            backgroundImage: `url(${editImgBlue})`
        }
    },
    videobuttonremove: {
        backgroundImage: `url(${deleteImg})`,
        height: '20px',
        width: '20px',
        border: '0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '0px 0px',
        backgroundColor: 'transparent',
        marginLeft: '10px',
        cursor: 'pointer',
        '&:hover': {
            backgroundImage: `url(${deleteImgBlue})`
        }
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, SetRows] = React.useState([]);

    const [filteredTranslation, setFilteredTranslation] = React.useState([]);
    const [newTranslationTerm, setNewTranslationTerm] = React.useState('');

    const [openPopup, setOpenPopup] = React.useState(false);

    const [openDelConfirmModal, setopenDelConfirmModal] = React.useState(false);
    const [rowID, setrowID] = React.useState('');
    // eslint-disable-next-line no-unused-vars
    const [rowName, setrowName] = React.useState('');


    const myRefname = useRef(null);

    const handleRequestSort = (event, property) => {
        if(property !== 'action'){
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }

    };
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [rows]);    
    /**Function invokes click event on table row */
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    /**Function changes the page number on pagination*/
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [OpenEditPopup, setOpenEditPopup] = React.useState(false);
    const [SelectedEditItem, setSelectedEditItem] = React.useState([]);
    const [openCreatePopup, setopenCreatePopup] = React.useState(false);




    const activeUser = localStorage.getItem('userID')
    const searchItem = event => {
        getFilteredTranslation({ key: searchTerm })
    };
    const handleSearchTerm = (e, value) => {
        if (e.keyCode === 13) {
            let term = e.target.value;
            if (value != null) {
                term = value.key;
            }
            setSearchTerm(term);
            getFilteredTranslation({ key: term })
        }
    }

    /**Function changes page on rows per page*/
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    /**get filtered trnslation */
    const getFilteredTranslation = (obj) => {
        let service = new TranslationService();
        service.getFilteredTranslation(obj).then((data) => {
            let result = data.data;
            let list = [];
            for (let i = 0; i < result.length; i++) {
                let result = data.data;
                list.push({ id: result[i]['translation_id'], text: result[i]['value'], locale: result[i]['locale_info'], key: result[i]['key'] })
            }
            SetRows(list);
        })
    }

    /**Variable shows selected index for page*/
    const isSelected = (name) => selected.indexOf(name) !== -1;

    /**Empty rows details*/
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    /**Breadcrumb for Translation management*/
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: 'Translation Management', path: '/translation-management' }
    ];
    const handleFormDataChange = (e) => {
       
        let fileName = e.target.files[0].name;

        let fileExtension = fileName.split('.').pop();
        if (fileExtension === "json") {
            const fileReader = new FileReader();
            fileReader.readAsText(e.target.files[0], "UTF-8");
            fileReader.onload = event => {
                let json_data = event.target.result;

                let result = Object.entries(JSON.parse(json_data));
                let list = [];
                let objectToSend = [];
                let uniqueLocale = [];
                for (let i = 0; i < result.length; i++) {
                    let value = createData('pt-BR', result[i][0], result[i][1], "")

                    objectToSend.push({ locale_info: 'pt-BR', value: result[i][1], key: result[i][0], created_by: activeUser, updated_by: activeUser, created_at: new Date(), updated_at: new Date(), status:true, filename: Date.now() + "_pt-BR" })
                    list.push(value)

                }
                uniqueLocale.push('pt-BR')
                let dataToSend = { main: objectToSend, localArray: uniqueLocale }
                updateTranslation(dataToSend, 'insert')
            }
        } else {
            ExcelRenderer(e.target.files[0], (err, resp) => {
                if (err) {
                } else {
                    const modifyData = resp.rows?.slice(1)?.map((itm, index) => ({
                        no: itm[0] || "",
                        locale: itm[1] || "",
                        key: itm[2] || "",
                        text: itm[3] || ""
                    }));
                    let list = [];
                    let objectToSend = [];
                    let uniqueLocale = [];
                    let uniqueLocaleKey = {};

                    for (let i = 0; i < modifyData.length; i++) {
                        let value = createData("", modifyData[i].locale, modifyData[i].key, modifyData[i].key, "")
                        objectToSend.push({ locale_info: modifyData[i].locale, value: modifyData[i].key, key: modifyData[i].text, created_by: 1, updated_by: 1, created_at: new Date(), updated_at: new Date(), filename: Date.now() + "_" + modifyData[i].locale, status:true })

                        if (!uniqueLocale.includes(modifyData[i].locale)) {
                            uniqueLocaleKey[ modifyData[i].locale] = []
                            uniqueLocale.push(modifyData[i].locale)
                        }
                        uniqueLocaleKey[ modifyData[i].locale].push( modifyData[i].text)
                        list.push(value)
                    }
                    let dataToSend = { main: objectToSend, localArray: uniqueLocaleKey }
                    updateTranslation(dataToSend, 'insert')

                }
            });
        }
    }

    // get all translation
    const getAllTranslation = () => {
        let service = new TranslationService();

        service.GetAllTranslation().then((data) => {
            let result = data.data;
            let list = [];
            for (let i = 0; i < result.length; i++) {
                let result = data.data;
                list.push({ id: result[i]['translation_id'], text: result[i]['value'], locale: result[i]['locale_info'], key: result[i]['key'] })
            }
            SetRows(list);
        })
    }

    //update imported translation
    const updateTranslation = (data, type) => {
        if (type === 'insert') {
            let service = new TranslationService();
            service.UpdateTranslation(data).then((dat) => {
                getAllTranslation();
            })
        }
    }

    React.useEffect(() => {
        getAllTranslation()
    }, []);

    React.useEffect(() => {
        if (!OpenEditPopup || !openCreatePopup) {
            getAllTranslation()
        }

    }, [OpenEditPopup, openCreatePopup]);

    const handleButtonClick = () => {
        setOpenPopup(true)
    }
    const handleCreateButtonClick = () => {
        setopenCreatePopup(true)
    }

    const handleDelConfirmClose = () => {
        setopenDelConfirmModal(false);
    };
    const openDelConfirm = (id, name) => {
        setrowID(id);
        setrowName(name);
        setopenDelConfirmModal(true);
    };

    const handleDelete = () => {
        let service = new TranslationService();
        service.DeleteTranslation(rowID).then((data) => {
            setopenDelConfirmModal(false);
            getAllTranslation();
        }).catch((err) => {});
    }

    const openEditModal = (id, row) => {
        setOpenEditPopup(true);
        setSelectedEditItem(row);
    }

    return (
        <>
            <Modal open={openDelConfirmModal} onClose={handleDelConfirmClose}>
                <Grid className='paper modalstyle'>
                    <Grid className="modal-title">
                        <button className='closeModal' onClick={() => handleDelConfirmClose()}></button>
                    </Grid>
                    <Grid className="modal-body">
                        <div className="m20">
                            <Alert severity="warning">Translation <b></b> will be deleted, are you sure?</Alert>
                        </div>
                    </Grid>
                    <Grid className="modal1FoBtn modal-footer">
                        <button type="button" onClick={() => handleDelConfirmClose()} className="modalBtn">Cancel</button>
                        <button type="button" onClick={() => handleDelete()} className="modalBtn">OK</button>
                    </Grid>
                </Grid>
            </Modal>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />


            <Modal open={openPopup} data={rows}>
                <TranslationExport data={rows} closePopUp={setOpenPopup} />
            </Modal>

            <Modal open={openCreatePopup} >
                <TranslationCreate data={rows} closePopUp={setopenCreatePopup} />
            </Modal>


            <Modal open={OpenEditPopup} >
                <TranslationEdit getAllTranslation={getAllTranslation} data={SelectedEditItem} closePopUp={setOpenEditPopup} />
            </Modal>


            <div className='content-mgmt'>
                <div className="main-wrp">
                    <Grid className="mgmt_header">
                        <Grid
                            container
                            className="row"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            {/* <Grid container > */}

                            <Grid item lg={5} xs={12} >
                                <label className="label-hd">
                                    Translation Management <span className='user'> {rows.length} Translation</span>
                                </label>
                            </Grid>
                            {/* </Grid> */}
                            <Grid container
                                lg={7} xs={12}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center">

                                <Grid item lg={3} sm={3} xs={12} className="marginTop">
                                    <div className='search_bar searchBx'>
                                        <Autocomplete
                                            open={open}
                                            onOpen={() => { if (searchTerm) { setOpen(true); } }}
                                            onClose={() => setOpen(false)}
                                            inputValue={searchTerm}
                                            onInputChange={(e, value) => {
                                                setSearchTerm(value);
                                                //  setFilteredTranslation([value]);
                                                if (value === null) {
                                                    setFilteredTranslation([]);
                                                } else {
                                                    // eslint-disable-next-line array-callback-return
                                                    let newUserData = rows.filter(function (item) {
                                                        if (item.key != null) {
                                                            let keyname = item.key.toLowerCase();
                                                            return keyname.startsWith(value.toLowerCase());
                                                        } else {
                                                            item.key = ""
                                                        }

                                                    });
                                                    let newArray = [];
                                                    let list = []
                                                    for (let j = 0; j < newUserData.length; j++) {
                                                        if (!newArray.includes(newUserData[j].key)) {
                                                            list.push(newUserData[j])
                                                            newArray.push(newUserData[j].key)
                                                        }

                                                    }
                                                    setFilteredTranslation(list);

                                                    const found = rows.some(el =>
                                                        el.key.toLowerCase() === value.toLowerCase()
                                                    );
                                                    if (!found) { setNewTranslationTerm(value); } else { setNewTranslationTerm(''); }
                                                }
                                                if (!value) { setOpen(false); }
                                            }}
                                            value={searchTerm}
                                            onChange={handleSearchTerm}
                                            onKeyDown={handleSearchTerm}
                                            freeSolo
                                            options={filteredTranslation.length > 0 || newTranslationTerm !== '' ? filteredTranslation : rows}
                                            getOptionLabel={(option) => typeof option === 'string' ? option : option.key}
                                            renderInput={(params) =>
                                                <TextField {...params} placeholder="Search by key" name="search_name" size="small"
                                                    InputLabelProps={{ shrink: false }}
                                                    {...params.InputProps.disableUnderline = true}
                                                    {...params.InputProps.endAdornment = <InputAdornment onClick={() => searchItem()} position="end"><IconButton className="mp0"><SearchIcon /></IconButton></InputAdornment>}
                                                />}
                                        />
                                    </div>
                                </Grid>

                                <Grid item lg={3} sm={3} xs={12} className="marginTop">

                                    <FormControl component="fieldset" style={{ width: '100%' }} >
                                        <input
                                            className='hidden'
                                            id="contained-button-file"
                                            type="file"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFormDataChange(e)}
                                            ref={myRefname}
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button className="createBtn createNewClass" variant="contained" color="primary" component="span">Import XLS
                                            </Button>
                                        </label>
                                    </FormControl>
                                </Grid>

                                <Grid item lg={3} sm={3} xs={12} className="marginTop">
                                    <Button onClick={handleButtonClick} className='createBtn createNewClass' >Export XLS</Button>
                                </Grid>
                                <Grid item lg={3} sm={3} xs={12} className="marginTop">
                                    <Button onClick={handleCreateButtonClick} className='createBtn createNewClass' >Create New</Button>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.name);
                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.name)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.name}
                                                selected={isItemSelected}>

                                                <TableCell align="center">{row.locale}</TableCell>
                                                <TableCell align="center">{row.key}</TableCell>
                                                <TableCell align="center" >{row.text} </TableCell>
                                                <TableCell width="12%" align="center">
                                                    <button onClick={() => openEditModal(row.id, row)} type="button" className={classes.videobuttonedit}></button>
                                                    <button onClick={() => openDelConfirm(row.id, row.name)} type="button" className={classes.videobuttonremove}></button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        {rows.length <= 0 ?
                                            <TableCell align="center" colSpan={7}>No Records Found.</TableCell> :
                                            <TableCell colSpan={7} />
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
            </div>
        </>
    );
}