import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainRoutes from './app/router/change_mainRoutes';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './app/language-translation/i18n';
import { Provider } from "react-redux";
import store from "./app/Session/store";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
const queryClient = new QueryClient();

const onUpdate = () => { 
  return window.scrollTo(0, 0); };
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Router onUpdate={onUpdate} >
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <MainRoutes />
          </QueryClientProvider>
        </Provider>
      </Router>
    </I18nextProvider>
  </React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
