/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import * as msal from "@azure/msal-browser";

const FederateRedirect = () => {


    const [checkState, SetCheckState] = React.useState('ok')
    useEffect(() => {
        localStorage.setItem('fedHash', window.location.hash)
        const msalConfig = {
            auth: {
                clientId: process.env.REACT_APP_FEDERATE_CLIENT_ID,
                authority: process.env.REACT_APP_SSO_AUTHORITY,
                redirectUri: "/login/startlogin",
            }
        };

    }, []);

    return (<p>Authenticating {checkState}</p>);
}

export default FederateRedirect;
// export default withRouter(AuthCallback);