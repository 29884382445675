/* eslint-disable linebreak-style */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable linebreak-style */
import React from 'react';
import './layout.css';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import leftArrow from '../public/img/leftArrow.svg';
import rightArrow from '../public/img/rightArrow.svg';
import { AppSidebarService } from './Services/AppSidebar';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as msal from "@azure/msal-browser";
import { setAffiliate } from '../Session/AffiliateSlice';
import {setCategories} from '../Session/CategorySlice';
const AppSidebar = (props) => {
  const navigate = useNavigate();
  let affiliateSelector = useSelector(state=>state.affilaiteSlice);
  let categoriesSelector = useSelector(state=> state.categorySlice);
  const [open, setOpen] = React.useState(false);
  const [openMain, setOpenMain] = React.useState(false);
  const [openSub, setOpenSub] = React.useState(false);
  const [menuList, setMenuList] = React.useState([]);
  const [mainMenuList, setMainMenuList] = React.useState([]);
  const [subMenuList, setSubMenuList] = React.useState([]);
  const [mainMenuMain, setMainMenuMain] = React.useState('');
  const [subMenuMain, setSubMenuMain] = React.useState('');
  const [HCPBool, setHCPBool] = React.useState(false);
  const [preventPropogation, SetPreventPropogation] = React.useState(false);
  const [thArea, settharea] = React.useState([]);
  const [cat1_area, setcat1_area] = React.useState([]);
  const [cat2_area, setcat2_area] = React.useState([]);
  const [subLibraryCategory, setsubLibraryCategory] = React.useState('1');
  const [directCategory2, setdirectCategory2] = React.useState(false);
  const [country_Code, setcountry_Code] = React.useState('');
  const dispatch = useDispatch();
  /** Function toggles sidebar section */
  const toggleDrawer = (event, val) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(true);
    setOpenSub(false);
    setOpenMain(false);
  };

  /** Function toggles sub menu section */
  const toggleDrawerSub = (event, val) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    let temp = mainMenuList.filter(elem => elem.id === val);
    let arr = []
    if (cat2_area.length > 0) {
      for (let i = 0; i < temp[0].children.length; i++) {
        if (cat2_area.includes(temp[0].children[i].id) && temp[0].children[i].name != '') {
          arr.push(temp[0].children[i])
        }
      }
    } else {
      // for (let i = 0; i < temp[0].children.length; i++) {
      //   if (temp[0].children[i].name != '') {
      //     arr.push(temp[0].children[i])
      //   }
      // }
      arr.push([])
      // if
    }

    setSubMenuList(sortbyName(arr));
    setOpen(false);
    setOpenSub(true);
    setOpenMain(false);
  };

  /** Function toggles main menu section */
  const toggleDrawerMain = (event, val) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    let temp = menuList.filter(elem => elem.id === val);
    let arr = [];
    if (cat1_area.length > 0) {
      for (let i = 0; i < temp[0].children.length; i++) {
        if (cat1_area.includes(temp[0].children[i].id) && temp[0].children[i].name != '') {
          arr.push(temp[0].children[i])
        }
      }
    } else {
      // for (let i = 0; i < temp[0].children.length; i++) {
      //   if (temp[0].children[i].name != '') {
      //     arr.push(temp[0].children[i])
      //   }
      // }
      arr = []
      if(cat2_area.length > 0){
        setdirectCategory2(true)
        arr = []
        for (let i = 0; i < temp[0].children.length; i++) {
          for (let j = 0; j < temp[0].children[i].children.length; j++) {
            if (cat2_area.includes(temp[0].children[i].children[j].id) && temp[0].children[i].children[j].name != '') {

              arr.push(temp[0].children[i].children[j])
            }
          }

        }
      }
      
    }

    setMainMenuList(sortbyName(arr));
    setOpen(false);
    setOpenSub(false);
    setOpenMain(true);
  };

  /** Function close event on sidebar section */
  const toggleDrawerClose = (event, val) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(false);
    setOpenSub(false);
    setOpenMain(false);
  };

  /** Function close event on main menu section */
  const toggleMain = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(true);
    setOpenMain(false);
    setOpenSub(false);
  }

  /** Function close event on sub menu section */
  const toggleSubmenu = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(false);
    setOpenMain(true);
    setOpenSub(false);
  }

  /**Search Filter Sidebar */
  const menuSearch = (value, name) => {
    navigate('/search/category_id=' + value + "?cat_name=" + name);
    setOpen(false);
    setOpenSub(false);
    setOpenMain(false);
  }

  React.useEffect(() => {
    // alert(country_Code)
  }, [country_Code]);

  /**Fetch sidebar therpatic data list */
  React.useEffect(() => {
    // if(preventPropogation){
    //    return;
      
    // }
    // SetPreventPropogation(true);
    if (props.selectedLibrary) {
      if(preventPropogation){
        return;
      
      }
      SetPreventPropogation(true);
      let sidebarService = new AppSidebarService();
      sidebarService.getLibraryById({ library_id: props.selectedLibrary }).then((da) => {
        if(Array.isArray(da.data)){
            let tid = [];
            let bid = [];
            let iid = [];
            for (let j = 0; j < da.data[0].library_categories.length; j++) {
          let cati = da.data[0].library_categories[j].toString()
              const catIDs = cati.split("_");
              if (catIDs[0]) {
                if (!tid.includes(catIDs[0])) {
              tid.push(catIDs[0])
                }
              }
              if (catIDs[1]) {
                if (!bid.includes(catIDs[1])) {
              bid.push(catIDs[1])
                }
              }
              if (catIDs[2]) {
                if (!iid.includes(catIDs[2])) {
              iid.push(catIDs[2])
                }
              }
            }
        settharea(tid)
        setcat1_area(bid)
            // setcat1_area(iid)
            setcat2_area(iid);
        let exisingCategories = Object.assign([],props.allcategory);
        console.log('-------------GetAllCategory-------------')
        sidebarService.GetAllCategory(exisingCategories).then((dat) => {
          if(Array.isArray(dat.data)){

            
            props.Setallcategory(dat.data)
                  let jsondata = dat.data;
                  let arr = [];
                  let arr2 = [];
                  let arr3 = [];
                  if (jsondata.length > 0) {
                    for (let i = 0; i < jsondata.length; i++) {
                      if (tid.includes(jsondata[i].id)) {
                  arr.push(jsondata[i])

                      }
                      for (let j = 0; j < jsondata[i].children.length; j++) {
                  if (bid.includes(jsondata[i].children[j]['id'])) {
                    arr2.push(jsondata[i].children[j])
                        }
                  for (let k = 0; k < jsondata[i].children[j].children.length; k++) {
                    if (iid.includes(jsondata[i].children[j].children[k]['id'])) {
                      arr3.push(jsondata[i].children[j].children[k])
                          }
                        }
                      }
                    }
                    setMenuList(sortbyName(arr));

                    if (arr.length == 0 && arr2.length > 0) {
                      setMenuList(sortbyName(arr2));
                setMainMenuList(sortbyName(arr2))
                setsubLibraryCategory('2')
                    }
              if (arr.length == 0 && arr2.length == 0 && arr3.length > 0) {
                      setMenuList(sortbyName(arr3));
                setSubMenuList(sortbyName(arr3))
                setsubLibraryCategory('3')

                    }
                  }
                }
                if (Array.isArray(dat)) {
                  props.Setallcategory(dat);
                  let jsondata = dat;
                  let arr = [];
                  let arr2 = [];
                  let arr3 = [];
                  if (jsondata.length > 0) {
                    for (let i = 0; i < jsondata.length; i++) {
                      if (tid.includes(jsondata[i].id)) {
                        arr.push(jsondata[i]);
                      }
                      for (let j = 0; j < jsondata[i].children.length; j++) {
                        if (bid.includes(jsondata[i].children[j]["id"])) {
                          arr2.push(jsondata[i].children[j]);
                        }
                        for (
                          let k = 0;
                          k < jsondata[i].children[j].children.length;
                          k++
                        ) {
                          if (
                            iid.includes(
                              jsondata[i].children[j].children[k]["id"]
                            )
                          ) {
                            arr3.push(jsondata[i].children[j].children[k]);
                          }
                        }
                      }
                    }
                    setMenuList(sortbyName(arr));

                    if (arr.length == 0 && arr2.length > 0) {
                      setMenuList(sortbyName(arr2));
                      setMainMenuList(sortbyName(arr2));
                      setsubLibraryCategory("2");
                    }
                    if (
                      arr.length == 0 &&
                      arr2.length == 0 &&
                      arr3.length > 0
                    ) {
                      setMenuList(sortbyName(arr3));
                      setSubMenuList(sortbyName(arr3));
                      setsubLibraryCategory("3");
                    }
                  }
                }
                SetPreventPropogation(false);
        }).catch((err) => {console.log(err); SetPreventPropogation(false);});
    }}).catch((err) => {console.log(err);SetPreventPropogation(false);})
    } else {
      let sidebarService = new AppSidebarService();
      let country = localStorage.getItem('country')
      setcountry_Code(country)

      if (country && props.sessionValue && props.sessionValue.payload && Array.isArray(props.chipData) && props.chipData.length>0) {
        if(preventPropogation){
          return;
         
        }
        SetPreventPropogation(true);
        console.log('--------------Appsidebar-----performance-----------',props, props.selectedLibrary, props.sessionValue, props.translations, props.selectedCountry)
        let resData = { affiliate_id: localStorage.getItem('country') ,  _dispatch: dispatch, _categoriesSelector:  categoriesSelector,_affiliateSelector : affiliateSelector, _setAffiliate:setAffiliate }
        sidebarService.GetAffiliateByQuery(resData).then((data) => {
          if(Array.isArray(data.data)){
              let country = data.data;
          let tharea = data.data[0].therapeutic_area_ids
          settharea(tharea)
          let cat1_id = data.data[0].category_1_ids
          setcat1_area(cat1_id)
          let cat2_id = data.data[0].category_2_ids
              setcat2_area(cat2_id);
          let exisingCategories = Object.assign([],props.allcategory);
          console.log('-------------GetAllCategory-------------')
          sidebarService.GetAllCategory(exisingCategories).then((dat) => {
            if(Array.isArray(dat.data)){
            props.Setallcategory(dat.data)

                    let jsondata = dat.data;
                    let arr = [];
                    if (jsondata?.length > 0) {
                      for (let i = 0; i < jsondata.length; i++) {
                        if (tharea.includes(jsondata[i].id)) {
                  arr.push(jsondata[i])
                        }
                      }
                      setMenuList(sortbyName(arr));
                    }
                  } 
                  if (Array.isArray(dat)) {
                    props.Setallcategory(dat);
                    let jsondata = dat;
                    let arr = [];
                    if (jsondata?.length > 0) {
                      for (let i = 0; i < jsondata.length; i++) {
                        if (tharea.includes(jsondata[i].id)) {
                          arr.push(jsondata[i]);
                        }
                      }
                      setMenuList(sortbyName(arr));
                    }
                  }
                  SetPreventPropogation(false);
          }).catch((err) => {console.log(err); SetPreventPropogation(false);});
            }
        }).catch((err)=> {console.log(err); SetPreventPropogation(false);})
      } else {
        if (props.sessionValue && props.sessionValue.payload && props.sessionValue.payload.affiliate && props.sessionValue.payload.affiliate[0] && props.sessionValue.payload.affiliate[0].country_code) {
          if(preventPropogation){
            return;
           
          }
          SetPreventPropogation(true);
          setcountry_Code(props.sessionValue.payload.affiliate[0].country_name)

          // alert(props.sessionValue.payload.affiliate[0].country_code)
          let resData = {affiliate_id: props.sessionValue.payload.affiliate[0].country_name ,  _dispatch: dispatch, _categoriesSelector:  categoriesSelector,_affiliateSelector : affiliateSelector, _setAffiliate:setAffiliate };
          console.log('--------------Appsidebar-----performance-----------', props.selectedLibrary, props.sessionValue, props.translations, props.selectedCountry)
          // sidebarService.GetAffiliateByQuery(resData).then((data) => {
            let data = {data : props.sessionValue.payload.affiliate};
          let country = data.data;
            let tharea = data.data[0].therapeutic_area_ids
            settharea(tharea)
            let cat1_id = data.data[0].category_1_ids
            setcat1_area(cat1_id)
            let cat2_id = data.data[0].category_2_ids
          setcat2_area(cat2_id);
            let exisingCategories = Object.assign([],props.allcategory);
            console.log('-------------GetAllCategory-------------', exisingCategories)
            sidebarService.GetAllCategory(exisingCategories).then((dat) => {
              if(Array.isArray(dat.data)){
                props.Setallcategory(dat.data)

                let jsondata = dat.data;
                let arr = [];
                if (jsondata?.length > 0) {
                  for (let i = 0; i < jsondata.length; i++) {
                    if (tharea.includes(jsondata[i].id)) {
                      arr.push(jsondata[i])
                    }
                  }
                  setMenuList(sortbyName(arr));
                }
                if (localStorage.getItem('HCP') === 'true') {
                  setHCPBool(true)
                } else {
                  setHCPBool(false)
                }
              }
              SetPreventPropogation(false);
            }).catch((err) => {console.log(err);SetPreventPropogation(false);});

          // })
        }else{
          SetPreventPropogation(false);
          // getAllcategory()
        }

      }
    }
    localStorage.setItem('useeffectpreventer', true);
  }, [  props.searchArea, props.selectedLibrary, props.sessionValue, props.translations]);

  const sortbyName = (items) => {
    let newItems = [];
    items.map(item => { 
      let translatedName = props.translations[item.name] ? props.translations[item.name] : item.name;
      newItems.push({id: item.id, name: translatedName, children: item.children})
    })
    newItems.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return newItems;
  }
  const getAllcategory = () => {
    let sidebarService = new AppSidebarService();
    let exisingCategories = Object.assign([],props.allcategory);
    console.log('-------------GetAllCategory-------------', exisingCategories)
    sidebarService.GetAllCategory(exisingCategories).then((data) => {
      if(Array.isArray(data.data)){
        props.Setallcategory(data.data)

          let jsondata = data.data;
          if (jsondata?.length > 0) {
            setMenuList(sortbyName(jsondata));
          }
        }
    }).catch((err) => console.log(err));
    if (localStorage.getItem('HCP') === 'true') {
      setHCPBool(true)
    } else {
      setHCPBool(false)
    }
  }
  let selectorValue = useSelector(state => state.session);
  const [sessionState, SetSessionState] = React.useState(selectorValue);
  const [uname, setUname] = React.useState('');

  React.useEffect(() => {
    SetSessionState(selectorValue);
  }, [selectorValue]);

  React.useEffect(() => {
    if (sessionState.sessionValue?.payload?.loginroute === "passport") {
      if (sessionState.sessionValue?.payload?.simulationCheck === true){
        setUname("Simulation User")
      } else {
        if (sessionState.sessionValue?.payload?.decodedToken?.enterpriseId){
          let firstName = sessionState.sessionValue.payload.decodedToken.firstName?sessionState.sessionValue.payload.decodedToken.firstName:'';
          let lastName = sessionState.sessionValue.payload.decodedToken.lastName?sessionState.sessionValue.payload.decodedToken.lastName:'';
          setUname(firstName + " " + lastName);
        } else if (sessionState.sessionValue?.payload?.decodedToken?.globalId) {
          setUname(sessionState.sessionValue?.payload?.decodedToken?.fullName);
        }
      }
    } else {
      if (sessionState.sessionValue?.payload?.user) {
        setUname(sessionState.sessionValue?.payload?.decodedToken?.name);
      } else {
        setUname("");
      }
    }
    console.log('BatmansessionObject',sessionState.sessionValue?.payload)
  }, [selectorValue, sessionState]);

  const logout = () => {
    let countryName = localStorage.getItem('country') ?? "";
    let loginType = sessionState.sessionValue?.payload?.loginroute;
    let logoutURL = window.location.origin + '/logout.html';

    let countryLocale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en-US';
    if (sessionState?.sessionValue?.payload?.user?.language) {
      countryLocale = sessionState?.sessionValue?.payload?.user?.language
    }

    if (loginType === "passport" || localStorage.getItem('HCP') === 'false') {
      // Redirect 2 Specific Landing Page
      let country_name = countryName.split(" ").join("").toLowerCase();
      const landingCountries = ["Australia", "Brazil", "Czech", "Germany", "Italy", "New Zealand", "Poland", "Romania", "Slovakia", "Spain", "Sweden", "Taiwan", "UK"];
      const lacamCountries = ['Argentina', 'Aruba', 'Bahamas', 'Barbados', 'Belize', 'Bolivia', 'Chile', 'Colombia', 'Costa Rica', 'Curacao', 'Dominican Republic', 'Ecuador', 'El Salvador', 'Guatemala', 'Guyana', 'Honduras', 'Jamaica', 'Mexico', 'Nicaragua', 'Panama', 'Paraguay', 'Peru', 'Trinidad and Tobago', 'Uruguay', 'Venezuela', 'Curacao'];
      const meaCountries = ["Iraq", "Kuwait", "Lebanon", "Oman", "Pakistan", "Qatar", "United Arab Emirates"];
      // if(landingCountries.includes(countryName)) {
      //   logoutURL = window.location.origin + '/landing/' + country_name + '/index.html';
      // } else if(lacamCountries.includes(countryName)) {
      //   logoutURL = window.location.origin + '/landing/lacam/index.html';
      // } else if(meaCountries.includes(countryName)) {
      //   logoutURL = window.location.origin + '/landing/mea/index.html';
      // }
      logoutURL = window.location.origin + "/" + country_name;
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = process.env.REACT_APP_PASSPORT_BASE_URL + `${countryLocale}/logout/?url=${logoutURL}`;
    }
    else if (loginType === "federate") {
      localStorage.clear();
      sessionStorage.clear();
      const msalConfig = {
        auth: {
          clientId: process.env.REACT_APP_FEDERATE_CLIENT_ID,
          authority: process.env.REACT_APP_SSO_AUTHORITY,
          redirectUri: "/",
        }
      };
      const msalInstance = new msal.PublicClientApplication(msalConfig);
      msalInstance.logoutRedirect({
          postLogoutRedirectUri: logoutURL
      });
    }
    else {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = logoutURL;
    }
  }

  const navigateFav = () => {
    navigate('/home/favorite')
    setOpen(false);
    setOpenSub(false);
    setOpenMain(false);
  }

  /**Section shows all therepeutic areas menu list*/
  const menu = () => (
    <div className='fix-width-hamberger-menu'>
      <List className='userList'>
        <ListItem className='ulistSec' button key="main">

          {subLibraryCategory == '1' &&
            <Typography variant="h6" className='tharpHd'>{props.translations['Therapeutic Areas'] ? props.translations['Therapeutic Areas'] : 'Therapeutic Areas'}</Typography>
          }
          {subLibraryCategory == '2' &&
            <Typography variant="h6" noWrap className='tharpHd'>{props.translations['Category 1'] ? props.translations['Category 1'] : 'Category 1'}</Typography>
          }
          {subLibraryCategory == '3' &&
            <Typography variant="h6" noWrap className='tharpHd'>{props.translations['Category 2'] ? props.translations['Category 2'] : 'Category 2'}</Typography>
          }

        </ListItem>
        <div button key="profile" className='ulistOuter userArea'>
          {menuList.length > 0 ? menuList.map((menu, index) => (
              <List className="padding-zero ulistOuter" key={index}>
                <div className="flex-div">
              <ListItem className='leftArrow-icon therapeutic-list ulist' 
                    onClick={(e) => {
                        if (subLibraryCategory == '1') {
                        toggleDrawerMain(e, menu.id);
                          setMainMenuMain(menu.name)
                        }
                        else if (subLibraryCategory == '2') {
                        setSubMenuMain(menu.name);
                          toggleDrawerSub(e, menu.id)
                      } else {
                          setMainMenuMain(menu.name)
                      }
                    }}
                    key={menu.id} >
                  <img alt="arrow" className='leftArrow' src={leftArrow}
                      onClick={(e) => {
                        if (subLibraryCategory == '1') {
                          toggleDrawerMain(e, menu.id);
                          setMainMenuMain(menu.name)
                        }
                        else if (subLibraryCategory == '2') {
                          setSubMenuMain(menu.name);
                          toggleDrawerSub(e, menu.id)
                        } else {
                          setMainMenuMain(menu.name)
                        }
                      }
                    }
                    onKeyDown={(e) => toggleDrawerClose(e, false)} />
                  </ListItem>
                <ListItem className='therapeutic-list ulist' key={menu.id} >
                  {/* <Typography variant="h6" noWrap className='thatTxt' onClick={() => menuSearch(menu.id, menu.name)}>{props.translations[menu.name] ? props.translations[menu.name] : menu.name}</Typography> */} {/*hamburger menu width changes*/}
                  <Typography variant="h6" className='thatTxt' onClick={() => menuSearch(menu.id, menu.name)}>{props.translations[menu.name] ? props.translations[menu.name] : menu.name}</Typography> 
                  </ListItem>
                </div>
              </List>
          )) : null}
        </div>
      </List>
      <button className='logout' onClick={() => logout()}>{props.translations['Log Out'] ? props.translations['Log Out'] : 'Log Out'}</button>
    </div>
  );

  /**Section shows all therepeutic areas main menu list*/
  const mainMenu = () => (
    <div className='fix-width-hamberger-menu'>
      <List>
        <ListItem button key="main" className='listGutter'>
          <Typography variant="h6" noWrap onClick={(e) => toggleMain(e)}>{props.translations['Main Menu'] ? props.translations['Main Menu'] : 'Main Menu'}
            <img alt="arrow" className='rightArrow' src={rightArrow} /> 
          </Typography>
        </ListItem>
        <ListItem button key="main" className='fix-width-list listGutter'>
          <Typography className='custom-line-height subMenu' variant="h6" onClick={(e) => toggleMain(e)} >{props.translations[mainMenuMain] ? props.translations[mainMenuMain] : mainMenuMain}</Typography>
        </ListItem>
        <List className="ulistOuter">
          {mainMenuList.length > 0 ? mainMenuList.map((menu, index) => (
            <ListItem className='therapeutic-list ulist' key={menu.id} >
              {!directCategory2 &&  (country_Code != 'UK' && country_Code != 'Sweden' && country_Code != 'Finland') && <img alt="arrow" className='leftArrow' src={leftArrow} onClick={(e) => { toggleDrawerSub(e, menu.id); setSubMenuMain(menu.name) }} onKeyDown={(e) => toggleDrawerClose(e, false)} />}
              {/* <Typography variant="h6" noWrap className='thatTxt' onClick={() => menuSearch(menu.id, menu.name)}>{props.translations[menu.name] ? props.translations[menu.name] : menu.name}</Typography> */} {/*hamburger menu width changes*/}
              <Typography variant="h6" className='thatTxt' onClick={() => menuSearch(menu.id, menu.name)}>{props.translations[menu.name] ? props.translations[menu.name] : menu.name}</Typography>
              </ListItem>
          )) : null}
        </List>
      </List>
    </div>
  );

  /**Section shows all therepeutic areas sub menu list*/
  const subMenu = () => (
    <div className='fix-width-hamberger-menu'>
      <List>
        <ListItem button key="main" className='listGutter'>
          <Typography variant="h6" onClick={(e) => toggleMain(e)} noWrap>{props.translations['Main Menu'] ? props.translations['Main Menu'] : 'Main Menu'}
            <img alt="arrow" className='rightArrow' src={rightArrow} />
          </Typography>
        </ListItem>
        <ListItem button key="main" className='fix-width-list listGutter'>
          <Typography variant="h6" onClick={(e) => toggleSubmenu(e)} className='custom-line-height subMenu' >{props.translations[mainMenuMain] ? props.translations[mainMenuMain] : mainMenuMain}
            {subLibraryCategory == '1'  && <img alt="arrow" className='rightArrow' src={rightArrow} />}
          </Typography>
        </ListItem>
        <ListItem button key="main" className='fix-width-list listGutter'>
          <Typography variant="h6" onClick={(e) => toggleSubmenu(e)} className='custom-line-height subMenu' >{props.translations[subMenuMain] ? props.translations[subMenuMain] : subMenuMain}</Typography>
        </ListItem>
        <List className="scrollbar ulistOuter">
          {subMenuList.length > 0 ? subMenuList.map((menu, index) => (
            <ListItem className='therapeutic-list sec-cat-list ulist' key={menu.id} onClick={(e) => toggleDrawerSub(e, menu.id)} onKeyDown={(e) => toggleDrawerClose(e, false)}>
              {/* <Typography variant="h6" noWrap className='thatTxt' onClick={() => menuSearch(menu.id, menu.name)}>{props.translations[menu.name] ? props.translations[menu.name] : menu.name}</Typography> */} {/*hamburger menu width changes*/}
              <Typography variant="h6" className='thatTxt-sec-cat thatTxt' onClick={() => menuSearch(menu.id, menu.name)}>{props.translations[menu.name] ? props.translations[menu.name] : menu.name}</Typography>
              </ListItem>
          )) : null}
        </List>
      </List>
    </div>
  );
  return (
    <>
      {/* Sidebar Section */}
      <>
        <React.Fragment>
          <IconButton className='showMenu' color="inherit" aria-label="menu" onClick={(e) => toggleDrawer(e, true)}>
            <MenuIcon className='showMenu FS-50' />
          </IconButton>
          <Drawer classes={HCPBool ? { paper: 'drawerContainer hcp' } : { paper: 'drawerContainer nonhcp' }} anchor={'right'} open={open} onClose={(e) => toggleDrawerClose(e, false)}>
            <Grid container>
              <Grid item lg={12} xs={12} className='pdL-10'>
                <Typography variant="span" noWrap className='uname'>{uname && uname.length > 0 ? uname : 'N/A'}</Typography>
                <button className='menuBtn' onClick={(e) => { navigate('/my-profile'); toggleDrawerClose(e, false) }}>{props.translations['Edit'] ? props.translations['Edit'] : 'Edit'}</button>
                <button className='menuBtn' onClick={() => navigateFav()}>{props.translations['My Favorites'] ? props.translations['My Favorites'] : 'My Favorites'}</button>
              </Grid>
            </Grid>
            {menu()}
          </Drawer>
        </React.Fragment>
      </>
      <>
        <React.Fragment>
          <Drawer classes={HCPBool ? { paper: 'drawerContainer hcp' } : { paper: 'drawerContainer nonhcp' }} anchor={'right'} open={openMain} onClose={(e) => toggleDrawerClose(e, false)}>
            {mainMenu()}
          </Drawer>
        </React.Fragment>
      </>
      <>
        <React.Fragment>
          <Drawer classes={HCPBool ? { paper: 'drawerContainer hcp' } : { paper: 'drawerContainer nonhcp' }} anchor={'right'} open={openSub} onClose={(e) => toggleDrawerClose(e, false)}>
            {subMenu()}
          </Drawer>
        </React.Fragment>
      </>
      {/* End Sidebar Section */}
    </>
  );
};

export default AppSidebar;