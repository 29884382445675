import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import podcastIcon from '../../../public/img/podcast.svg';
import videoplayIcon from '../../../public/img/videoPlay.svg';
import playlistIcon from '../../../public/img/playlist.png';
import { useNavigate } from "react-router-dom";
import Link from '@material-ui/core/Link';
import { BlackTriangleShowMore } from "../../shared/BlackTriangle";
import Thumbnail from '../../shared/Thumbnail_bkp';
import PIInfo from '../../shared/PIInfo';
import {ContentService} from '../Service/ContentService'
 const RelatedContent = (props) => {
    const [hasShowMore, sethasShowMore] = React.useState(false);
    const [firstLoadRelatedContent, setFirstLoadRelatedContent] = React.useState([]);
    let relatedContentCount = 6;
    const navigate = useNavigate();

    const showMoreRelatedContent = () => {

        let firstLoadData = [...props.relatedVideos];
        setFirstLoadRelatedContent(firstLoadData)

        sethasShowMore(false)
    }

    React.useEffect(() => {
        let relatedVideoCount = [...props.relatedVideos];

        let firstLoadData = [];
        if (relatedVideoCount.length > 6) {
            sethasShowMore(true);
            for (let i = 0; i < 6; i++) {
                firstLoadData.push(relatedVideoCount[i])
            }
        }
        else {
            sethasShowMore(false);
            firstLoadData = relatedVideoCount;
        }
        setFirstLoadRelatedContent(firstLoadData)
    }, [props.relatedVideos]);



    return (
        <>
            <Grid>
                <Grid className='related_content-wrapper'>
                    <Paper className='shared-topTag'>{props.translations['Related Content'] ? props.translations['Related Content'] : 'Related Contents'}</Paper>
                    {firstLoadRelatedContent.length ? firstLoadRelatedContent.map((content, index) => {

                        let contentIcon = content.content_type === 'video' ? videoplayIcon :
                            content.content_type === 'playlist' ? playlistIcon :
                                content.content_type === 'podcast' ? podcastIcon : null;
                        let contentTitle = content.content_type === 'video' ? content.title :
                            content.content_type === 'playlist' ? content.title :
                                content.content_type === 'podcast' ? content.title : null;
                        let contentSpeakers = content.speakers_id ? content.speakers_id.join(' ') : '';
                        let contentURL = content.content_type === 'playlist' ? `/content-watch/${content.kaltura_id}-${content.playlist_id}/${content.playlist_id}` : `/content-watch/${content.kaltura_id}/${content.content_id}`;
                        let gaIdContent =  content.content_type === 'playlist' ? content.playlist_id: content.content_id;

                        return (
                            <div key={index} className='related-content-card'>
                                <div className='related-content-container'>
                                    <div className='related-cover-content click_link' onClick={() => {
                                        props.SetPositionMainPlayer(0);
                                        props.setVideoEnd(false);
                                        (new ContentService()).VideoSelectedGAEvent(gaIdContent, null);
                                        (new ContentService()).NavigatedToVideoFromContentPageEvent(gaIdContent, 'Navigated to video from another content page', props.filter); navigate(contentURL)
                                    }}>
                                        <div className='custom-related-con-cover related-cover-content-play'>
                                            <img src={contentIcon} className={'custom-svgIcon custom-shared-svgIcon-content-' + content.content_type + ' shared-svgIcon-content-' + content.content_type} alt='icon' />
                                        </div>
                                        <div className='related-cover-content-thumbnail'>

                                            <Thumbnail className='related-content-thumbnail' content={content} page={'related-content'} />
                                            {parseInt(content.duration) > 0 && <span className="videTime-search">{(content.duration) / 3600 > 1 ? (new Date(content.duration * 1000)).toISOString().slice(11, 19) :
                                                (new Date(content.duration * 1000)).toISOString().slice(14, 19)}</span>}
                                        </div>

                                    </div>
                                    <div className='related-contents-pl10'>
                                        <div>
                                            <Paper className={'custom-shared-video-tag shared-' + content.content_type + 'Tag-content'}>{content.content_type}
                                            </Paper>
                                        </div>
                                        <div className='medium-device-contents'>
                                            <div className='custom-clickableLink shared-cont-title clickableLink' onClick={() => { (new ContentService()).VideoSelectedGAEvent(gaIdContent, null);(new ContentService()).NavigatedToVideoFromContentPageEvent(gaIdContent, 'Navigated to video from another content page', props.filter); navigate(contentURL) }}>{contentTitle.length > 25 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 24)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</div>
                                            <div className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 20 ? `${contentSpeakers.slice(0, 19)}...` : contentSpeakers}</div>
                                        </div>
                                        <div className='large-device-contents'>
                                            <div className='custom-clickableLink shared-cont-title clickableLink' onClick={() => { (new ContentService()).VideoSelectedGAEvent(gaIdContent, null);(new ContentService()).NavigatedToVideoFromContentPageEvent(gaIdContent, 'Navigated to video from another content page', props.filter); navigate(contentURL) }}>{contentTitle.length > 35 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 30)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</div>
                                            <div className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 40 ? `${contentSpeakers.slice(0, 39)}...` : contentSpeakers}</div>
                                        </div>
                                        <div className='small-device-contents'>

                                            <div className='custom-clickableLink shared-cont-title clickableLink' onClick={() => { (new ContentService()).VideoSelectedGAEvent(gaIdContent, null);(new ContentService()).NavigatedToVideoFromContentPageEvent(gaIdContent, 'Navigated to video from another content page', props.filter); navigate(contentURL) }}>{contentTitle.length > 30 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 30)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</div>
                                            <div className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 29 ? `${contentSpeakers.slice(0, 28)}...` : contentSpeakers}</div>

                                        </div>
                                        {content?.category_1_id?.length ?
                                            <div className="cont-piinfo">
                                                <PIInfo category1Ids={content.category_1_id} />
                                            </div>
                                            : ""}
                                    </div>

                                </div>
                            </div>

                            // <Grid key={index}>
                            //     <Grid container className='shared-sOuter'>
                            //         <Grid item xs={12} sm={5} lg={5} md={5}>
                            //             <Grid className='shared-sCover-content' onClick={() => {props.SetPositionMainPlayer(0);
                            //                 (new ContentService()).VideoSelectedGAEvent(gaIdContent, null);navigate(contentURL)}}>
                            //                 <Grid className='custom-related-con-cover shared-sCoverInner-Content'>
                            //                     <img src={contentIcon} className={'custom-svgIcon custom-shared-svgIcon-content-' + content.content_type +' shared-svgIcon-content-' + content.content_type} alt='icon' />
                            //                 </Grid>
                            //                 <Thumbnail className='related-content-thumbnail' content={content} page={'related-content'} />
                            //                 {parseInt(content.duration) > 0 && <span className="videTime-search">{(content.duration) / 3600 > 1 ? (new Date(content.duration * 1000)).toISOString().slice(14, 19) :
                            //                         (new Date(content.duration * 1000)).toISOString().slice(11, 19)}</span>}
                            //             </Grid>
                            //         </Grid>
                            //         <Grid item xs={12} sm={7} lg={7} md={7} className='related-contents-pl10'>
                            //             <Grid><Paper className={'custom-shared-video-tag shared-' + content.content_type + 'Tag-content'}>{content.content_type}</Paper></Grid>
                            //             <div className='medium-device-contents'>
                            //                 <Grid className='custom-clickableLink shared-cont-title clickableLink' onClick={() => {(new ContentService()).VideoSelectedGAEvent(gaIdContent, null);navigate(contentURL)}}>{contentTitle.length > 25 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 24)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</Grid>
                            //                 <Grid className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 20 ? `${contentSpeakers.slice(0, 19)}...` : contentSpeakers}</Grid>
                            //             </div>
                            //             <div className='large-device-contents'>
                            //                 <Grid className='custom-clickableLink shared-cont-title clickableLink' onClick={() => {(new ContentService()).VideoSelectedGAEvent(gaIdContent, null);navigate(contentURL)}}>{contentTitle.length > 56 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 55)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</Grid>
                            //                 <Grid className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 40 ? `${contentSpeakers.slice(0, 39)}...` : contentSpeakers}</Grid>
                            //             </div>
                            //             <div className='small-device-contents'>
                            //                 <Grid className='custom-clickableLink shared-cont-title clickableLink' onClick={() => {(new ContentService()).VideoSelectedGAEvent(gaIdContent, null);navigate(contentURL)}}>{contentTitle.length > 50 ? <BlackTriangleShowMore title={`${contentTitle.slice(0, 49)}...`} /> : <BlackTriangleShowMore title={contentTitle} />}</Grid>
                            //                 <Grid className='custom-clickableLink shared-upName-content clickableLink' onClick={() => navigate('/speaker-detail/' + contentSpeakers)}>{contentSpeakers.length > 29 ? `by ${contentSpeakers.slice(0, 28)}...` : 'by '+contentSpeakers}</Grid>
                            //             </div>
                            //             <Grid className="cont-piinfo">
                            //                 {content?.category_1_id?.length ? <PIInfo category1Ids={content.category_1_id} /> : ""}
                            //             </Grid>
                            //         </Grid>
                            //     </Grid>
                            // </Grid>

                        )
                    }) : <Grid className='shared-cont-no-data'>{props.translations['There is no content related to this ' + props.contentType] ? props.translations['There is no content related to this ' + props.contentType] : 'There is no content related to this ' + props.contentType}</Grid>}
                    {hasShowMore ? <Grid item lg={6} xs={12} sm={6} className=''>
                        <Link onClick={() => showMoreRelatedContent()} className='showBtn'>{props.translations['Show more'] ? props.translations['Show more'] : 'Show more'}</Link>
                    </Grid> : ""}
                </Grid>
            </Grid>
        </>
    )
}

export default RelatedContent