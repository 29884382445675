import {configureStore} from '@reduxjs/toolkit'
import sessionReducer from './sessionSlice'
import translationSlice from './translationSlice';
import playerDimensionSlice from './PlayerDimension';
import AffiliateSlice from './AffiliateSlice';
import CategorySlice from './CategorySlice';
export default configureStore({
    reducer : {
        session : sessionReducer,
        translation : translationSlice,
        playerDimesion : playerDimensionSlice,
        affilaiteSlice : AffiliateSlice,
        categorySlice : CategorySlice
    }
});