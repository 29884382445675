import axios from 'axios';

class SpeakerService {
    
    constructor(jwtToken)
    {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeaders = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': jwtToken?jwtToken:localStorage.getItem('jwtToken')
            }
        };
    }
    
    async GetSpeakerByQuery(params,start,end){
        let qs = '?start='+start+'&end='+end;
        if(Object.keys(params).length > 0) {
            if(params.searchKeyword && params.searchKeyword !='') {
                qs += '&name='+ params.searchKeyword;
            }
            if(params.speakerID) {
                qs += '&speaker_id='+ params.speakerID;
            }
        }
        return await axios.post(this.baseUrl +'/management/api/speaker/GetSpeakersByQueryPagination'+ qs, {}, this.authHeaders);
    };
    async GetSpeakerByQueryCreateEdit(params){
        let qs = '?';
        if(Object.keys(params).length > 0) {
            // qs += "?";
            if(params.searchKeyword && params.searchKeyword !='') {
                qs += '&name='+ params.searchKeyword;
            }
            if(params.speakerID) {
                qs += '&speaker_id='+ params.speakerID;
            }
        }
        return await axios.post(this.baseUrl +'/management/api/speaker/GetSpeakersById'+ qs, [params.speakerID], this.authHeaders);
    };
    async GetSpeakerByQuerySearch(params,start,end){
        let qs = ''
        if(Object.keys(params).length > 0) {
            qs += "?";
            if(params.searchKeyword && params.searchKeyword !='') {
                qs += '&name='+ params.searchKeyword;
            }
            if(params.speakerID) {
                qs += '&speaker_id='+ params.speakerID;
            }
        }
        return await axios.post(this.baseUrl +'/management/api/speaker/GetSpeakersByQuery'+ qs, {}, this.authHeaders);
    };
    async GetAllSpeakerByQuery(){
        return await axios.post(this.baseUrl +'/management/api/speaker/GetSpeakersByQueryPaginationCout', {}, this.authHeaders);
    };

    async DeleteSpeaker(id){
        return await axios.post(this.baseUrl +'/management/api/speaker/DeleteSpeaker?speaker_id='+id, {}, this.authHeaders);
    };

    async UpdateSpeaker(modal){
        return await axios.post(this.baseUrl +'/management/api/speaker/UpdateSpeaker', modal, this.authHeaders);
    };

    async UploadFileToS3(fileName, renderResult){
        let _key =fileName;// 'Speaker/'+ fileName;
        let _result = renderResult;
        let dataUrl = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedUrlPutObject', {key : _key, body : _result}, this.authHeaders);
        return (await axios.put(dataUrl.data, renderResult)).data;
    }
    async GetS3File(key){
        try{
            if(key !=null && key.length>0){
                let data = await axios.post(this.baseUrl + '/content/api/aws/GetPresignedURIGet', {key : key.trim()}, this.authHeaders);
                return (await axios.get(data.data)).data//.binarySring;
            } else{
                return null;
            }
        }catch(err){ 
            return null;
        }
    }
    async GetAllContent(param) {
        if(param) {
            return await axios.post(this.baseUrl + '/content/api/video/GetVideosByQueryCountData?'+param, {}, this.authHeaders);
        }
        else {
            return await axios.post(this.baseUrl + '/content/api/video/GetVideosByQueryCountData?category_id=All', {}, this.authHeaders);
        }
        
    };
}
export { SpeakerService }


