import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Breadcrumb from '../../../layout/breadcrumb';
import ContentList from '../../shared/ContentList/ContentList';
import { ContentService } from '../Service/contentService';
import { HomeServiceBaseMatchingModel } from '../Service/homeService';
import { useSelector, useDispatch } from 'react-redux';
import { UserBrandService } from '../Service/userBrandService';
import loaderNewGif from '../../../public/img/new-loader.png';
export let util = { loadProducts: null };

const ShowMore = (props) => {
    //alert("alert")
    let secTitle = props.title;
    let secType = props.type;
    const [videoData, setVideoData] = React.useState([]);
    const [hasMore, setHasmore] = React.useState(false);
    const [lastPosition, setLastPosition] = React.useState(5);
    const [startPosition, setStartPosition] = React.useState(0);
    const [allvideoData, setAllvideoData] = React.useState([]);
    const [showLoader, setShowLoader] = React.useState(true);
    const [secFilterId, setsecFilterId] = React.useState('');
    const [sessionCheck, SetsessionCheck] = React.useState(false);
    const [eventListenerTrigger, SetEventListenerTrigger] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [userId, SetUserId] = React.useState(1);
    let selectorValue = useSelector(state => state.session);
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [taIdsArrData, SetTaIdsArrData] = React.useState([]);
    const [brandIdsArrData, SetBrandIdsArrData] = React.useState([]);
    const [indIdsArrData, SetIndIdsArrData] = React.useState([]);
    const [userBrandFilterData, SetUserBrandFilterData] = React.useState(false);
    const[brandCompletion,SetBrandCompletion]= React.useState(false);

    let contentService = new ContentService();
    function resetQueryData() {
        setAllvideoData([]);
        setVideoData([]);
        setLastPosition(101);
        setStartPosition(0);
    }

    React.useEffect(() => {
      SetSessionState(selectorValue);
  }, [selectorValue])

   React.useEffect(() => {
     function getUserSelectedBrandData() {
      let userBrandService = new UserBrandService();
     userBrandService.getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData).then((data)=> {
      
      SetBrandCompletion(true);
     });
    }
    getUserSelectedBrandData()
  }, [])

    /**Function will load Load Next list of contents on scroll */
    util.loadProducts = async () => {
        let user_id = userId
        if (props.selectorValueSession) {
          if (props.selectorValueSession.sessionValue.payload) {
            if (props.selectorValueSession.sessionValue.payload.user) {
              if (props.selectorValueSession.sessionValue.payload.user) {
                user_id = props.selectorValueSession.sessionValue.payload.user.id
              }
            }
          }
        }
        const paginateData = await contentService.getContentListCount(startPosition, lastPosition, secType, secFilterId, user_id,props.selectedCountry,props.selectedLibraryid, GetHomeServiceModel());
        const tempData = paginateData.data;
        let content_id = [];
        let array1 = videoData.concat(tempData);
        let list = []
        for(let i = 0; i<array1.length; i++){
            if(!content_id.includes(array1[i].content_id)){
                list.push(array1[i])
                content_id.push(array1[i].content_id)
            }
        }
        setVideoData(list);
        setLastPosition(lastPosition+ 101);
        setStartPosition(lastPosition+1);
        if (paginateData.data.length === lastPosition-startPosition +1) {
            setHasmore(true);
        } else {
          if(allvideoData.length == videoData.length){
            setHasmore(false);
          }
            
        }
        return true;
    };

    const GetAffiliateCodesArray = () => {
      let list = []
      if (props.selectorValueSession) {
        if (props.selectorValueSession.sessionValue.payload) {
          if (Array.isArray(props.selectorValueSession.sessionValue.payload.affiliate)) {
            let affiliates = props.selectorValueSession.sessionValue.payload.affiliate
            for (let i = 0; i < affiliates.length; i++) {
              if (affiliates[i]) {
                list.push(affiliates[i].country_code);
              }
            }
          }
        }
      }
      return list;
    }
    const GetHomeServiceModel = (specialty = '', profession = '') => {
        let countryName = localStorage.getItem('country') ?? '';
        let localeName = localStorage.getItem('lang') ?? '';
        let loginROute = '';
        if (props.selectorValueSession) {
          if (props.selectorValueSession.sessionValue.payload) {
            if(props.selectorValueSession.sessionValue.payload.loginroute){
              loginROute = props.selectorValueSession.sessionValue.payload.loginroute
            }
            if (props.selectorValueSession.sessionValue.payload.decodedToken) {
              if (props.selectorValueSession.sessionValue.payload.decodedToken) {
                let _spec = props.selectorValueSession.sessionValue.payload.decodedToken.specialty?props.selectorValueSession.sessionValue.payload.decodedToken.specialty:"";
                SetsessionCheck(true)
                let _prof = props.selectorValueSession.sessionValue.payload.decodedToken.profession?props.selectorValueSession.sessionValue.payload.decodedToken.profession:"";
                let taArray = [];
                if(props.filterId.includes(',')){
                  taArray = props.filterId.split(',');
                }else{
                  taArray.push(props.filterId);
                }
                if(props.selectorValueSession?.sessionValue?.payload?.user?.language){
                  localeName = props.selectorValueSession?.sessionValue?.payload?.user?.language;
                  if(countryName == 'Switzerland'){
                      if(localeName == 'de-CH'){
                        localeName = 'German'
                      }else if(localeName == 'fr_CH'){
                        localeName = 'French'
                      }
                  }else{
                    if(localeName == 'nl-BE'){
                      localeName = 'Dutch'
                    }else if(localeName == 'fr-BE'){
                      localeName = 'French'
                    }
                  }
                }
                let affiliateArray = GetAffiliateCodesArray();
            let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;

                let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, _spec, _prof, taArray, 'Active',brandIdsArrData,indIdsArrData, secType, countryName, localeName, loginROute);
                return model;
              }
            }
          }
        }
        let taFilter = props.filterId;
        if(typeof taFilter == 'string'){
          taFilter = taFilter.split(',')
        }
        let affiliateArray = GetAffiliateCodesArray();
            let affiliateValue = Array.isArray(affiliateArray) && affiliateArray.length > 0 ? affiliateArray : props.selectedCountry;
        let model = new HomeServiceBaseMatchingModel(affiliateValue, props.selectedLibraryid, specialty, profession, taFilter, 'Active', brandIdsArrData,indIdsArrData, secType, countryName, localeName, loginROute);
        return model;
    }

    /**Function will fetch list of contents on page load */
    util.fetchList = (startPosition, lastPosition, secType, secFilterId) => {
        setShowLoader(true);
        let user_id = userId
        if (props.selectorValueSession) {
          if (props.selectorValueSession.sessionValue.payload) {
            if (props.selectorValueSession.sessionValue.payload.user) {
              if (props.selectorValueSession.sessionValue.payload.user) {
                user_id = props.selectorValueSession.sessionValue.payload.user.id
              }
            }
          }
        }
        contentService.getContentListCount(startPosition, lastPosition, secType, secFilterId, user_id, props.selectedCountry,props.selectedLibraryid, GetHomeServiceModel()).then(resp => {
            if (Array.isArray(resp.data) && resp.data?.length > 0) {
                let tempData = (resp.data);
                setHasmore(true);
                let content_id = [];
                let list = []
                for(let i = 0; i<tempData.length; i++){
                    if(!content_id.includes(tempData[i].content_id)){
                        list.push(tempData[i])
                        content_id.push(tempData[i].content_id)
                    }
                }
                setVideoData(list);
                let StartPoint = startPosition + 6;
                if (startPosition === 0) {
                    StartPoint = startPosition + 6
                }
                if(list.length != lastPosition-startPosition+1){
                  setShowLoader(false);
                  setHasmore(false);
                }
                setLastPosition(lastPosition + 101);
                setStartPosition(lastPosition+ 1);
            } else {
                resetQueryData();
            }
            setShowLoader(false);
        }).catch(err => { setShowLoader(false);  });
        contentService.getContentList(secType, secFilterId, user_id, props.selectedCountry,props.selectedLibraryid, GetHomeServiceModel()).then(resp => {
            if (Array.isArray(resp.data) && resp.data?.length > 0) {
                let content_id = [];
                let list = []
                for(let i = 0; i<resp.data.length; i++){
                    if(!content_id.includes(resp.data[i].content_id)){
                        list.push(resp.data[i])
                        content_id.push(resp.data[i].content_id)
                    }
                }
                setAllvideoData(list);
                // setAllvideoData(resp.data)
            } else {
                resetQueryData();
            }
            setShowLoader(false);
        }).catch(err =>{});
        
        return true;
    }

    React.useEffect(() => {
    }, []);

    
    React.useEffect(() => {
        setsecFilterId(props.filterId)
        window.scrollTo(0, 0);
        resetQueryData();
        if(brandCompletion && props.filterId != ''){
          util.fetchList(0, 100, secType,props.filterId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionCheck, brandCompletion, props.filterId, props.selectorValueSession]);
    /**Breadcrumb for content list section */
    const BreadcrumbData = [
        { value: props.translations['Home'] ? props.translations['Home'] : 'Home', path: '/home' },
        { value: secTitle, path: '/home/' + secType }
    ]
    return (
        <Grid>
            <Breadcrumb translations={props.translations} setFilter={props.setFilter} BreadcrumbData={BreadcrumbData} />
            <Grid className='outer-wrapper' style={{ overflow: 'hidden' }}>
                <Paper className='topTag'>
                  {props.translations[secTitle]? props.translations[secTitle] : secTitle}&nbsp;
                  {
                    !showLoader && (
                      <>
                      <span className='verticalLine'>|</span> <span>{allvideoData.length} {allvideoData.length < 2 && (props.selectedCountry === 'BR'|| props.selectedCountry === 'FR') ? props.translations['content found'] ? props.translations['content found'] : 'content found' : props.translations['contents found'] ? props.translations['contents found'] : 'contents found'}</span> 
                      </>
                    )
                  }
                </Paper>
                {
                  showLoader ? <Grid className='text-center'><img src={loaderNewGif} className='loadSvg rotate' alt='icon' /></Grid> :
                    videoData.length > 0 ?
                        <ContentList
                            breadcrumbData={BreadcrumbData}
                            contentDataCount={allvideoData.length}
                            contentData={videoData}
                            loadProducts={util.loadProducts}
                            hasMore={hasMore}
                            showLoader={showLoader}
                            translations={props.translations}
                            filter ={props.filter}
                        />
                        : <Grid className="ml10">{props.translations['No Content Found'] ? props.translations['No Content Found'] : 'No Content Found'}</Grid>
                }
            </Grid>
        </Grid>
    );
}

export default ShowMore;
