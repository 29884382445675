/* eslint-disable linebreak-style */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TranslationManagement from './translation-management/translation-management';
import Forbidden from '../forbidden/forbidden';


const TranslationRoute = (props) => (
    <Routes>
        {props.isRoleAdmin ?
            <Route exact path="/translation-management" element={<TranslationManagement  translations={props.translations} setFilter={props.setFilter}/>}/> :
            <Route exact path="/translation-management" element={<Forbidden translations={props.translations} />} />
        }          
        
    </Routes>
);

export default TranslationRoute;