import {createSlice} from '@reduxjs/toolkit';
import translationFn from '../language-translation/locales/fn/translation.json';
import translationEn from '../language-translation/locales/en-US/translation.json';

export const translationSlice= createSlice({
    name : 'translation',
    initialState : {
        fn : translationFn,
        en : translationEn,
        // es : translationEs
    },   
    reducers : {
        setTranslation : (state, action)=> {}
    }
});
export const {setTranslation} = translationSlice.actions

export default translationSlice.reducer;