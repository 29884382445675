import React, { forwardRef, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';



const Tag = ({ label, onDelete, disabled, ...props }) => {

    return (
        <div className='filterTag' {...props}>
            <span>{label}</span>
            {disabled === true ?
                <CloseIcon />
                :
                <CloseIcon onClick={onDelete} />
            }
        </div>
    )
};


const Multiselect = forwardRef(({ open, mandatory, defaultvalue, title, list, handleSelected, tags, disabled, error = false }, ref) => {
    const [options, setOptions] = useState(list?.length > 0 ? list : []);
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        filterSelectedOptions,
        groupedOptions,
        value,
        focused,
        setAnchorEl
    } = useAutocomplete({
        id: 'autsearch-styled',
        value: tags,
        multiple: true,
        options: options,
        filterSelectedOptions: true,
        // deafultValue: defaultvalue ? defaultvalue : [],
        getOptionLabel: (option) => option.label,
        getOptionSelected: (option) => tags.map(x => x.key).includes(option.key),
        onChange: (e, value) => handleSelected(value),
    });
    useEffect(() => { setOptions(list) }, [list])
    return (
        <>
            {open ?
                <div className='filter-dropdown-popout'>
                    <NoSsr>
                        <div className='some-filter'>
                            <div {...getRootProps()} >
                                <label className={mandatory ? 'filterLabel mandatory' : 'filterLabel'} {...getInputLabelProps()}>{title}</label>
                                {/* {mandatory ? <span className='mandatory'> * </span> : ''}</label> */}
                                <div className={`filterInputWrapping ${disabled ? 'disabled' : ''} ${error ? 'error' : ''}`} ref={setAnchorEl}>
                                    {tags.map((option, index) => (
                                        <Tag label={option.label} disabled={disabled ? true : false} {...getTagProps({ index })} />
                                    ))}

                                    {tags.length === 0 ?
                                        <input
                                            placeholder={`Select ${title}`} {...getInputProps()}
                                            ref={ref}
                                            disabled={disabled}
                                        />
                                        :
                                        <input
                                            {...getInputProps()}
                                            ref={ref}
                                            disabled={disabled} />
                                    }
                                    <KeyboardArrowDownIcon style={{ color: '#d9d9d9', margin: 'auto' }} {...getInputProps()} />
                                </div>
                            </div>
                            {groupedOptions.length > 0 && !disabled ? (
                                <ul
                                    className='filterUl'
                                    {...getListboxProps()}>
                                    {groupedOptions.map((option, index) => (
                                        <li {...getOptionProps({ option, index })}>
                                            <span>{option.label}</span>
                                            <CheckIcon fontSize="small" />
                                        </li>
                                    ))}
                                </ul>
                            ) : null}
                        </div>
                    </NoSsr>
                </div>
                : ''
            }
        </>
        // </div>
    )
});
export default Multiselect