import { useEffect, useRef } from "react";

const KalturaPlayerWidget = ({
  kalturaId,
  playerWrapperId,
  thumbnailURL,
  ...props
}) => {
  const kalturaPlayer = useRef(null);

  const updateThumbnail = (wrapperId, url) => {
    if (url) {
      const element = document
        .getElementById(wrapperId)
        .getElementsByClassName("playkit-poster")[0];
      if (element) {
        element.style.backgroundImage = `url('${url}')`;
      }
    }
  }
  
  useEffect(() => {
    const widgetSetting = {
      targetId: playerWrapperId,
      provider: {
        partnerId: process.env.REACT_APP_KALTURA_PARTNER_ID,
        uiConfId: process.env.REACT_APP_KALTURA_UI_CONF_ID,
      },
      playback: {
        autoplay: false,
        muted: true,
        allowMutedAutoPlay: true,
      },
      plugins: {
        navigation: {
          disable: true,
        },
        "playkit-js-info": {
          disable: true,
        },
        floating: {
          disable: true,
        },
        preventSeek: {
          disable: true,
        },
      },
    };

    if (window.KalturaPlayer) {
      kalturaPlayer.current = window.KalturaPlayer.setup(widgetSetting);
    }

    return () => {
      if (kalturaPlayer.current) {
        kalturaPlayer.current.destroy();
      }
    };
  }, [playerWrapperId]);

  useEffect(() => {
    if (kalturaId && kalturaPlayer.current) {
      kalturaPlayer.current.loadMedia({ entryId: kalturaId }).then(() => {
        updateThumbnail(playerWrapperId, thumbnailURL);
      });
    }
  }, [kalturaId]);

  useEffect(() => {
    updateThumbnail(playerWrapperId, thumbnailURL)
  }, [thumbnailURL, playerWrapperId]);

  return <div id={playerWrapperId} {...props}></div>;
};

export default KalturaPlayerWidget;
