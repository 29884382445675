import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import addIcon from '../../../public/img/Add@3x.png';
import editIcon from '../../../public/img/BlueEdit@3x.png';
import deleteIcon from '../../../public/img/RedClose@3x.png';


const GetTreeItemsFromData = (props) => {
    React.useEffect(() => {
    }, [props.categoryTree])

    return (
      <>
        <div className='category_tree'>
          <Typography variant="subtitle1">
            Therapeutic Area and Category  
            <img className='iconChild' src={addIcon} alt='Add' onClick={() => props.addPopup('Therapeutic Area', '', '')} />
          </Typography>
          <TreeView className='treeview' defaultCollapseIcon={<ExpandMoreIcon className='iconSvg' />} defaultExpandIcon={<ExpandMoreIcon className='iconSvg' />}>
            {props.categoryTree.length > 0 ? props.categoryTree.map((treeItemData, index) => {
              return (
                  <TreeItem nodeId={100+treeItemData?.id} key={`cat_`+index} label={
                    <>
                      <div className='childDrop'>
                        {treeItemData?.name ? treeItemData.name : null}
                        <span className='childDropInnr'>
                          <img className='iconChild' src={addIcon} alt='Add' onClick={() => 
                            props.addPopup('Category1', treeItemData?.id, '') } />
                          <img className='iconChild' src={editIcon} alt='Edit' onClick={() => 
                            props.editPopup(treeItemData?.name, treeItemData?.id, 'Therapeutic Area', treeItemData?.id, "")} />
                          <img className='iconChild' src={deleteIcon} alt='Delete' onClick={() => props.deletePopup(
                            treeItemData?.name, treeItemData?.id, 'Therapeutic Area')} />
                        </span>
                      </div>
                    </>
                  }>
                    {treeItemData?.children?.length > 0 ? treeItemData?.children.map((treeItemChildren, index2) => {
                      return (
                        <TreeItem nodeId={1000+treeItemChildren?.id} key={`cat1_`+index2} label={
                          <>
                            <div className='childDrop'>
                              {treeItemChildren?.name ? treeItemChildren.name : null}
                              <span className='childDropInnr'>
                                <img className='iconChild' src={addIcon} alt='Add' onClick={() =>
                                   props.addPopup('Category2', treeItemData?.id, treeItemChildren?.id)} />
                                <img className='iconChild' src={editIcon} alt='Edit' onClick={() => props.editPopup(
                                  treeItemChildren?.name, treeItemChildren?.id, 'Category1', treeItemData?.id, treeItemChildren?.id)} />
                                <img className='iconChild' src={deleteIcon} alt='Delete' onClick={() => props.deletePopup(
                                  treeItemChildren?.name, treeItemChildren?.id, 'Category1')} />
                              </span>
                            </div>
                          </>
                        }
                        >
                          {treeItemChildren?.children?.length > 0 ? treeItemChildren?.children.map((treeItemSubchildren, index3) => {
                            return (
                              <TreeItem nodeId={10000+treeItemSubchildren?.id} key={`cat2_`+index3} label={
                                <>
                                  <div className='subchildDrop'>
                                    {treeItemSubchildren?.name ? treeItemSubchildren.name : null}
                                    <span className='subchildDropInnr'>
                                      <img className='iconChild' src={editIcon} alt='Edit' onClick={() => props.editPopup(
                                        treeItemSubchildren?.name, treeItemSubchildren?.id, 'Category2', treeItemData?.id, treeItemChildren?.id)} />
                                      <img className='iconChild' src={deleteIcon} alt='Delete' onClick={() => props.deletePopup(
                                        treeItemSubchildren?.name, treeItemSubchildren?.id, 'Category2')} />
                                    </span>
                                  </div>
                                </>
                              }
                              />
                            )
                          }) : null}
                        </TreeItem>
                      )
                    }) : null}
                  </TreeItem>
              );
            }) : "Loading..."}
          </TreeView>
        </div>
      </>
    );
  };
  export default GetTreeItemsFromData;