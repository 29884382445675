import React, { } from 'react';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import { ContentService } from '../Service/content-service';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContentModal from '../Components/ContentModal';
import { PlaylistService } from '../Service/playlist-service';
import Tooltip from '@mui/material/Tooltip';


const ContentQuiz = (props) => {
  
  const modalFunc = React.useRef(null);
  const [quizList, SetQuizList] = React.useState([]);  
  // eslint-disable-next-line no-unused-vars
  const [passingScore, SetPassingScore] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [creditScore, SetCreditScore] = React.useState("0");
  const [checked, setChecked] = React.useState(false);
  const [sendDataToModal, setSendDataToModal] = React.useState("");
  // eslint-disable-next-line no-unused-vars
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [quizIdActive, setquizIdActive] = React.useState('');
  const [quizArray, setquizArray] = React.useState([]);

  let quizObj = {
    "quiz_credit" : "0","passing_score": "", "is_mandatory" : "false", "quiz_id": "0"
  }
    
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSave = (event) => {
    quizObj = {
      "quiz_credit" : creditScore,"passing_score": passingScore, "is_mandatory" : checked, "quiz_id": props.quizId
    }
    props.SetQuizDetails(quizArray);
    modalFunc.current();
    let msg = "Quiz information has been saved!";
    setSendDataToModal(msg);
  }

  let service = new PlaylistService();
  const GetQuizDetails =(id) => {
    service.GetAllQuiz().then((data) => {
      let result = data.data;
      // SetQuizList(result);
      result.map((quiz) => {
        if(quiz.quiz_id === quizIdActive || quiz.quiz_id === id) {
          props.SetQuizSelected(quiz);
          setFirstLoad(false);
        }
        return true;
      })
    }).catch((err) => {});
  }
  React.useEffect(() => {
    let newArr={};
    newArr['quiz_credit'] = creditScore;
    newArr['passing_score'] = passingScore;
    newArr['is_mandatory'] = checked;
    newArr['quiz_id'] = quizIdActive;
    setquizArray(newArr)
  },[passingScore,checked, creditScore, quizIdActive])
  
  React.useEffect(() => {
    if (props.quizDetails && Object.keys(props.quizDetails).length > 0) {
      let quizObj = JSON.parse(JSON.stringify(props.quizDetails));
      props.SetQuizSelected(quizObj);
      SetPassingScore(quizObj.passing_score)
      setChecked(quizObj.is_mandatory);
      setquizIdActive(quizObj.quiz_id);
      SetCreditScore(quizObj.quiz_credit);
      GetQuizDetails(quizObj.quiz_id);
      //return false;
      }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.quizDetails]);
  React.useEffect(() => {
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.quizObj]);
  React.useEffect(() => {
    fetchQuizData();
  }, []);
  const fetchQuizData = async () => {
    let list = await service.GetAllQuiz()
    SetQuizList(list.data);
  }
  return (

    <Grid container className=" content white_row">
      <Grid item lg={12} xs={12} >
        <FormControl component="fieldset">
          <FormLabel component="legend">Quiz </FormLabel>

          <Grid container item lg={12} xs={12} className="ml20 quizSection">

            <Grid item lg={3} xs={11} >
              <FormControl component="fieldset" className='formControl'>
                <FormLabel component="legend">Quiz Sheet</FormLabel>
                <Autocomplete
                  options={quizList}
                  getOptionLabel={(option) => option.quiz_title || ""}
                  className="selectQuizdetails"
                  value={props.quizSelected}
                  onChange={(e, value) => {
                    if (value !== null) {
                      SetPassingScore(value?.passing_score);
                      setquizIdActive(value?.quiz_id)
                      props.SetQuizId(value?.quiz_id);
                      props.SetQuizSelected(value);
                    } else {
                      SetPassingScore('0');
                      setquizIdActive('')
                      props.SetQuizId(0);
                      props.SetQuizSelected([]);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} name="subtype" size="small" InputLabelProps={{ shrink: false }} variant="outlined" />}
                />
              </FormControl>
            </Grid>

            <Grid item lg={2} xs={11} className="quizTextfield ml10" >
              <FormControl component="fieldset" className='formControl'>
                <FormLabel component="legend">Score to Pass</FormLabel>
                <TextField id="standard-full-width" className='textfiledBxModal ' value={passingScore} size="small" variant="outlined" margin="normal" InputLabelProps={{ shrink: true, }} disabled />
              </FormControl>
            </Grid>

            <Grid item lg={2} xs={11} className="quizTextfield ml10" >
              <FormControl component="fieldset" className='formControl'>
                <FormLabel component="legend">Quiz Credit</FormLabel>
                <TextField id="standard-full-width" className='textfiledBxModal ' value={creditScore}  onChange={(event)=> { SetCreditScore(event.target.value) }} size="small" variant="outlined" margin="normal" InputLabelProps={{ shrink: true, }} />
              </FormControl>
            </Grid>

            <Tooltip title = "Once this is enabled user will not be able to skip the content and user can attempt the quiz after the end of the content" placement='top' arrow>
            <Grid item lg={2} xs={11} className="alignCenter">
              <FormControl component="fieldset" className='formControl'>
                <FormLabel component="legend">Content Quiz Mandatory</FormLabel>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </FormControl>
            </Grid>
            </Tooltip>
            <Grid item lg={1} xs={2}  >
              <FormControl component="fieldset" className='formControl'>
                <FormLabel component="legend"></FormLabel>
                <Button variant="outlined" className='createBtn' onClick={async () => { await handleSave(); }}>Save</Button>
              </FormControl>
            </Grid>

          </Grid>
          <ContentModal modalFunc={modalFunc} sendDataToModal={sendDataToModal} />



        </FormControl>
      </Grid>

    </Grid>

  )
}
export default ContentQuiz