import axios from 'axios';

class AppFooterService {

    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL;
        this.authHeader = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationToken': localStorage.getItem('jwtToken')
            }
        };
    }
    
    async GetAffiliateByQuery(params) {
        let qs = "?status=Active";
        if(params) {
            qs += "";
            if(params.affiliate_id) {
                qs += '&country_name='+ params.affiliate_id;
            }
        }
        
        if(!localStorage.getItem('jwtToken')){
            throw new Error('Access Token Not Available');
        }
        if( params._affiliateSelector.affiliates){
            if(Array.isArray(params._affiliateSelector.affiliates.payload)){
                return {data : params._affiliateSelector.affiliates.payload}
            }
        }
        let data = await axios.post(this.baseUrl + '/management/api/user/GetAffiliateByQuery' + qs, {}, this.authHeader);
        if(Array.isArray(data.data)){
            if(data.data.length > 0){
                if(params._dispatch &&params._setAffiliate ){
                    params._dispatch(params._setAffiliate(data.data));
                }
                
            }
            
        }
        return data;
    
    }
}
export { AppFooterService }