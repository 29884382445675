import { UserProfileService } from '../../user/Services/user-profile-service';

class UserBrandService {

  constructor() {
    this._userProfileService = new UserProfileService();
  }

  async getUserSelectedBrands(sessionState, SetTaIdsArrData, SetBrandIdsArrData, SetIndIdsArrData, SetUserBrandFilterData) {
    if (sessionState.sessionValue?.payload?.loginroute) {
      if (sessionState.sessionValue.payload.loginroute === "passport") {
        let param = "id=" + sessionState.sessionValue.payload.user.id
        let data = await this._userProfileService.GetUsersByQuery(param)
        if (data.data.length !== 0) {
          let userTA = data.data[0].therapeutic_area;
          let categories = userTA && Array.isArray(userTA) ? userTA : [];
          let brandIdsArr = [];
          let taIdsArr = [];
          let indIdsArray = [];
          let countryTAList = [];
          let countryCat1Ids = [];
          let countryCat2Ids = [];
          if(Array.isArray(sessionState.sessionValue.payload.affiliate)){
            for(let k=0;k<sessionState.sessionValue.payload.affiliate.length;k++){
              if(Array.isArray(sessionState.sessionValue.payload.affiliate[k].therapeutic_area_ids)){
                countryTAList = countryTAList.concat(sessionState.sessionValue.payload.affiliate[k].therapeutic_area_ids);
              }
              if(sessionState.sessionValue.payload.affiliate[k].speciality_customization){
                if(Array.isArray(sessionState.sessionValue.payload.affiliate[k].category_1_ids)){
                  countryCat1Ids = countryCat1Ids.concat(sessionState.sessionValue.payload.affiliate[k].category_1_ids);
                }
                if(Array.isArray(sessionState.sessionValue.payload.affiliate[k].category_2_ids)){
                  countryCat2Ids = countryCat2Ids.concat(sessionState.sessionValue.payload.affiliate[k].category_2_ids);
                }
              }
              
            }
          }
          if (categories?.length > 0) {
            for (let i = 0; categories?.length > i; i++) {
              let ta_id = categories[i].therapeutic_area_id;
              if(countryTAList.includes(ta_id)){
                taIdsArr.push(ta_id);
              }
             
              let brand_id = categories[i].brand_id;
              if(countryCat1Ids.includes(brand_id)){
                brandIdsArr.push(brand_id);
              }
             
              let ind_id = categories[i].indication_id;
              if(countryCat2Ids.includes(ind_id)){
                if (ind_id !== null) {
                  indIdsArray.push(ind_id);
                }
              }
              
            }
          }
          SetTaIdsArrData(taIdsArr);
          SetBrandIdsArrData(brandIdsArr);
          SetIndIdsArrData(indIdsArray);
          SetUserBrandFilterData(true);
        }
      } else {
        let param = "user_email=" + sessionState.sessionValue.payload.user.user_email;
        let data = await this._userProfileService.GetUsersByQuery(param);
        if (data.data.length !== 0) {
          let userTA = data.data[0].therapeutic_area;
          let categories = userTA && Array.isArray(userTA) ? userTA : [];
          let brandIdsArr = [];
          let taIdsArr = [];
          let indIdsArray = [];
          if (categories?.length > 0) {
            for (let i = 0; categories?.length > i; i++) {
              let ta_id = categories[i].therapeutic_area_id;
              taIdsArr.push(ta_id);
              let brand_id = categories[i].brand_id;
              brandIdsArr.push(brand_id);
              let ind_id = categories[i].indication_id;
              if (ind_id !== null) {
                indIdsArray.push(ind_id);
              }
            }
          }
          SetTaIdsArrData(taIdsArr);
          SetBrandIdsArrData(brandIdsArr);
          SetIndIdsArrData(indIdsArray);
          SetUserBrandFilterData(true);
        }
      }
    }
  }
}

export { UserBrandService }