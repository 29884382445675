import axios from 'axios';

// const authHeader = {
//   headers: {
//     'Content-Type': 'application/json',
//     'authorizationtoken': localStorage.getItem('jwtToken')
//   }
// };
// export const getContentList = (filter) => {
//   return axios.post(process.env.REACT_APP_BASE_URL + '/home/api/content/ShowMoreData/1/100', {
//     category_id : filter
// }, authHeader);
// };

// export const getContentListCount = (startCount, endCount, filter) => {
//   return axios.post(process.env.REACT_APP_BASE_URL + `/home/api/content/ShowMoreData/${startCount}/${endCount}`, {
//     category_id : filter
// }, authHeader);
// };

class HomeServiceBaseMatchingModel{
  constructor(_country='',_library='',_specialty='',_profession='',_ta=[],_status='',_cat1=[],_cat2=[],_countryName, _localeName, _loginRoute){
      this.country = _country;
      this.library = _library;
      this.specialty = _specialty;
      this.profession = _profession;
      this.ta = _ta;
      this.status = _status;
      this.cat1 = _cat1;
      this.cat2 =_cat2;
      this.countryName = _countryName;
      this.localeName =_localeName;
      this.loginRoute = _loginRoute;
  }
}

class ContentShowService {

  constructor(jwtToken = null) {
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.authHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'authorizationtoken': jwtToken ? jwtToken : localStorage.getItem('jwtToken')
      }
    };
  }

  getContentList(filter, country, library, model) {
    return axios.post(process.env.REACT_APP_BASE_URL + '/home/api/content/ShowMoreData/0/1000000', model, this.authHeaders);
  };

  getContentListCount(startCount, endCount, filter, country, library, model) {
    return axios.post(process.env.REACT_APP_BASE_URL + `/home/api/content/ShowMoreData/${startCount}/${endCount}`, model, this.authHeaders);
  };

  async GetLiveEvents(category_id,country,library)
  {        
      let data = await axios.post(process.env.REACT_APP_BASE_URL +'/content/api/event/GetLiveEvents?category_id=' + category_id+'&country='+country+"&library="+library, {category_id : category_id,country:country,library:library}, this.authHeaders);
      return data.data;
  }


}

export { ContentShowService, HomeServiceBaseMatchingModel }