import { createSlice } from '@reduxjs/toolkit';


export const AffiliateSlice = createSlice({
    name : 'affiliate',
    initialState : {
        affiliates : []
        
    },
    reducers : {
        setAffiliate: (state, action) => {
            state.affiliates = action;
        },
    }

});

export const {setAffiliate} = AffiliateSlice.actions;
export default AffiliateSlice.reducer;
























