




class GoogleAnalyticsService
{
    constructor()
    {

    }
    NavigatedToVideoFromSearchPage(ta){
        let model = {
            event : 'NavigatedToVideoFromSearchPage',
            eventCategory : 'Navigated to video',
            eventDescription :'',// 'Navigated to video from TA Homepage',
            // videoSelectedID : videoId,
            eventAction : 'Search Results Page Origin',
            eventLabel: ta
        };
        this.PushObjectInDatalayer(model);
    }
    pad(num) {
        let norm = Math.abs(Math.floor(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    HitTimeStamp(){
        let now = new Date();
        let tzo = -now.getTimezoneOffset();
        let dif = tzo >= 0 ? '+' : '-';
        
    return now.getFullYear() 
        + '-' + this.pad(now.getMonth()+1)
        + '-' + this.pad(now.getDate())
        + ' T ' + this.pad(now.getHours())
        + ':' + this.pad(now.getMinutes()) 
        + ':' + this.pad(now.getSeconds())
        + '.' + this.pad(now.getMilliseconds())
        + dif + this.pad(tzo / 60) 
        + ':' + this.pad(tzo % 60);
    }
    CreateVirtualPageViewEventModel()
    {
        let model = {
            event : 'virtualPageView',
              googleanalyticsValue :'',// gaValue,
              hotjarValue :'',// hjValue, 
              specialtyArray:[],
              brandArray:[],
              pageUrl:window.location.href,
              videoId:''//self.context.videoId
        }
        return model;
    }
    PushSingleVariables(_key, value)
    {
        let x = {};
        x[_key] = value;
        let hitTimeStamp = new Date();
        x.hitTimeStamp =this.HitTimeStamp();
        x.country = localStorage.getItem('country');
        window.dataLayer.push(x);
        return true;
    }
    PushObjectInDatalayer(model)
    {
        let hitTimeStamp = new Date();
        model.hitTimeStamp =this.HitTimeStamp();
        model.country = localStorage.getItem('country');
        window.dataLayer.push(model);
        return true;
    }
}
export {GoogleAnalyticsService}