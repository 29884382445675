import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Telegram from '../../../public/img/send-01.svg';
import UploadIcon from '../../../public/img/upload-03.svg';
import MinusIcon from '../../../public/img/minus-circle.svg';
import TelegramIcon from '../../../public/img/send-01-red.svg';
import ArrowIcon from '../../../public/img/arrow-circle-red.svg';
import RedMinusIcon from '../../../public/img/minus-circle-red.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const ContentPublish = (props) => {
    let selectorValue = useSelector(state => state.session);

    const [activeContentSelected, setActiveContentSelected] = React.useState({});
    const [sessionState, SetSessionState] = React.useState(selectorValue);
    const [countryPublisher, SetcountryPublisher] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [popupContent, setPopupContent] = React.useState({
        heading: 'Publish content',
        subtext: 'Do you want to Publish the following content?',
        buttontext: 'Publish',
        changecase: 'publish'
    })


    const [status, statusArray] = React.useState({
        'Active': "Published",
        'Published': "Published",
        'Draft': "Draft",
        'Inactive': "Inactive",
        '': 'Draft',
        'Pending': 'Pending',
        'Rejected': 'Rejected'
    })

    React.useEffect(() => {
        const publishCOuntry = sessionState.sessionValue?.payload?.affiliate.map((e) => e.country_code);
        SetcountryPublisher(publishCOuntry)
    }, [props.affiliateToPublish])

    React.useEffect(() => {
        let affTopblsh = JSON.parse(JSON.stringify(props.affiliateToPublish))
        for (let i = 0; i < props.affiliatesName?.length; i++) {
            if (!props.affiliateToPublish[props?.affiliatesName[i]?.country_code]) {
                affTopblsh[props?.affiliatesName[i]?.country_code] = { name: props?.affiliatesName[i]?.country_name, status: "Draft" }
            }
        }
        props.setAffiliateToPublish(affTopblsh)

    }, [props.affiliatesName])

    const handlelistChange = (affiliate, status, prevstatus) => {
        const list = JSON.parse(JSON.stringify(props.affiliateToPublish))
        list[affiliate?.country_code] = { name: affiliate?.country_name, status: status, prevstatus: prevstatus }
        props.setAffiliateToPublish(list)
        setOpen(false)
    }
    return (
        <>
            <Dialog open={open} className='status-modal content-publish-modal' >
                <div className='modal-heading'>
                    <div className='info-modal'>
                        {popupContent.changecase === 'publish' ?
                            <img src={TelegramIcon} alt='publish-icon' />
                            : null
                        }
                        {popupContent.changecase === 'inactive' ?
                            <img src={RedMinusIcon} alt='inactive-icon' />
                            : null
                        }
                        {popupContent.changecase === 'reject' ?
                            <img src={RedMinusIcon} alt='reject-icon' />
                            : null
                        }
                        {popupContent.changecase === 'submit' ?
                            <img src={ArrowIcon} alt='submit-icon' />
                            : null
                        }
                    </div>
                    <div className='modal-heading-text'>
                        <div>{popupContent.heading}</div>
                        <div className='modal-heading-subtext'>{popupContent.subtext}</div>
                    </div>
                    <div className='close-modal'>
                        <IconButton
                            aria-label="close"
                            onClick={() => { setOpen(false) }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <DialogContent className='modal-content'>
                    <div className='grey-text' >
                        <div><strong>{props.title ? props.title : ''}</strong></div>
                        <div>{props.type ? props.type.charAt(0).toUpperCase() + props.type.slice(1) : ''}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='modal-actions'>
                        <button className="modal-cancel" onClick={() => { setOpen(false) }}>Cancel</button>
                        <button onClick={() => { handlelistChange(activeContentSelected, activeContentSelected.currentStatus, activeContentSelected.prevStatus) }}>
                            {popupContent.buttontext}
                        </button>
                    </div>
                </DialogActions>
            </Dialog>

            <div className='country-to-publish-section'>
                <div className='content-field'>
                    <label className='input-label'>
                        Country to Publish
                    </label>
                    <label className='helper-text'>
                        To save the changed status, click on the next button
                    </label>
                </div>
                <div className='publish-status-box'>
                    <div className='publish-status-box-header'>
                        <div>
                            Country
                        </div>
                        <div>
                            Status
                        </div>
                        <div className='invisible'>
                            <span>
                                Action
                            </span>
                        </div>
                    </div>
                    <div className='publish-status-box-body'>
                        {props.affiliatesName.length > 0 ? props.affiliatesName.map((affiliate, index) => {
                            affiliate = props.affiliatesName[index];
                            return (
                                <>
                                    <div className='table-row'>
                                        <div className='table-cell'>
                                            {affiliate?.country_name}
                                        </div>
                                        <div className='table-cell'>
                                            <div class="content-status">
                                                <div class={"single-content " + status[props.affiliateToPublish[affiliate?.country_code]?.status] || 'Draft'}>
                                                    <div class="dot"></div>
                                                    <div class="text">{status[props.affiliateToPublish[affiliate?.country_code]?.status] || 'Draft'}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='action-items'>
                                                <div>
                                                    {(status[props.affiliateToPublish[affiliate?.country_code]?.status] === 'Draft') && countryPublisher.includes(affiliate?.country_code) ?
                                                        (
                                                            <div className='action-icon'
                                                                onClick={() => {
                                                                    setPopupContent(((prevState) => ({
                                                                        ...prevState,
                                                                        heading: 'Submit to Publish',
                                                                        subtext: 'Do you want to Publish the following content?',
                                                                        buttontext: 'Submit',
                                                                        changecase: 'submit'
                                                                    })));
                                                                    setOpen(true);
                                                                    setActiveContentSelected({ ...affiliate, currentStatus: 'Active', prevStatus: 'Draft' })
                                                                }}
                                                            >
                                                                <Tooltip title={"Submit to Publish"} placement='bottom' arrow>
                                                                    <img src={UploadIcon} alt='upload-icon' />
                                                                </Tooltip>
                                                            </div>
                                                        ) :
                                                        null
                                                    }
                                                    {(status[props.affiliateToPublish[affiliate?.country_code]?.status] === 'Draft') && !countryPublisher.includes(affiliate?.country_code) ?
                                                        (
                                                            <div className='action-icon'
                                                                onClick={() => {
                                                                    setPopupContent(((prevState) => ({
                                                                        ...prevState,
                                                                        heading: 'Submit for Approval',
                                                                        subtext: 'Do you want to Submit the following content for approval?',
                                                                        buttontext: 'Submit',
                                                                        changecase: 'submit'
                                                                    })));
                                                                    setOpen(true);
                                                                    setActiveContentSelected({ ...affiliate, currentStatus: 'Pending', prevStatus: 'Draft' })
                                                                }}>
                                                                <Tooltip title={"Submit for Approval"} placement='bottom' arrow>
                                                                    <img src={UploadIcon} alt='upload-icon' />
                                                                </Tooltip>
                                                            </div>
                                                        ) :
                                                        null
                                                    }
                                                    {(status[props.affiliateToPublish[affiliate?.country_code]?.status] === 'Pending') && countryPublisher.includes(affiliate?.country_code) ?
                                                        (
                                                            <>
                                                                <div className='no-bg action-icon '
                                                                    onClick={() => {
                                                                        setPopupContent(((prevState) => ({
                                                                            ...prevState,
                                                                            heading: 'Reject',
                                                                            subtext: 'Do you want to Reject the following content?',
                                                                            buttontext: 'Reject',
                                                                            changecase: 'reject'
                                                                        })));
                                                                        setOpen(true);
                                                                        setActiveContentSelected({ ...affiliate, currentStatus: 'Rejected', prevStatus: 'Pending' })
                                                                    }}>
                                                                    <Tooltip title="Reject" placement='bottom' arrow>
                                                                        <img src={MinusIcon} alt='unpublish-icon' />
                                                                    </Tooltip>
                                                                </div>

                                                                <div className='action-icon'
                                                                    onClick={() => {
                                                                        setPopupContent(((prevState) => ({
                                                                            ...prevState,
                                                                            heading: 'Publish content',
                                                                            subtext: 'Do you want to Publish the following content?',
                                                                            buttontext: 'Publish',
                                                                            changecase: 'publish'
                                                                        })))
                                                                        setOpen(true);
                                                                        setActiveContentSelected({ ...affiliate, currentStatus: 'Active', prevStatus: 'Pending' })
                                                                    }}
                                                                >
                                                                    <Tooltip title="Publish" placement='bottom' arrow>
                                                                        <img src={Telegram} alt='publish-icon' />
                                                                    </Tooltip>
                                                                </div>
                                                            </>
                                                        ) : null
                                                    }
                                                    {(status[props.affiliateToPublish[affiliate?.country_code]?.status] === 'Published') && countryPublisher.includes(affiliate?.country_code) ?
                                                        (<div className='no-bg action-icon '
                                                            onClick={() => {
                                                                setPopupContent(((prevState) => ({
                                                                    ...prevState,
                                                                    heading: 'Inactivate content',
                                                                    subtext: 'Do you want to Inactivate the following content?',
                                                                    buttontext: 'Inactivate',
                                                                    changecase: 'inactive'
                                                                })))
                                                                setOpen(true)
                                                                setActiveContentSelected({ ...affiliate, currentStatus: 'Inactive', prevStatus: 'Active' })
                                                            }}>
                                                            <Tooltip title="Inactive" placement='bottom' arrow>
                                                                <img src={MinusIcon} alt='unpublish-icon' />
                                                            </Tooltip>
                                                        </div>) :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </>
                            )
                        }) : null}
                    </div>
                </div>
            </div >
        </>
    )
}
export default ContentPublish