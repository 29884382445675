import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const Speciality = (props) => {
    const handleInput = (field, value, index, speciality_obj) => {
        let specialitiesArr = [...props.specialities];
        specialitiesArr[index] = speciality_obj;
        if(field === "specialty_key") {
            specialitiesArr[index].specialty_key = value;
        }
        if(field === "specialty_name") {
            specialitiesArr[index].specialty_name = value;
        }
        props.SetSpecialities( specialitiesArr );
    }
    const addSpeciality = (speciality_obj) => {
        props.SetSpecialities([...props.specialities, speciality_obj])
    }
    const removeSpeciality = (speciality_obj) => {
        var array = [...props.specialities];
        const filteredArray = array.filter(item => item.specialty_id !== speciality_obj.specialty_id);
        props.SetSpecialities(filteredArray);
    }
  return (
    <>
    <Grid container className='content'>
        <Grid container item xs={12} lg={12} spacing={2}>
            <Grid item xs={12} lg={12}>
                <FormControl component="fieldset">
                    <FormLabel component="label">Specific Speciality</FormLabel>
                </FormControl>
            </Grid>
            <Grid item xs={12} lg={12}>
                {props.specialities.map((speciality,index) => {
                    return (
                        <Grid container spacing={2} key={speciality.specialty_id}>
                            <Grid item xs={5} lg={5}>
                                <TextField fullWidth value={speciality.specialty_key} onChange={(e) => handleInput('specialty_key', e.target.value, index, speciality)} placeholder="key" size="small" variant="outlined" InputLabelProps={{ shrink: false }} />
                            </Grid>
                            <Grid item xs={5} lg={5}>
                                <TextField fullWidth value={speciality.specialty_name} onChange={(e) => handleInput('specialty_name', e.target.value, index, speciality)} placeholder="value" size="small" variant="outlined" InputLabelProps={{ shrink: false }} />
                            </Grid>
                            <Grid item xs={2} lg={2}>
                                <button className='iconImg removeIcon' onClick={() => removeSpeciality(speciality)}></button>
                            </Grid>
                        </Grid>
                    )
                })}
                <Grid className='itemAdd' onClick={() => addSpeciality({})}>
                    <img src={require('../../../public/img/Add@3x.png')} className='itemAddIcon' alt='img' />
                    <span>Add a Specific Speciality</span>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    </>
  )
}

export default Speciality