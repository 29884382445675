import React from 'react';

import BannerLogo from '../../../public/img/responsive_banner.png';
import LillyPlayLogo from '../../../public/img/logo-lilyplay.png';

const UK = (props) => {
  React.useEffect(() => {
    let head = document.head;
    let link1 = document.createElement("link");
    link1.type = "text/css";
    link1.rel = "stylesheet";
    link1.href = window.location.origin +"/css/lilyplay.webflow.css";
    head.appendChild(link1);

    let link2 = document.createElement("link");
    link2.type = "text/css";
    link2.rel = "stylesheet";
    link2.href = window.location.origin +"/css/webflow.css";
    head.appendChild(link2);

    let link3 = document.createElement("link");
    link3.type = "text/css";
    link3.rel = "stylesheet";
    link3.href = window.location.origin +"/css/landing1.css";
    head.appendChild(link3);
    // document.getElementById('link10').disabled = true
    return () => { 
      head.removeChild(link1); 
      head.removeChild(link2); 
      head.removeChild(link3); 
    }
  }, []);

  return (
    <>
      <div className="navbar">
        <div className="custom-container">
          <div className="w-row">
            <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
              <img src={LillyPlayLogo} alt="" className="logo-navbar" />
              <p className="newLogo">Lilly <span>| Play</span></p>
              <small>Video on Demand</small>
            </div>
            <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
              <div className="button-list-nav">
                <a href name="landing-Login" className="botao-nav au-login" onClick={props.passportLogin}>Sign in</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainContent">
        <div className="mainBanner">
            <div className="lp-content">
                <h3>Lilly Play is a video on demand platform for healthcare professionals.</h3>
                <img className="banner_mobile" src={BannerLogo} alt="banner_mobile"/>
                <div className="button-list-nav">
                    <a href name="landing-Register" className="botao-nav au-signup" onClick={props.passportRegister}>Create your Lilly account</a>
                </div>
                <span className="login">Do you already have an account? <a href name="landing-Login" className="au-login loginLink" onClick={props.passportLogin}>Sign in</a></span>
            </div>
            <div className="banner"><img src={BannerLogo} alt="banner" /></div>
        </div>
      </div>

      <div className="lp-footer">
        <div className="custom-container">
          <div className="w-row">
            <div className="w-col w-col-4 w-col-sm-3 w-hidden-small w-hidden-tiny coluna-logo-footer">
              <img src={LillyPlayLogo} className="logo-footer" alt="Lilly Play" />
            </div>
            <div className="coluna-textos w-col w-col-8 w-col-sm-9">
              <p className="disclaimer">The content of this platform is intended for UK healthcare professionals only. The content is promotional in nature.</p>
              <div>
                <p className="copyright-text">
                  <span className="white">©2021 Eli Lilly and Company. All rights reserved. Lilly is a registered trademark of Eli Lilly and Company.</span>
                </p>
                <p className="termos">
                  <a href="https://www.lillyprivacy.com/uk-en/hcp" className="link-2" rel="noreferrer" target="_blank"><span className="white">Privacy Policy</span></a>
                  <span className="white"> | </span>
                  <a href="https://www.lilly.co.uk/terms-of-use" className="link" rel="noreferrer" target="_blank"><span className="white">Terms of Use</span></a>
                  <span className="white"> | </span>
                  <a href="https://www.lilly.co.uk/copyright" className="link" rel="noreferrer" target="_blank"><span className="white">Copyright</span></a>
                  <span className="white"> | </span>
                  <a href="https://www.lilly.co.uk/accessibility-statement" className="link" rel="noreferrer" target="_blank"><span className="white">Accessibility Statement</span></a>
                  <span className="white"> | </span>
                  <a href className="link" rel="noreferrer" target="_blank" onClick={props.cookieSettingBtnHandler}><span className="white">Cookie Settings</span></a>
                  <br />
                  PP-MG-GB-0249 Date of Preparation: Jun 2021
                </p>
              </div>
            </div>
            <div className="w-col w-col-4 w-col-sm-3 visible-xs">
              <img src={LillyPlayLogo} className="logo-footer center-block" alt="Lilly Play" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UK