/* eslint-disable eqeqeq */
/* eslint-disable no-unreachable */

import axios from 'axios';
import FormData from 'form-data'
import {KalturaMediaEntry} from '../ServiceModels/KalturaServiceModels';
import {GoogleAnalyticsService} from '../../../GA/GoogleAnalyticsService';
import config from '../../../../config/config.json';
class KalturaService
{
    constructor()
    {
        this.authHeader = {
            headers: {
                'Content-Type': 'application/json',
                'authorizationtoken': localStorage.getItem('jwtToken')
            }
        };
        this.ks = localStorage.getItem('ks');
        if(!localStorage.getItem('ks')){
            // this.GetKalturaSession()
        }
        this._gaService = new GoogleAnalyticsService();
    }

    async GetKalturaSession()
    {
        let data = await axios.post(process.env.REACT_APP_BASE_URL + '/content/api/kaltura/StartKalturaSession',{},this.authHeader);
        let ks = data.data;
        localStorage.setItem('ks', ks);
        this.ks= ks;
    }
    async GetKalturaUploadToken()
    {
        let uri = config?.kalturaUrl + '/uploadtoken/action/add';
        let formData = new FormData();
        formData.append('ks',this.ks)
        formData.append('format', 1);
        let data = await axios.post(uri, formData);
        return data.data && data.data.id ? data.data.id : null;
    }
    async GetMediaEntry()
    {
        let uri = config?.kalturaUrl + '/media/action/add';
        let formData = new FormData();
        let testmediaEntry = new KalturaMediaEntry('', '');
        formData.append('ks',this.ks)
        formData.append('entry[Array]', testmediaEntry);
        formData.append('entry[description]', testmediaEntry.description)
        formData.append('entry[name]', testmediaEntry.name)
        formData.append('entry[mediaType]', testmediaEntry.mediaType)
        formData.append('entry[categoriesIds]', '355798933');
        formData.append('format', 1);
        let data = await axios.post(uri, formData);
        return data.data && data.data.id ? data.data.id : null;
    }

    async MapUploadTokenAndKalturaId(uploadToken, kalturaId)
    {
        let uri = config?.kalturaUrl + '/media/action/addContent';
        let formData = new FormData();
        formData.append('ks',this.ks);
        formData.append('entryId',kalturaId);
        formData.append('resource[objectType]','KalturaUploadedFileTokenResource');
        formData.append('resource[token]',uploadToken)
        formData.append('format', 1);
        let data = await axios.post(uri, formData);
        return data.data;
    }
    async UploadFile(file, uploadToken, kalturaId, SetKalturaId, SetThumbnailUrl, SetUploadPercentage, SetDuration,
        SetShowPreviewOfVideo)
    {
        let uri = config?.kalturaUrl + '/uploadtoken/action/upload';
        let formData = new FormData();
        formData.append('ks',this.ks);
        formData.append('uploadTokenId',uploadToken);
        formData.append('fileData',file);
        formData.append('resume',false);
        formData.append('finalChunk',true);
        formData.append('resumeAt',-1);
        formData.append('format', 1);
        const configLink = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                SetUploadPercentage(percentCompleted);
            }
        }
        let data = await axios.post(uri, formData, configLink);
        let mappingData = await this.MapUploadTokenAndKalturaId(uploadToken, kalturaId)
        if(mappingData) {
            SetThumbnailUrl(mappingData.thumbnailUrl + '/width/420/height/320/quality/100');
            SetKalturaId(mappingData.id);
        }
        await this.PollMediaStatusAPI(kalturaId, SetShowPreviewOfVideo, SetDuration);
        
        return data;
    }
    async PollMediaStatusAPI(kalturaId, SetShowPreviewOfVideo, SetDuration){
        const videoDetails = await this.GetVideoDetails(kalturaId);
        const isVideoProcessed = videoDetails && videoDetails.status === 2;

        if(!isVideoProcessed){
            setTimeout( ()=>{
                this.PollMediaStatusAPI(kalturaId, SetShowPreviewOfVideo, SetDuration);
            },2000)
        }
        else{
            SetShowPreviewOfVideo(true);
            SetDuration(videoDetails.duration);
            
            this._gaService?.PushSingleVariables('videoTime', videoDetails.duration);
            this._gaService?.PushSingleVariables('videoLength', videoDetails.duration);
        }
        return;
    }
    async GetVideoDetails(kalturaId){
        try {
            let uri = config?.kalturaUrl + '/media/action/get';
            let formData = new FormData();
            formData.append('entryId',kalturaId);
            formData.append('format', 1);
            formData.append('version', -1);
            formData.append('ks',this.ks);
            const response = await axios.post(uri, formData);
            if (response.status === 200) {
                return response.data;
            } else {
                return null;
            }
        }catch(err){
            return null;
        }
    }
    async CheckValidKalturaID(kalturaId, SetDuration = null)
    {
        let uri = config?.kalturaUrl + '/media/action/list';
        let formData = new FormData();
        formData.append('filter[idEqual]',kalturaId);
        formData.append('format', 1);
        formData.append('ks',this.ks);
        let data = await axios.post(uri, formData);
        let mediaModel = data.data;
        if(Array.isArray(mediaModel?.objects)){
            for(let i =0;i<mediaModel?.objects.length;i++ ){
                let duration = mediaModel?.objects[i].duration;
                if(parseInt(duration)>0){
                    this._gaService.PushSingleVariables('videoTime', duration);
                    this._gaService.PushSingleVariables('videoLength', duration);
                    if(SetDuration){
                        SetDuration(duration);
                        break;
                    }
                    
                }
                
            }
        }
        return data.data;
    }
    async UploadKalturaThumbnail(kalturaId, file)
    {
        let uri = config?.kalturaUrl + '/thumbasset/action/addFromImage';
        let formData = new FormData();
        formData.append('fileData',file);
        formData.append('entryId',kalturaId);
        formData.append('ks',this.ks);
        formData.append('format', 1);
        let data = await axios.post(uri, formData);
        return data;
    }
    async UploadSubtitleFile(file, uploadToken, entryId, language)
    {
        let uri = config?.kalturaUrl + '/uploadtoken/action/upload';
        let formData = new FormData();
        formData.append('ks',this.ks);
        formData.append('uploadTokenId',uploadToken);
        formData.append('fileData',file);
        formData.append('resume',false);
        formData.append('finalChunk',true);
        formData.append('resumeAt',-1);
        formData.append('format', 1);
        let data = await axios.post(uri, formData);
        let captionAsset = await this.createCaptionAsset(entryId, language);
        if(captionAsset) {
            let captionContent = await this.createCaptionContent(captionAsset.id, uploadToken);
        }
        return data;
    }
    async createCaptionAsset(entryId, language)
    {
        let uri = config?.kalturaUrl + '/caption_captionasset/action/add';
        let formData = new FormData();
        formData.append('ks',this.ks);
        formData.append('entryId',entryId);
        formData.append('captionAsset[objectType]','KalturaCaptionAsset');
        formData.append('captionAsset[format]',1);
        formData.append('captionAsset[language]',language);
        formData.append('format', 1);
        let data = await axios.post(uri, formData);
        return data.data;
    }
    async createCaptionContent(captionId, uploadToken)
    {
        let uri = config?.kalturaUrl + '/caption_captionasset/action/setContent';
        let formData = new FormData();
        formData.append('ks',this.ks);
        formData.append('id',captionId);
        formData.append('contentResource[token]',uploadToken);
        formData.append('contentResource[objectType]','KalturaUploadedFileTokenResource');
        formData.append('format', 1);
        let data = await axios.post(uri, formData);
        return data.data;
    }
}
export {KalturaService}