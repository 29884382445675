import React from 'react';
import BannerLogo from '../../../public/img/responsive_banner.png';
import LillyPlayLogo from '../../../public/img/logo-lilyplay.png';

const NewZealand = (props) => {
  React.useEffect(() => {
    var head = document.head;
    var link1 = document.createElement("link");
    link1.type = "text/css";
    link1.rel = "stylesheet";
    link1.href = window.location.origin +"/css/lilyplay.webflow.css";
    head.appendChild(link1);

    var link2 = document.createElement("link");
    link2.type = "text/css";
    link2.rel = "stylesheet";
    link2.href = window.location.origin +"/css/webflow.css";
    head.appendChild(link2);

    var link3 = document.createElement("link");
    link3.type = "text/css";
    link3.rel = "stylesheet";
    link3.href = window.location.origin +"/css/landing1.css";
    head.appendChild(link3);
    // document.getElementById('link10').disabled = true
    return () => { 
      head.removeChild(link1); 
      head.removeChild(link2); 
      head.removeChild(link3); 
    }
  }, []);
  return (
    <>
        {/* <link rel="stylesheet" href={window.location.origin +"/css/lilyplay.webflow.css"}></link>
        <link rel="stylesheet" href={window.location.origin +"/css/webflow.css"}></link>
        <link rel="stylesheet" href={window.location.origin +"/css/landing1.css"}></link> */}

        <div className="navbar">
          <div className="custom-container">
            <div className="w-row">
              <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
                <img src={LillyPlayLogo} alt="Lilly Play" className="logo-navbar" />
                <p className="newLogo">Lilly <span>| Play</span></p>
                <small>&nbsp;</small>
              </div>
              <div className="w-col w-col-6 w-col-medium-6 w-col-small-6 w-col-tiny-6">
                <div className="button-list-nav">
                  <a href name="landing-Login" className="botao-nav au-login" onClick={props.passportLogin}>Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mainContent">
          <div className="mainBanner">
              <div className="lp-content">
                  <h3>Welcome to Lilly Play New Zealand</h3>
                  <img className="banner_mobile" src={BannerLogo} alt="banner_mobile"/>
                  <p><span>Lilly Play</span> is a video platform with scientific content, to access <span>anywhere, anytime, and all free.</span></p>
                  <div className="button-list-nav">
                      <a href name="landing-Register" className="botao-nav au-signup" onClick={props.passportRegister}>Register Now</a>
                  </div>
                  <span className="login">Already registered? <a href name="landing-Login" className="au-login loginLink" onClick={props.passportLogin}>Log in here</a></span>
              </div>
              <div className="banner"><img src={BannerLogo} alt="banner" /></div>
          </div>
          <div className="featureList">
              <div className="feature first">
                  <h3>An on demand platform to access exclusive videos.</h3>
                  <p>Lectures with specialists, debates, studies and research, <b>with updated content and without advertising.</b></p>
              </div>
              <div className="feature second">
                  <h3>Lilly Play is accessible across various devices.</h3>
                  <p>Watch our videos on mobile, tablet or computer.</p>
              </div>
          </div>
        </div>

        <div className="lp-footer">
          <div className="custom-container">
            <div className="w-row">
              <div className="w-col w-col-4 w-col-sm-3 w-hidden-small w-hidden-tiny coluna-logo-footer">
                <img src={LillyPlayLogo} className="logo-footer" alt="" />
              </div>
              <div className="coluna-textos w-col w-col-8 w-col-sm-9">
                <p className="disclaimer">This website is intended for New Zealand Healthcare Professionals only.</p>
                <div>
                  <p className="copyright-text">
                    <span className="white">©2020 Copyright Eli Lilly and Company. Eli Lilly and Company (NZ) Limited. NZBN: 9429039560643. Level 27, 88 Shortland Street, Auckland, 1010, New Zealand.</span>
                  </p>
                  <p className="termos">
                    <a href="https://www.lilly.co.nz/terms-of-use" className="link-2" rel="noreferrer" target="_blank"><span className="white">Terms of Use</span></a>
                    <span className="white"> | </span>
                    <a href="https://www.lillyprivacy.com/nz-en/hcp" className="link" rel="noreferrer" target="_blank"><span className="white">Privacy Policy</span></a>
                    <br />
                    PP-LILLY-AU-0096 Date of Preparation: October 2020
                  </p>
                </div>
              </div>
              <div className="w-col w-col-4 w-col-sm-3 visible-xs">
                <img src={LillyPlayLogo} className="logo-footer center-block" alt="Lilly Play" />
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default NewZealand